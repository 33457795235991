<click-to-resume></click-to-resume>
<lux></lux>
<div *ngIf="isMobile == false">
  <keyboard></keyboard>
  <webgl-gameview></webgl-gameview>
</div>
<div *ngIf="isMobile == true">
  <keyboard-mobile></keyboard-mobile>
  <html5-gameview></html5-gameview>
</div>
