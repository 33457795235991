import { Injectable } from '@angular/core';
import { Player } from '../../model/player';
import { AuthenticationService } from '../http/authentication.service';
import { User } from '../../model/user';
import { AppConfigService } from 'src/app/services/config/app-config.service';

@Injectable({
  providedIn: 'root',
})
export class PlayerService {
  player: Player;
  LS_USER_KEY: 'token';
  LS_LANG_KEY: 'lang';
  LS_GAME_KEY: 'game';
  UNKNOWN_LOCATION: 'xx';
  session = true;

  constructor(
    private authService: AuthenticationService,
    private appConfig: AppConfigService
  ) {}

  init(): void {
    this.session = true;
    this._newPlayer();
    if (this.appConfig.DEBUG) {
      console.log(
        'currentUser: ' +
          JSON.stringify(this.authService.currentUserValue, null, 4)
      );
    }
    if (this.authService.currentUserValue !== null) {
      this.setPlayer(this.authService.currentUserValue);
    }
  }

  _newPlayer(): void {
    this.player = new Player();
    this.player.location = this.UNKNOWN_LOCATION;
    this.player.id = 0;
    this.player.token = '';
    this.player.gameid = '';
    this.player.username = '';
    this.player.name = 'Guest';
    this.player.position = 0;
    this.player.ranking = '';
    this.player.connected = false;
  }

  setPlayer(user: User): void {
    this.player.provider = user.provider_type;
    this.player.name = user.name;
    // this.player.id = user.provider_id;
    this.player.gameid = user.game;
    this.player.connected = true;
    this.player.token = user.jwt;
  }

  resetPlayer(): void {
    this._newPlayer();
  }

  get token(): string {
    return this.player.jwt;
  }

  set token(value: string) {
    this.player.jwt = value;
  }

  get baseScore(): number {
    return this.player.baseScore;
  }

  set baseScore(value: number) {
    this.player.baseScore = value;
  }
  get providerId(): number {
    return this.player.id;
  }

  set providerId(value: number) {
    this.player.id = value;
  }

  resetGameID(): void {
    this.player.gameid = '';
  }

  get avatar(): string {
    return this.player.picture;
  }

  set avatar(value: string) {
    this.player.picture = value;
  }
  get name(): string {
    return this.player.name;
  }

  set name(value: string) {
    this.player.name = value;
  }

  get location(): string {
    return this.player.location;
  }

  set location(value: string) {
    this.player.location = value;
  }

  get isConnected(): boolean {
    return this.player.connected;
  }

  set isConnected(value: boolean) {
    this.player.connected = value;
  }

  get position(): number {
    return this.player.position;
  }

  set position(value: number) {
    this.player.position = value;
  }
  get ranking(): string {
    return this.player.ranking;
  }

  set ranking(value: string) {
    this.player.ranking = value;
  }

  set gameid(value: string) {
    this.player.gameid = value;
  }
  get gameid(): string {
    return this.player.gameid;
  }

  updateTimestamp(): void {
    this.player.endTime = Date.now();
  }

  hasGame(): boolean {
    if (this.player.gameid === '') {
      return false;
    } else {
      return true;
    }
  }
}
