<div id="gamestatus" class="flex-column justify-content-center align-items-center h-100">
  <div class="gamestatus-win">
    <div class="d-flex flex-column justify-content-center align-items-center">
      <div><img src="{{ assetsUrl }}/assets/images/game/you-win.png" srcset="{{ assetsUrl }}/assets/images/game/you-win@2x.png 2x,
                         {{ assetsUrl }}/assets/images/game/you-win@3x.png 3x" class="titre"></div>
    </div>
  </div>
  <div class="gamestatus-defeat">
    <div class="d-flex flex-column justify-content-center align-items-center">
      <div><img src="{{ assetsUrl }}/assets/images/game/game-over.png" srcset="{{ assetsUrl }}/assets/images/game/game-over@2x.png 2x,
                         {{ assetsUrl }}/assets/images/game/game-over@3x.png 3x" class="titre"></div>
    </div>
  </div>
</div>
