<div id="hello" class="d-flex flex-column justify-content-center align-items-center h-100">
  <div class="d-flex flex-column align-items-center mb-3">
    <div><img class="hello" src="{{assetsUrl}}/assets/images/intro/hello.png" data-pin-nopin="true" /></div>
    <div class="name gold">{{playerName}}</div>
    <div class="d-flex flex-row align-items-center">
      <img src="{{ assetsUrl }}/assets/images/ranking/liseret.svg" class="liseret">
    </div>
  </div>
  <div class="d-flex flex-row align-items-end  bloc-score">
    <div class="recap">
      <ul>
        <li>{{playerNbRefs}}/{{maxRefs}}</li>
        <li>
          <!-- <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="27px" height="23px">
            <path fill-rule="evenodd" class="heart-1 heart-ok"
              d="M23.092,0.657 C19.748,-0.856 15.375,0.568 13.828,3.830 C12.281,0.568 7.908,-0.858 4.564,0.657 C1.003,2.273 -0.699,6.400 2.113,11.268 C4.111,14.732 7.651,17.342 13.828,22.021 C20.005,17.342 23.546,14.731 25.543,11.268 C28.356,6.400 26.653,2.273 23.092,0.657 Z" />
          </svg> -->
          <svg xmlns="http://www.w3.org/2000/svg" width="43.466" height="122.916" viewBox="0 0 43.466 122.916"
            class="mic mic-first">
            <path class="mic-1 mic-ok"
              d="M2515.914 342.877h-43.268a31.968 31.968 0 0 1 0-5.034h43.268a31.97 31.97 0 0 1 0 5.034zm-33.065 24.964h22.867l-2.883 62.723a8.559 8.559 0 0 1-17.1 0zm-9.891-22.03h42.642a36.393 36.393 0 0 1-8.993 19.1h-24.646a36.324 36.324 0 0 1-9.003-19.1zm33.484 5.874a1.467 1.467 0 0 0 1.467 1.467h.074a1.467 1.467 0 0 0 0-2.934h-.074a1.467 1.467 0 0 0-1.467 1.466zm-4.134 7.349a1.467 1.467 0 0 0 1.467 1.467h.074a1.467 1.467 0 0 0 0-2.934h-.074a1.467 1.467 0 0 0-1.467 1.466zm-1.114-5.882a1.467 1.467 0 0 0 0-2.934 1.467 1.467 0 1 0 0 2.933zm-3.648 7.349a1.471 1.471 0 1 0-.154-2.934 1.471 1.471 0 1 0 .153 2.933zm-3.2-7.349a1.467 1.467 0 0 0 0-2.934 1.466 1.466 0 1 0-.002 2.933zm-3.182 7.349a1.471 1.471 0 1 0-.155-2.934 1.471 1.471 0 1 0 .154 2.933zm-3.669-7.349a1.467 1.467 0 0 0 0-2.934 1.466 1.466 0 1 0-.001 2.933zm-4.255 5.882a1.466 1.466 0 0 0 1.467 1.467h.074a1.467 1.467 0 0 0 0-2.934h-.074a1.466 1.466 0 0 0-1.468 1.466zm-4.134-7.349a1.466 1.466 0 0 0 1.467 1.467h.074a1.437 1.437 0 0 0 1.431-1.467 1.5 1.5 0 0 0-1.5-1.467 1.466 1.466 0 0 0-1.478 1.466zm36.49-16.776h-42.638a36.43 36.43 0 0 1 8.263-18.247 2.334 2.334 0 0 1 1.779-.849h22.563a2.34 2.34 0 0 1 1.781.85 36.49 36.49 0 0 1 8.256 18.246zm-33.519-5.873a1.5 1.5 0 0 0-1.5-1.467 1.467 1.467 0 0 0 0 2.934h.074a1.437 1.437 0 0 0 1.426-1.467zm4.17-7.35a1.467 1.467 0 0 0-1.467-1.467h-.074a1.467 1.467 0 0 0 0 2.934h.074a1.467 1.467 0 0 0 1.467-1.467zm1.113 5.883a1.467 1.467 0 0 0 0 2.934 1.467 1.467 0 1 0 0-2.934zm3.647-7.35a1.471 1.471 0 1 0 .155 2.934 1.471 1.471 0 1 0-.155-2.934zm3.2 7.35a1.467 1.467 0 0 0 0 2.934 1.467 1.467 0 1 0 .003-2.934zm3.181-7.35a1.471 1.471 0 1 0 .154 2.934 1.471 1.471 0 1 0-.151-2.934zm3.669 7.35a1.467 1.467 0 0 0 0 2.934 1.466 1.466 0 1 0 .003-2.934zm4.255-5.883a1.467 1.467 0 0 0-1.466-1.467h-.074a1.467 1.467 0 0 0 0 2.934h.074a1.467 1.467 0 0 0 1.469-1.467zm4.135 7.35a1.467 1.467 0 0 0-1.467-1.467h-.074a1.467 1.467 0 0 0 0 2.934h.074a1.468 1.468 0 0 0 1.47-1.467z"
              transform="translate(-2472.547 -315.813)" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" width="43.466" height="122.916" viewBox="0 0 43.466 122.916"
            class="mic">
            <path class="mic-2 mic-ok"
              d="M2515.914 342.877h-43.268a31.968 31.968 0 0 1 0-5.034h43.268a31.97 31.97 0 0 1 0 5.034zm-33.065 24.964h22.867l-2.883 62.723a8.559 8.559 0 0 1-17.1 0zm-9.891-22.03h42.642a36.393 36.393 0 0 1-8.993 19.1h-24.646a36.324 36.324 0 0 1-9.003-19.1zm33.484 5.874a1.467 1.467 0 0 0 1.467 1.467h.074a1.467 1.467 0 0 0 0-2.934h-.074a1.467 1.467 0 0 0-1.467 1.466zm-4.134 7.349a1.467 1.467 0 0 0 1.467 1.467h.074a1.467 1.467 0 0 0 0-2.934h-.074a1.467 1.467 0 0 0-1.467 1.466zm-1.114-5.882a1.467 1.467 0 0 0 0-2.934 1.467 1.467 0 1 0 0 2.933zm-3.648 7.349a1.471 1.471 0 1 0-.154-2.934 1.471 1.471 0 1 0 .153 2.933zm-3.2-7.349a1.467 1.467 0 0 0 0-2.934 1.466 1.466 0 1 0-.002 2.933zm-3.182 7.349a1.471 1.471 0 1 0-.155-2.934 1.471 1.471 0 1 0 .154 2.933zm-3.669-7.349a1.467 1.467 0 0 0 0-2.934 1.466 1.466 0 1 0-.001 2.933zm-4.255 5.882a1.466 1.466 0 0 0 1.467 1.467h.074a1.467 1.467 0 0 0 0-2.934h-.074a1.466 1.466 0 0 0-1.468 1.466zm-4.134-7.349a1.466 1.466 0 0 0 1.467 1.467h.074a1.437 1.437 0 0 0 1.431-1.467 1.5 1.5 0 0 0-1.5-1.467 1.466 1.466 0 0 0-1.478 1.466zm36.49-16.776h-42.638a36.43 36.43 0 0 1 8.263-18.247 2.334 2.334 0 0 1 1.779-.849h22.563a2.34 2.34 0 0 1 1.781.85 36.49 36.49 0 0 1 8.256 18.246zm-33.519-5.873a1.5 1.5 0 0 0-1.5-1.467 1.467 1.467 0 0 0 0 2.934h.074a1.437 1.437 0 0 0 1.426-1.467zm4.17-7.35a1.467 1.467 0 0 0-1.467-1.467h-.074a1.467 1.467 0 0 0 0 2.934h.074a1.467 1.467 0 0 0 1.467-1.467zm1.113 5.883a1.467 1.467 0 0 0 0 2.934 1.467 1.467 0 1 0 0-2.934zm3.647-7.35a1.471 1.471 0 1 0 .155 2.934 1.471 1.471 0 1 0-.155-2.934zm3.2 7.35a1.467 1.467 0 0 0 0 2.934 1.467 1.467 0 1 0 .003-2.934zm3.181-7.35a1.471 1.471 0 1 0 .154 2.934 1.471 1.471 0 1 0-.151-2.934zm3.669 7.35a1.467 1.467 0 0 0 0 2.934 1.466 1.466 0 1 0 .003-2.934zm4.255-5.883a1.467 1.467 0 0 0-1.466-1.467h-.074a1.467 1.467 0 0 0 0 2.934h.074a1.467 1.467 0 0 0 1.469-1.467zm4.135 7.35a1.467 1.467 0 0 0-1.467-1.467h-.074a1.467 1.467 0 0 0 0 2.934h.074a1.468 1.468 0 0 0 1.47-1.467z"
              transform="translate(-2472.547 -315.813)" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" width="43.466" height="122.916" viewBox="0 0 43.466 122.916"
            class="mic">
            <path class="mic-3 mic-ok"
              d="M2515.914 342.877h-43.268a31.968 31.968 0 0 1 0-5.034h43.268a31.97 31.97 0 0 1 0 5.034zm-33.065 24.964h22.867l-2.883 62.723a8.559 8.559 0 0 1-17.1 0zm-9.891-22.03h42.642a36.393 36.393 0 0 1-8.993 19.1h-24.646a36.324 36.324 0 0 1-9.003-19.1zm33.484 5.874a1.467 1.467 0 0 0 1.467 1.467h.074a1.467 1.467 0 0 0 0-2.934h-.074a1.467 1.467 0 0 0-1.467 1.466zm-4.134 7.349a1.467 1.467 0 0 0 1.467 1.467h.074a1.467 1.467 0 0 0 0-2.934h-.074a1.467 1.467 0 0 0-1.467 1.466zm-1.114-5.882a1.467 1.467 0 0 0 0-2.934 1.467 1.467 0 1 0 0 2.933zm-3.648 7.349a1.471 1.471 0 1 0-.154-2.934 1.471 1.471 0 1 0 .153 2.933zm-3.2-7.349a1.467 1.467 0 0 0 0-2.934 1.466 1.466 0 1 0-.002 2.933zm-3.182 7.349a1.471 1.471 0 1 0-.155-2.934 1.471 1.471 0 1 0 .154 2.933zm-3.669-7.349a1.467 1.467 0 0 0 0-2.934 1.466 1.466 0 1 0-.001 2.933zm-4.255 5.882a1.466 1.466 0 0 0 1.467 1.467h.074a1.467 1.467 0 0 0 0-2.934h-.074a1.466 1.466 0 0 0-1.468 1.466zm-4.134-7.349a1.466 1.466 0 0 0 1.467 1.467h.074a1.437 1.437 0 0 0 1.431-1.467 1.5 1.5 0 0 0-1.5-1.467 1.466 1.466 0 0 0-1.478 1.466zm36.49-16.776h-42.638a36.43 36.43 0 0 1 8.263-18.247 2.334 2.334 0 0 1 1.779-.849h22.563a2.34 2.34 0 0 1 1.781.85 36.49 36.49 0 0 1 8.256 18.246zm-33.519-5.873a1.5 1.5 0 0 0-1.5-1.467 1.467 1.467 0 0 0 0 2.934h.074a1.437 1.437 0 0 0 1.426-1.467zm4.17-7.35a1.467 1.467 0 0 0-1.467-1.467h-.074a1.467 1.467 0 0 0 0 2.934h.074a1.467 1.467 0 0 0 1.467-1.467zm1.113 5.883a1.467 1.467 0 0 0 0 2.934 1.467 1.467 0 1 0 0-2.934zm3.647-7.35a1.471 1.471 0 1 0 .155 2.934 1.471 1.471 0 1 0-.155-2.934zm3.2 7.35a1.467 1.467 0 0 0 0 2.934 1.467 1.467 0 1 0 .003-2.934zm3.181-7.35a1.471 1.471 0 1 0 .154 2.934 1.471 1.471 0 1 0-.151-2.934zm3.669 7.35a1.467 1.467 0 0 0 0 2.934 1.466 1.466 0 1 0 .003-2.934zm4.255-5.883a1.467 1.467 0 0 0-1.466-1.467h-.074a1.467 1.467 0 0 0 0 2.934h.074a1.467 1.467 0 0 0 1.469-1.467zm4.135 7.35a1.467 1.467 0 0 0-1.467-1.467h-.074a1.467 1.467 0 0 0 0 2.934h.074a1.468 1.468 0 0 0 1.47-1.467z"
              transform="translate(-2472.547 -315.813)" />
          </svg>
        </li>
      </ul>
    </div>
    <div class="ml-3 mr-3 separator">
      <ul>
        <li>
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="1px" height="100px">
            <path fill-rule="evenodd" opacity="0.4" fill="rgb(255, 255, 255)"
              d="M-0.000,0.000 L1.000,0.000 L1.000,100.000 L-0.000,100.000 L-0.000,0.000 Z" />
          </svg>
        </li>
      </ul>
    </div>
    <div class="recap text-left">
      <ul>
        <li class="hello-score-label">TON SCORE</li>
        <li class="hello-score-value">
          {{playerScore}}<svg width="37px" height="42px" style="margin-left: 10px;">
            <path fill-rule="evenodd" fill="rgb(255, 255, 255)"
              d="M37.000,22.213 C37.000,28.713 31.638,34.000 25.047,34.000 C22.682,34.000 20.436,33.335 18.490,32.069 C16.609,33.286 14.365,34.000 11.953,34.000 C5.362,34.000 -0.000,28.713 -0.000,22.213 C-0.000,17.735 2.553,13.734 6.550,11.733 C6.580,5.259 11.928,-0.000 18.500,-0.000 C25.066,-0.000 30.408,5.248 30.449,11.713 C34.330,13.663 37.000,17.632 37.000,22.213 ZM25.047,13.146 C22.958,13.146 20.989,13.819 19.354,15.093 C18.756,15.558 17.889,15.458 17.417,14.868 C16.945,14.279 17.047,13.424 17.645,12.958 C19.772,11.302 22.332,10.427 25.047,10.427 C25.933,10.427 26.793,10.529 27.624,10.710 C27.082,6.217 23.200,2.719 18.500,2.719 C13.430,2.719 9.305,6.787 9.305,11.787 C9.305,12.014 9.314,12.243 9.330,12.466 C9.585,15.863 11.820,18.892 15.023,20.183 C15.728,20.468 16.065,21.261 15.777,21.956 C15.559,22.483 15.044,22.802 14.500,22.802 C14.327,22.802 14.150,22.769 13.979,22.701 C10.450,21.278 7.844,18.225 6.925,14.640 C4.357,16.294 2.758,19.105 2.758,22.213 C2.758,27.213 6.883,31.281 11.953,31.281 C17.023,31.281 21.148,27.213 21.148,22.213 C21.148,21.990 21.140,21.764 21.123,21.541 C21.068,20.792 21.638,20.141 22.398,20.086 C23.159,20.032 23.818,20.594 23.873,21.343 C23.895,21.631 23.906,21.924 23.906,22.213 C23.906,25.300 22.687,28.104 20.711,30.207 C22.035,30.909 23.507,31.281 25.047,31.281 C30.117,31.281 34.242,27.213 34.242,22.213 C34.242,17.214 30.117,13.146 25.047,13.146 Z" />
          </svg>
          <!-- <div class="icon baseline">

          </div> -->

        </li>
      </ul>

    </div>
  </div>
  <div class="d-flex flex-column justify-content-center align-items-center">
    <div class="mt-2 mb-2 btn1">
      <simple-btn [buttonText]="title1" [buttonTextOver]="title1Over" (buttonClick)="ranking()" [isDisabled]="false">
      </simple-btn>
    </div>
    <div class="mb-2 btn2">
      <simple-btn [buttonText]="title2" [buttonTextOver]="title2Over" (buttonClick)="resetGame()" [isDisabled]="false">
      </simple-btn>
    </div>
    <div class="btn3">
      <simple-btn [buttonText]="title3" [buttonTextOver]="title3Over" (buttonClick)="startNewGame()"
        [isDisabled]="false" [isFocused]="true">
      </simple-btn>
    </div>
    <div class="logout">
      <a href="javascript:void(0)" class="home-link" (click)="logout()" (mouseover)="onRollOver()"
        (mouseout)="onRollOut()">se deconnecter</a>
    </div>
  </div>
</div>
