import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { AppConfigService } from 'src/app/services/config/app-config.service';
import {
  ButtonService,
  ButtonEvent,
} from '../../../services/view/button.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'simple-btn',
  templateUrl: './simple-btn.component.html',
  styleUrls: ['./simple-btn.component.scss'],
})
export class SimpleBtnComponent implements OnInit, OnDestroy {
  @Input() buttonText: string;
  @Input() buttonTextOver: string;
  @Input() buttonSubmit = false;
  // @ViewChild('btn') btn: ElementRef;

  @Input()
  set loading(loading: boolean) {
    this._loading = loading || false;
  }
  get loading(): boolean {
    return this._loading;
  }
  @Input()
  set isDisabled(isDisabled: boolean) {
    this._isDisabled = isDisabled || false;
  }
  get isDisabled(): boolean {
    return this._isDisabled;
  }
  @Input()
  set isActivated(isActivated: boolean) {
    this._isActivated = isActivated || false;
  }
  get isActivated(): boolean {
    return this._isActivated;
  }
  @Input()
  set isFocused(isFocused: boolean) {
    this._isFocused = isFocused || false;
  }
  get isFocused(): boolean {
    return this._isFocused;
  }
  @Input()
  set isConnected(isConnected: boolean) {
    this._isConnected = isConnected || false;
  }
  get isConnected(): boolean {
    return this._isConnected;
  }
  @Input()
  set isTransparent(isTransparent: boolean) {
    this._transparentBg = isTransparent || false;
  }
  get isTransparent(): boolean {
    return this._transparentBg;
  }

  @Output() buttonClick: EventEmitter<any>;

  active = false;
  buttonType: string;
  // tslint:disable-next-line: variable-name
  private _loading: boolean;
  // tslint:disable-next-line: variable-name
  // tslint:disable-next-line: variable-name
  private _isDisabled: boolean;
  // tslint:disable-next-line: variable-name
  private _isActivated: boolean;
  // tslint:disable-next-line: variable-name
  // tslint:disable-next-line: variable-name
  private _isFocused: boolean;
  // tslint:disable-next-line: variable-name
  private _isConnected: boolean;
  // tslint:disable-next-line: variable-name
  private _transparentBg: boolean;

  constructor(
    private appConfig: AppConfigService,
    private buttonService: ButtonService
  ) {
    this.buttonClick = new EventEmitter<any>();
    this.buttonType = this.buttonSubmit ? `submit` : `button`;
  }

  ngOnInit(): void {
    if (this.appConfig.DEBUG) {
      console.log('SimpleBtnComponent:ngOnInit');
    }
    this.buttonText = this.buttonText ? this.buttonText : `No buttonText`;
    if (this.isFocused) {
      // this.btn.nativeElement.focus();
    }
  }

  ngOnDestroy(): void {
    if (this.appConfig.DEBUG) {
      console.log('SimpleBtnComponent:ngOnDestroy');
    }
  }

  onClickButton(event): any {
    if (this.isDisabled) {
      return;
    } else {
      this.buttonClick.emit(event);
      this.buttonService.emitEvent(new ButtonEvent(this.buttonService.CLICK));
    }
  }

  onRollOverButton(event): any {
    if (this.isDisabled) {
      return;
    } else {
      this.buttonService.emitEvent(
        new ButtonEvent(this.buttonService.ROLLOVER)
      );
    }
  }
  onRollOutButton(event): any {
    if (this.isDisabled) {
      return;
    } else {
      this.buttonService.emitEvent(new ButtonEvent(this.buttonService.ROLLOUT));
    }
  }
}
