import { Injectable, EventEmitter } from '@angular/core';
import { BonusService } from './bonus.service';
import { Combo } from 'src/app/model/combo';
import { AppConfigService } from 'src/app/services/config/app-config.service';
// import { GameBonus } from 'src/app/model/game-bonus'
import { GoogleAnalyticsService } from 'ngx-google-analytics';

export class DashboardEvent {
  constructor(
    public name: string,
    public score?: Array<number>,
    public error?: number,
    public badges?: Array<number>,
    public mode?: string
  ) {}
}
@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  public emitter$: EventEmitter<DashboardEvent>;

  public MASK = 'DashboardService:MASK';
  public UNMASK = 'DashboardService:UNMASK';
  public SHOW = 'DashboardService:SHOW';
  public HIDE = 'DashboardService:HIDE';
  public HIDDEN = 'DashboardService:HIDDEN';
  public UPDATESCORE = 'DashboardService:UPDATESCORE';
  public UPDATEERROR = 'DashboardService:UPDATEERROR';
  public UPDATEBADGES = 'DashboardService:UPDATEBADGES';
  public UPDATEPROGRESS = 'DashboardService:UPDATEPROGRESS';
  public UPDATEBONUS = 'DashboardService:UPDATEBONUS';
  public NEWMODE = 'DashboardService:NEWMODE';

  public GAMEMODE = 'DashboardService:GAMEMODE';
  public ENDMODE = 'DashboardService:ENDMODE';

  dashboardMode: string;
  enabled = false;

  constructor(
    private bonusService: BonusService,

    private appConfig: AppConfigService
  ) {
    this.emitter$ = new EventEmitter();
  }

  public emitEvent(event: DashboardEvent): void {
    if (this.appConfig.DEBUG) {
      console.log('DashboardService emitEvent %s', event.name);
    }
    this.emitter$.emit(event);
  }

  set mode(newMode: string) {
    this.dashboardMode = newMode;
    this.emitEvent(
      new DashboardEvent(this.NEWMODE, undefined, undefined, undefined, newMode)
    );
  }
  get mode(): string {
    return this.dashboardMode;
  }

  set isReady(val: boolean) {
    this.enabled = val;
  }
  get isReady(): boolean {
    return this.enabled;
  }

  show(): void {
    this.emitEvent(new DashboardEvent(this.SHOW));
    // this.$gaService.event('Show Dashboard', 'Interactions', 'Show Dashboard')
  }
  hide(): void {
    this.emitEvent(new DashboardEvent(this.HIDE));
  }
  mask(): void {
    this.emitEvent(new DashboardEvent(this.MASK));
  }
  unmask(): void {
    this.emitEvent(new DashboardEvent(this.UNMASK));
  }
  updateScore(score: Array<number>): void {
    this.emitEvent(new DashboardEvent(this.UPDATESCORE, score));
  }
  updateError(error: number): void {
    this.emitEvent(new DashboardEvent(this.UPDATEERROR, undefined, error));
  }
  updateBadges(badges: Array<number>): void {
    this.emitEvent(
      new DashboardEvent(this.UPDATEBADGES, undefined, undefined, badges)
    );
  }
  updateProgress(): void {
    this.emitEvent(new DashboardEvent(this.UPDATEPROGRESS));
  }

  unlockBonus(combo: Combo): void {
    this.bonusService.unlock(combo);
    this.emitEvent(new DashboardEvent(this.UPDATEBONUS));
  }
}
