<div id="errorui" class="flex-column justify-content-center align-items-center">
  <div class="message-block d-flex flex-column justify-content-center align-items-center">

    <div class="skulls d-flex flex-row justify-content-center align-items-center">
      <div class="skull">
        <img src="{{ assetsUrl }}/assets/images/lux/{{champion3}}.svg" class="micro" id="errorMic3">
      </div>
      <div class="skull">
        <img src="{{ assetsUrl }}/assets/images/lux/{{champion2}}.svg" class="micro" id="errorMic2">
      </div>
      <div class="skull">
        <img src="{{ assetsUrl }}/assets/images/lux/{{champion1}}.svg" class="micro" id="errorMic1">
      </div>
    </div>
    <div class="message d-flex flex-column justify-content-center align-items-center">
      <div>FAUX</div>
    </div>
    <div class="points d-flex flex-row justify-content-center align-items-center">
      - {{ points }}
    </div>
  </div>
  <div id="bg">
    <overlay [keyboard]="showHelper" [error]="true"></overlay>
  </div>
</div>
