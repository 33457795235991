import { Injectable, EventEmitter } from '@angular/core';
import { Show } from 'src/app/model/show';
import { AppConfigService } from 'src/app/services/config/app-config.service';

export class BadgeEvent {
  constructor(public name: string, public show?: Show) {}
}
@Injectable({
  providedIn: 'root',
})
export class BadgeService {
  public emitter$: EventEmitter<BadgeEvent>;

  public SHOWBADGE = 'BadgeService:SHOWBADGE';
  public SHOWBADGESUCESS = 'BadgeService:SHOWBADGESUCESS';

  constructor(private appConfig: AppConfigService) {
    this.emitter$ = new EventEmitter();
  }

  public emitEvent(event: BadgeEvent): void {
    if (this.appConfig.DEBUG) {
      console.log('BadgeService emitEvent %s', event.name);
    }
    this.emitter$.emit(event);
  }

  public showBadge(show: Show): void {
    this.emitEvent(new BadgeEvent(this.SHOWBADGE, show));
  }
}
