import { EventEmitter, Injectable } from '@angular/core';
import { AppConfigService } from 'src/app/services/config/app-config.service';

export class GameEvent {
  constructor(public name: string, public bonusMode?: boolean) {}
}

@Injectable({
  providedIn: 'root',
})
export class GameService {
  public emitter$: EventEmitter<GameEvent>;

  public START = 'GameService:start';
  public STOP = 'GameService:stop';
  public GODMODE = 'GameService:godmode';
  public PAUSE = 'GameService:pause';
  public RESUME = 'GameService:resume';
  public MISSION_COMPLETE = 'GameService:MISSION_COMPLETE';
  public MISSION_FAILED = 'GameService:MISSION_FAILED';

  unlockedBonus = 0;

  constructor(private appConfig: AppConfigService) {
    this.emitter$ = new EventEmitter();
  }
  emitEvent(event: GameEvent): void {
    if (this.appConfig.DEBUG) {
      console.log('GameService emitEvent %s', event.name);
    }
    this.emitter$.emit(event);
  }
  startGame(): void {
    this.emitEvent(new GameEvent(this.START));
  }
  stopGame(): void {
    this.emitEvent(new GameEvent(this.STOP));
  }

  pauseGame(bonusMode = false): void {
    this.emitEvent(new GameEvent(this.PAUSE, bonusMode));
  }
  resumeGame(): void {
    this.emitEvent(new GameEvent(this.RESUME));
  }

  startGodMode(): void {
    this.emitEvent(new GameEvent(this.GODMODE));
  }

  public get total(): number {
    return this.unlockedBonus;
  }
  public set total(num: number) {
    this.unlockedBonus = num;
  }
}
