import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from 'src/app/sections/home/home.component';
import { PreHomeComponent } from 'src/app/sections/pre-home/pre-home.component';
import { HelloComponent } from 'src/app/sections/hello/hello.component';
import { HomeLoginComponent } from 'src/app/sections/home-login/home-login.component';
import { RankingComponent } from 'src/app/sections/ranking/ranking.component';
import { MaintenanceComponent } from 'src/app/sections/maintenance/maintenance.component';

const routes: Routes = [
  { path: '', component: PreHomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'hello', component: HelloComponent },
  { path: 'login', component: HomeLoginComponent },
  { path: 'ranking', component: RankingComponent },
  { path: 'maintenance', component: MaintenanceComponent },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class IntroRoutingModule {}
