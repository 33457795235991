import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppConfigService } from 'src/app/services/config/app-config.service';
import {
  GameStatusEvent,
  GameStatusService,
} from 'src/app/services/game/game-status.service';

@Component({
  selector: 'game-status',
  templateUrl: './game-status.component.html',
  styleUrls: ['./game-status.component.scss'],
})
export class GameStatusComponent implements OnInit, OnDestroy {
  constructor(
    private appConfig: AppConfigService // private gameStatusService: GameStatusService
  ) {}
  assetsUrl = this.appConfig.assetsUrl;

  ngOnInit(): void {
    if (this.appConfig.DEBUG) {
      console.log('GameStatusComponent:ngOnInit');
    }
  }

  ngOnDestroy(): void {
    if (this.appConfig.DEBUG) {
      console.log('GameStatusComponent:ngOnDestroy');
    }
  }
}
