<div class="bonusModule {{mode}} align-middle text-center">
  <div class="locked" *ngIf="status === 0">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122 122" class="locked-svg" id="{{id}}Root"
      transform="scale(0.42)">
      <g id="{{id}}LockFrameHandler1" transform="translate(9 9)" style="stroke:#fff;fill:none">
        <circle cx="52" cy="52" r="51.5" style="fill:none" #frame1 />
      </g>
      <g id="{{id}}LockFrameHandler2" transform="translate(9 9)"
        style="stroke-width:9px;opacity:.3;stroke:#fff;fill:none">
        <circle cx="52" cy="52" r="56.5" style="fill:none" #frame2 />
      </g>
      <g id="{{id}}Lock">
        <path id="{{id}}LockHandler"
          d="M-100.39-91.795v-4.433a6.343 6.343 0 0 0-6.345-6.345 6.344 6.344 0 0 0-6.346 6.345v4.4h-1.883v14.429h16.487v-14.4zm-6.345 8.687a1.662 1.662 0 0 1-1.665-1.662 1.662 1.662 0 0 1 1.662-1.662 1.662 1.662 0 0 1 1.662 1.662 1.662 1.662 0 0 1-1.659 1.663zm4-8.716-8 .029v-4.433a4.007 4.007 0 0 1 4-4 4.007 4.007 0 0 1 4 4z"
          transform="translate(167.34 150.637)" style="fill:#fff" #lock />
      </g>
    </svg>
  </div>
  <div *ngIf="status === 1">
    <img id="{{id}}_badge" class="quiz-badges" [src]="svg" (load)="onImgChange()" (click)="openBonus($event)"
      (mouseover)="onRollOverButton()" (mouseout)="onRollOutButton()" data-pin-nopin="true">
  </div>
  <div *ngIf="status === 2">
    <div class="quiz-results {{mode}}">
      <img id="{{id}}_badge_done" class="quiz-done" [src]="svg" (load)="updateResults()" data-pin-nopin="true">
      <div class="bonus-score {{mode}}" #score>{{ currentScore }}/6</div>
    </div>
  </div>
</div>
