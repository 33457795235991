import { Component, OnInit, OnDestroy } from '@angular/core';
import * as $ from 'jquery';
import gsap from 'gsap';
import { RoughEase } from 'gsap/EasePack';
import { ComboService } from 'src/app/services/game/combo.service';
import { ComboEvent } from '../../../services/game/combo.service';
import {
  ScreenService,
  ScreenEvent,
} from 'src/app/services/view/screen.service';
import { AppConfigService } from 'src/app/services/config/app-config.service';
import { Subscription } from 'rxjs';
import { Combo } from 'src/app/model/combo';
import { SoundService } from 'src/app/services/sound/sound.service';
import { TimerService } from 'src/app/services/utilities/timer.service';
import { GameService } from 'src/app/services/game/game.service';
import { KeyboardService } from 'src/app/services/view/keyboard.service';
import { JarvisService } from 'src/app/services/view/jarvis.service';
import { UtilsService } from 'src/app/services/utilities/utils.service';
import {
  RotateDeviceEvent,
  RotateDeviceService,
} from 'src/app/services/view/rotate-device.service';
import {
  CursorEvent,
  CursorService,
} from 'src/app/services/view/cursor.service';
import { ButtonService } from 'src/app/services/view/button.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'combo',
  templateUrl: './combo.component.html',
  styleUrls: ['./combo.component.scss'],
})
export class ComboComponent implements OnInit, OnDestroy {
  constructor(
    private comboService: ComboService,
    private screenService: ScreenService,
    private gameService: GameService,
    private appConfig: AppConfigService,
    private soundService: SoundService,
    private jarvisService: JarvisService,
    private utils: UtilsService,
    private keyboardService: KeyboardService,
    private rotateDeviceService: RotateDeviceService,
    private cursorService: CursorService,
    private buttonService: ButtonService,

    private timerService: TimerService
  ) {}
  showHelper = false;
  centerX: number;
  centerY: number;
  innerWidth: number;
  innerHeight: number;
  svg: string;
  currentResolve: any;
  currentBonus: Combo;
  gameResolve: any;

  btnTitle1 = 'Continuer';
  btnTitle2 = 'NOW';
  comboName = '';
  points = '00';
  bonus = '' + this.appConfig.SCORE_COMBO;
  tmpTxt = [' ', ' '];
  comboList: Array<Combo>;
  subscription: Subscription;
  subscription2: Subscription;
  buttonSubs: Subscription;

  closing = false;
  multiplier = 2;
  duration = 66;
  assetsUrl = this.appConfig.assetsUrl;
  mobileMode = false;
  cheatCodePoints = '000';
  cheatCodeBonus = '' + this.appConfig.SCORE_CHEATCODE;
  title1 = "Let's gooo !";
  title1Over = "Let's gooo !";
  ngOnInit(): void {
    gsap.registerPlugin(RoughEase);

    this.innerWidth = $(window).innerWidth();
    this.innerHeight = $(window).innerHeight();
    this.centerX = innerWidth / 2;
    this.centerY = innerHeight / 2;

    this.svg = this.assetsUrl + '/assets/images/game/bonus/none.svg';
    // $(document).on('resize', () => {
    //   this.onResize();
    // });
    if (this.utils.md().mobile() !== null) {
      this.mobileMode = true;
      this.rotateDeviceService.emitter$.subscribe((event: RotateDeviceEvent) =>
        this.onRotateDevice(event)
      );
    }
    this.subscription = this.comboService.emitter$.subscribe((event) =>
      this._eventDispatch(event)
    );
    this.subscription2 = this.timerService.emitter$.subscribe((event) =>
      this._eventDispatch(event)
    );

    this.buttonSubs = this.buttonService.emitter$.subscribe((event) =>
      this._eventDispatch(event)
    );
    // this.resetLines();
    // $('.bonus-badge').css('opacity', 0);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.subscription2.unsubscribe();
    this.buttonSubs.unsubscribe();
  }

  // onResize() {
  //   this.innerWidth = $(window).innerWidth();
  //   this.innerHeight = $(window).innerHeight();
  //   this.centerX = innerWidth / 2;
  //   this.centerY = innerHeight / 2;
  // }

  _eventDispatch(event: any): void {
    switch (event.name) {
      case this.comboService.SHOW:
        this.gameResolve = event.resolve; // resolve boucle principale du jeu
        this.showCombos(event.comboList);
        break;
      case this.timerService.CHRONOEND:
        this.comboService.stopPopCornTime();
        break;
      // case this.comboService.KAZUHISA:
      //   this.showKonami(event.comboList[0]);
      //   break;
      // case this.comboService.CHEATCODE:
      //   this.showCheatCode();
      //   break;
      case this.buttonService.ROLLOVER:
        this.cursorService.emitEvent(
          new CursorEvent(this.cursorService.ROLLOVER)
        );
        break;
      case this.buttonService.ROLLOUT:
        this.cursorService.emitEvent(
          new CursorEvent(this.cursorService.ROLLOUT)
        );
        break;
      default:
        break;
    }
  }

  // Show Bonus Unlocked !!
  showCombos(comboList: Array<Combo>): void {
    if (this.appConfig.DEBUG) {
      console.log(
        'ComboComponent:showCombos ' + JSON.stringify(comboList, null, 4)
      );
    }

    if (comboList.length > 0) {
      const combo = comboList.shift(); // on recup le premier combo / bonus à afficher

      if (combo.comboType === 1) {
        this.comboList = comboList;
        this.showBonus(combo).then(() => {
          this.showCombos(this.comboList);
        });
      }
      if (combo.comboType === 2) {
        this.svg =
          this.assetsUrl +
          '/assets/images/game/bonus/' +
          combo.comboID +
          '.png?f=42'; // declenche onSVGChange !
        // this.comboName = combo.comboName;
        this.comboName = 'STICKER';
        this.comboList = comboList;
        this.showCombo(this.comboName).then(() => {
          this.showCombos(this.comboList);
        });
      }
    } else {
      // plus de combos à afficher
      this.gameResolve(true); // retour à la boucle principale
    }
  }

  // Show PopCorn Time
  showBonus(combo: Combo): any {
    if (this.appConfig.DEBUG) {
      console.log('ComboComponent:showBonus ' + combo.comboName);
    }
    const promise = new Promise((resolve) => {
      this.timerService.pauseChrono();

      $('.rulez').css('opacity', 0);
      $('.rbmoto').css('opacity', 0);
      $('.popcornTime-button').css('opacity', 0);

      this.multiplier = combo.multiplier;
      this.duration = combo.duration;
      this.currentBonus = combo;

      this.currentResolve = resolve;
      setTimeout(() => {
        // if (this.mobileMode) {
        //   this.jarvisService.hideui();
        // }
        // this.screenService.blur();
        this.gameService.pauseGame(true);
        $('#bonusContainer').addClass('d-flex');
        this.soundService.dimSound();
        this.soundService.bonus();
        this.soundService.ultraCombo();

        gsap.to('#bonusContainer', {
          duration: 0.4,
          ease: this.appConfig.tweenFunc,
          opacity: 1,
          onStart: () => {
            this.animateBonus(combo);
          },
          onComplete: () => {},
        });
      }, 100);
    });

    return promise;
  }
  showKonami(combo: Combo): any {
    if (this.appConfig.DEBUG) {
      console.log('ComboComponent:showKonami ' + combo.comboName);
    }
    this.multiplier = combo.multiplier;
    this.duration = combo.duration;
    this.currentBonus = combo;
    gsap.to('.round-button', {
      duration: 0,
      scale: 0.5,
      opacity: 0,
    });
    $('.rulez').css('opacity', 0);
    $('.rbmoto').css('opacity', 0);

    setTimeout(() => {
      this.timerService.pauseChrono();
      this.gameService.pauseGame(true);
      $('#kcContainer').addClass('d-flex');
      this.soundService.bonus();
      gsap.to('#kcContainer', {
        duration: 0.4,
        ease: this.appConfig.tweenFunc,
        opacity: 1,
        onStart: () => {
          this.animateKonami(combo);
        },
        onComplete: () => {},
      });
    }, 100);
  }

  animateKonami(combo: Combo): void {
    gsap.from('.top-layer', {
      duration: 0.8,
      opacity: 0,
      scale: 2,
      ease: 'back.out',
    });
    gsap.from('.middle-layer', {
      delay: 0.2,
      duration: 0.6,
      top: '-=100',
      opacity: 0,
      ease: 'expo.out',
    });
    gsap.from('.bottom-layer', {
      delay: 0.2,
      duration: 0.6,
      top: '+=100',
      opacity: 0,
      ease: 'expo.out',
    });
    gsap.from('.mode', {
      delay: 0.5,
      duration: 0.4,
      opacity: 0,
      scale: 1.2,
      ease: 'back.out',
      onComplete: () => {
        gsap.to('.rbmoto', {
          duration: 0.6,
          opacity: 1,
          ease: this.appConfig.tweenFunc,
        });
        gsap.to('.rulez', {
          duration: 0.6,
          opacity: 1,
          ease: this.appConfig.tweenFunc,
        });

        gsap.to('.round-button', {
          duration: 0.6,
          opacity: 1,
          scale: 1,
          ease: 'back.out',
        });
      },
    });
  }

  onRotateDevice(event: RotateDeviceEvent): void {
    if (this.appConfig.DEBUG) {
      console.log('ComboComponent:onRotateDevice ' + event.name);
    }

    // $('.middle-popcorn-layer').css('top', '');
    // $('.bottom-popcorn-layer').css('top', '');
  }

  // PopCorn time
  animateBonus(combo: Combo): void {
    gsap.from('.multiplier', {
      // delay: 0.5,
      duration: 0.4,
      opacity: 0,
      scale: 1.2,
      ease: 'back.out',
      onComplete: () => {
        gsap.to('.rbmoto', {
          duration: 0.6,
          opacity: 1,
          ease: this.appConfig.tweenFunc,
        });
        gsap.to('.rulez', {
          duration: 0.6,
          opacity: 1,
          ease: this.appConfig.tweenFunc,
        });

        gsap.to('.popcornTime-button', {
          delay: 0.2,
          duration: 0.6,
          opacity: 1,
          scale: 1,
          ease: 'back.out',
        });
      },
    });
  }

  showCheatCode(): any {
    if (this.appConfig.DEBUG) {
      console.log('BadgeComponent:showCheatCode');
    }

    $('.rulez').css('opacity', 0);
    $('.rbmoto').css('opacity', 0);

    this.gameService.pauseGame(true);
    this.timerService.pauseChrono();
    $('#cheatContainer').addClass('d-flex');
    this.soundService.dimSound();
    this.soundService.bonus();
    gsap.to('#cheatContainer', {
      duration: 0.4,
      opacity: 1,
      ease: this.appConfig.tweenFunc,
      onStart: () => {
        this.animateCheatCode();
      },
      onComplete: () => {},
    });
  }

  animateCheatCode(): void {
    this.innerWidth = $(window).innerWidth();
    this.innerHeight = $(window).innerHeight();
    this.centerX = innerWidth / 2;
    this.centerY = innerHeight / 2;

    gsap.from('.top-layer', {
      duration: 0.8,
      opacity: 0,
      scale: 2,
      ease: 'back.out',
    });
    gsap.from('.code', {
      delay: 0.5,
      duration: 0.4,
      opacity: 0,
      scale: 1.2,
      ease: 'back.out',
      onComplete: () => {
        gsap.to('.rbmoto', {
          duration: 0.6,
          opacity: 1,
          ease: this.appConfig.tweenFunc,
        });
        gsap.to('.rulez', {
          duration: 0.6,
          opacity: 1,
          ease: this.appConfig.tweenFunc,
        });
        this.cheatCodePoints = '000';
        this.cheatCodeBonus =
          '' + this.appConfig.SCORE_CHEATCODE * this.comboService.multiplier;
        if (this.cheatCodeBonus.length > 2) {
          this.cheatCodePoints = '000';
        }
        // anim + points
        this.tmpTxt = this.cheatCodeBonus.split('');
        for (let i = 0; i < this.tmpTxt.length; i++) {
          this.letterRandomize2(i);
        }
        gsap.to('.gain', {
          duration: 0.1,
          opacity: 1,
          ease: this.appConfig.tweenFunc,
        });
        setTimeout(() => {
          this.hideCheatCode();
        }, 2000);
      },
    });
    gsap.from('.middle-layer', {
      delay: 0.2,
      duration: 0.6,
      top: '-=100',
      opacity: 0,
      ease: 'expo.out',
    });
    gsap.from('.bottom-layer', {
      delay: 0.2,
      duration: 0.6,
      top: '+=100',
      opacity: 0,
      ease: 'expo.out',
    });
  }

  hideCheatCode(): void {
    this.gameService.resumeGame();
    this.timerService.resumeChrono();
    this.soundService.undimSound();
    gsap.to('#cheatContainer', {
      duration: 0.6,
      opacity: 0,
      ease: this.appConfig.tweenFunc,
      onComplete: () => {
        $('#cheatContainer').removeClass('d-flex');
      },
    });
  }

  // Show Bonus Unlocked !!

  showCombo(id: string): any {
    if (this.appConfig.DEBUG) {
      console.log('ComboComponent:showCombo ' + id + '1');
    }
    // this.resetLines();

    const promise = new Promise((resolve) => {
      this.currentResolve = resolve;
      // this.closeModalService.show();
      setTimeout(() => {
        if (this.appConfig.DEBUG) {
          console.log('ComboComponent:showCombo ' + id + '1');
        }
        this.screenService.blur();
        this.timerService.pauseChrono();
        this.gameService.total += 1;

        // $('#comboContainer').css('display', 'block');
        gsap.to('.bonus-badge', {
          duration: 0,
          opacity: 0,
          scale: 1.4,
        });
        //   .animate({ opacity: 1 }, 400);
        $('#comboContainer').addClass('d-flex');

        gsap.to('#comboContainer', {
          duration: 0.4,
          ease: this.appConfig.tweenFunc,
          opacity: 1,
          onStart: () => {
            this.soundService.dimSound();
            this.soundService.zap();
          },
          onComplete: () => {
            // svg.dispatchEvent(new Event('click'));
            this.soundService.bonus();

            gsap.to('.bonus-badge', {
              duration: 0.5,
              opacity: 1,
              scale: 1,
              ease: 'expo.out',
            });

            gsap.to('.title', {
              duration: 0.6,
              opacity: 1,
              // delay: 1,
              ease: this.appConfig.tweenFunc,
              // onStart: () => {
              //   this.animateLines();
              // },
              onComplete: () => {
                gsap.to('.label', {
                  duration: 0.6,
                  opacity: 1,
                  ease: this.appConfig.tweenFunc,

                  onStart: () => {
                    this.soundService.blink();
                  },
                  onComplete: () => {
                    this.points = '000';
                    this.bonus =
                      '' +
                      this.appConfig.SCORE_COMBO * this.comboService.multiplier;
                    if (this.bonus.length > 2) {
                      this.points = '000';
                    }
                    $('.combo-points').css('opacity', 1);
                    $('.combo-points').css('visibility', 'visible');
                    this.tmpTxt = this.bonus.split('');
                    for (let i = 0; i < this.tmpTxt.length; i++) {
                      this.letterRandomize(i);
                    }
                    // return;
                    setTimeout(() => {
                      this.closing = true;
                      gsap.to('.title', {
                        duration: 0.3,
                        opacity: 0,
                      });
                      gsap.to('.label', {
                        duration: 0.3,
                        opacity: 0,
                      });
                      gsap.to('.combo-points', {
                        duration: 0.3,
                        opacity: 0,
                      });
                      // this.hideLines();
                      gsap.to('.bonus-badge', {
                        duration: 0.45,
                        scale: 0.00001,
                        ease: 'back.in',
                        onStart: () => {
                          this.soundService.zap();
                          this.soundService.undimSound();
                          this.screenService.unblur();
                        },
                        onComplete: () => {
                          this.close();
                          gsap.to('.bonus-badge', {
                            duration: 0,
                            opacity: 0,
                            scale: 1.4,
                          });
                        },
                      });
                    }, 2000);
                  },
                });
              },
            });
          },
        });
      }, 100);
    });

    return promise;
  }

  animateLines(): void {
    const delai = 0;
    gsap.fromTo(
      '#line1_',
      {
        drawSVG: '0 0',
      },
      {
        delay: delai,
        duration: 0.6,
        drawSVG: '0 100%',
        ease: 'power3.out',
      }
    );
    gsap.fromTo(
      '#line2_',
      {
        drawSVG: '0 0',
      },
      {
        delay: delai + 0.1,
        duration: 0.6,
        drawSVG: '0 100%',
        ease: 'power3.out',
      }
    );
    gsap.fromTo(
      '#line3_',
      {
        drawSVG: '0 0',
      },
      {
        delay: delai + 0.2,
        duration: 0.6,
        drawSVG: '0 100%',
        ease: 'power3.out',
      }
    );
    gsap.fromTo(
      '#line4_',
      {
        drawSVG: '100% 100%',
      },
      {
        delay: delai,
        duration: 0.6,
        drawSVG: '100% 0',
        ease: 'power3.out',
      }
    );
    gsap.fromTo(
      '#line5_',
      {
        drawSVG: '100% 100%',
      },
      {
        delay: delai + 0.1,
        duration: 0.6,
        drawSVG: '100% 0',
        ease: 'power3.out',
      }
    );
    gsap.fromTo(
      '#line6_',
      {
        drawSVG: '100% 100%',
      },
      {
        delay: delai + 0.2,
        duration: 0.6,
        drawSVG: '100% 0',
        ease: 'power3.out',
      }
    );
  }

  hideLines(): void {
    gsap.to('#line1_', {
      duration: 0.2,
      drawSVG: '0',
      ease: 'power3.in',
    });
    gsap.to('#line2_', {
      duration: 0.2,
      drawSVG: '0',
      ease: 'power3.in',
    });
    gsap.to('#line3_', {
      duration: 0.2,
      drawSVG: '0',
      ease: 'power3.in',
    });
    gsap.fromTo(
      '#line4_',
      {
        drawSVG: '100% 0%',
      },
      {
        duration: 0.2,
        drawSVG: '100% 100%',
        ease: 'power3.in',
      }
    );
    gsap.fromTo(
      '#line5_',
      {
        drawSVG: '100% 0%',
      },
      {
        duration: 0.2,
        drawSVG: '100% 100%',
        ease: 'power3.in',
      }
    );
    gsap.fromTo(
      '#line6_',
      {
        drawSVG: '100% 0%',
      },
      {
        duration: 0.2,
        drawSVG: '100% 100%',
        ease: 'power3.in',
      }
    );
  }

  resetLines(): void {
    // reset des lines
    gsap.to('#line1_', {
      duration: 0,
      drawSVG: '0',
    });
    gsap.to('#line2_', {
      duration: 0,
      drawSVG: '0',
    });
    gsap.to('#line3_', {
      duration: 0,
      drawSVG: '0',
    });

    gsap.fromTo(
      '#line4_',
      {
        drawSVG: '100% 0%',
      },
      {
        duration: 0,
        drawSVG: '100% 100%',
      }
    );
    gsap.fromTo(
      '#line5_',
      {
        drawSVG: '100% 0%',
      },
      {
        duration: 0,
        drawSVG: '100% 100%',
      }
    );
    gsap.fromTo(
      '#line6_',
      {
        drawSVG: '100% 0%',
      },
      {
        duration: 0,
        drawSVG: '100% 100%',
      }
    );
  }

  close(): void {
    if (this.appConfig.DEBUG) {
      console.log('ComboComponent:close');
    }
    this.timerService.resumeChrono();

    $('.continueButton').css('pointer-events', 'none');
    // $('#comboContainer').css('display', 'block');
    gsap.to('#comboContainer', {
      duration: 0.4,
      ease: this.appConfig.tweenFunc,
      opacity: 0,
      onComplete: () => {
        $('.label').css('opacity', 0);
        $('.title').css('opacity', 0);
        $('.combo-points').css('opacity', 0);
        $('.continueButton').css('opacity', 0);
        $('.continueButton').css('display', 'none');
        // $('.combo-points').css('display', 'block');

        // $('#comboContainer').css('display', 'none');
        $('#comboContainer').removeClass('d-flex');

        // this.soundService.undimSound();

        this.screenService.unblur();
        this.closing = false;

        // this.closeModalService.hide();
        // this.subscription.unsubscribe();
        this.currentResolve(true);
      },
    });
  }

  letterRandomize(index): void {
    const intervals = [];
    const possible = '0123456789*-+=.;"()&%$#@!<>?';

    this.soundService.coins();

    intervals[index] = setInterval(() => {
      this.tmpTxt[index] = possible.charAt(
        Math.floor(Math.random() * possible.length)
      );
      this.points = this.tmpTxt.join('');
    }, 25);
    setTimeout(() => {
      clearInterval(intervals[index]);
      this.tmpTxt[index] = this.bonus.split('')[index];
      this.points = this.tmpTxt.join('');
    }, 600 * index);
  }

  letterRandomize2(index): void {
    const intervals = [];
    const possible = '0123456789*-+=.;"()&%$#@!<>?';

    // if (this.animSpeed > 0.5) {
    this.soundService.coins();
    // }
    intervals[index] = setInterval(() => {
      this.tmpTxt[index] = possible.charAt(
        Math.floor(Math.random() * possible.length)
      );
      this.cheatCodePoints = this.tmpTxt.join('');
    }, 14);
    setTimeout(() => {
      clearInterval(intervals[index]);
      this.tmpTxt[index] = this.cheatCodeBonus.split('')[index];
      this.cheatCodePoints = this.tmpTxt.join('');
    }, 100 * index);
  }

  popCornTime(event): void {
    event.preventDefault();
    event.stopPropagation();
    if (this.appConfig.DEBUG) {
      console.log('ComboComponent:popCornTime');
    }
    this.closing = true;
    // this.screenService.unblur();
    // if (this.mobileMode) {
    //   this.jarvisService.showui();
    // }
    this.gameService.resumeGame();
    this.soundService.go();
    this.soundService.powerup();

    gsap.to('#bonusContainer', {
      duration: 0.4,
      ease: this.appConfig.tweenFunc,
      opacity: 0,
      onComplete: () => {
        // $('#comboContainer').css('display', 'none');
        $('#bonusContainer').removeClass('d-flex');

        // this.soundService.undimSound();
        this.comboService.startPopCornTime(this.currentBonus);
        this.timerService.startChrono(this.currentBonus.duration);
        this.closing = false;

        // this.closeModalService.hide();
        // this.subscription.unsubscribe();
        this.currentResolve(true); // retour à la boucle showCombos -> retour boucle principale
      },
    });
  }
  godMode(event): void {
    event.preventDefault();
    event.stopPropagation();
    if (this.appConfig.DEBUG) {
      console.log('ComboComponent:godMode');
    }
    this.closing = true;
    this.gameService.resumeGame();
    this.gameService.startGodMode();
    this.soundService.go();

    gsap.to('#kcContainer', {
      duration: 0.4,
      ease: this.appConfig.tweenFunc,
      autoAlpha: 0,
      onComplete: () => {
        // $('#comboContainer').css('display', 'none');
        $('#kcContainer').removeClass('d-flex');

        this.comboService.startPopCornTime(this.currentBonus);
        this.timerService.startChrono(this.currentBonus.duration);
        this.closing = false;
        // this.keyboardService.escape();
      },
    });
  }
}
