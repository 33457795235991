import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  AuthenticationService,
  AuthEvent,
} from 'src/app/services/http/authentication.service';
// import { AuthService, SocialLoginModule } from 'angularx-social-login';
// import { FacebookLoginProvider } from 'angularx-social-login';
import {
  CursorService,
  CursorEvent,
} from 'src/app/services/view/cursor.service';
// import * as Inputmask from 'inputmask';
import { AlertService } from 'src/app/services/utilities/alert.service';
import { User } from 'src/app/model/user';
import { first } from 'rxjs/operators';
import { AppConfigService } from 'src/app/services/config/app-config.service';
import { Subscription } from 'rxjs';

declare const Inputmask: any;
// declare const UimFormsApi: any;
// declare const SystemJS: any;

declare global {
  interface Window {
    define: any;
    UimFormsApi: any;
  }
}
// custom validator to check that two fields match
export function MustMatch(
  controlName: string,
  matchingControlName: string
): any {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  // providers: [SocialLoginModule, AuthService]
})
export class RegisterComponent implements OnInit, AfterViewInit, OnDestroy {
  registerForm: FormGroup;
  loading = false;
  submitted = false;
  placeHolderName = 'Blaze';
  placeHolderFirstName = 'Prénom';
  placeHolderEmail = 'E-mail';
  placeHolderPassword = 'Mot de passe';
  placeHolderConfirmPassword = 'Confirmer Mot de passe';
  // tslint:disable-next-line: quotemark
  buttonText = "s'inscrire";
  buttonTextOver = 'envoyer';
  fbButtonText = 'Facebook connect';
  fbButtonTextOver = 'connect';
  formsApiInstance: any;
  rgpd =
    'Tes données personnelles seront utilisées pour améliorer ton expérience sur ce site, comme décrit dans notre politique de confidentialité.';
  policyTypes: any;
  authSubs: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    // private fbAuthService: AuthService,
    private alertService: AlertService,
    private cursorService: CursorService,
    private appConfig: AppConfigService
  ) {
    if (this.authService.currentUserValue) {
      // this.router.navigate(['/']);
      if (this.appConfig.DEBUG) {
        console.log('currentUserValue: ' + this.authService.currentUserValue);
      }
    }
  }

  ngOnInit(): void {
    if (this.appConfig.DEBUG) {
      console.log('RegisterComponent::ngOnInit');
    }
    this.registerForm = this.formBuilder.group(
      {
        name: [
          '',
          [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(96),
          ],
        ],
        // fname: [
        //   '',
        //   [
        //     Validators.required,
        //     Validators.minLength(2),
        //     Validators.maxLength(96),
        //   ],
        // ],
        email: [
          '',
          [Validators.required, Validators.email, Validators.minLength(6)],
        ],
        password: ['', [Validators.required, Validators.minLength(8)]],
        // confirmPassword: ['', [Validators.required, Validators.minLength(8)]],
        rgpd: [true, Validators.requiredTrue],
      }
      // { validator: MustMatch('password', 'confirmPassword') }
    );
    this.authSubs = this.authService.emitter$.subscribe((event) => {
      if (event.name === this.authService.AUTHFAILED) {
        this.loading = false;
        this.registerForm.controls.email.enable();
        this.registerForm.controls.password.enable();
        // this.registerForm.controls.confirmPassword.enable();
        this.registerForm.controls.name.enable();
        // this.registerForm.controls.fname.enable();
        this.registerForm.controls.rgpd.enable();
      }
    });

    // Getting the legal texts
  }

  addScript(src): any {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = resolve;
      script.onerror = reject;
      document.body.appendChild(script);
    });
  }

  ngAfterViewInit(): void {
    const im = new Inputmask({
      // tslint:disable-next-line: quotemark
      regex: "^[a-zA-Z0-9àéèêãáćǵíñüóśúøõç\\-'\\s]+$",
      placeholder: '',
      showMaskOnHover: false,
      showMaskOnFocus: false,
      clearMaskOnLostFocus: true,
    });
    im.mask(document.querySelector('.form-name'));

    // this.loadUim();
  }

  ngOnDestroy(): void {
    if (this.appConfig.DEBUG) {
      console.log('RegisterComponent::ngOnDestroy');
    }
    this.authSubs.unsubscribe();
  }

  loadUim(): void {
    let originalDefine = window.define;
    delete window.define;
    return this.addScript(
      'https://uim.redbull.com/static/uim-web-sdk/4.3/uimFormsApi.min.js'
    )
      .then(() => (window.define = originalDefine))
      .then(() => {
        if (this.appConfig.DEBUG) {
          console.log(
            'RegisterComponent UimFormsApi init ' +
              window.UimFormsApi.setupFormsApi
          );
        }
        this.formsApiInstance = new window.UimFormsApi.setupFormsApi()
          // .withEnvironment('design')
          .withEnvironment('production')
          .withLanguage('fr')
          .withCountry('FR')
          .build();

        this.formsApiInstance
          .getItems(this.appConfig.RB_ALIAS)
          .then((response) => {
            response.fields.map((rendering) => {
              if (this.appConfig.DEBUG) {
                console.log(
                  'RegisterComponent::ngOnInit fields ' +
                    JSON.stringify(rendering, null, 4)
                );
              }
              switch (rendering.uniqueAlias) {
                case 'last_name':
                  this.placeHolderName = rendering.label;
                  break;
                case 'first_name':
                  this.placeHolderFirstName = rendering.label;
                  break;
                case 'email':
                  this.placeHolderEmail = rendering.label;
                  break;

                default:
                  break;
              }
            });
            response.policyRenderings.map((rendering) => {
              if (this.appConfig.DEBUG) {
                console.log(
                  'RegisterComponent::ngOnInit rendering.text: ' +
                    rendering.text
                );
              }
              this.rgpd = rendering.text;
              // Read the translations from the response object, and display them in your GUI.
              // rendering.text; // HTML text to be displayed.
              // rendering.checkbox; // Whether a checkbox is needed or not
              // rendering.prechecked; // Whether the policy is accepted by default.
              this.policyTypes = rendering.policyTypes;
              // Don't display the types. Store which types the user accepts and send them on the submission.
            });
          });
      });
  }

  //   this.addScript(
  //     'https://uim.redbull.com/static/uim-web-sdk/4.3/uimFormsApi.min.js'
  //   )
  //     .then(() => SystemJS.import('@uim/web-sdk/formsApi'))
  //     .then((data) => {
  //       // .then(() => {

  //     });
  // }

  onRollOverButton(): any {
    if (this.loading) {
      return;
    } else {
      this.cursorService.emitEvent(
        new CursorEvent(this.cursorService.ROLLOVER)
      );
    }
  }
  onRollOutButton(): any {
    if (this.loading) {
      return;
    } else {
      this.cursorService.emitEvent(new CursorEvent(this.cursorService.ROLLOUT));
    }
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.registerForm.controls;
  }

  onSubmit(): void {
    this.submitted = true;

    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }
    this.registerForm.controls.email.disable();
    this.registerForm.controls.password.disable();
    // this.registerForm.controls.confirmPassword.disable();
    this.registerForm.controls.name.disable();
    // this.registerForm.controls.fname.disable();
    this.registerForm.controls.rgpd.disable();
    this.loading = true;
    this.authService.submitRegister(this.registerForm.value);

    return;
    const today = new Date();
    let mm = today.getMonth() + 1 + '';
    if (today.getMonth() + 1 < 10) {
      mm = '0' + mm;
    }
    const yy = (today.getFullYear() + '').slice(2);
    const RB_SOURCE = this.appConfig.RB_SOURCE + mm + '_' + yy;

    const formSubmission = this.formsApiInstance
      .newFormSubmission()
      .setFields({
        first_name: this.registerForm.controls.name.value,
        last_name: this.registerForm.controls.name.value,
        email: this.registerForm.controls.email.value,
      })
      .setFormAlias(this.appConfig.RB_ALIAS)
      // language of the app - it must be in ISO 639-1 Code. e.g. 'en', 'it'
      .setLanguage(this.appConfig.LANG)
      // country of the app - it must be in ISO 3166-1 alpha-2 format. e.g. 'DE', 'ES'
      .setCountry('FR')
      .setNewsletterAccepted(false)
      // source format: COUNTRY-ISO_PROJECT-NAME_MM_YY
      .setSource(RB_SOURCE)
      // lucidId example: L001
      // .setLucidId('L456-2')
      .setLucidId(this.appConfig.LUCID_ID)
      // policyTypes of the accepted polices - see the block above
      .setPolicyTypes(this.policyTypes); // e.g. ["privacy"]
    // customPolicies set by the applications
    // .setCustomPolicies([{type: "participation", url: "https://www.policies.redbull.com/somefile.pdf"}])

    // this.authService.submitRegister(this.registerForm.value);
    this.formsApiInstance
      .submit(formSubmission)
      .then((response) => {
        if (this.appConfig.DEBUG) {
          console.log(
            'form submitted returned the formSubmissionId: ' + response.id
          );
        }
        this.authService.submitRegister(this.registerForm.value);
      })
      .catch((error) => {
        console.log(error);
        this.alertService.error('Registration failed');
        this.authService.emitEvent(new AuthEvent(this.authService.AUTHFAILED));
      });
  }

  none(): void {}
}
