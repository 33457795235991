import { Injectable, EventEmitter } from '@angular/core';

export class CloseEvent {
  constructor(public name: string) {}
}

@Injectable({
  providedIn: 'root',
})
export class CloseModalService {
  public emitter$: EventEmitter<CloseEvent>;

  public SHOW = 'CloseModalService:SHOW';
  public HIDE = 'CloseModalService:HIDE';
  public CLOSEMODAL = 'CloseModalService:CLOSEMODAL';

  constructor() {
    this.emitter$ = new EventEmitter();
  }

  public emitEvent(event: CloseEvent): void {
    // console.log('CloseModalService emitEvent %s', event.name);
    this.emitter$.emit(event);
  }

  close(): void {
    this.emitEvent(new CloseEvent(this.CLOSEMODAL));
  }

  show(): void {
    this.emitEvent(new CloseEvent(this.SHOW));
  }

  hide(): void {
    this.emitEvent(new CloseEvent(this.HIDE));
  }
}
