import { Component, OnInit } from '@angular/core';
import gsap from 'gsap';
import { GameService } from 'src/app/services/game/game.service';
import { UtilsService } from 'src/app/services/utilities/utils.service';
import { SoundService } from 'src/app/services/sound/sound.service';
import * as $ from 'jquery';
import { AppConfigService } from 'src/app/services/config/app-config.service';
import { PreloaderService } from 'src/app/services/utilities/preloader.service';
import {
  ScreenService,
  ScreenEvent,
} from 'src/app/services/view/screen.service';
import * as OpenSeadragon from 'openseadragon';
import { TouchControlsService } from '../../../services/view/touch-controls.service';
// declare var OpenSeadragon: any;

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'html5-gameview',
  templateUrl: './html5-gameview.component.html',
  styleUrls: ['./html5-gameview.component.scss'],
})
export class Html5GameviewComponent implements OnInit {
  constructor(
    private appConfig: AppConfigService,
    // private logger: ConsoleLoggerService,
    private screenService: ScreenService,
    private touchControls: TouchControlsService,
    private preloaderService: PreloaderService,
    private gameService: GameService,
    private soundService: SoundService,
    private utils: UtilsService
  ) {}

  maxZoomLevel = 5;
  minZoomLevel = 1;
  imgViewer: OpenSeadragon.Viewer;
  loadBound;
  center = {
    x: 0,
    y: 0,
  };
  assetsUrl = this.appConfig.assetsUrl;

  ngOnInit(): void {
    if (this.appConfig.DEBUG) {
      console.log('Html5GameviewComponent ngOnInit');
    }

    this.gameService.emitter$.subscribe((event: any) =>
      this._eventDispatch(event)
    );
    this.screenService.emitter$.subscribe((event: any) =>
      this._eventDispatch(event)
    );

    this.touchControls.init();
  }

  /**
   * Dispatch en fct de la valeur d'event
   * @param event  event
   */
  private _eventDispatch(event): void {
    switch (event.name) {
      case this.screenService.ENABLE:
        this.touchControls.enable();
        break;
      case this.screenService.DISABLE:
        this.touchControls.disable();
        break;
      case this.screenService.GAMEERROR:
        this.touchControls.error((event as ScreenEvent).errors);
        break;
      case this.screenService.BLUR:
        this.touchControls._blur();
        break;
      case this.screenService.UNBLUR:
        this.touchControls._unblur();
        break;
      case this.screenService.PAUSE:
        this.touchControls._pause();
        break;
      case this.screenService.RESUME:
        this.touchControls._resume();
        break;
      case this.gameService.START:
        this.touchControls.showTheRoom();
        break;
      case this.gameService.STOP:
        this.touchControls.hideTheRoom();
        break;
      // case this.mouseControlsService.CHANGE:
      //   this._requestRenderIfNotRequested();
      //   break;

      default:
        break;
    }
  }
}
