import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppConfigService } from 'src/app/services/config/app-config.service';
import { JarvisService } from 'src/app/services/view/jarvis.service';
import { JarvisEvent } from '../../../services/view/jarvis.service';
import { ScreenService } from 'src/app/services/view/screen.service';
import * as $ from 'jquery';
import gsap from 'gsap';
import { ProgressService } from 'src/app/services/game/progress.service';
import { SVG, Element, Text, Svg } from '@svgdotjs/svg.js';
import { UtilsService } from 'src/app/services/utilities/utils.service';
import { RoughEase } from 'gsap/EasePack';
import { SoundService } from 'src/app/services/sound/sound.service';

@Component({
  selector: 'error',
  templateUrl: './error-ui.component.html',
  styleUrls: ['./error-ui.component.scss'],
})
export class ErrorUiComponent implements OnInit, OnDestroy {
  constructor(
    private appConfig: AppConfigService,
    private jarvisService: JarvisService,
    private screenService: ScreenService,
    private soundService: SoundService,
    private progressService: ProgressService,
    private ngZone: NgZone,

    private utils: UtilsService
  ) {}

  jarvisSubs: Subscription;
  showHelper = false;
  points = '00';
  bonus = '50';
  champion_on = 'heart2-on';
  champion_off = 'heart2-off';
  champion1 = this.champion_on;
  champion2 = this.champion_on;
  champion3 = this.champion_on;
  tmpTxt = [' ', ' '];
  assetsUrl = this.appConfig.assetsUrl;

  ngOnInit(): void {
    if (this.appConfig.DEBUG) {
      console.log('ErrorUiComponent:ngOnInit ');
    }
    gsap.registerPlugin(RoughEase);
    this.jarvisSubs = this.jarvisService.emitter$.subscribe(
      (event: JarvisEvent) => this._eventDispatch(event)
    );
  }

  ngOnDestroy(): void {
    if (this.appConfig.DEBUG) {
      console.log('ErrorUiComponent:ngOnDestroy ');
    }
    this.jarvisSubs.unsubscribe();
  }

  _eventDispatch(event: JarvisEvent): void {
    if (this.appConfig.DEBUG) {
      console.log('ErrorUiComponent._eventDispatch : ' + event.name);
    }
    switch (event.name) {
      case this.jarvisService.ERROR:
        this.show(event.resolve);
        break;
      default:
        break;
    }
  }

  show(resolve: any): void {
    // this.screenService.blur();
    $('#errorui').css('display', 'flex');
    $('#errorui .message').css('opacity', 0);
    $('.message-block > .points').css('opacity', 0);

    $('#errorMic1').css('opacity', 0);
    $('#errorMic2').css('opacity', 0);
    $('#errorMic3').css('opacity', 0);
    this.points = '00';
    gsap.to('#errorui', {
      duration: 0.3,
      autoAlpha: 1,
      delay: 0.8,
      ease: this.appConfig.tweenFunc,
      onStart: () => {
        this.screenService.blur();
        // this.jarvisService.hideui();
        this.soundService.dimSound();
      },
      onComplete: () => {
        const nbError = this.progressService.getProgress(
          this.progressService.ERROR
        );
        gsap.to('#errorui .message', {
          duration: 1,
          autoAlpha: 1,
          ease: 'power3.out',
          onComplete: () => {},
        });
        switch (nbError) {
          case 1:
            gsap.to('#errorMic1', {
              duration: 0.3,
              delay: 0,
              opacity: 1,
              ease: 'power3.out',
              onStart: () => {
                // setTimeout(() => {
                this.champion1 = this.champion_off;
                //   this.soundService.wobble()
                // }, 500)
              },
            });
            gsap.to('#errorMic2', {
              duration: 0.3,
              delay: 0,
              opacity: 1,
              ease: 'power3.out',
            });
            gsap.to('#errorMic3', {
              duration: 0.3,
              delay: 0,
              opacity: 1,
              ease: 'power3.out',
            });
            // this.soundService.playSound('error1', 1)

            break;
          case 2:
            gsap.to('#errorMic1', {
              duration: 0.3,
              delay: 0,
              opacity: 1,
              ease: 'power3.out',
              onStart: () => {
                this.champion1 = this.champion_off;
              },
            });
            gsap.to('#errorMic2', {
              duration: 0.3,
              delay: 0,
              opacity: 1,
              ease: 'power3.out',
              onStart: () => {
                // setTimeout(() => {
                this.champion2 = this.champion_off;
                // this.soundService.wobble()
                // }, 500)
              },
            });
            gsap.to('#errorMic3', {
              duration: 0.3,
              delay: 0,
              opacity: 1,
              ease: 'power3.out',
            });
            // this.soundService.playSound('error2', 1)

            break;

          default:
            break;
        }
        this.points = '00';
        $('.message-block > .points').css('opacity', 1);
        this.ngZone.run(() => {
          for (let i = 0; i < this.tmpTxt.length; i++) {
            this.letterRandomize(i);
          }
        });

        setTimeout(() => {
          this.hide(resolve);
        }, 1700);
      },
    });
  }

  hide(resolve: any): void {
    gsap.killTweensOf('#errorui');
    gsap.to('#errorui', {
      duration: 1,
      autoAlpha: 0,
      ease: this.appConfig.tweenFunc,
      onStart: () => {
        this.screenService.unblur();
        // this.jarvisService.showui();
        this.soundService.undimSound();
      },
      onComplete: () => {
        $('#errorui').css('display', 'none');
        resolve(true);
      },
    });
  }

  letterRandomize(index) {
    const intervals = [];
    const possible = '0123456789*-+=.;"()&%$#@!<>?';

    this.soundService.coins();

    intervals[index] = setInterval(() => {
      this.tmpTxt[index] = possible.charAt(
        Math.floor(Math.random() * possible.length)
      );
      this.points = this.tmpTxt.join('');
    }, 25);
    setTimeout(() => {
      clearInterval(intervals[index]);
      this.tmpTxt[index] = this.bonus.split('')[index];
      this.points = this.tmpTxt.join('');
    }, 600 * index);
  }
}
