import { Component, OnInit, OnDestroy } from '@angular/core';
import * as $ from 'jquery';
import { GameService } from 'src/app/services/game/game.service';
import gsap from 'gsap';
import { AppConfigService } from 'src/app/services/config/app-config.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'click-to-resume',
  templateUrl: './click-to-resume.component.html',
  styleUrls: ['./click-to-resume.component.scss'],
})
export class ClickToResumeComponent implements OnInit, OnDestroy {
  constructor(
    private appConfig: AppConfigService,
    private gameService: GameService
  ) {}

  closeBound;

  ngOnInit(): void {
    if (this.appConfig.DEBUG) {
      console.log('ClickToResumeComponent::ngOnInit');
    }
    this.closeBound = this.close.bind(this);
    $('#click-to-resume').on('click', this.closeBound);
  }

  ngOnDestroy(): void {
    if (this.appConfig.DEBUG) {
      console.log('ClickToResumeComponent::ngOnDestroy');
    }
    $('#click-to-resume').off('click', this.closeBound);
  }

  close(): void {
    this.gameService.resumeGame();
    gsap.to('#click-to-resume', {
      duration: 0.5,
      opacity: 0,
      ease: 'quad.out',
      onComplete: () => {
        $('#click-to-resume').css('display', 'none');
      },
    });
  }
}
