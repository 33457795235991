<button type="buttonType" class="simple-button" (click)="onClickButton($event)" (mouseover)="onRollOverButton($event)"
  (mouseout)="onRollOutButton($event)" [disabled]="isDisabled" [ngClass]="{
    loading: loading,
    disabled: isDisabled,
    active: isActivated,
    focus: isFocused,
    connect: isConnected,
    transparent: isTransparent
  }">
  <span>{{ buttonTextOver }}</span>
  <span>{{ buttonText }}</span>
</button>
