import {
  AfterViewInit,
  Component,
  NgZone,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { AppConfigService } from 'src/app/services/config/app-config.service';
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin';
import { RoughEase } from 'gsap/EasePack';
import gsap from 'gsap';
import * as $ from 'jquery';
import { ProgressService } from 'src/app/services/game/progress.service';
import { UtilsService } from 'src/app/services/utilities/utils.service';
import { RankingService } from 'src/app/services/game/ranking.service';
import { Router } from '@angular/router';
import {
  FacebookService,
  InitParams,
  UIParams,
  UIResponse,
} from 'ngx-facebook';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { ShareService } from '../../services/game/share.service';
import { RedbullTrackingService } from 'src/app/services/utilities/redbull-tracking.service';
import { SoundService } from 'src/app/services/sound/sound.service';
import * as screenfull from 'screenfull';
import { LoadService } from 'src/app/services/game/load.service';
import { SaveService } from 'src/app/services/game/save.service';
import { PlayerService } from 'src/app/services/user/player.service';
import { VideoplayerService } from 'src/app/services/video/videoplayer.service';
import { Subscription } from 'rxjs';
import { ButtonService } from 'src/app/services/view/button.service';
import {
  CursorService,
  CursorEvent,
} from 'src/app/services/view/cursor.service';

@Component({
  selector: 'app-end',
  templateUrl: './end.component.html',
  styleUrls: ['./end.component.scss'],
})
export class EndComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(
    private appConfig: AppConfigService,
    private progressService: ProgressService,
    private playerService: PlayerService,
    private loadService: LoadService,
    private saveService: SaveService,
    private soundService: SoundService,
    private rankingService: RankingService,
    private utils: UtilsService,
    private router: Router,
    private ngZone: NgZone,
    private $gaService: GoogleAnalyticsService,
    // private rbTracking: RedbullTrackingService,
    private shareService: ShareService,
    private cursorService: CursorService,
    private buttonService: ButtonService,

    private videoplayerService: VideoplayerService // private fb: FacebookService
  ) {}

  title1 = 'Classement';
  title1Over = 'top 10';
  title2 = 'Partage ton score';
  title2Over = 'share';
  title3 = 'Rejouer';
  title3Over = 'Bonne chance !';

  score: string;
  refs: string;
  gif = '/assets/images/end/gif/noob.gif';
  grad = '/assets/images/end/title/gameover.png';
  desc = '';

  shareUrl = 'https://playhard.redbull.com/';
  shareDesc = 'toto';
  shareFBDesc = 'toto';
  shareTitle = 'Red Bull Play Hard';
  shareTo = 'facebook,twitter,linkedin,whatsapp,telegram,email,copy';
  shareImg = '/assets/images/share/Share_Logo_1.png';

  params: InitParams;
  assetsUrl = this.appConfig.assetsUrl;
  buttonSubs: Subscription;
  isMobile = false;

  ngOnInit(): void {
    gsap.registerPlugin(DrawSVGPlugin, RoughEase);
    this.fillScore();
    const score = this.progressService.score[1];
    // this.shareDesc =
    //   "J'ai trouvé " +
    //   score +
    //   ' jeux sur Red Bull PlayHard, toi aussi teste ta culture Gaming ! https://playhard.redbull.com #RedBullPlayHard par #PopCorn66';
    // this.shareFBDesc =
    //   "J'ai trouvé " +
    //   score +
    //   ' jeux sur Red Bull PlayHard, toi aussi teste ta culture du Gaming ! https://playhard.redbull.com #RedBullPlayHard par #PopCorn66';

    // this.shareImg = this.shareService.getGifURL(score);
    // this.shareUrl = this.shareService.getGifURL(score);

    // score = 66;
    this.gif =
      this.assetsUrl +
      '/assets/images/end/' +
      this.rankingService.getRanking(score) +
      '.gif';

    this.grad =
      this.assetsUrl +
      '/assets/images/end/' +
      this.rankingService.getRanking(score) +
      '.png';

    // this.params = {
    //   appId: '379230166529523',
    //   // appId: '278968609110838',
    //   xfbml: true,
    //   version: 'v8.0',
    // };

    // this.fb.init(this.params);
    this.cursorService.loading();
    this._initButtonsEvent();

    // this.videoplayerService.show();
  }

  ngAfterViewInit(): void {
    this.$gaService.pageView('/end', 'End / Recap');
    this.desc = this.rankingService.getDesc();

    if (this.utils.md().mobile() !== null) {
      this.desc = this.desc.replace(/<br>/gi, ' ');
      this.isMobile = true;
    }

    this.$gaService.event('Final Score', 'Events', '' + this.score);
    this.$gaService.event('Final Refs', 'Events', '' + this.refs);

    this._showEnd();
  }

  ngOnDestroy(): void {
    // $(window).off('resize', this.onResizeBound)

    this.buttonSubs.unsubscribe();
  }

  _initButtonsEvent() {
    $('html,body,canvas').css('cursor', 'none');
    this.buttonSubs = this.buttonService.emitter$.subscribe((event) =>
      this._eventDispatch(event)
    );
  }

  onRollOver(): void {
    this.cursorService.emitEvent(new CursorEvent(this.cursorService.ROLLOVER));
  }

  onRollOut(): void {
    this.cursorService.emitEvent(new CursorEvent(this.cursorService.ROLLOUT));
  }

  _eventDispatch(event: any) {
    switch (event.name) {
      case this.buttonService.ROLLOVER:
        this.cursorService.emitEvent(
          new CursorEvent(this.cursorService.ROLLOVER)
        );
        break;
      case this.buttonService.ROLLOUT:
        this.cursorService.emitEvent(
          new CursorEvent(this.cursorService.ROLLOUT)
        );
        break;
      default:
        break;
    }
  }

  fillScore(): void {
    this.score = this.utils.pad(this.progressService.score[0], 4);
    this.refs = this.utils.pad(this.progressService.score[1], 2);
  }

  _showEnd(): void {
    this.cursorService.normal();

    gsap.to('#end', {
      duration: 0.6,
      autoAlpha: 1,
      ease: 'power3.out',
    });

    gsap.to('.gif', {
      delay: 0.1,
      duration: 0.6,
      opacity: 1,
      ease: 'power3.out',
    });
    // gsap.to('.gameover', {
    //   delay: 0.2,
    //   duration: 0.6,
    //   opacity: 1,
    //   ease: 'power3.out',
    // });

    gsap.to('.grad', {
      delay: 0.4,
      duration: 0.6,
      opacity: 1,
      ease: 'power3.out',
    });
    gsap.to('.score', {
      delay: 0.5,
      duration: 0.6,
      opacity: 1,
      ease: 'power3.out',
    });
    gsap.to('.btn1', {
      delay: 0.6,
      duration: 0.6,
      opacity: 1,
      ease: 'power3.out',
    });
    gsap.to('.btn2', {
      delay: 0.8,
      duration: 0.6,
      opacity: 1,
      ease: 'power3.out',
    });
  }

  none(): void {}

  restart(): void {
    this.$gaService.event('Play Again', 'Interactions', 'Play Again');
    // this.rbTracking.track(
    //   'gaEvent',
    //   'Interactions',
    //   'Play Again',
    //   'Play Again',
    //   'Red Bull Play Hard'
    // );

    document.location.reload();
    return;

    $('.footer').css('display', 'none');
    this.progressService.reset();
    this.playerService.gameid = '';
    this.loadService.reset();
    this.progressService.resetAnswers();
    this.saveService.reset();
    const div = '#end';
    this.soundService.stopIntro();
    // if (this.utils.md().mobile() !== null) {
    //   if (this.utils.md().os() !== 'iOS' && this.utils.md().os() !== 'iPadOS') {
    //     if (screenfull.isEnabled) {
    //       screenfull.toggle();
    //     }
    //   }
    // }
    gsap.killTweensOf(div);
    gsap.to(div, {
      duration: 0.3,
      autoAlpha: 0,
      ease: this.appConfig.tweenFunc,
      onComplete: () => {
        // if (this.utils.md().mobile() !== null) {
        //   this.rotateDeviceService
        //     .switchToHorizontal('pour jouer.')
        //     .then(() => {
        //       this.ngZone.run(() =>
        //         this.router.navigate(['/game'], {
        //           // this.router.navigate(['/end'], {
        //           skipLocationChange: true,
        //         })
        //       );
        //     });
        // } else {
        this.ngZone.run(() =>
          this.router.navigate(['/game'], {
            // this.router.navigate(['/end'], {
            skipLocationChange: true,
          })
        );
        // }
      },
    });
  }

  // shareFB(): void {
  //   const score = this.progressService.score[1];
  //   this.shareUrl = this.shareService.getGifURL(score);

  //   this.fb
  //     .ui({
  //       method: 'share',
  //       href: this.shareUrl,
  //       quote: this.shareFBDesc,
  //       hashtag: '#RedBullPlayHard',
  //     })
  //     .then((res: UIResponse) => {
  //       if (this.appConfig.DEBUG) {
  //         console.log(res);
  //       }
  //     })
  //     .catch((e: any) => console.log(e));
  //   this.$gaService.event(
  //     'Share Facebook',
  //     'Interactions',
  //     'Share score on FaceBook'
  //   );
  //   this.rbTracking.track(
  //     'gaEvent',
  //     'Interactions',
  //     'Share Facebook',
  //     'Share score on Facebook',
  //     'Red Bull Play Hard'
  //   );
  // }

  shareTwitter(): void {
    this.$gaService.event(
      'Share Twitter',
      'Interactions',
      'Share score on Twitter'
    );
    // this.rbTracking.track(
    //   'gaEvent',
    //   'Interactions',
    //   'Share Twitter',
    //   'Share score on Twitter',
    //   'Red Bull Play Hard'
    // );
  }

  ranking(): void {
    this.$gaService.event(
      'Show Ranking From End Page',
      'Interactions',
      'Show Ranking From End Page'
    );
    // this.rbTracking.track(
    //   'gaEvent',
    //   'Interactions',
    //   'Show Ranking From End Page',
    //   'Show Ranking From End Page',
    //   'Red Bull Play Hard'
    // );
    const div = '#end';
    // this.sounsService.stopIntro();
    gsap.killTweensOf(div);
    gsap.to(div, {
      duration: 0.3,
      autoAlpha: 0,
      ease: this.appConfig.tweenFunc,
      onComplete: () => {
        this.ngZone.run(() =>
          this.router.navigate(['/ranking'], {
            skipLocationChange: true,
          })
        );
      },
    });
  }
}
