import { Component, NgZone, OnInit, OnDestroy } from '@angular/core';
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin';
import { RoughEase } from 'gsap/EasePack';
import gsap from 'gsap';
import * as $ from 'jquery';

import { RankingService } from 'src/app/services/game/ranking.service';
import { ServerService } from 'src/app/services/http/server.service';
import { HighScore } from 'src/app/model/ranking';
import { Router } from '@angular/router';
import {
  CursorService,
  CursorEvent,
} from 'src/app/services/view/cursor.service';
import { AppConfigService } from 'src/app/services/config/app-config.service';
import { PlayerService } from 'src/app/services/user/player.service';
import { UtilsService } from 'src/app/services/utilities/utils.service';
import { ProgressService } from 'src/app/services/game/progress.service';
import { RotateDeviceService } from 'src/app/services/view/rotate-device.service';
import { ButtonService } from 'src/app/services/view/button.service';
import { Subscription } from 'rxjs';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'ranking',
  templateUrl: './ranking.component.html',
  styleUrls: ['./ranking.component.scss'],
})
export class RankingComponent implements OnInit, OnDestroy {
  constructor(
    private rankingService: RankingService,
    private appConfig: AppConfigService,
    private serverService: ServerService,
    private progressService: ProgressService,
    private playerService: PlayerService,
    private rotateDeviceService: RotateDeviceService,
    private $gaService: GoogleAnalyticsService,

    private ngZone: NgZone,
    private router: Router,
    private cursorService: CursorService,
    private buttonService: ButtonService,
    private utils: UtilsService
  ) {}

  highScore: Array<HighScore> = [];
  isConnected = false;

  playerName = '';
  playerScore = '0000';
  playerNbRefs = '00';
  playerPosition = 0;
  playerFlag = 'xx';
  assetsUrl = this.appConfig.assetsUrl;
  onCloseBound;
  rollOverBound;
  rollOutBound;
  private onResizeBound: () => {};
  buttonSubs: Subscription;
  MAX = 10;
  showPlayer = false;

  ngOnInit(): void {
    gsap.registerPlugin(DrawSVGPlugin, RoughEase);

    this.isConnected = this.playerService.isConnected;
    this.MAX = this.appConfig.TOP_LIMIT;

    if (this.isConnected) {
      if (this.playerService.gameid === '') this.showPlayer = false;
      else this.showPlayer = true;

      this.playerName = this.playerService.name;
      this.playerScore = this.utils.pad(this.progressService.score[0], 4);
      this.playerPosition = this.playerService.position;
      this.playerFlag = this.playerService.location;

      // if (this.progressService.score[1] < this.appConfig.MAX_REFS) {
      //   this.showPlayer = false
      // }
    }

    // $('.wrapper').css('overflow-y', 'unset')

    this.serverService.getTop66().subscribe((data: Array<HighScore>) => {
      this.highScore = data;
      if (this.highScore.length < this.appConfig.TOP_LIMIT) {
        const diff = this.appConfig.TOP_LIMIT - this.highScore.length;
        for (let i = 0; i < diff; i++) {
          const zombie = new HighScore();
          zombie.name = 'AAA';
          zombie.score = 0;
          this.highScore.push(zombie);
        }
      }

      this.onCloseBound = this.back.bind(this);
      this.rollOverBound = this.onRollOver.bind(this);
      this.rollOutBound = this.onRollOut.bind(this);

      $('#ranking .close-cross').on('mouseover', this.rollOverBound);
      $('#ranking .close-cross').on('mouseout', this.rollOutBound);
      $('#ranking').on('click', this.onCloseBound);
      // $('#ranking').css('cursor', 'pointer')
      this.onResizeBound = this._onCanvasResize.bind(this);
      $(window).on('resize', this.onResizeBound);
      this._onCanvasResize();
      this._initButtonsEvent();

      this.formatScore();
      this.showRanking();
    });
  }

  ngOnDestroy(): void {
    if (this.appConfig.DEBUG) {
      console.log('RankingComponent:ngOnDestroy');
    }
    if (this.isConnected) {
    }
    $('#ranking').off('click', this.onCloseBound);
    $('#ranking .close-cross').off('mouseover', this.rollOverBound);
    $('#ranking .close-cross').off('mouseout', this.rollOutBound);
    $(window).off('resize', this.onResizeBound);
  }

  private _initButtonsEvent() {
    this.buttonSubs = this.buttonService.emitter$.subscribe((event) =>
      this._eventDispatch(event)
    );
  }
  private _eventDispatch(event: any) {
    switch (event.name) {
      case this.buttonService.ROLLOVER:
        this.cursorService.emitEvent(
          new CursorEvent(this.cursorService.ROLLOVER)
        );
        break;
      case this.buttonService.ROLLOUT:
        this.cursorService.emitEvent(
          new CursorEvent(this.cursorService.ROLLOUT)
        );
        break;
      default:
        break;
    }
  }

  formatScore(): void {
    for (let i = 0; i < this.highScore.length; i++) {
      this.highScore[i].scoreStr = this.utils.pad(this.highScore[i].score, 4);
    }
  }

  showRanking(): void {
    this.cursorService.cross();
    if (this.appConfig.GA_STATS) {
      this.$gaService.pageView('/ranking', 'Ranking');
    }
    gsap.to('#ranking', {
      duration: 0.6,
      opacity: 1,
      ease: 'power3.out',
    });
    gsap.to('.ranking-title', {
      y: -20,
      duration: 0,
    });
    gsap.to('.ranking-title', {
      delay: 0.1,
      duration: 0.6,
      y: 0,
      opacity: 1,
      ease: 'power3.out',
    });
    gsap.to('.ranking-goal', {
      delay: 0.2,
      duration: 0.6,
      opacity: 1,
      ease: 'power3.out',
    });
  }

  onRollOver(): void {
    this.cursorService.emitEvent(new CursorEvent(this.cursorService.ROLLOVER));
  }

  onRollOut(): void {
    this.cursorService.emitEvent(new CursorEvent(this.cursorService.ROLLOUT));
  }

  onMouseWheel(evt) {
    // console.log('Wheel event: ', evt)
    evt.preventDefault();

    let documentHeight = Math.max(
      document.body.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.clientHeight,
      document.documentElement.scrollHeight,
      document.documentElement.offsetHeight
    );
    let windowHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.getElementsByTagName('body')[0].clientHeight;

    let destinationOffset =
      document.getElementById('ranking').scrollHeight - documentHeight;

    if (destinationOffset > 0) {
      var destinationOffsetToScroll = Math.round(
        documentHeight - destinationOffset < windowHeight
          ? documentHeight - windowHeight
          : destinationOffset
      );
      let top = $('#ranking').offset().top;
      let delta = evt.deltaY / 2;
      top -= delta;
      if (top > 0) {
        top = 0;
      }
      if (top <= -1 * destinationOffset) {
        top = -1 * destinationOffset;
      }

      gsap.to('#ranking', {
        duration: 0.6,
        top: top,
        ease: this.appConfig.tweenFunc,
        overwrite: true,
      });

      // console.log('documentHeight ' + documentHeight)
      // console.log('windowHeight ' + windowHeight)
      // console.log('destinationOffset ' + destinationOffset)
      // console.log('destinationOffsetToScroll ' + destinationOffsetToScroll)
      // // let top = $('#ranking').scrollTop()
      // console.log('top ' + top)
    } else {
      gsap.to('#ranking', {
        duration: 0.6,
        top: 0,
        ease: this.appConfig.tweenFunc,
        overwrite: true,
      });
    }
  }
  back(): void {
    if (this.playerService.session) {
      if (this.playerService.gameid !== '') {
        this._goto('/hello');
      } else {
        this._goto('/home');
      }
    } else {
      this._goto('/end');
    }
  }

  _goto(page: string): void {
    $('#closeRanking').css('pointer-events', 'none');

    this.ngZone.run(() =>
      this.router.navigate([page], {
        skipLocationChange: true,
      })
    );
  }

  _onCanvasResize() {
    //   if (this.appConfig.DEBUG) {
    //     console.log(
    //       'RankingComponent:_onCanvasResize scale : ' + this.utils.getUIScale()
    //     );
    //   }
    //   gsap.to('#ranking', {
    //     duration: 0.6,
    //     top: 0,
    //     ease: this.appConfig.tweenFunc,
    //     overwrite: true,
    //   });
    //   if (this.utils.md().mobile() !== null) {
    //     if (this.rotateDeviceService.getOrientation() === 'landscape') {
    //       this.rotateDeviceService.enable();
    //     } else {
    //       this.rotateDeviceService.disable();
    //     }
    //   }
    //   if (this.utils.getUIScale() > 1) {
    //     $('#ranking').css('transform', 'scale(' + this.utils.getUIScale() + ')');
    //   } else {
    //     $('#ranking').css('transform', 'unset');
    //   }
  }
}
