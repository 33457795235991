import { NgModule } from '@angular/core';

import { IntroRoutingModule } from './intro-routing.module';
import { PreHomeComponent } from '../../sections/pre-home/pre-home.component';
import { SharedModule } from '../shared/shared.module';
import { HomeComponent } from '../../sections/home/home.component';
import { HelloComponent } from 'src/app/sections/hello/hello.component';
import { HomeLoginComponent } from 'src/app/sections/home-login/home-login.component';
import { AuthModule } from '../auth/auth.module';

@NgModule({
  declarations: [
    PreHomeComponent,
    HomeComponent,
    HelloComponent,
    HomeLoginComponent,
  ],
  imports: [IntroRoutingModule, SharedModule, AuthModule],
  exports: [PreHomeComponent],
})
export class IntroModule {}
