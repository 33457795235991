import { Component, OnInit, OnDestroy } from '@angular/core';
import * as $ from 'jquery';
import { Scene, WebGLRenderer, Mesh, PerspectiveCamera, Texture } from 'three';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import {
  MaskPass,
  ClearMaskPass,
} from 'three/examples/jsm/postprocessing/MaskPass';
import { ClearPass } from 'three/examples/jsm/postprocessing/ClearPass';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass';
import { CopyShader } from 'three/examples/jsm/shaders/CopyShader';
import { FilmShader } from 'three/examples/jsm/shaders/FilmShader';
import { VignetteShader } from 'three/examples/jsm/shaders/VignetteShader';
import { HorizontalBlurShader } from 'three/examples/jsm/shaders/HorizontalBlurShader';
import { VerticalBlurShader } from 'three/examples/jsm/shaders/VerticalBlurShader';
import { isWebGLSupported } from 'webgl-detector';

// import { ConsoleLoggerService } from 'src/app/services/log/console-logger.service';
import { AppConfigService } from 'src/app/services/config/app-config.service';
import * as THREE from 'three';
import { PreloaderService } from 'src/app/services/utilities/preloader.service';
import {
  ScreenService,
  ScreenEvent,
} from 'src/app/services/view/screen.service';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
// import { RenderPass } from '@johh/three-effectcomposer';
import { MouseControlsService } from 'src/app/services/view/mouse-controls.service';
import gsap from 'gsap';

import { GameService } from 'src/app/services/game/game.service';
import * as Stats from 'stats-js';
import { UtilsService } from 'src/app/services/utilities/utils.service';
import { SoundService } from 'src/app/services/sound/sound.service';
import { Subscription } from 'rxjs';

// declare var ScreenShake: any;

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'webgl-gameview',
  templateUrl: './gameview.component.html',
  styleUrls: ['./gameview.component.scss'],
})
export class WebGLGameviewComponent implements OnInit, OnDestroy {
  constructor(
    private appConfig: AppConfigService,
    // private logger: ConsoleLoggerService,
    private screenService: ScreenService,
    private preloaderService: PreloaderService,
    private mouseControlsService: MouseControlsService,
    private gameService: GameService,
    private soundService: SoundService,
    private utils: UtilsService
  ) {}

  private scene: Scene;
  private transitionScene: Scene;
  private camera: PerspectiveCamera;
  private renderer: WebGLRenderer;
  private composer: EffectComposer;
  private renderPass: RenderPass;
  private outputPass: ShaderPass;
  private vignettePass: ShaderPass;
  private hBlurPass: ShaderPass;
  private vBlurPass: ShaderPass;
  private filmPass: ShaderPass;
  private plane: Mesh;
  private mainTexture: Texture;
  private onRenderFcts = [];
  private zpos = 0;
  private stats = new Stats();
  private idle = false;
  private renderRequested = false;
  private blurIntensity = 2;
  private blurErrorIntensity = 6;
  private blurDuration = this.appConfig.ERROR_DURATION;
  transitionDuration = 1;
  errorTween;
  // private screenShake: any;

  filmParams = {
    show: true,
    sCount: 4096,
    sIntensity: 0.01,
    nIntensity: 0.07,
  };

  onResizeBound;
  gameSub: Subscription;
  screenSub: Subscription;

  ngOnInit(): void {
    // this.stats.showPanel(0)
    // document.body.appendChild(this.stats.dom);
    if (this.appConfig.DEBUG) {
      console.log('WebGLGameviewComponent ngOnInit THREE v' + THREE.REVISION);
    }
    if (isWebGLSupported()) {
      // webGL is supported
      console.log('webGL is supported');
      this.appConfig.isWebGLSupported = true;
      this.gameSub = this.gameService.emitter$.subscribe((event: any) =>
        this._eventDispatch(event)
      );
      this.screenSub = this.screenService.emitter$.subscribe((event: any) =>
        this._eventDispatch(event)
      );
      this.onResizeBound = this._onResize.bind(this);
      this.init3DEngine();
      $(() => {
        this._onResize();
        $(window).on('resize', this.onResizeBound);
      });
    } else {
      console.log('webGL is not supported!');
    }
  }

  ngOnDestroy(): void {
    $(window).off('resize', this.onResizeBound);
    this.screenSub.unsubscribe();
    this.gameSub.unsubscribe();
    this.mouseControlsService.resetEvents();
  }

  /**
   * Dispatch en fct de la valeur d'event
   * @param event  event
   */
  private _eventDispatch(event): void {
    switch (event.name) {
      case this.screenService.ENABLE:
        this.enable();
        break;
      case this.screenService.DISABLE:
        this.disable();
        break;
      case this.screenService.GAMEERROR:
        this.error((event as ScreenEvent).errors);
        break;
      case this.screenService.BLUR:
        this._blur();
        break;
      case this.screenService.UNBLUR:
        this._unblur();
        break;
      case this.screenService.STOP:
        this._stop();
        break;
      case this.screenService.PAUSE:
        this._pause();
        break;
      case this.screenService.RESUME:
        this._resume();
        break;
      case this.gameService.START:
        this._animate();
        this._animateMask();
        break;
      case this.gameService.STOP:
        this._reverseMask();
        break;
      case this.mouseControlsService.CHANGE:
        this._requestRenderIfNotRequested();
        break;

      default:
        break;
    }
  }

  init3DEngine(): void {
    if (this.appConfig.DEBUG) {
      console.log('WebGLGameviewComponent init3DEngine');
    }
    let innerWidth = window.innerWidth;
    let innerHeight = window.innerHeight;
    if (this.utils.md().mobile()) {
      innerWidth = window.screen.width;
      innerHeight = window.screen.height;
    }
    // this.screenShake = ScreenShake();
    this.scene = new THREE.Scene();
    this.scene.background = null;

    this.camera = new THREE.PerspectiveCamera(
      60,
      innerWidth / innerHeight,
      0.1,
      5000
    );

    const canvas: HTMLCanvasElement = document.querySelector('#gameview');
    canvas.style.opacity = '1';

    $('#gameview').on('contextmenu', (e) => {
      return false;
    });
    this.renderer = new THREE.WebGLRenderer({
      canvas,
      alpha: true,
      antialias: false,
      premultipliedAlpha: false,
      powerPreference: 'high-performance',
    });
    // this.renderer.setPixelRatio(window.devicePixelRatio)
    this.renderer.setClearColor(this.appConfig.CANVAS_COLOR, 0);

    this.mainTexture = new THREE.Texture(
      this.preloaderService.queue.getResult('gameview')
    );
    this.mainTexture.needsUpdate = true;
    this.mainTexture.wrapS = THREE.RepeatWrapping;
    this.mainTexture.wrapT = THREE.RepeatWrapping;
    this.mainTexture.repeat.set(1, 1);
    this.mainTexture.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
    // this.mainTexture.minFilter = THREE.LinearMipMapLinearFilter;
    // this.mainTexture.magFilter = THREE.LinearMipMapLinearFilter;
    this.mainTexture.minFilter = THREE.LinearFilter;
    this.mainTexture.magFilter = THREE.LinearFilter;
    const material = new THREE.MeshBasicMaterial({
      map: this.mainTexture,
    });
    const geometry = new THREE.PlaneBufferGeometry(
      this.appConfig.viewWidth,
      this.appConfig.viewHeight,
      1
    );

    this.plane = new THREE.Mesh(geometry, material);

    // tslint:disable-next-line: no-string-literal
    this.plane.material['depthTest'] = false;
    // tslint:disable-next-line: no-string-literal
    this.plane.material['depthWrite'] = false;

    this.scene.add(this.plane);
    this.scene.add(this.camera);

    const aspect = innerWidth / innerHeight;
    const ratio =
      this.appConfig.RESOLUTION.width / this.appConfig.RESOLUTION.height;

    if (aspect >= ratio) {
      this.zpos =
        this.appConfig.RESOLUTION.width /
        aspect /
        (2 * Math.tan((this.camera.fov * Math.PI) / 360));
    } else {
      this.zpos =
        this.appConfig.RESOLUTION.height /
        (2 * Math.tan((this.camera.fov * Math.PI) / 360));
    }

    this.camera.position.z = this.zpos;
    // this.renderPass = new RenderPass(this.scene, this.camera);
    // this.renderPass.clear = false;

    this.mouseControlsService.init(this.camera, this.plane, this.renderer);

    this.onRenderFcts.push(() => {
      this.mouseControlsService.update();
    });

    this._createMask();
    this.outputPass = new ShaderPass(CopyShader);
    this.renderPass = new RenderPass(this.scene, this.camera);
    this.renderPass.clear = false;
    const parameters = {
      minFilter: THREE.LinearFilter,
      magFilter: THREE.NearestFilter,
      format: THREE.RGBFormat,
      stencilBuffer: true,
    };
    const renderTarget = new THREE.WebGLRenderTarget(
      innerWidth,
      innerHeight,
      parameters
    );
    const clearPass = new ClearPass();
    const clearMaskPass = new ClearMaskPass();
    const maskPass = new MaskPass(this.transitionScene, this.camera);
    this.filmPass = new ShaderPass(FilmShader);

    // tslint:disable-next-line: no-string-literal
    this.filmPass.uniforms['sCount'].value = this.filmParams.sCount;
    // tslint:disable-next-line: no-string-literal
    this.filmPass.uniforms['sIntensity'].value = this.filmParams.sIntensity;
    // tslint:disable-next-line: no-string-literal
    this.filmPass.uniforms['nIntensity'].value = this.filmParams.nIntensity;
    // tslint:disable-next-line: no-string-literal
    this.filmPass.uniforms['grayscale'].value = 0;

    this.vignettePass = new ShaderPass(VignetteShader);
    // tslint:disable-next-line: no-string-literal
    this.vignettePass.uniforms['offset'].value = 0.6;
    // tslint:disable-next-line: no-string-literal
    this.vignettePass.uniforms['darkness'].value = 1.42;

    this.hBlurPass = new ShaderPass(HorizontalBlurShader);
    this.hBlurPass.enabled = false;
    // tslint:disable-next-line: no-string-literal
    this.hBlurPass.uniforms['h'].value = 1 / window.innerHeight;

    this.vBlurPass = new ShaderPass(VerticalBlurShader);
    this.vBlurPass.enabled = false;
    // tslint:disable-next-line: no-string-literal
    this.vBlurPass.uniforms['v'].value = 1 / window.innerWidth;

    // this.bleachPass = new ShaderPass(BleachBypassShader);

    this.composer = new EffectComposer(this.renderer, renderTarget);

    this.composer.addPass(clearPass);
    this.composer.addPass(maskPass);
    this.composer.addPass(this.renderPass);
    this.composer.addPass(clearMaskPass);
    this.composer.addPass(this.filmPass);
    this.composer.addPass(this.hBlurPass);
    this.composer.addPass(this.vBlurPass);
    // this.composer.addPass(this.vignettePass);
    this.composer.addPass(this.outputPass);

    this.outputPass.renderToScreen = true;
    // this.mouseControlsService.initEvents();
    this.screenService.emitEvent(
      new ScreenEvent(this.screenService.GAMEVIEWLOADED)
    );
  }

  private _onResize(): void {
    this.mouseControlsService.handleResize();
    this._requestRenderIfNotRequested();
  }

  private _animate(): void {
    // console.log('_animate: idle = ' + this.idle);

    if (!this.idle) {
      this.stats.begin();
      this.renderRequested = false;

      const time = performance.now() * 0.001;
      let innerWidth = window.innerWidth;
      let innerHeight = window.innerHeight;
      if (this.utils.md().mobile()) {
        innerWidth = window.screen.width;
        innerHeight = window.screen.height;
      }
      if (this._resizeRendererToDisplaySize(this.renderer, this.composer)) {
        this.camera.aspect = innerWidth / innerHeight;
        this.camera.updateProjectionMatrix();

        this.mouseControlsService.handleResize();
      }

      this.onRenderFcts.forEach((onRenderFct) => {
        onRenderFct();
      });

      // this.screenShake.update(this.camera);
      this.renderer.clear();
      this.composer.render(time);
      this.stats.end();

      // requestAnimationFrame(() => {
      //   this._animate();
      // });
    }
  }

  private _requestRenderIfNotRequested(): void {
    if (!this.renderRequested) {
      // console.log('_requestRenderIfNotRequested');

      this.renderRequested = true;
      requestAnimationFrame(() => {
        this._animate();
      });
    }
  }

  enable(): void {
    // console.log('enable: idle = ' + this.idle);

    if (this.idle) {
      if (this.appConfig.DEBUG) {
        console.log('WebGLGameviewComponent:enable');
      }

      this.idle = false;
      // console.log('enable: set idle = ' + this.idle);

      this.mouseControlsService.enable();

      this._animate();
    }
  }

  disable(): void {
    // console.log('disable: idle = ' + this.idle);

    if (!this.idle) {
      if (this.appConfig.DEBUG) {
        console.log('WebGLGameviewComponent:disable');
      }

      this.idle = true;
      // console.log('disable: set idle = ' + this.idle);

      this.mouseControlsService.disable();
    }
  }

  _stop(): void {
    if (this.appConfig.DEBUG) {
      console.log('WebGLGameviewComponent:stop');
    }
    // gsap.killTweensOf(this.filmPass.uniforms['nIntensity']);
    // // this.filmPass.uniforms['grayscale'].value = 1;
    // this._requestRenderIfNotRequested();

    this.idle = false;
    // console.log('enable: set idle = ' + this.idle);

    this._animate();
    // }
    // tslint:disable-next-line: no-string-literal
    this.hBlurPass.uniforms['h'].value = 0;
    // tslint:disable-next-line: no-string-literal
    this.vBlurPass.uniforms['v'].value = 0;
    this.vBlurPass.enabled = true;
    this.hBlurPass.enabled = true;

    // tslint:disable-next-line: no-string-literal
    gsap.killTweensOf(this.hBlurPass.uniforms['h']);
    // tslint:disable-next-line: no-string-literal
    gsap.killTweensOf(this.vBlurPass.uniforms['v']);
    // tslint:disable-next-line: no-string-literal
    gsap.killTweensOf(this.filmPass.uniforms['nIntensity']);

    // tslint:disable-next-line: no-string-literal
    gsap.to(this.hBlurPass.uniforms['h'], {
      duration: 0.6,
      value: this.blurIntensity / window.innerWidth,
      ease: 'power3.out',
      onStart: () => {
        this._requestRenderIfNotRequested();
      },
      onUpdate: () => {
        this.filmPass.uniforms['grayscale'].value = 1;
        this._requestRenderIfNotRequested();
      },
      onComplete: () => {
        this._requestRenderIfNotRequested();
        this.disable();
      },
    });

    // tslint:disable-next-line: no-string-literal
    gsap.to(this.vBlurPass.uniforms['v'], {
      duration: 0.6,
      value: this.blurIntensity / window.innerHeight,
      ease: 'power3.out',
    });
  }
  _pause(): void {
    if (this.appConfig.DEBUG) {
      console.log('WebGLGameviewComponent:pause');
    }
    gsap.killTweensOf(this.filmPass.uniforms['nIntensity']);
    this.filmPass.uniforms['grayscale'].value = 1;
    this._requestRenderIfNotRequested();
  }
  _resume(): void {
    if (this.appConfig.DEBUG) {
      console.log('WebGLGameviewComponent:_resume');
    }
    gsap.killTweensOf(this.filmPass.uniforms['nIntensity']);
    this.filmPass.uniforms['grayscale'].value = 0;
    this._requestRenderIfNotRequested();
  }

  _blur(): void {
    // return;
    if (this.appConfig.DEBUG) {
      console.log('WebGLGameviewComponent:blur');
    }
    // if (this.idle) {
    //   console.log('WebGLGameviewComponent:enable');

    this.idle = false;
    // console.log('enable: set idle = ' + this.idle);

    this._animate();
    // }
    // tslint:disable-next-line: no-string-literal
    this.hBlurPass.uniforms['h'].value = 0;
    // tslint:disable-next-line: no-string-literal
    this.vBlurPass.uniforms['v'].value = 0;
    this.vBlurPass.enabled = true;
    this.hBlurPass.enabled = true;

    // tslint:disable-next-line: no-string-literal
    gsap.killTweensOf(this.hBlurPass.uniforms['h']);
    // tslint:disable-next-line: no-string-literal
    gsap.killTweensOf(this.vBlurPass.uniforms['v']);
    // tslint:disable-next-line: no-string-literal
    gsap.killTweensOf(this.filmPass.uniforms['nIntensity']);

    // tslint:disable-next-line: no-string-literal
    gsap.to(this.hBlurPass.uniforms['h'], {
      duration: 0.6,
      value: this.blurIntensity / window.innerWidth,
      ease: 'power3.out',
      onStart: () => {
        this._requestRenderIfNotRequested();
      },
      onUpdate: () => {
        // tslint:disable-next-line: no-string-literal
        this.filmPass.uniforms['grayscale'].value = 1;
        this._requestRenderIfNotRequested();
      },
      onComplete: () => {
        this._requestRenderIfNotRequested();
        this.disable();
      },
    });

    // tslint:disable-next-line: no-string-literal
    gsap.to(this.vBlurPass.uniforms['v'], {
      duration: 0.6,
      value: this.blurIntensity / window.innerHeight,
      ease: 'power3.out',
    });
  }

  _unblur(): void {
    // return;
    if (this.appConfig.DEBUG) {
      console.log('WebGLGameviewComponent:unblur');
    }
    // tslint:disable-next-line: no-string-literal
    gsap.killTweensOf(this.hBlurPass.uniforms['h']);
    // tslint:disable-next-line: no-string-literal
    gsap.killTweensOf(this.vBlurPass.uniforms['v']);
    // tslint:disable-next-line: no-string-literal
    gsap.killTweensOf(this.filmPass.uniforms['nIntensity']);

    // tslint:disable-next-line: no-string-literal
    gsap.to(this.hBlurPass.uniforms['h'], {
      duration: 0.3,
      value: 0,
      ease: 'power3.out',
      onStart: () => {
        this._requestRenderIfNotRequested();
        this.filmPass.uniforms['grayscale'].value = 0;
      },
      onUpdate: () => {
        this._requestRenderIfNotRequested();
      },
      onComplete: () => {
        this._requestRenderIfNotRequested();
        this.hBlurPass.enabled = false;
        this.enable();
      },
    });

    // tslint:disable-next-line: no-string-literal
    gsap.to(this.vBlurPass.uniforms['v'], {
      duration: 0.3,
      value: 0,
      ease: 'power3.out',
      onComplete: () => {
        this.vBlurPass.enabled = false;
      },
    });

    // tslint:disable-next-line: no-string-literal
    // TweenLite.to(this.filmPass.uniforms['nIntensity'], 0.3, {
    //   value: this.filmParams.nIntensity,
    //   ease: Power3.easeOut
    // });
  }

  error(nbErrors): void {
    if (this.appConfig.DEBUG) {
      console.log('WebGLGameviewComponent:error ' + nbErrors);
    }

    // tslint:disable-next-line: no-string-literal
    this.hBlurPass.uniforms['h'].value = 0;
    // tslint:disable-next-line: no-string-literal
    this.vBlurPass.uniforms['v'].value = 0;
    this.vBlurPass.enabled = true;
    this.hBlurPass.enabled = true;
    // tslint:disable-next-line: no-string-literal
    this.filmPass.uniforms['grayscale'].value = 0;
    this.soundService.dimSound();
    this.soundService.zap();
    this.soundService.bump();
    this.soundService.negative();
    // setTimeout(() => {
    //   this.soundService.oooh();
    // }, 500);

    if (nbErrors > this.appConfig.MAX_ERRORS - 1) {
      // this.filmPass.uniforms["grayscale"].value = 1;
      this.blurDuration /= 2;
      this.blurErrorIntensity /= 2;
    }
    // tslint:disable-next-line: no-string-literal
    gsap.killTweensOf(this.hBlurPass.uniforms['h']);
    // tslint:disable-next-line: no-string-literal
    gsap.killTweensOf(this.vBlurPass.uniforms['v']);
    // tslint:disable-next-line: no-string-literal
    gsap.killTweensOf(this.filmPass.uniforms['nIntensity']);
    gsap.killTweensOf('#error');

    // this.screenShake.shake(this.camera, new THREE.Vector3(0.1, 0, 0), 300);

    // tslint:disable-next-line: no-string-literal
    gsap.to(this.hBlurPass.uniforms['h'], {
      duration: 0,
      value: this.blurErrorIntensity / window.innerWidth,
      ease: 'power3.out',
      onStart: () => {
        this._requestRenderIfNotRequested();
      },
      onUpdate: () => {
        this._requestRenderIfNotRequested();
      },
      onComplete: () => {
        if (this.errorTween) {
          gsap.killTweensOf('.error-warning');
        }
        $('#error').css('display', 'block').css('opacity', 1);
        $('.error-warning').css('display', 'block').css('opacity', 1);
        // gsap.to(".warning", { duration: 0, opacity: 1, ease: "power3.out" });
        // gsap.to("#error", { duration: 0, opacity: 1, ease: "power3.out" });
        // tslint:disable-next-line: no-string-literal
        this.mouseControlsService._onError(this.blurDuration);
        // tslint:disable-next-line: no-string-literal
        gsap.to(this.hBlurPass.uniforms['h'], {
          duration: this.blurErrorIntensity / 2,
          value: 0,
          ease: 'power3.out',
          onStart: () => {
            this._requestRenderIfNotRequested();

            gsap.to('#error', {
              duration: this.blurDuration,
              opacity: 0,
              delay: 0.5,
              ease: 'power3.out',
              onComplete: () => {
                $('#error').css('display', 'none');
              },
            });
            if (nbErrors === this.appConfig.MAX_ERRORS - 1) {
              gsap.to('.error-warning', {
                duration: this.blurDuration,
                opacity: 0,
                delay: 0.5,
                ease: 'power3.out',
                onComplete: () => {
                  this.errorTween = gsap.to('.error-warning', {
                    duration: 1.6,
                    opacity: 0.7,
                    ease: 'power3.out',
                    repeat: -1,
                    yoyo: true,
                  });
                },
              });
            } else {
              gsap.to('.error-warning', {
                duration: this.blurDuration,
                opacity: 0,
                delay: 0.5,
                ease: 'power3.out',
                onComplete: () => {
                  $('.error-warning').css('display', 'none');
                },
              });
            }
          },
          onUpdate: () => {
            this._requestRenderIfNotRequested();
          },
          onComplete: () => {
            this.hBlurPass.enabled = false;
          },
        });
      },
    });
    // tslint:disable-next-line: no-string-literal
    gsap.to(this.vBlurPass.uniforms['v'], {
      duration: 0,
      value: this.blurErrorIntensity / window.innerHeight,
      ease: 'power3.out',
      onUpdate: () => {
        this._requestRenderIfNotRequested();
      },
      onComplete: () => {
        // tslint:disable-next-line: no-string-literal
        gsap.to(this.vBlurPass.uniforms['v'], {
          duration: this.blurErrorIntensity / 2,
          value: 0,
          ease: 'power3.out',
          onUpdate: () => {
            this._requestRenderIfNotRequested();
          },
          onComplete: () => {
            this.vBlurPass.enabled = false;
          },
        });
      },
    });
  }

  private _resizeRendererToDisplaySize(renderer, composer): boolean {
    const canvas = renderer.domElement;
    let innerWidth = canvas.clientWidth;
    let innerHeight = canvas.clientHeight;
    if (this.utils.md().mobile()) {
      innerWidth = window.screen.width;
      innerHeight = window.screen.height;
    }
    let pixelRatio = window.devicePixelRatio ? window.devicePixelRatio : 1;
    pixelRatio = 1;
    // tslint:disable-next-line: no-bitwise
    const width = (innerWidth * pixelRatio) | 0;
    // tslint:disable-next-line: no-bitwise
    const height = (innerHeight * pixelRatio) | 0;
    const needResize = canvas.width !== width || canvas.height !== height;

    if (needResize) {
      // renderer.setPixelRatio(pixelRatio);
      // composer.setPixelRatio(pixelRatio);
      renderer.setSize(width, height, false);
      composer.setSize(width, height, false);
    }
    return needResize;
  }

  private _createMask(): void {
    const maxRadius = Math.sqrt(
      Math.pow(this.appConfig.viewWidth / 2, 2) +
        Math.pow(this.appConfig.viewHeight / 2, 2)
    );
    this.transitionScene = new THREE.Scene();
    const geometry = new THREE.CircleBufferGeometry(maxRadius, 64);
    const material = new THREE.MeshBasicMaterial({ color: 0xffff00 });
    const circle = new THREE.Mesh(geometry, material);
    this.transitionScene.add(circle);

    circle.scale.x = circle.scale.y = 0.00001;
  }

  private _animateMask(): void {
    const posZ = this.camera.position.z;
    const planePosZ = this.plane.position.z;
    const wait = 0;
    this.plane.position.z = posZ / 4;
    if (this.appConfig.DEBUG) {
      console.log('WebGLGameviewComponent::_animateMask');
    }
    // return

    this.hBlurPass.uniforms['h'].value = 0;
    this.vBlurPass.uniforms['v'].value = 0;
    this.vBlurPass.enabled = false;
    this.hBlurPass.enabled = false;

    gsap.to(this.plane.position, {
      duration: this.transitionDuration,
      delay: wait,
      z: planePosZ,
      ease: 'power3.inOut',
      onStart: () => {
        requestAnimationFrame(() => {
          this._animate();
        });
      },
      onUpdate: () => {
        requestAnimationFrame(() => {
          this._animate();
        });
      },
      onComplete: () => {
        requestAnimationFrame(() => {
          this._animate();
        });
      },
    });
    gsap.to(this.transitionScene.children[0].scale, {
      duration: this.transitionDuration,
      delay: wait,
      x: 1,
      y: 1,
      ease: 'power3.inOut',
      onStart: () => {
        this.soundService.arc();
      },
      onComplete: () => {
        this.screenService.emitEvent(
          new ScreenEvent(this.screenService.GAMEVIEWREADY)
        );
        this.mouseControlsService.initEvents();
        this.mouseControlsService.emitter$.subscribe((event: any) =>
          this._eventDispatch(event)
        );
      },
    });
  }

  private _reverseMask(): void {
    // this.plane.position.z = 0;
    if (this.appConfig.DEBUG) {
      console.log('WebGLGameviewComponent::_reverseMask');
    }
    this.enable();
    // this.renderer.setClearColor(this.appConfig.CANVAS_COLOR, 0);
    this._unblur();

    gsap.to(this.transitionScene.children[0].scale, {
      duration: this.transitionDuration,
      x: 0.0000000000001,
      y: 0.0000000000001,
      ease: 'power3.out',
      onStart: () => {
        this.soundService.arc();

        requestAnimationFrame(() => {
          this._animate();
        });
      },
      onUpdate: () => {
        requestAnimationFrame(() => {
          this._animate();
        });
      },
      onComplete: () => {
        this.screenService.emitEvent(
          new ScreenEvent(this.screenService.GAMEVIEWUNLOADED)
        );
        this.disable();
        const canvas: HTMLCanvasElement = document.querySelector('#gameview');
        canvas.style.opacity = '0';
        requestAnimationFrame(() => {
          this._animate();
        });
      },
    });
  }
}
