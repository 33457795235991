<div id="lux" class="ui-selector d-flex flex-row justify-content-between align-items-center">
  <div class="d-flex flex-column justify-content-between align-items-start h-100">
    <div>
      <div class='d-flex flex-row justify-content-center align-items-center lux-bloc-score'>
        <div class='d-flex flex-column justify-content-center align-items-center text-center lux-bloc-1'>
          <div class="d-flex flex-row justify-content-center align-items-center">{{nbrefs}}</div>
          <div class="d-flex flex-row justify-content-around align-items-center">
            <div>
              <img src="{{ assetsUrl }}/assets/images/lux/heart-on.svg" class="icon_Champion skull3 life-ok">
            </div>
            <div>
              <img src="{{ assetsUrl }}/assets/images/lux/heart-on.svg" class="icon_Champion skull2 life-ok">
            </div>
            <div>
              <img src="{{ assetsUrl }}/assets/images/lux/heart-on.svg" class="icon_Champion skull1 life-ok">
            </div>
          </div>
          <div class="d-flex flex-row justify-content-center lux-score-mobile w-100">
            <div>{{score}}</div>
            <div class="d-flex flex-row aligns-items-center justify-content-center">
              <img src="{{ assetsUrl }}/assets/images/lux/symbol-pop-corn.svg" class="Symbol_PopCorn_small">
            </div>
          </div>

        </div>
        <div class='d-flex flex-column justify-content-center align-items-start lux-bloc-2'>
          <div class="mt-2 mb-2">ton score</div>
          <div class="d-flex flex-row justify-content-between w-100">
            <div>{{score}}</div>
            <div>
              <img src="{{ assetsUrl }}/assets/images/lux/symbol-pop-corn.svg" class="Symbol_PopCorn">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <svg width="59px" height="59px" id="radarBtn" class="svgUI radarBtn">
        <path id="radar" fill-rule="evenodd" stroke-width="2px" stroke="rgb(255, 255, 255)" fill-opacity="0.3"
          fill="rgb(1, 10, 19)"
          d="M29.548,4.317 C43.587,4.317 54.968,15.693 54.968,29.724 C54.968,43.756 43.587,55.131 29.548,55.131 C15.509,55.131 4.128,43.756 4.128,29.724 C4.128,15.693 15.509,4.317 29.548,4.317 Z" />
        <g id="radarAnim" transform="matrix(0.26,0,0,0.26,13,13)">
          <path fill="#ffffff" class="cls-1"
            d="M64 127.75a64 64 0 1 1 64-64 64 64 0 0 1-64 64zM125.72 65h-13.75A47.86 47.86 0 0 1 65 111.73v13.74A61.73 61.73 0 0 0 125.72 65zM65 65v21.95A23.2 23.2 0 0 0 87.2 65H65zm22.2-2A23.22 23.22 0 0 0 65 40.55V63h22.23zm-46.37 2A23.2 23.2 0 0 0 63 86.95V65H40.8zM63 63V40.55A23.22 23.22 0 0 0 40.78 63H63zm-24.2 2H18.3A45.85 45.85 0 0 0 63 109.72V88.95A25.2 25.2 0 0 1 38.8 65zm0-2A25.2 25.2 0 0 1 63 38.55V18.03A45.85 45.85 0 0 0 18.28 63h20.5zM65 38.55A25.2 25.2 0 0 1 89.2 63h20.77A45.85 45.85 0 0 0 65 18.03v20.52zM89.2 65A25.2 25.2 0 0 1 65 88.95v20.77A45.85 45.85 0 0 0 109.97 65H89.2zM63 125.47v-13.75A47.86 47.86 0 0 1 16.28 65h-14A61.73 61.73 0 0 0 63 125.47zM2.27 63h14A47.86 47.86 0 0 1 63 16.03v-14A61.73 61.73 0 0 0 2.27 63zM65 2.02v14A47.86 47.86 0 0 1 111.98 63h13.75A61.73 61.73 0 0 0 65 2.02z" />
          <g>
            <linearGradient id="lux-linear-gradient">
              <stop offset="0%" stop-color="#ffffff" />
              <stop offset="100%" stop-color="#ffffff" />
            </linearGradient>
            <path fill="url(#lux-linear-gradient)" fill-opacity="0.5"
              d="M65.128,64.894l0.025,60.968a61.781,61.781,0,0,1-32.011-8.315q-0.705-.406-1.4-0.83L62.531,63.4Z" />
            <animateTransform attributeName="transform" type="rotate" from="0 64 64" to="360 64 64" dur="1710ms"
              repeatCount="indefinite"></animateTransform>
            <path fill="#ffffff" d="M62.531,63.4l2.6,1.5L34.257,118.374l-2.6-1.5Z" />
          </g>
          <circle fill="#ffffff" cx="55.641" cy="97.563" r="6.047">
            <animate attributeName="opacity" dur="1710ms" begin="0s" repeatCount="indefinite" keyTimes="0;1"
              values="1;0" />
          </circle>
        </g>
      </svg>
    </div>
  </div>

  <div class="d-flex flex-column justify-content-between align-items-end h-100">
    <div>
      <img src="{{ assetsUrl }}/assets/images/lux/logo-pop-star-game.png" class="logo_rap_jeu">
    </div>
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" id="saveBtn" class="saveBtn">
        <g transform="translate(3652.67 1457.11)">

          <circle id="save" cx="26.5" cy="26.5" r="26.5" transform="translate(-3651.67 -1456.11)"
            style="fill:rgb(1 ,10 ,19);fill-opacity: 0.3;stroke:#fff;stroke-miterlimit:10;stroke-width:2px" />
          <path
            d="M-3628.989-1441.3v6.551h8.734v-6.551h2.184l4.366 4.368v17.469h-21.836v-21.837zm4.368 0h2.183v4.368h-2.183zm-8.734 8.734v10.919h17.469v-10.919z"
            transform="translate(-.767 .557)" style="fill:#fff" />
        </g>
      </svg>
    </div>
  </div>
</div>
<div *ngIf="mobileMode===false" id="zoomWidget" class="ui-selector flex-column justify-content-end align-items-center">
  <div class="zoom-panel d-flex flex-row justify-content-between align-items-center">
    <div id="zoomMinus" class="zoom-button" (click)="zoom($event,'-')" (dblclick)="none()">-</div>
    <div class="zoom-label">ZOOM</div>
    <div id="zoomPlus" class="zoom-button" (click)="zoom($event,'+')" (dblclick)="none()">+</div>
  </div>
</div>
<div class="ui-selector bonus-frame flex-column justify-content-center align-items-center">
  <div class="bonus-type" [innerText]="bonusType">
  </div>
  <div class="bonus-chrono" [innerText]="chrono"></div>
</div>
<badge></badge>
<pins></pins>
<combo></combo>
<!-- <dashboard></dashboard> -->
<tutos></tutos>
<connect></connect>
<error></error>
<game-status></game-status>
<quiz></quiz>