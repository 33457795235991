<div id="insertcoin" class="d-flex flex-column justify-content-center align-items-center h-100">
  <div class="d-flex flex-row  justify-content-center align-items-center spin-disc spinning">
    <div class="progress-line">
      <svg id="svg" width="208" height="208" viewPort="0 0 104 104" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <circle r="100" cx="104" cy="104" fill="transparent"></circle>
        <circle id="bar" r="100" cx="104" cy="104" fill="transparent">
        </circle>
      </svg>
    </div>
    <div class="d-flex flex-row  justify-content-center align-items-center logo">
      <img src="{{ assetsUrl }}/assets/images/intro/logo-popcorn.svg" class="logo_popcorn">
    </div>
    <div class="d-flex flex-row  justify-content-center align-items-center">
      <button type="button" class="round-button" (click)="insertCoin()" (mouseover)="rollOver()" (mouseout)="rollOut()">
        Jouer
      </button>
    </div>
  </div>
</div>

<div #prehome id="prehome" class="flex-column justify-content-center align-items-center h-100">
  <div class="d-flex flex-row align-items-end">
    <div class="bloc-logo">
      <img class="redbull" src="{{ assetsUrl }}/assets/images/intro/logo-pop-star-game.png" data-pin-nopin="true" />
    </div>
  </div>
  <div class="d-flex flex-row press-any-key">{{ action }} pour commencer</div>
</div>