<div id="saisie" class="d-flex flex-column justify-content-start align-items-center text-center h-101">
  <div id="saisie_mobile"
    class="field-wrap d-flex flex-column justify-content-center align-items-around text-center mobile">
    <form
      class="form-horizontal css-form d-flex flex-column flex-sm-row justify-content-around align-items-center align-items-sm-start text-center"
      enctype="multipart/form-data" charset="UTF-8" accept-charset="UTF-8" (ngSubmit)="onSubmit(ref.value)">
      <div class="inputcontainer">
        <input id="inputfieldtxt" name="titrefilmtv" type="text" ng-minlength="2" ng-maxlength="35" maxlength="35"
          submit-required="true" autocomplete="off" [(ngModel)]="titre" spellcheck="false" #ref />
      </div>
      <div>
        <button [disabled]="loading" id="btnsubmit" class="submit-button border-gradient border-gradient-gold"
          type="button" (click)="onSubmit(ref.value)">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            style="margin: auto; background: none; display: block; shape-rendering: auto;" width="51px" height="51px"
            viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" class="loading">
            <circle cx="50" cy="50" r="32" stroke-width="8" stroke="#fff"
              stroke-dasharray="50.26548245743669 50.26548245743669" fill="none" stroke-linecap="square"
              transform="rotate(58.5425 50 50)">
              <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" keyTimes="0;1"
                values="0 50 50;360 50 50"></animateTransform>
            </circle>
          </svg>Valider
        </button>
      </div>
    </form>
  </div>
  <overlay [keyboard]="showHelper"></overlay>
</div>