export class Show {
  x: string;
  y: string;
  lang: string;
  // tslint:disable-next-line: variable-name
  badge_id: string;
  flag: number; // gold flag
  type: number; // movie or tvshow
  hash: string;
  title: string;
  combo_id: string;
  bonus_id: string;
}
