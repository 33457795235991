import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SimpleBtnComponent } from '../../components/buttons/simple-btn/simple-btn.component';
import { OverlayComponent } from '../../components/screen/overlay/overlay.component';

import { InlineSVGModule } from 'ng-inline-svg';
import { DomChangeDirective } from '../../directives/dom-change.directive';
import { WideBtnComponent } from 'src/app/components/buttons/wide-btn/wide-btn.component';
import { RankingComponent } from 'src/app/sections/ranking/ranking.component';
import { FacebookBtnComponent } from 'src/app/components/buttons/facebook-btn/facebook-btn.component';

@NgModule({
  declarations: [
    SimpleBtnComponent,
    OverlayComponent,
    DomChangeDirective,
    WideBtnComponent,
    RankingComponent,
    FacebookBtnComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InlineSVGModule.forRoot(),
  ],
  exports: [
    CommonModule,
    SimpleBtnComponent,
    WideBtnComponent,
    OverlayComponent,
    DomChangeDirective,
    RankingComponent,
    FacebookBtnComponent,
  ],
})
export class SharedModule {}
