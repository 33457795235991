<div id="quizContainer" class="flex-column justify-content-center align-items-center h-100 text-center">

  <div class="quiz-intro flex-column justify-content-center align-items-center">
    <div>
      <img class="quiz-badge" [src]="svg" (load)="onSVGChange()" data-pin-nopin="true" />
    </div>
    <div class="msg" [innerHTML]="description">
    </div>
    <div>
      <div class="startquiz-button" (click)="startQuiz($event)" (mouseover)="onRollOver()" (mouseout)="onRollOut()">
        {{ btnTitle1 }}
      </div>
    </div>
  </div>
  <!-- ****************** -->
  <div class="quiz-game">

    <!-- Mobile-->
    <div *ngIf="isMobile === true"
      class="d-flex flex-row flex-wrap justify-content-around align-items-center mt-3 mb-3">
      <div class="d-flex flex-row justify-content-center align-items-center" style="min-width: 150px;">
        <div class="chrono-1" [ngClass]="{ invisible: quiz.blink }">
          {{ quiz.chronoStr }}
        </div>
      </div>
      <div class="d-flex flex-row justify-content-center align-items-center">
        <div class="d-flex align-items-center question" [innerHTML]="quiz.question"></div>
      </div>
    </div>


    <!-- desktop -->

    <div *ngIf="isMobile === false" class="d-flex flex-row justify-content-center align-items-center">
      <div class="chrono-1" [ngClass]="{ invisible: quiz.blink }">
        {{ quiz.chronoStr }}
      </div>
    </div>

    <div *ngIf="isMobile === false" class="d-flex flex-row justify-content-center align-items-center">
      <div class="d-flex align-items-center question" [innerHTML]="quiz.question"></div>
    </div>

    <!-- fin desktop-->
    <div class="d-flex flex-row flex-wrap justify-content-center align-items-center">

      <button class="btn-reponse-quiz-1 button-selector" type="button" (click)="checkQuizAnswer(1)"
        [disabled]="DISABLEBTN">
        <img src='{{assetsUrl}}/assets/images/quiz/v.svg' [hidden]="!quiz.response1.v">
        <img src='{{assetsUrl}}/assets/images/quiz/x.svg' [hidden]="!quiz.response1.x">
        {{ quiz.response1.text }}
      </button>

      <button class="btn-reponse-quiz-2 button-selector" type="button" (click)="checkQuizAnswer(2)"
        [disabled]="DISABLEBTN">
        <img src='{{assetsUrl}}/assets/images/quiz/v.svg' [hidden]="!quiz.response2.v">
        <img src='{{assetsUrl}}/assets/images/quiz/x.svg' [hidden]="!quiz.response2.x">
        {{ quiz.response2.text }}
      </button>
    </div>
    <div class="d-flex flex-row flex-wrap justify-content-center align-items-center" *ngIf="!hidebuttons">

      <button class="btn-reponse-quiz-3 button-selector" type="button" (click)="checkQuizAnswer(3)"
        [disabled]="DISABLEBTN">
        <img src='{{assetsUrl}}/assets/images/quiz/v.svg' [hidden]="!quiz.response3.v">
        <img src='{{assetsUrl}}/assets/images/quiz/x.svg' [hidden]="!quiz.response3.x">
        {{ quiz.response3.text }}
      </button>

      <button class="btn-reponse-quiz-4 button-selector" type="button" (click)="checkQuizAnswer(4)"
        [disabled]="DISABLEBTN">
        <img src='{{assetsUrl}}/assets/images/quiz/v.svg' [hidden]="!quiz.response4.v">
        <img src='{{assetsUrl}}/assets/images/quiz/x.svg' [hidden]="!quiz.response4.x">
        {{ quiz.response4.text }}
      </button>

    </div>
    <div class="d-flex flex-row justify-content-center align-items-center">
      <ul class="list-inline">
        <li class="bt-1 bt-pin-1"></li>
        <li class="bt-2 bt-pin"></li>
        <li class="bt-3 bt-pin"></li>
        <li class="bt-4 bt-pin"></li>
        <li class="bt-5 bt-pin"></li>
        <li class="bt-6 bt-pin"></li>
      </ul>
    </div>
  </div>
  <!-- ****************** -->
  <div class="quiz-end">
    <div class="d-flex flex-row justify-content-center align-items-center">
      <!-- <div class="col end-score">{{ score }} / {{ STEP_QUIZ }}</div> -->

      <img src="{{assetsUrl}}/assets/images/quiz/profil-quiz-{{score}}.png" srcset="{{assetsUrl}}/assets/images/quiz/profil-quiz-{{score}}@2x.png 2x,
                   {{assetsUrl}}/assets/images/quiz/profil-quiz-{{score}}@3x.png 3x" class="profil_quiz">
    </div>
    <!-- <div class="d-flex flex-row justify-content-center align-items-center">
      <div class=" mission">QUIZ TERMINÉ</div>
    </div> -->
    <div class="d-flex flex-row justify-content-center align-items-center">
      <ul class="list-inline">
        <li class="bt-1 bt-pin-1"></li>
        <li class="bt-2 bt-pin"></li>
        <li class="bt-3 bt-pin"></li>
        <li class="bt-4 bt-pin"></li>
        <li class="bt-5 bt-pin"></li>
        <li class="bt-6 bt-pin"></li>
      </ul>

    </div>
    <div class="d-flex flex-row justify-content-center align-items-center">
      <div class=" points">+{{ points }}</div>
    </div>
  </div>

  <close></close>
  <div id="bgQuiz">
    <overlay [keyboard]="showHelper" [darker]="true"></overlay>
  </div>
</div>
