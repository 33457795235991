import { EventEmitter, Injectable } from '@angular/core';
import { AppConfigService } from '../config/app-config.service';
import * as $ from 'jquery';
import gsap from 'gsap';
import { SoundService } from 'src/app/services/sound/sound.service';
import { Howl, Howler } from 'howler';

export class GameStatusEvent {
  constructor(public name: string) {}
}
@Injectable({
  providedIn: 'root',
})
export class GameStatusService {
  public emitter$: EventEmitter<GameStatusEvent>;

  public SHOW = 'GameService:SHOW';
  public HIDE = 'GameService:HIDE';

  DELAY = 1500;
  assetsUrl = this.appConfig.assetsUrl;

  constructor(
    private appConfig: AppConfigService,
    private soundService: SoundService
  ) {
    this.emitter$ = new EventEmitter();
  }

  emitEvent(event: GameStatusEvent): void {
    if (this.appConfig.DEBUG) {
      console.log('GameStatusService emitEvent %s', event.name);
    }
    this.emitter$.emit(event);
  }
  win(): Promise<any> {
    const promise = new Promise((resolve) => {
      if (this.appConfig.DEBUG) {
        console.log('GameStatusService:win');
      }
      $('#gamestatus').css('display', 'flex');
      $('.gamestatus-win').css('display', 'flex');
      gsap.to('.titre', { duration: 0, opacity: 0, scale: 2 });
      // gsap.to('.bg', { duration: 0, opacity: 0, scale: 1.5 })

      const victorySound = new Howl({
        src: [this.assetsUrl + '/assets/sounds/victory.mp3'],
        volume: 0,
        autoplay: false,
        html5: false,
        onload: () => {
          gsap.to('#gamestatus', {
            duration: 0.3,
            opacity: 1,
            ease: 'expo.out',

            onStart: () => {
              victorySound.play();
              gsap.to('.gamestatus-win', {
                duration: 0.3,
                opacity: 1,
                ease: 'expo.out',

                onStart: () => {
                  gsap.to('.legend', {
                    duration: 0.6,
                    delay: 0.3,
                    opacity: 1,
                    ease: 'expo.out',
                  });
                  gsap.to('.titre', {
                    delay: 0.1,
                    duration: 0.6,
                    scale: 1,
                    opacity: 1,
                    ease: 'expo.out',
                    onComplete: () => {
                      setTimeout(() => {
                        this.hide(resolve);
                      }, this.appConfig.ENDSCREEN_DURATION * 1000);
                    },
                  });
                },
              });
            },
          });
        },
      });
    });

    return promise;
  }
  defeat(): Promise<any> {
    const promise = new Promise((resolve) => {
      if (this.appConfig.DEBUG) {
        console.log('GameStatusService:win');
      }
      $('#gamestatus').css('display', 'flex');
      $('.gamestatus-defeat').css('display', 'flex');
      gsap.to('.titre', { duration: 0, opacity: 0, scale: 2 });
      // gsap.to('.bg', { duration: 0, opacity: 0, scale: 1.5 })

      const defeatSound = new Howl({
        src: [this.assetsUrl + '/assets/sounds/defeat.mp3'],
        volume: 0.7,
        autoplay: false,
        html5: false,
        onload: () => {
          gsap.to('#gamestatus', {
            duration: 0.3,
            opacity: 1,
            ease: 'expo.out',

            onStart: () => {
              defeatSound.play();

              gsap.to('.gamestatus-defeat', {
                duration: 0.3,
                opacity: 1,
                ease: 'expo.out',

                onStart: () => {
                  gsap.to('.legend', {
                    duration: 0.6,
                    delay: 0.3,
                    opacity: 1,
                    ease: 'expo.out',
                  });
                  gsap.to('.titre', {
                    delay: 0.1,
                    duration: 0.6,
                    scale: 1,
                    opacity: 1,
                    ease: 'expo.out',
                    onComplete: () => {
                      setTimeout(() => {
                        this.hide(resolve);
                      }, this.appConfig.ENDSCREEN_DURATION * 1000);
                    },
                  });
                },
              });
            },
          });
        },
      });
    });

    return promise;
  }
  hide(resolve): void {
    resolve(true);

    gsap.to('#gamestatus', {
      duration: 0.3,
      opacity: 0,
      ease: 'expo.out',
    });

    // gsap.to('.legend', {
    //   duration: 0.6,
    //   opacity: 0,
    //   ease: 'expo.out',
    // });
    // gsap.to('.titre', {
    //   duration: 0.6,
    //   // scale: 0.1,
    //   opacity: 0,
    //   ease: 'expo.out',
    //   onStart: () => {},
    //   onComplete: () => {
    //     gsap.to('#gamestatus', {
    //       duration: 0.3,
    //       opacity: 0,
    //       ease: 'expo.out',
    //     });
    //   },
    // });
  }
}
