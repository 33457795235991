<div id="end" class="d-flex flex-column justify-content-center align-items-center h-100">

  <div class="d-flex flex-row align-items-center">
    <img class="gif" [src]="gif" data-pin-nopin="true" />
  </div>
  <div class="d-flex flex-row align-items-center">
    <img class="grad" [src]="grad" data-pin-nopin="true" />
  </div>
  <!-- <div class="d-flex flex-row align-items-center desc" [innerHTML]="desc">
  </div> -->
  <div class="d-flex flex-row align-items-center score">
    <div>
      <ul>
        <li class="label">références</li>
        <li>{{ refs }}/40</li>
      </ul>
    </div>
    <div class="separator">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="1px" height="79px">
        <path fill-rule="evenodd" fill="rgb(175, 175, 175)"
          d="M-0.000,-0.000 L1.000,-0.000 L1.000,79.000 L-0.000,79.000 L-0.000,-0.000 Z" />
      </svg>
    </div>
    <div>
      <ul>
        <li class="label">score</li>
        <li>
          {{ score
          }}<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="34px"
            height="37px" class="popcorn-svg">
            <path fill-rule="evenodd" fill="rgb(54, 2, 89)"
              d="M23.016,31.000 C20.845,31.000 18.782,30.396 16.995,29.244 C15.267,30.354 13.201,31.000 10.984,31.000 C4.927,31.000 0.000,26.179 0.000,20.253 C0.000,16.165 2.335,12.487 6.017,10.665 C6.061,4.777 10.971,-0.000 17.000,-0.000 C23.030,-0.000 27.941,4.779 27.983,10.670 C31.551,12.447 34.000,16.076 34.000,20.253 C34.000,26.179 29.073,31.000 23.016,31.000 ZM19.039,27.552 C20.253,28.189 21.605,28.521 23.016,28.521 C27.675,28.521 31.466,24.812 31.466,20.253 C31.466,15.695 27.675,11.986 23.016,11.986 C21.096,11.986 19.288,12.600 17.785,13.761 C17.236,14.185 16.439,14.094 16.005,13.556 C15.571,13.019 15.665,12.239 16.214,11.815 C18.169,10.305 20.521,9.507 23.016,9.507 C23.831,9.507 24.625,9.594 25.390,9.759 C24.890,5.664 21.318,2.479 17.000,2.479 C12.341,2.479 8.550,6.188 8.550,10.747 C8.550,10.953 8.558,11.161 8.574,11.367 C8.808,14.463 10.861,17.225 13.805,18.402 C14.452,18.662 14.763,19.385 14.498,20.019 C14.233,20.653 13.493,20.956 12.846,20.698 C9.598,19.399 7.185,16.615 6.343,13.341 C3.988,14.849 2.534,17.423 2.534,20.253 C2.534,24.812 6.325,28.521 10.984,28.521 C15.643,28.521 19.433,24.812 19.433,20.253 C19.433,20.049 19.425,19.843 19.410,19.640 C19.359,18.958 19.884,18.364 20.582,18.314 C21.279,18.265 21.887,18.777 21.938,19.460 C21.957,19.722 21.967,19.989 21.967,20.253 C21.967,23.068 20.856,25.634 19.039,27.552 Z" />
          </svg>
        </li>
      </ul>
    </div>
  </div>
  <div class="d-flex flex-column justify-content-center align-items-center">
    <div class="mt-1 mb-2 btn1">
      <simple-btn [buttonText]="title1" [buttonTextOver]="title1Over" (buttonClick)="ranking()" [isDisabled]="false"
        [isFocused]="false">
      </simple-btn>
    </div>
    <div class="mb-3 btn2">
      <simple-btn [buttonText]="title3" [buttonTextOver]="title3Over" (buttonClick)="restart()" [isDisabled]="false"
        [isFocused]="true">
      </simple-btn>
    </div>
  </div>
</div>