<div id="badgesContainer" class="flex-column justify-content-center align-items-center">
  <div class="champion-badge-block d-flex flex-column justify-content-center align-items-center">
    <div class="champion-image-block d-flex justify-content-center align-items-center">
      <div class="champion-image">
        <img [src]="championImage" class="Cercle_Champion">
      </div>
    </div>
    <div class="champion-name" [innerHTML]="championName"></div>
    <div *ngIf="pctime === false" class="champion-difficulty d-flex flex-row align-items-center">
      <div>{{championLevel}}</div>
    </div>
    <div *ngIf="pctime === true" class="champion-difficulty d-flex flex-row align-items-center">
      <div>{{championLevel}}</div>
    </div>
    <div class="champion-bonus" [innerText]="championBonus">
    </div>
  </div>
  <div id="bg">
    <overlay [keyboard]="showHelper"></overlay>
  </div>
</div>
