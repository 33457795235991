import {
  Component,
  ElementRef,
  HostListener,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin';
import { CSSRulePlugin } from 'gsap/CSSRulePlugin';
import { CSSPlugin } from 'gsap/CSSPlugin';
import gsap from 'gsap';
import { PreloaderService } from '../../services/utilities/preloader.service';
import { PlayerService } from '../../services/user/player.service';
import { ProgressEvent, ErrorEvent, Event } from 'createjs-module';

// import { Timeline } from 'createjs-module';
import { Router } from '@angular/router';
import { ProgressService } from 'src/app/services/game/progress.service';
import { RankingService } from 'src/app/services/game/ranking.service';
import * as $ from 'jquery';
import { SoundService } from 'src/app/services/sound/sound.service';
import { AppConfigService } from 'src/app/services/config/app-config.service';
import { UtilsService } from 'src/app/services/utilities/utils.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { RotateDeviceService } from '../../services/view/rotate-device.service';
import { VideoplayerService } from 'src/app/services/video/videoplayer.service';
import { Subscription } from 'rxjs';
import { AfterViewInit } from '@angular/core';
import { CursorService } from 'src/app/services/view/cursor.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'pre-home',
  templateUrl: './pre-home.component.html',
  styleUrls: ['./pre-home.component.scss'],
})
export class PreHomeComponent implements OnInit, OnDestroy, AfterViewInit {
  tl;
  tlRotate;
  tlProgress = {
    v: 0,
  };
  tween;
  blink;
  active = false;
  rotateAnim;
  frame = 0;
  stopAnim = false;
  startPreload = true;
  action = 'Clique';
  needPermission = false;
  oneClick = false;
  clicked = false;
  assetsUrl = this.appConfig.assetsUrl;
  btnLabel = 'LOADING';
  videoSub: Subscription;

  private onResizeBound: () => {};

  constructor(
    private preloaderService: PreloaderService,
    private playerService: PlayerService,
    private progressService: ProgressService,
    private rankingService: RankingService,
    private soundService: SoundService,
    private appConfig: AppConfigService,
    // private rbTracking: RedbullTrackingService,
    private utils: UtilsService,
    private $gaService: GoogleAnalyticsService,
    private rotateDeviceService: RotateDeviceService,
    private videoPlayerService: VideoplayerService,
    private cursorService: CursorService,
    private toastr: ToastrService,
    private router: Router,
    private ngZone: NgZone
  ) {}

  // @ViewChild('prehome', { read: ElementRef }) prehome: ElementRef;
  // // @ViewChild('popcoin', { read: ElementRef }) popcoin: ElementRef;
  // @ViewChild('insert', { read: ElementRef }) insert: ElementRef;

  // @HostListener('document:keydown.space') onPressSpaceBar(): void {
  //   if (this.active) {
  //     if (this.appConfig.DEBUG) {
  //       console.log('keypress');
  //     }
  //     this.active = false;
  //     gsap.to('#prehome', {
  //       duration: 0.6,
  //       opacity: 0,
  //       ease: 'power3.out',
  //       onComplete: () => {
  //         this.ngZone.run(() =>
  //           this.router.navigate(['/home'], {
  //             skipLocationChange: true,
  //           })
  //         );
  //       },
  //     });
  //   }
  // }

  ngOnInit(): void {
    gsap.registerPlugin(DrawSVGPlugin, CSSPlugin, CSSRulePlugin);
    // on affiche le footer Red Bull
    // window.parent.postMessage('show-footer', '*');
    $('.footer').css('display', 'block');
    $('.header-panel').css('display', 'none');
    if (this.utils.md().mobile() !== null) {
      this.action = 'Touche';
    }

    this.tl = gsap.timeline({ paused: true });
    this.tl.fromTo(
      '#bar',
      0.3,
      { drawSVG: '0% 0%' },
      { drawSVG: '0% 100%', ease: 'quad.out' }
    );
    this.preloaderService.init();

    this.preloaderService.maxConnection = 6;
    this.preloaderService.onComplete = () => {
      this._completePreload();
    };
    this.preloaderService.onProgress = (event: ProgressEvent) => {
      this._handleProgress(event);
    };

    this.preloaderService.onError = (event: ErrorEvent) => {
      this._handleFileErrorPreload(event);
    };

    if (this.utils.md().mobile() !== null) {
      this.rotateDeviceService.init();
    }

    // this.videoSub = this.videoPlayerService.emitter$.subscribe((event) => {
    //   //  this._eventDispatch(event)
    //   if (event.name === this.videoPlayerService.READY) {
    //     this.videoSub.unsubscribe();
    //     this.initPreLoader();
    //   }
    // });

    this.playerService.init();
    this.progressService.init();
    this.rankingService.init();
    this.soundService.init();
    // this.videoPlayerService.init();

    // this.onResizeBound = this._onCanvasResize.bind(this);
    // $(window).on('resize', this.onResizeBound);
    // this._onCanvasResize();

    // this.animCoin();
    this.initPreLoader();

    $('.round-button').css('opacity', 0);
    gsap.to('#insertcoin', {
      delay: 1,
      duration: 1,
      ease: 'quad.out',
      opacity: 1,
    });
    if (this.appConfig.GA_STATS) {
      this.$gaService.pageView('/prehome', 'PreHome / Loader');
    }
  }

  ngAfterViewInit(): void {
    // let rule = CSSRulePlugin.getRule('.spin-disc::after'); //get the rule
    // this.tlRotate = gsap.timeline({ repeat: -1 });
    // this.tlRotate.to(rule, 4, {
    //   cssRule: { rotation: 360 },
    //   ease: 'none',
    // });
  }

  ngOnDestroy(): void {
    if (this.appConfig.DEBUG) {
      console.log('PreHomeComponent:ngOnDestroy');
    }
    this.preloaderService.onComplete = undefined;
    this.preloaderService.onProgress = undefined;
    this.preloaderService.onFileLoad = undefined;
    this.preloaderService.onError = undefined;
    // this.videoSub.unsubscribe();
    // $(window).off('resize', this.onResizeBound);
  }

  animCoin(): void {
    // this.rotateAnim = setInterval(() => {
    //   this.frame++;
    //   if (this.frame > 5) {
    //     this.frame = 0;
    //     if (this.startPreload) {
    //       this.startPreload = false;
    //       this.initPreLoader();
    //     }
    //   }
    //   if (this.frame === 0 && this.stopAnim) {
    //     clearInterval(this.rotateAnim);
    //   }
    //   let oldClass = this.frame - 1;
    //   if (oldClass < 0) {
    //     oldClass = 5;
    //   }
    //   $('.popcoin')
    //     .addClass('popcoin-' + this.frame)
    //     .removeClass('popcoin-' + oldClass);
    // }, 110);
  }

  insertCoin(): void {
    // createjs.Sound.initializeDefaultPlugins();
    // this.preloaderService.queue.installPlugin(createjs.Sound);
    if (this.oneClick) {
      this.oneClick = false;

      this.$gaService.event('Start', 'Interactions', 'Click on Play');

      // this.rbTracking.track(
      //   'gaEvent',
      //   'Interactions',
      //   'Play',
      //   'Click on Play',
      //   'Red Bull Rap Jeu'
      // );

      $('.round-button').prop('disabled', true);
      // this.insert.nativeElement.style.setProperty(
      //   'display',
      //   'none',
      //   'important'
      // );

      this.hideCoin();
    }
  }

  rollOver(): void {
    this.cursorService.rollOver();

    this.stopAnim = true;
  }
  rollOut(): void {
    this.cursorService.rollOut();

    this.stopAnim = false;
    // clearInterval(this.rotateAnim);
    // this.animCoin();
  }

  none(): void {}

  hideCoin(): void {
    this.soundService.intro();
    gsap.to('#insertcoin', {
      // delay: 0.5,
      duration: 0.6,
      ease: 'quad.out',
      opacity: 0,
      onComplete: () => {
        $('#insertcoin').css('display', 'none').removeClass('d-flex');
        $('#prehome').addClass('d-flex');
        this.cursorService.normal();
        this._showLogo();
      },
    });
  }
  initPreLoader(): void {
    const manifest = [
      {
        src: 'sounds/intro.mp3',
        id: this.soundService.soundId.INTRO,
      },
      'images/game/bonus/bonus-popcorn-x2.png',
      'images/game/bonus/bonus-popcorn-x3.png',
      'images/game/bonus/bonus-popcorn-x4.png',
      'images/end/correct.gif',
      'images/end/endormi.gif',
      'images/end/excellent.gif',
      'images/end/idiot.gif',
      'images/end/nul.gif',
      'images/end/correct.png',
      'images/end/endormi.png',
      'images/end/excellent.png',
      'images/end/idiot.png',
      'images/end/nul.png',
      'images/intro/40.png',
      'images/intro/logo-rap-jeu.png',
      'images/intro/signat-logo.png',
      'images/intro/hello.png',
    ];

    if (this.utils.md().mobile() === null) {
      manifest.push({
        id: 'gameview',
        src: 'images/game/PopStar90s.jpg',
      });
    }

    this.preloaderService.preloadAssets(manifest);
    if (this.appConfig.GA_STATS) {
      this.$gaService.event(
        'PreLoading started',
        'Events',
        manifest.length + ' files'
      );
    }
    this.cursorService.loading();
  }

  _handleProgress(event: ProgressEvent): void {
    // console.log('[_handleProgress] : ' + event.loaded);

    this.tween = gsap.to(this.tlProgress, {
      duration: 3,
      v: event.loaded,
      onUpdate: () => {
        this._updateTimeline();
      },
    });
  }

  _handleFileErrorPreload(event: ErrorEvent) {
    if (this.appConfig.DEBUG) {
      console.log(
        'PreHomeComponent:_handleFileErrorPreload  Loading error' +
          event.title +
          ' / ' +
          event.message
      );
    }
    if (this.appConfig.GA_STATS) {
      this.$gaService.event(
        'Loading error',
        'Alert',
        event.title + ' / ' + event.data
      );
    }
    this.toastr.error(
      'Erreur de chargement, redémarrez le jeu svp',
      'WARNING',
      {
        timeOut: 2000,
      }
    );
  }

  _updateTimeline(): void {
    // if (this.appConfig.DEBUG) {
    //   console.log('[_updateTimeline] ' + this.tlProgress.v);
    // }
    this.tl.progress(this.tlProgress.v).pause();
  }

  _completePreload(): void {
    if (this.appConfig.DEBUG) {
      console.log('[_completePreload]');
    }

    let delay = 3.1;

    gsap.to('.logo', {
      delay,
      duration: 1,
      opacity: 0,
      ease: 'power3.out',
      onStart: () => {},
      onComplete: () => {
        $('.spin-disc').removeClass('spinning');
        this.cursorService.normal();

        gsap.to('.round-button', {
          duration: 0.3,
          autoAlpha: 1,
          ease: 'quint.out',
          onStart: () => {},
          onComplete: () => {
            $('.round-button').css('pointer-events', 'all');
            this.oneClick = true;
          },
        });
      },
    });
    // gsap.to('#bar', {
    //   delay,
    //   duration: 1,
    //   opacity: 0,
    //   ease: 'power3.out',
    //   onComplete: () => {},
    // });
  }

  _showLogo(): void {
    // this.prehome.nativeElement.style.display = 'block';
    // this.videoPlayerService.show();

    gsap.to('#prehome', {
      duration: 0.6,
      opacity: 1,
      ease: 'power3.out',
    });
    gsap.to('#footer', {
      duration: 1,
      autoAlpha: 1,
      delay: 1,
      ease: 'power3.out',
    });
    // gsap.from('.redbull', { y: -20, duration: 0.6, ease: 'back.out' });
    gsap.from('.redbull', {
      y: -20,
      duration: 0.6,
      ease: 'back.out',
      onComplete: () => {
        // lancement de l'anim blink Press Any Key
        this.blink = gsap.to('.press-any-key', {
          duration: 1,
          opacity: 1,
          repeat: -1,
          yoyo: true,
        });
        setTimeout(() => {
          this.active = true;
        }, 500);
        $('#prehome').on('click', () => {
          this.$gaService.event(
            'Click to start',
            'Interactions',
            'Click to show Menu'
          );
          if (this.active) {
            $('#prehome').off('click');
            if (this.appConfig.DEBUG) {
              console.log('keypress');
            }
            this.active = false;
            gsap.to('#prehome', {
              duration: 0.6,
              opacity: 0,
              ease: 'power3.out',
              onComplete: () => {
                if (this.playerService.isConnected) {
                  this.ngZone.run(() =>
                    this.router.navigate(['/hello'], {
                      skipLocationChange: true,
                    })
                  );
                } else {
                  this.ngZone.run(() =>
                    this.router.navigate(['/home'], {
                      skipLocationChange: true,
                    })
                  );
                }
              },
            });
          }
        });
      },
    });
  }
  _onCanvasResize() {
    if (this.appConfig.DEBUG) {
      console.log(
        'HomeComponent:_onCanvasResize scale : ' + this.utils.getUIScale()
      );
    }

    if (this.utils.md().mobile() !== null) {
      if (this.rotateDeviceService.getOrientation() === 'landscape') {
        this.rotateDeviceService.enable();
      } else {
        this.rotateDeviceService.disable();
      }
    }

    if (this.utils.getUIScale() > 1) {
      $('#prehome').css('transform', 'scale(' + this.utils.getUIScale() + ')');
      $('#prehome-loader').css(
        'transform',
        'scale(' + this.utils.getUIScale() + ')'
      );
    } else {
      $('#prehome').css('transform', 'unset');
      $('#prehome-loader').css('transform', 'unset');
    }
  }
}
