import { Injectable, EventEmitter } from '@angular/core';
import * as $ from 'jquery';
import gsap from 'gsap';

import { ScreenService, ScreenEvent } from './screen.service';
import { UtilsService } from '../utilities/utils.service';
import { AppConfigService } from 'src/app/services/config/app-config.service';

export class MyKeyboardEvent {
  constructor(
    public name: string,
    public key?: number,
    public titre?: string
  ) {}
}
@Injectable({
  providedIn: 'root',
})
export class KeyboardService {
  public emitter$: EventEmitter<MyKeyboardEvent>;
  reset: boolean;
  keyDownBound: () => {};
  clickInputfieldBound: () => {};
  KEYCODE_ESC = 27;
  KEYCODE_BACKSPACE = 8;

  KEYPRESSED = 'KeyboardService:keypressed';
  ESC = 'KeyboardService:escape';
  RESET = 'KeyboardService:reset';
  SUBMIT = 'KeyboardService:submit';
  CENTER = 'KeyboardService:center';
  SHOW = 'KeyboardService:show';
  HIDE = 'KeyboardService:hide';
  FOCUS = 'KeyboardService:focus';
  KONAMI = 'KeyboardService:KONAMI';
  CHEATCODE = 'KeyboardService:CHEATCODE';
  LOADING = 'KeyboardService:LOADING';
  IDLE = 'KeyboardService:IDLE';
  ENABLE = 'KeyboardService:ENABLE';
  DISABLE = 'KeyboardService:DISABLE';

  constructor(
    private utils: UtilsService,
    private appConfig: AppConfigService
  ) {
    this.emitter$ = new EventEmitter();
  }

  emitEvent(event: MyKeyboardEvent): void {
    if (this.appConfig.DEBUG) {
      console.log('KeyboardService emitEvent %s', event.name);
    }
    this.emitter$.emit(event);
  }

  init(): void {
    if (this.appConfig.DEBUG) {
      console.log('KeyboardService:init');
    }

    this.reset = false;
    this.keyDownBound = this._onKeyDown.bind(this);
  }

  enable(): void {
    if (this.appConfig.DEBUG) {
      console.log('KeyboardService:enable');
    }
    this.emitEvent(new MyKeyboardEvent(this.ENABLE));

    window.addEventListener('keydown', this.keyDownBound, false);
    this.reset = false;
    this.focus();
  }

  disable(): void {
    if (this.appConfig.DEBUG) {
      console.log('KeyboardService:disable');
    }
    this.emitEvent(new MyKeyboardEvent(this.DISABLE));

    window.removeEventListener('keydown', this.keyDownBound, false);
    this.reset = false;
    // $('#inputfieldtxt').trigger('blur');
  }

  isVisible(): boolean {
    if (Number($('#saisie').css('opacity')) > 0) {
      return true;
    }
    return false;
  }

  submit(titre: string): void {
    $('#saisie').off('click', this.clickInputfieldBound);

    this.emitEvent(new MyKeyboardEvent(this.SUBMIT, 0, titre));
  }

  focus(): void {
    if (this.utils.md().mobile() === null) {
      if (this.appConfig.DEBUG) {
        console.log('KeyboardService:focus');
      }
      $('#inputfieldtxt').trigger('focus');
    }
  }

  show(tweenDuration, tweenDelay, clickInputfieldBound): any {
    if (this.appConfig.DEBUG) {
      console.log('KeyboardService show');
    }

    this.clickInputfieldBound = clickInputfieldBound;

    const promise = new Promise((resolve, reject) => {
      // this.centerInputField($('.copycat').width());
      $('#inputfieldtxt').trigger('focus');

      $('#saisie').css('display', 'block').css('pointer-events', 'all');

      gsap.to('#saisie', {
        duration: tweenDuration,
        opacity: 1,
        ease: 'power3.out',
        onComplete: () => {
          $('#inputfieldtxt').trigger('focus');

          gsap.killTweensOf('.btn-enter');
          gsap.to('.btn-enter', {
            duration: 0.6,
            opacity: 0,
            ease: 'power2.out',
            delay: tweenDelay,
          });
          setTimeout(() => {
            $('#saisie').on('click', this.clickInputfieldBound);
            resolve(true);
          }, tweenDuration * 2200); // anti fast click
        },
      });

      // .animate({ opacity: 1 }, tweenDuration * 1000, () => {

      // })
    });
    return promise;
  }

  hide(): any {
    if (this.appConfig.DEBUG) {
      console.log('KeyboardService hide');
    }
    const promise = new Promise((resolve, reject) => {
      $('#saisie').off('click', this.clickInputfieldBound);

      $('#saisie')
        .css('display', 'block')
        .css('pointer-events', 'none')
        .animate({ opacity: 0 }, 100, () => {
          this.resetKeyboard();
          if (this.utils.md().mobile() === null) {
            $('#inputfieldtxt').val('').trigger('focus');
          } else {
            $('#inputfieldtxt').val('');
          }
          $('.copycat').html($('#inputfieldtxt').val() as string);

          this.centerInputField($('.copycat').width());

          // $('#saisie').css('display', 'none');
          // $('html,body,canvas').css('cursor',_currentCursor);
          gsap.killTweensOf('.btn-enter');
          gsap.to('.btn-enter', { duration: 0, opacity: 1 });
          // $rootScope.$emit('cursorService:restore');
          if (resolve !== undefined) {
            resolve(true);
          }
        });
    });
    return promise;
  }

  clear(): void {
    $('#inputfieldtxt').val('');
  }

  resetKeyboard(): void {
    this.reset = false;
    this.emitEvent(new MyKeyboardEvent(this.RESET));
  }

  centerInputField(width): void {
    this.emitEvent(new MyKeyboardEvent(this.CENTER, width));
  }

  escape(): void {
    this.emitEvent(new MyKeyboardEvent(this.ESC, this.KEYCODE_ESC));
    this.reset = false;
  }

  konami(): void {
    this.emitEvent(new MyKeyboardEvent(this.KONAMI));
  }
  cheatCode(): void {
    this.emitEvent(new MyKeyboardEvent(this.CHEATCODE));
  }

  _onKeyDown(event): void {
    if (event.altKey || event.ctrlKey || event.metaKey) {
      return;
    }
    const key = event.keyCode;
    if (this.appConfig.DEBUG) {
      console.log('KeyboardService:_onKeyDown ' + key);
    }

    if (key > 47 && key < 91 && !this.reset) {
      this.emitEvent(new MyKeyboardEvent(this.KEYPRESSED, key));
      this.reset = true;
    } else if (key === this.KEYCODE_ESC) {
      // esc or enter
      this.emitEvent(new MyKeyboardEvent(this.ESC, key));
      this.reset = false;
    } else if (key === this.KEYCODE_BACKSPACE) {
      this.emitEvent(new MyKeyboardEvent(this.KEYPRESSED, key));
    }
  }
}
