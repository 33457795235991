<button type="buttonType" class="facebook-button" (click)="onClickButton($event)" (mouseover)="onRollOverButton($event)"
  (mouseout)="onRollOutButton($event)" [disabled]="isDisabled" [ngClass]="{
    loading: loading,
    disabled: isDisabled,
    active: isActivated,
    focus: isFocused,
    connect: isConnected,
    transparent: isTransparent
  }">
  Facebook
</button>
