import { Component, OnInit, OnDestroy } from '@angular/core';
import * as $ from 'jquery';
import { CursorService, CursorEvent } from '../../services/view/cursor.service';
import { SaveService, SaveEvent } from '../../services/game/save.service';
import { AppConfigService } from 'src/app/services/config/app-config.service';
import { Subscription } from 'rxjs';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'connect',
  templateUrl: './connect.component.html',
  styleUrls: ['./connect.component.scss'],
})
export class ConnectComponent implements OnInit, OnDestroy {
  constructor(
    private cursorService: CursorService,
    private saveService: SaveService,
    private appConfig: AppConfigService
  ) {}
  showHelper = false;
  currentPlayerName = 'Marty';
  assetsUrl = this.appConfig.assetsUrl;
  onCloseBound;
  onClickBound;
  rollOverBound;
  rollOutBound;
  saveSub: Subscription;

  ngOnInit(): void {
    if (this.appConfig.DEBUG) {
      console.log('ConnectComponent::ngOnInit');
    }
    this.rollOverBound = this.onRollOverButton.bind(this);
    this.rollOutBound = this.onRollOutButton.bind(this);
    this.onCloseBound = this.onClose.bind(this);
    this.onClickBound = this.onClick.bind(this);

    this.saveSub = this.saveService.emitter$.subscribe((event) => {
      switch (event.name) {
        case this.saveService.UPDATENAME:
          this.currentPlayerName = (event as SaveEvent).playerName;
          break;

        default:
          break;
      }
    });

    $('#closeConnect').on('click', this.onCloseBound);
    $('#closeConnect').on('mouseover', this.rollOverBound);
    $('#closeConnect').on('mouseout', this.rollOutBound);

    $('#closeFirstSave').on('click', this.onClickBound);
    $('#closeFirstSave').on('mouseover', this.rollOverBound);
    $('#closeFirstSave').on('mouseout', this.rollOutBound);
    this.saveService.init();

    // setTimeout(() => {
    //   this.saveService.showConnect();
    // }, 1500);
  }

  ngOnDestroy(): void {
    if (this.appConfig.DEBUG) {
      console.log('ConnectComponent::ngOnDestroy');
    }
    $('#closeConnect').off('click', this.onCloseBound);
    $('#closeConnect').off('mouseover', this.rollOverBound);
    $('#closeConnect').off('mouseout', this.rollOutBound);

    $('#closeFirstSave').off('click', this.onClickBound);
    $('#closeFirstSave').off('mouseover', this.rollOverBound);
    $('#closeFirstSave').off('mouseout', this.rollOutBound);
    this.saveSub.unsubscribe();
  }

  onClick(): void {
    this.saveService.emitEvent(new SaveEvent(this.saveService.HIDE));
    this.saveService.hideFirstSave().then(() => {
      this.saveService.emitEvent(new SaveEvent(this.saveService.HIDDEN));
    });
  }

  onClose(): void {
    this.saveService.emitEvent(new SaveEvent(this.saveService.HIDE));
    this.saveService.hideConnect().then(() => {
      this.saveService.emitEvent(new SaveEvent(this.saveService.HIDDEN));
    });
  }
  onRollOverButton(): any {
    this.cursorService.emitEvent(new CursorEvent(this.cursorService.ROLLOVER));
  }
  onRollOutButton(): any {
    this.cursorService.emitEvent(new CursorEvent(this.cursorService.ROLLOUT));
  }
}
