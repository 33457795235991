import { NgModule } from '@angular/core';
import { EndComponent } from '../../sections/end/end.component';
import { SharedModule } from '../shared/shared.module';
import { EndRoutingModule } from './end-routing.module';
import { InlineSVGModule } from 'ng-inline-svg';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { FacebookModule } from 'ngx-facebook';

@NgModule({
  declarations: [EndComponent],
  imports: [
    SharedModule,
    EndRoutingModule,
    InlineSVGModule.forRoot(),
    FacebookModule.forRoot(),
    ShareButtonsModule,
    ShareIconsModule,
  ],
  exports: [EndComponent],
})
export class EndModule {}
