import { Injectable, EventEmitter } from '@angular/core';
import * as createjs from 'createjs-module';
// import * as $ from 'jquery';
import { AppConfigService } from 'src/app/services/config/app-config.service';

@Injectable({
  providedIn: 'root',
})
// export class PreloaderEvent {
//   constructor(public name: string) {}
// }
export class PreloaderService {
  public queue;
  public states = {
    PENDING: 1,
    LOADING: 2,
    RESOLVED: 3,
    REJECTED: 4,
  };
  public state = 0;
  public maxConnection = 1;
  public handleProgressStep = 0;
  // public emitter$: EventEmitter<PreloaderEvent>;

  public DONE = 'PreloaderService:done';

  constructor(private appConfig: AppConfigService) {
    // this.emitter$ = new EventEmitter();
  }

  // emitEvent(event: PreloaderEvent): void {
  //   console.log('PreloaderService emitEvent %s', event.name);
  //   this.emitter$.emit(event);
  // }

  init(): void {
    (window as any).createjs = createjs;
    createjs.Sound.initializeDefaultPlugins();
    this.queue = new createjs.LoadQueue(
      true,
      this.appConfig.assetsUrl + '/assets/'
    );
    // this.queue = new createjs.LoadQueue(true, '/assets/');
    this.queue.installPlugin(createjs.Sound);
  }
  /**
   * Initialise le service et charge les assets
   * @param manifest tableau contenant la liste des assets à charger
   */
  preloadAssets(manifest: Array<any>): void {
    // const str = JSON.stringify(manifest, null, 4);
    // console.log('preloadAssets ' + str);
    this.handleProgressStep = 0;

    this.queue.maintainScriptOrder = true;
    this.queue.on('complete', this.onComplete);
    this.queue.on('progress', this.onProgress);
    this.queue.on('fileload', this.onFileLoad);
    this.queue.on('error', this.onError);
    this.queue.setMaxConnections(this.maxConnection);

    createjs.Sound.alternateExtensions = ['ogg'];

    this.queue.loadManifest(manifest);
  }

  loadFile(url: string): void {
    if (this.queue) {
      this.queue.loadFile(url);
    }
  }

  disableFileLoadCallBack(): void {
    this.queue.off('fileload', this.onFileLoad);
  }

  onComplete(): void {
    // console.log('PreloaderService::onComplete');
  }

  onFileLoad(event): void {}

  onProgress(event): void {
    // console.log('PreloaderService::onProgress');
  }

  onError(event): void {}
}
