import { Component, OnInit } from '@angular/core';
import { TutosService } from 'src/app/services/game/tutos.service';
import { TutosEvent } from '../../../services/game/tutos.service';
import * as $ from 'jquery';
import gsap from 'gsap';
import { AppConfigService } from 'src/app/services/config/app-config.service';
import { ScreenService } from 'src/app/services/view/screen.service';
import { JarvisService } from 'src/app/services/view/jarvis.service';
import { KeyboardService } from '../../../services/view/keyboard.service';
import { TimerService } from '../../../services/utilities/timer.service';
import { UtilsService } from 'src/app/services/utilities/utils.service';
import { SoundService } from 'src/app/services/sound/sound.service';
import { ComboService } from 'src/app/services/game/combo.service';
import { CursorService } from 'src/app/services/view/cursor.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'tutos',
  templateUrl: './tutos.component.html',
  styleUrls: ['./tutos.component.scss'],
})
export class TutosComponent implements OnInit {
  constructor(
    private tutosService: TutosService,
    private appConfig: AppConfigService,
    private screenService: ScreenService,
    private jarvisService: JarvisService,
    private keyboardService: KeyboardService,
    private soundService: SoundService,
    private comboService: ComboService,
    private timerService: TimerService,
    private cursorService: CursorService,
    private utils: UtilsService
  ) {}
  showHelper = false;
  clickBound;
  escBound;
  currentResolve;
  tutoShown = [];
  assetsUrl = this.appConfig.assetsUrl;
  mobileMode = false;
  notifOpacity = 0;

  ngOnInit(): void {
    if (this.utils.md().mobile() !== null) {
      this.mobileMode = true;
    }
    this.tutosService.emitter$.subscribe((event: TutosEvent) =>
      this._eventDispatch(event)
    );

    this.clickBound = (() => {
      this.onClickContainer();
    }).bind(this);
    this.escBound = ((event) => {
      this.onEsc(event);
    }).bind(this);
  }

  showTuto(id: number, resolve?: any): void {
    if (!this.tutosService.shown(id)) {
      this.tutosService.addTuto(id);

      if (this.appConfig.DEBUG) {
        console.log('TutosComponent:showTuto ' + id);
      }
      this.timerService.pauseChrono();
      this.jarvisService.showui();
      this.cursorService.cross();

      $('#tutosContainer').css('display', 'flex');
      this.currentResolve = resolve;
      gsap.to('#tutosContainer', {
        duration: 0.4,
        autoAlpha: 1,
        ease: this.appConfig.tweenFunc,
        onStart: () => {
          this.screenService.blur();
          this.keyboardService.disable();
          this.jarvisService.disable();
          this.soundService.tuto();
        },
        onComplete: () => {
          switch (id) {
            case 1:
              gsap.to('#tuto_' + id, {
                duration: 0,
                y: +20,
              });
              gsap.to('#zoomWidget', {
                opacity: 0,
                duration: 0,
                overwrite: true,
              });
              gsap.to('#radarBtn', { opacity: 0, duration: 0 });
              gsap.to('.lux-bloc-score', { opacity: 0, duration: 0 });
              gsap.to('#saveBtn', { opacity: 0, duration: 0 });
              // gsap.to('#floppyDisk', { opacity: 0, duration: 0 })
              gsap.to('.logo_rap_jeu', { opacity: 0, duration: 0 });
              break;
            case 2:
              gsap.to('#tuto_' + id, {
                duration: 0,
                y: +20,
              });
              gsap.to('#zoomWidget', {
                opacity: 0,
                duration: 0,
                overwrite: true,
              });
              gsap.to('#radarBtn', { opacity: 0, duration: 0 });
              gsap.to('.lux-bloc-score', { opacity: 0, duration: 0 });
              gsap.to('#saveBtn', { opacity: 0, duration: 0 });
              gsap.to('.logo_rap_jeu', { opacity: 0, duration: 0 });
              break;
            case 3:
              gsap.to('#tuto_' + id, {
                duration: 0,
                x: +20,
              });

              gsap.to('#zoomWidget', {
                opacity: 0,
                duration: 0,
                overwrite: true,
              });
              gsap.to('#saveBtn', { opacity: 0, duration: 0 });
              gsap.to('.lux-bloc-score', { opacity: 0, duration: 0 });
              gsap.to('.logo_rap_jeu', { opacity: 0, duration: 0 });
              break;
            case 4:
              gsap.to('#tuto_' + id, {
                duration: 0,
                x: -20,
              });
              gsap.to('#zoomWidget', {
                opacity: 0,
                duration: 0,
                overwrite: true,
              });
              gsap.to('#radarBtn', { opacity: 0, duration: 0 });
              gsap.to('.lux-bloc-score', { opacity: 0, duration: 0 });
              gsap.to('.logo_rap_jeu', { opacity: 0, duration: 0 });
              break;
            case 5:
              gsap.to('#tuto_' + id, {
                duration: 0,
                x: -20,
              });
              gsap.to('#zoomWidget', {
                opacity: 0,
                duration: 0,
                overwrite: true,
              });
              gsap.to('#radarBtn', { opacity: 0, duration: 0 });
              gsap.to('.lux-bloc-1', { opacity: 0, duration: 0 });
              gsap.to('.lux-bloc-2', { opacity: 0, duration: 0 });
              gsap.to('#saveBtn', { opacity: 0, duration: 0 });
              gsap.to('.logo_rap_jeu', { opacity: 0, duration: 0 });
              break;
            case 6:
              gsap.to('#tuto_' + id, {
                duration: 0,
                y: -20,
              });

              gsap.to('.lux-bloc-score', { opacity: 0, duration: 0 });
              gsap.to('#radarBtn', { opacity: 0, duration: 0 });
              gsap.to('.lux-bloc-1', { opacity: 0, duration: 0 });
              gsap.to('.lux-bloc-2', { opacity: 0, duration: 0 });
              gsap.to('#saveBtn', { opacity: 0, duration: 0 });
              gsap.to('.logo_rap_jeu', {
                opacity: 0,
                duration: 0,
              });
              break;
          }
          gsap.to('#tuto_' + id, {
            duration: 0.4,
            autoAlpha: 1,
            x: 0,
            y: 0,
            ease: 'back.out',
            onStart: () => {},
            onComplete: () => {
              $('#tutosContainer').on('click', this.clickBound);
              $(document).on(
                'keyup',
                null,
                { callbackFunc: this.clickBound },
                this.escBound
              );
            },
          });
        },
      });
    } else {
      if (this.appConfig.DEBUG) {
        console.log('TutosComponent:showTuto already done for ' + id);
      }
      resolve(true);
    }
  }

  hideTuto(): void {
    gsap.to('#tutosContainer', {
      duration: 0.4,
      autoAlpha: 0,
      ease: this.appConfig.tweenFunc,
      onStart: () => {
        this.screenService.unblur();
        this.jarvisService.enable();
        this.timerService.resumeChrono();
        this.cursorService.normal();

        gsap.to('#zoomWidget', { opacity: 1, duration: 0 });
        gsap.to('#saveBtn', { opacity: 1, duration: 0 });
        gsap.to('.lux-bloc-score', { opacity: 1, duration: 0 });
        gsap.to('.lux-bloc-1', { opacity: 1, duration: 0 });
        gsap.to('.lux-bloc-2', { opacity: 1, duration: 0 });
        gsap.to('.logo_rap_jeu', { opacity: 1, duration: 0 });
        gsap.to('#radarBtn', { opacity: 1, duration: 0 });
      },
      onComplete: () => {
        $('#tutosContainer').css('display', 'none');
        for (let index = 3; index <= this.tutosService.NBTUTO; index++) {
          gsap.to('#tuto_' + index, { duration: 0, autoAlpha: 0 });
        }
        this.currentResolve(true);
      },
    });
  }

  onClickContainer(): void {
    $('#tutosContainer').off('click', this.clickBound);
    $(document).off('keyup', this.escBound);
    this.hideTuto();
  }

  onEsc(event): void {
    event.preventDefault();
    event.stopPropagation();
    if (this.appConfig.DEBUG) {
      console.log('TutosComponent:onEsc');
    }
    $(document).off('keyup', this.escBound);
    if (event.keyCode === 27) {
      event.data.callbackFunc();
    } else {
      $(document).on(
        'keyup',
        null,
        { callbackFunc: this.clickBound },
        this.escBound
      );
    }
  }

  _eventDispatch(event: TutosEvent): void {
    if (this.appConfig.DEBUG) {
      console.log('TutosComponent._eventDispatch : ' + event.name);
    }
    switch (event.name) {
      case this.tutosService.SHOW:
        this.showTuto(event.id, event.resolve);
        break;
      case this.tutosService.HIDE:
        break;

      default:
        break;
    }
  }
}
