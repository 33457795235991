<div id="main-app" class="wrapper">
  <router-outlet></router-outlet>
  <!-- <video-background></video-background> -->
  <footer></footer>
  <cursor></cursor>
  <credits></credits>
</div>
<!-- <div id="smalldevices"></div> -->
<!-- <div *ngIf="isMobile == true">
  <rotate-device></rotate-device>
</div> -->