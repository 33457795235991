export class Game {
  soundPlayer: any;
  titre = '';
  fontSize = 80;
  // score66 = '00';
  // score = '0000';
  // baseScore = 66;
  showFounded = [];
  lastShowPosition = {
    xpos: 0,
    ypos: 0,
  };
  showTypeHereTimer: any;
  showPinsTimer: any;
  showTypeHereModal = false;
  gameState = {
    FOUND_GOLD: false,
    ERROR: false,
  };
  broadcasters = [];
}
