export class Progress {
  constructor() {}
  nbRefs: number; // sur 66
  easyRefs: number;
  mediumRefs: number;
  hardRefs: number;
  nbBonus: number; // nombre de bonus debloqué
  nbQuiz: number; // nombre de bonne reponses au quiz
  score: number; // popcorn points
  nbError: number; // nb error
  nbGold: number;
  nbMovies: number;
  nbSeries: number;
  progressValue: number;
  duration: number;
  answers: Array<any>;
  errors: Array<any>;
  // negative: number;
}
