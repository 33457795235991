<div id="saisie">
  <overlay [keyboard]="showHelper"></overlay>
  <div class="field-wrap">
    <div>
      <form class="form-horizontal css-form" enctype="multipart/form-data" charset="UTF-8" accept-charset="UTF-8"
        (ngSubmit)="onSubmit()">
        <div class="copycat"></div>
        <input id="inputfieldtxt" name="videogame" type="text" ng-minlength="2" ng-maxlength="35" maxlength="35"
          submit-required="true" autocomplete="off" [(ngModel)]="titre" (input)="onChange($event)" spellcheck="false"
          (keydown.backspace)="onChange($event)" [ngKeyboardShortcuts]="shortcuts" />
      </form>
    </div>
  </div>
</div>
