import { EventEmitter, Injectable } from '@angular/core';
import gsap from 'gsap';
import * as $ from 'jquery';
import Player from '@vimeo/player';
import { UtilsService } from '../utilities/utils.service';
import { AppConfigService } from 'src/app/services/config/app-config.service';

export class VideoPlayerEvent {
  constructor(public name: string) {}
}
@Injectable({
  providedIn: 'root',
})
export class VideoplayerService {
  public emitter$: EventEmitter<VideoPlayerEvent>;

  public READY = 'VideoplayerService:READY';
  public PLAY = 'VideoplayerService:PLAY';
  public PAUSE = 'VideoplayerService:PAUSE';
  public PLAYING = 'VideoplayerService:PLAYING';
  public PAUSED = 'VideoplayerService:PAUSED';
  public HIDDEN = 'VideoplayerService:HIDDEN';

  // https://vimeo.com/798668660

  player: Player;
  iframe;
  options = {
    id: 798668660,
    background: true,
    muted: true,
    loop: true,
    // responsive: true,
  };

  constructor(
    private utils: UtilsService,
    private appConfig: AppConfigService
  ) {
    this.emitter$ = new EventEmitter();
  }

  init(): void {
    if (this.appConfig.DEBUG) {
      console.log('VideoplayerService:init');
    }
    this.player = new Player('popcorn', this.options);
    // this.player = new Player(this.iframe);
    this.player.on('loaded', () => {
      this.iframe = document.querySelector('iframe');
      $(this.iframe).css('width', '100vw');
      $(this.iframe).css('height', '56.25vw');
      $(this.iframe).css('min-height', '100vh');
      $(this.iframe).css('min-width', '177.77vh');
      $(this.iframe).css('position', 'absolute');
      $(this.iframe).css('top', '50%');
      $(this.iframe).css('left', '50%');
      $(this.iframe).css('transform', 'translate(-50%, -50%)');
    });

    this.player
      .ready()
      .then(() => {
        this.player.getDuration().then((duration) => {
          const startTime = this.utils.getRandom(10, duration);
          if (this.appConfig.DEBUG) {
            console.log(
              'VideoplayerService init startime (%s) %s',
              duration,
              startTime
            );
          }
          this.player.setCurrentTime(startTime);
        });
        this.emitEvent(new VideoPlayerEvent(this.READY));
      })
      .catch(function (error) {
        // an error occurred
        if (this.appConfig.DEBUG) {
          console.log('VideoplayerService init error %s', error.name);
        }
      });

    this.player.on('playing', () => {
      this.emitEvent(new VideoPlayerEvent(this.PLAYING));
    });
    this.player.on('error', (error) => {
      if (this.appConfig.DEBUG) {
        console.log('VideoplayerService  error %s', error.name);
      }
    });
  }

  destroy(): void {
    if (this.appConfig.DEBUG) {
      console.log('VideoplayerService:destroy');
    }
    this.player.off('loaded');
    this.player.off('playing');
    this.player.off('error');
    this.player
      .destroy()
      .then(() => {
        if (this.appConfig.DEBUG) {
          // console.log('VideoplayerService:destroy')
          console.log('VideoplayerService:destroy > the player was destroyed');
        }
      })
      .catch((error) => {
        // an error occurred
      });
  }

  public emitEvent(event: VideoPlayerEvent): void {
    if (this.appConfig.DEBUG) {
      console.log('VideoplayerService emitEvent %s', event.name);
    }
    this.emitter$.emit(event);
  }

  public show(): void {
    if (this.appConfig.DEBUG) {
      console.log('VideoplayerService:show');
    }
    $('.noise').css('opacity', 0.15);
    $('.video-hero').css('transform', 'scale(2.5)');
    $('.video-hero').css('display', 'block');
    gsap.to('.video-hero', {
      duration: 1.2,
      scale: 1,
      ease: 'expo.out',
      opacity: 1,
    });
  }
  public hide(): any {
    if (this.appConfig.DEBUG) {
      console.log('VideoplayerService:hide');
    }
    const promise = new Promise((resolve) => {
      gsap.to('.noise', {
        delay: 0.4,
        duration: 0.4,
        opacity: 0,
        ease: 'power3.out',
      });
      gsap.to('.video-hero', {
        duration: 0.8,
        ease: 'expo.in',
        scale: 1.5,
        opacity: 0,
        onComplete: () => {
          // $('.video-hero').css('display', 'none')
          // $('.noise').css('opacity', 0);
          this.emitEvent(new VideoPlayerEvent(this.HIDDEN));
          this.pause();
          resolve(true);
        },
      });
    });

    return promise;
  }
  public play(): void {
    // this.emitEvent(new VideoPlayerEvent(this.PLAY))
    if (this.appConfig.DEBUG) {
      console.log('VideoplayerService:play');
    }
    this.player
      .play()
      .then(() => {
        this.show();
      })
      .catch(function (error) {
        // an error occurred
        if (this.appConfig.DEBUG) {
          console.log('VideoplayerService play error %s', error.name);
        }
      });
  }
  public pause(): void {
    if (this.appConfig.DEBUG) {
      console.log('VideoplayerService:pause');
    }
    // this.emitEvent(new VideoPlayerEvent(this.PAUSE))
    this.player
      .pause()
      .then(() => {
        this.emitEvent(new VideoPlayerEvent(this.PAUSED));
      })
      .catch(function (error) {
        // an error occurred
        if (this.appConfig.DEBUG) {
          console.log('VideoplayerService pause error %s', error.name);
        }
      });
  }
}
