import { EventEmitter, Injectable } from '@angular/core';

export class CreditsEvent {
  constructor(public name: string) {}
}
@Injectable({
  providedIn: 'root',
})
export class CreditsService {
  public emitter$: EventEmitter<CreditsEvent>;

  public SHOW = 'DashboardService:SHOW';
  public HIDE = 'DashboardService:HIDE';
  constructor() {
    this.emitter$ = new EventEmitter();
  }

  public emitEvent(event: CreditsEvent): void {
    // console.log('CreditsService emitEvent %s', event.name);
    this.emitter$.emit(event);
  }

  show(): void {
    this.emitEvent(new CreditsEvent(this.SHOW));
  }
  hide(): void {
    this.emitEvent(new CreditsEvent(this.HIDE));
  }
}
