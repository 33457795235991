import { Injectable, EventEmitter } from '@angular/core'

import { Show } from 'src/app/model/show'
import { AppConfigService } from 'src/app/services/config/app-config.service'

export class JarvisEvent {
  constructor(
    public name: string,
    public score?: Array<number>,
    public show?: Show,
    public missionStatus?: string,
    public resolve?: any
  ) {}
}

@Injectable({
  providedIn: 'root'
})
export class JarvisService {
  public emitter$: EventEmitter<JarvisEvent>

  public BOOT = 'JarvisService:boot'
  public ERROR = 'JarvisService:ERROR'
  public BOOTSUCCESS = 'JarvisService:booted'
  public SHUTDOWN = 'JarvisService:shutdown'
  public SHUTDOWNSUCESS = 'JarvisService:shutdownsucess'
  public BUILDUI = 'JarvisService:buildUI'
  public UIREADY = 'JarvisService:UIREADY'
  public UPDATESCORE = 'JarvisService:UPDATESCORE'
  public UPDATEERROR = 'JarvisService:UPDATEERROR'
  public SHOWBADGE = 'JarvisService:SHOWBADGE'
  public SHOWBADGESUCESS = 'JarvisService:SHOWBADGESUCESS'
  public SHOWFOCUS = 'JarvisService:SHOWFOCUS'
  public SHOWFOCUSSUCESS = 'JarvisService:SHOWFOCUSSUCESS'
  public SHOWUI = 'JarvisService:SHOWUI'
  public HIDEUI = 'JarvisService:HIDEUI'
  public UIHIDDEN = 'JarvisService:UIHIDDEN'
  public CONNECTED = 'JarvisService:CONNECTED'
  public SHOWCHRONO = 'JarvisService:STARTCHRONO'
  public UPDATECHRONO = 'JarvisService:UPDATECHRONO'
  public HIDECHRONO = 'JarvisService:HIDECHRONO'
  public ENABLEBUTTONS = 'JarvisService:ENABLEBUTTONS'
  public DISABLEBUTTONS = 'JarvisService:DISABLEBUTTONS'
  public UPDATENOTIF = 'JarvisService:UPDATENOTIF'
  public BLUR = 'JarvisService:BLUR'
  public UNBLUR = 'JarvisService:UNBLUR'

  constructor(private appConfig: AppConfigService) {
    this.emitter$ = new EventEmitter()
  }

  public emitEvent(event: JarvisEvent): void {
    if (this.appConfig.DEBUG) {
      console.log('JarvisService emitEvent %s', event.name)
    }
    this.emitter$.emit(event)
  }

  boot(): void {
    this.emitEvent(new JarvisEvent(this.BOOT))
  }

  shutdown(gameStatus: string): void {
    this.emitEvent(
      new JarvisEvent(this.SHUTDOWN, undefined, undefined, gameStatus)
    )
  }

  buildUI(): void {
    this.emitEvent(new JarvisEvent(this.BUILDUI))
  }
  blur(): void {
    this.emitEvent(new JarvisEvent(this.BLUR))
  }
  unblur(): void {
    this.emitEvent(new JarvisEvent(this.UNBLUR))
  }

  showui(): void {
    this.emitEvent(new JarvisEvent(this.SHOWUI))
  }
  hideui(): void {
    this.emitEvent(new JarvisEvent(this.HIDEUI))
  }
  updateScore(score: Array<number>): void {
    this.emitEvent(new JarvisEvent(this.UPDATESCORE, score))
  }
  updateError(): void {
    this.emitEvent(new JarvisEvent(this.UPDATEERROR))
  }
  error() {
    const promise = new Promise((resolve) => {
      this.emitEvent(new JarvisEvent(this.ERROR, null, null, '', resolve))
    })

    return promise
  }
  updateNotif(): void {
    this.emitEvent(new JarvisEvent(this.UPDATENOTIF))
  }

  showBadge(show: Show): void {
    this.emitEvent(new JarvisEvent(this.SHOWBADGE, undefined, show))
  }

  showFocus(show: Show): void {
    this.emitEvent(new JarvisEvent(this.SHOWFOCUS, undefined, show))
  }

  userConnected(): void {
    this.emitEvent(new JarvisEvent(this.CONNECTED))
  }

  enable(): void {
    this.emitEvent(new JarvisEvent(this.ENABLEBUTTONS))
  }

  disable(): void {
    this.emitEvent(new JarvisEvent(this.DISABLEBUTTONS))
  }
}
