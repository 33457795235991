import { Component, NgZone, OnInit, OnDestroy } from '@angular/core';
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin';
import { RoughEase } from 'gsap/EasePack';
import gsap from 'gsap';
import * as $ from 'jquery';

import { Router } from '@angular/router';
import { AppConfigService } from 'src/app/services/config/app-config.service';
import { SoundService } from 'src/app/services/sound/sound.service';
import { LoadService } from 'src/app/services/game/load.service';
import { ProgressService } from 'src/app/services/game/progress.service';
import { RankingService } from 'src/app/services/game/ranking.service';
import { AuthenticationService } from 'src/app/services/http/authentication.service';
import { PlayerService } from 'src/app/services/user/player.service';
import { UtilsService } from 'src/app/services/utilities/utils.service';
import { Progress } from 'src/app/model/progress';
import { SaveService } from 'src/app/services/game/save.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { RotateDeviceService } from 'src/app/services/view/rotate-device.service';
import * as screenfull from 'screenfull';
import { RedbullTrackingService } from 'src/app/services/utilities/redbull-tracking.service';
import {
  CursorEvent,
  CursorService,
} from 'src/app/services/view/cursor.service';
import { Subscription } from 'rxjs';
import { ButtonService } from 'src/app/services/view/button.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'hello',
  templateUrl: './hello.component.html',
  styleUrls: ['./hello.component.scss'],
})
export class HelloComponent implements OnInit, OnDestroy {
  constructor(
    private appConfig: AppConfigService,
    private soundService: SoundService,
    private playerService: PlayerService,
    private authService: AuthenticationService,
    private loadService: LoadService,
    private progressService: ProgressService,
    private saveService: SaveService,
    private rankingService: RankingService,
    private $gaService: GoogleAnalyticsService,
    // private rbTracking: RedbullTrackingService,
    private cursorService: CursorService,
    private buttonService: ButtonService,

    private rotateDeviceService: RotateDeviceService,
    private utils: UtilsService,
    private router: Router,
    private ngZone: NgZone
  ) {}

  title1 = 'Classement';
  title1Over = 'top 10';
  title2 = 'Nouvelle partie';
  title2Over = 'Commencer';
  title3 = 'Reprende ma partie';
  title3Over = 'Continuer';

  playerName = '';
  playerGrade = '1st class';
  playerBadge = 'level0';
  playerScore = '0000';
  playerNbRefs = '00';
  playerNbErrors = 0;
  maxRefs = this.appConfig.MAX_REFS;
  assetsUrl = this.appConfig.assetsUrl;
  buttonSubs: Subscription;
  onResizeBound;

  ngOnInit(): void {
    gsap.registerPlugin(DrawSVGPlugin, RoughEase);
    this.cursorService.normal();
    this.buttonSubs = this.buttonService.emitter$.subscribe((event) =>
      this._eventDispatch(event)
    );
    this.loadService.loadGame().then((loadedGameParsed: Progress) => {
      this.progressService.loadProgress(loadedGameParsed);
      this.playerScore = this.utils.pad(loadedGameParsed.score, 4);
      this.playerNbRefs = this.utils.pad(loadedGameParsed.nbRefs, 2);
      this.playerNbErrors = loadedGameParsed.nbError;
      this.playerName = this.playerService.name;
      switch (this.playerNbErrors) {
        case 1:
          $('.mic-1').addClass('mic-done').removeClass('mic-ok');
          break;
        case 2:
          $('.mic-1').addClass('mic-done').removeClass('mic-ok');
          $('.mic-2').addClass('mic-done').removeClass('mic-ok');
          break;
        case 3:
          $('.mic-1').addClass('mic-done').removeClass('mic-ok');
          $('.mic-2').addClass('mic-done').removeClass('mic-ok');
          $('.mic-3').addClass('mic-done').removeClass('mic-ok');
          break;
        default:
          break;
      }
      this._showHome();
      this.$gaService.pageView('/hello', 'Hello Menu');
    });

    // setTimeout(() => {
    // }, 10);
  }

  ngOnDestroy() {
    if (this.appConfig.DEBUG) {
      console.log('HelloComponent:ngOnDestroy');
    }
    this.buttonSubs.unsubscribe();
    // $(window).off('resize', this.onResizeBound);
  }
  private _eventDispatch(event: any) {
    switch (event.name) {
      case this.buttonService.ROLLOVER:
        this.cursorService.emitEvent(
          new CursorEvent(this.cursorService.ROLLOVER)
        );
        break;
      case this.buttonService.ROLLOUT:
        this.cursorService.emitEvent(
          new CursorEvent(this.cursorService.ROLLOUT)
        );
        break;
      default:
        break;
    }
  }
  _showHome(): void {
    gsap.to('#hello', {
      duration: 0.6,
      opacity: 1,
      ease: 'power3.out',
    });
    gsap.to('.hello', {
      delay: 0.1,
      duration: 0.6,
      opacity: 1,
      ease: 'power3.out',
    });

    gsap.to('.separator', {
      delay: 0.4,
      duration: 0.6,
      opacity: 1,
      ease: 'power3.out',
    });
    gsap.to('.recap', {
      delay: 0.4,
      duration: 0.6,
      opacity: 1,
      ease: 'power3.out',
    });
    gsap.to('.btn1', {
      delay: 0.6,
      duration: 0.6,
      opacity: 1,
      ease: 'power3.out',
    });
    gsap.to('.btn2', {
      delay: 0.8,
      duration: 0.6,
      opacity: 1,
      ease: 'power3.out',
    });
    gsap.to('.btn3', {
      delay: 1,
      duration: 0.6,
      opacity: 1,
      onComplete: () => {
        $('.home-link').css('opacity', 1);
      },
      ease: 'power3.out',
    });
  }
  none(): void {}
  onRollOver(): void {}
  onRollOut(): void {}

  resetGame(): void {
    this.$gaService.event('New Game', 'Interactions', 'Reset Game');
    // this.rbTracking.track(
    //   'gaEvent',
    //   'Interactions',
    //   'New Game',
    //   'Reset Game',
    //   'Red Bull Play Hard'
    // );
    $('.footer').css('display', 'none');
    this.progressService.reset();
    this.playerService.gameid = '';
    this.loadService.reset();
    this.progressService.resetAnswers();
    this.saveService.reset();
    const div = '#hello';
    this.soundService.stopIntro();
    if (this.utils.md().mobile() !== null) {
      if (this.utils.md().os() !== 'iOS' && this.utils.md().os() !== 'iPadOS') {
        if (screenfull.isEnabled) {
          screenfull.toggle();
        }
      }
    }
    gsap.killTweensOf(div);
    gsap.to(div, {
      duration: 0.3,
      autoAlpha: 0,
      ease: this.appConfig.tweenFunc,
      onComplete: () => {
        // if (this.utils.md().mobile() !== null) {
        //   this.rotateDeviceService
        //     .switchToHorizontal('pour jouer.')
        //     .then(() => {
        //       this.ngZone.run(() =>
        //         this.router.navigate(['/game'], {
        //           // this.router.navigate(['/end'], {
        //           skipLocationChange: true,
        //         })
        //       );
        //     });
        // } else {
        this.ngZone.run(() =>
          this.router.navigate(['/game'], {
            // this.router.navigate(['/end'], {
            skipLocationChange: true,
          })
        );
        // }
      },
    });
  }
  logout(): void {
    this.$gaService.event('Logout', 'Interactions', 'Logout');
    // this.rbTracking.track(
    //   'gaEvent',
    //   'Interactions',
    //   'Logout',
    //   'Logout',
    //   'Red Bull Play Hard'
    // );
    this.authService.logout();
    this.playerService.resetPlayer();

    this.progressService.reset();
    this.playerService.gameid = '';
    this.loadService.reset();
    this.progressService.resetAnswers();
    this.saveService.reset();

    const div = '#hello';
    // this.sounsService.stopIntro();
    gsap.killTweensOf(div);
    gsap.to(div, {
      duration: 0.3,
      autoAlpha: 0,
      ease: this.appConfig.tweenFunc,
      onComplete: () => {
        this.ngZone.run(() =>
          this.router.navigate(['/home'], {
            skipLocationChange: true,
          })
        );
      },
    });
  }

  ranking(): void {
    const div = '#hello';
    this.soundService.stopIntro(); // <- ???
    gsap.killTweensOf(div);
    gsap.to(div, {
      duration: 0.3,
      autoAlpha: 0,
      ease: this.appConfig.tweenFunc,
      onComplete: () => {
        this.ngZone.run(() =>
          this.router.navigate(['/ranking'], {
            skipLocationChange: true,
          })
        );
      },
    });
  }

  startNewGame(): void {
    this.$gaService.event('Continue Game', 'Interactions', 'Continue Game');
    // this.rbTracking.track(
    //   'gaEvent',
    //   'Interactions',
    //   'Continue Game',
    //   'Continue Game',
    //   'Red Bull Play Hard'
    // );
    $('.footer').css('display', 'none');
    const div = '#hello';
    if (this.utils.md().mobile() !== null) {
      if (this.utils.md().os() !== 'iOS' && this.utils.md().os() !== 'iPadOS') {
        if (screenfull.isEnabled) {
          screenfull.toggle();
        }
      }
    }
    this.soundService.stopIntro();
    gsap.killTweensOf(div);
    gsap.to(div, {
      duration: 0.3,
      autoAlpha: 0,
      ease: this.appConfig.tweenFunc,
      onComplete: () => {
        // if (this.utils.md().mobile() !== null) {
        //   this.rotateDeviceService
        //     .switchToHorizontal('pour jouer.')
        //     .then(() => {
        //       this.ngZone.run(() =>
        //         this.router.navigate(['/game'], {
        //           // this.router.navigate(['/end'], {
        //           skipLocationChange: true,
        //         })
        //       );
        //     });
        // } else {
        this.ngZone.run(() =>
          this.router.navigate(['/game'], {
            // this.router.navigate(['/end'], {
            skipLocationChange: true,
          })
        );
        // }
      },
    });
  }
}
