<div id="footer" class="justify-content-between">
  <div class="d-flex flex-row justify-content-center align-items-center">
    <div class="flex-row justify-content-center align-items-center footer-button">
      <button id="fullscreenBtn" class="btn-fullscreen" type="button" (mouseover)="onRollOverButton()"
        (mouseout)="onRollOutButton()" (click)="fullscreen($event)"></button>
    </div>
    <div class="d-flex flex-row justify-content-center align-items-center">
      <button id="creditsBtn" class="btn-text" type="button" (mouseover)="onRollOverButton()"
        (mouseout)="onRollOutButton()" (click)="showCredits($event)">
        Crédits
      </button>
    </div>
  </div>

  <div class="d-flex flex-row justify-content-center align-items-center">
    <!-- <div class="d-flex flex-row justify-content-center align-items-center"><button id="legalsBtn" class="btn-text"
        type="button" (mouseover)="onRollOverButton()" (mouseout)="onRollOutButton()"
        (click)="toggleRBHeaderFooter($event)">
        Mentions Légales
      </button></div> -->
    <div class="flex-row justify-content-center align-items-center footer-button"><button id="soundBtn"
        class="btn-sound" type="button" (mouseover)="onRollOverButton()" (mouseout)="onRollOutButton()"
        (click)="toggleSound($event)"></button></div>
  </div>
</div>
