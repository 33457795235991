<form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
  <!-- <div class="row">
    <div class="col-6"> -->
  <div class="form-group">
    <input type="text" placeholder="{{ placeHolderName }}" formControlName="name"
      class="form-control form-name first-input regForm" [ngClass]="{ 'is-invalid': submitted && f.name.errors }"
      (mouseover)="onRollOverButton()" (mouseout)="onRollOutButton()" />
    <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
      <div *ngIf="f.name.errors.required">
        Merci de saisir un {{ placeHolderName }}
      </div>
    </div>
  </div>
  <!-- <div class="form-group">
    <input type="text" placeholder="{{ placeHolderFirstName }}" formControlName="fname"
      class="form-control form-name regForm" [ngClass]="{ 'is-invalid': submitted && f.fname.errors }"
      (mouseover)="onRollOverButton()" (mouseout)="onRollOutButton()" />
    <div *ngIf="submitted && f.fname.errors" class="invalid-feedback">
      <div *ngIf="f.fname.errors.required">
        Merci de saisir un {{ placeHolderFirstName }}
      </div>
    </div>
  </div> -->
  <div class="form-group">
    <input type="text" placeholder="{{ placeHolderEmail }}" formControlName="email"
      class="form-control form-email regForm" [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
      (mouseover)="onRollOverButton()" (mouseout)="onRollOutButton()" />
    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
      <div *ngIf="f.email.errors.required">
        Merci de saisir une adresse {{ placeHolderEmail }}
      </div>
    </div>
  </div>
  <!-- </div>
    <div class="col-6"> -->
  <div class="form-group">
    <input type="password" placeholder="{{ placeHolderPassword }}" formControlName="password"
      class="form-control form-password regForm" [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
      (mouseover)="onRollOverButton()" (mouseout)="onRollOutButton()" autocomplete="on" />
    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
      <div *ngIf="f.password.errors.required">
        Merci de saisir un {{ placeHolderPassword }}
      </div>
      <div *ngIf="f.password.errors.minlength">
        {{ placeHolderPassword }} doit contenir au moins 6 cacarctères
      </div>
    </div>
  </div>
  <!-- <div class="form-group">
        <input type="password" placeholder="{{placeHolderConfirmPassword}}" formControlName="confirmPassword"
          class="form-control form-confirmPassword regForm"
          [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" (mouseover)="onRollOverButton()"
          (mouseout)="onRollOutButton()" autocomplete="on" />
        <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
          <div *ngIf="f.confirmPassword.errors.required">{{placeHolderPassword}} is required</div>
          <div *ngIf="f.confirmPassword.errors.mustMatch">{{placeHolderPassword}} must match</div>
        </div>
      </div> -->
  <!-- </div>
  </div>
  <div class="row">
    <div class="col"> -->
  <div class="form-group">
    <button [disabled]="loading" class="submit-button" (mouseover)="onRollOverButton()" (mouseout)="onRollOutButton()">
      <span>{{ buttonTextOver }}</span>
      <span><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="
            margin: auto;
            background: none;
            display: block;
            shape-rendering: auto;
          " width="51px" height="51px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" class="loading">
          <circle cx="50" cy="50" r="32" stroke-width="8" stroke="#1d0e0b"
            stroke-dasharray="50.26548245743669 50.26548245743669" fill="none" stroke-linecap="square"
            transform="rotate(58.5425 50 50)">
            <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" keyTimes="0;1"
              values="0 50 50;360 50 50"></animateTransform>
          </circle>
        </svg>{{ buttonText }}
      </span>
    </button>
  </div>
  <div class="form-group form-check">
    <input type="checkbox" formControlName="rgpd" class="form-check-input form-rgpd largerCheckbox"
      [ngClass]="{ 'is-invalid': submitted && f.rgpd.errors }" (mouseover)="onRollOverButton()"
      (mouseout)="onRollOutButton()" />

    <label class="form-check-label" for="rgpd" [innerHTML]="rgpd"> </label>
  </div>
</form>
<div class="container">
  <div class="row"></div>
</div>
