import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GameRoutingModule } from './game-routing.module';
import { SharedModule } from '../shared/shared.module';
import { InlineSVGModule } from 'ng-inline-svg';

import { GameComponent } from 'src/app/sections/game/game.component';
// import { JarvisComponent } from 'src/app/components/screen/jarvis/jarvis.component';
import { LuxComponent } from 'src/app/components/screen/lux/lux.component';
import { KeyboardComponent } from 'src/app/components/keyboard/keyboard.component';
import { WebGLGameviewComponent } from 'src/app/components/screen/webgl/gameview.component';
import { BadgeComponent } from 'src/app/components/screen/badge/badge.component';
import { PinsComponent } from 'src/app/components/screen/pins/pins.component';
import { ComboComponent } from 'src/app/components/screen/combo/combo.component';
import { BonusComponent } from 'src/app/components/screen/bonus/bonus.component';
import { DashboardComponent } from 'src/app/components/screen/dashboard/dashboard.component';
import { TutosComponent } from 'src/app/components/screen/tutos/tutos.component';
import { AuthModule } from '../auth/auth.module';
import { QuizComponent } from 'src/app/components/quiz/quiz.component';
import { CloseComponent } from 'src/app/components/close/close.component';
import { ClickToResumeComponent } from 'src/app/sections/game/click-to-resume/click-to-resume.component';
import { KonamiModule } from 'ngx-konami';
import { Html5GameviewComponent } from 'src/app/components/screen/html5/html5-gameview.component';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { ErrorUiComponent } from '../../components/screen/error-ui/error-ui.component';
import { KeyboardMobileComponent } from 'src/app/components/keyboard-mobile/keyboard-mobile.component';
import { GameStatusComponent } from 'src/app/components/screen/game-status/game-status.component';

@NgModule({
  declarations: [
    LuxComponent,
    KeyboardComponent,
    KeyboardMobileComponent,
    WebGLGameviewComponent,
    Html5GameviewComponent,
    GameComponent,
    BadgeComponent,
    PinsComponent,
    ComboComponent,
    BonusComponent,
    DashboardComponent,
    TutosComponent,
    QuizComponent,
    CloseComponent,
    ClickToResumeComponent,
    ErrorUiComponent,
    GameStatusComponent,
  ],
  imports: [
    CommonModule,
    GameRoutingModule,
    SharedModule,
    AuthModule,
    FormsModule,
    ReactiveFormsModule,
    InlineSVGModule.forRoot(),
    KonamiModule,
    KeyboardShortcutsModule.forRoot(),
  ],
})
export class GameModule {}
