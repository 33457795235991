export class Ranking {
  grade: string;
  level: number;
  desc: string;
  lang: string;
  width: number;
}

export class HighScore {
  name: string;
  update: string;
  score: number;
  lang: string;
  scoreStr: string;
}
