import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import gsap from 'gsap';
import * as $ from 'jquery';
import { AppConfigService } from 'src/app/services/config/app-config.service';
import { SoundService } from 'src/app/services/sound/sound.service';
import { CreditsService } from 'src/app/services/view/credits.service';
import { Router } from '@angular/router';
import { JarvisService } from 'src/app/services/view/jarvis.service';
import { KeyboardService } from 'src/app/services/view/keyboard.service';
import { MouseControlsService } from 'src/app/services/view/mouse-controls.service';
import { ScreenService } from 'src/app/services/view/screen.service';
import {
  CursorEvent,
  CursorService,
} from 'src/app/services/view/cursor.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { Subscription } from 'rxjs';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'credits',
  templateUrl: './credits.component.html',
  styleUrls: ['./credits.component.scss'],
})
export class CreditsComponent implements OnInit, OnDestroy {
  constructor(
    private appConfig: AppConfigService,
    private soundService: SoundService,
    private creditsService: CreditsService,
    private screenService: ScreenService,
    private keyboardService: KeyboardService,
    private mouseControlsService: MouseControlsService,
    private jarvisService: JarvisService,
    private cursorService: CursorService,
    private $gaService: GoogleAnalyticsService,
    private router: Router
  ) {}

  route = '';
  showHelper = false;
  escBound;
  clickBound;
  assetsUrl = this.appConfig.assetsUrl;
  creditsSubs: Subscription;

  ngOnInit(): void {
    this.creditsSubs = this.creditsService.emitter$.subscribe((event) =>
      this._eventDispatch(event)
    );

    $('#closeCredits').on('click', () => {
      this.creditsService.hide();
    });

    // this.escBound = ((event) => {
    //   this.onEsc(event);
    // }).bind(this);
    this.clickBound = (() => {
      this.creditsService.hide();
    }).bind(this);
    $('#closeCredits').on('click', this.clickBound);
    $('#creditsContainer').on('click', this.clickBound);
    $('.ext-facebook').on('click', () => {
      window.open('https://www.facebook.com/PopCornSoixanteSix/', '_new');
    });
    $('.ext-twitter').on('click', () => {
      window.open('https://twitter.com/PopCorn66_FR', '_new');
    });
    $('.ext-instagram').on('click', () => {
      window.open('https://www.instagram.com/popcorn66fr/', '_new');
    });
    $('.ext-youtube').on('click', () => {
      window.open('https://www.youtube.com/c/popcorn66', '_new');
    });
    $('.ext-soundcloud').on('click', () => {
      window.open('https://soundcloud.com/popcorn66fr', '_new');
    });
  }

  ngOnDestroy(): void {
    if (this.appConfig.DEBUG) {
      console.log('CreditsComponent:ngOnDestroy');
    }
    this.creditsSubs.unsubscribe();
    $('#closeCredits').off('click', this.clickBound);
    $('#creditsContainer').off('click', this.clickBound);

    // $(window).off('resize', this.onResizeBound)
  }

  onEsc(event): void {
    event.preventDefault();
    event.stopPropagation();
    if (this.appConfig.DEBUG) {
      console.log('PinComponent:onEsc');
    }

    $(document).off('keyup', this.escBound);
    if (event.keyCode === 27) {
      event.data.callbackFunc();
    } else {
      $(document).on(
        'keyup',
        null,
        {
          callbackFunc: this.clickBound,
        },
        this.escBound
      );
    }
  }

  onClick(): void {
    this.creditsService.hide();
  }

  onRollOverButton(): any {
    this.cursorService.emitEvent(new CursorEvent(this.cursorService.ROLLOVER));
  }
  onRollOutButton(): any {
    this.cursorService.emitEvent(new CursorEvent(this.cursorService.ROLLOUT));
  }

  showCredits(): void {
    if (this.appConfig.DEBUG) {
      console.log('CreditsComponent:showCredits ' + this.router.url);
    }
    if (this.router.url === '/game') {
      this.screenService.blur();
      this.keyboardService.disable();
      this.mouseControlsService.disable();
      this.jarvisService.hideui();
    }
    this.cursorService.cross();
    if (this.appConfig.GA_STATS) {
      this.$gaService.pageView('/credits', 'Credits');
    }
    $('#creditsContainer').css('display', 'flex');
    gsap.to('#creditsContainer', {
      duration: 0.6,
      autoAlpha: 1,
      ease: this.appConfig.tweenFunc,
      onStart: () => {
        this.soundService.openDashB();
      },
      onComplete: () => {
        gsap.to('#closeCredits', {
          duration: 0.6,
          autoAlpha: 1,
          ease: this.appConfig.tweenFunc,
          onComplete: () => {
            $('#closeCredits').css('pointer-events', 'all');
            // $(document).on(
            //   'keyup',
            //   null,
            //   { callbackFunc: this.clickBound },
            //   this.escBound
            // );
          },
        });
      },
    });
  }

  hideCredits(): void {
    gsap.to('#creditsContainer', {
      duration: 0.3,
      autoAlpha: 0,
      ease: 'power3.out',
      onStart: () => {
        this.soundService.closeDashB();
        if (this.router.url === '/game') {
          this.screenService.unblur();
          this.keyboardService.enable();
          this.mouseControlsService.enable();
          this.jarvisService.showui();
        }
        this.cursorService.normal();
      },
      onComplete: () => {
        $('#creditsContainer').css('display', 'none');
        gsap.to('#closeCredits', {
          duration: 0.6,
          autoAlpha: 0,
          ease: this.appConfig.tweenFunc,
          onComplete: () => {
            $('#closeCredits').css('pointer-events', 'none');
          },
        });
      },
    });
  }

  private _eventDispatch(event: any): void {
    switch (event.name) {
      case this.creditsService.SHOW:
        this.showCredits();
        break;
      case this.creditsService.HIDE:
        this.hideCredits();
        break;
      default:
        break;
    }
  }
}
