import { Component, NgZone, OnInit, OnDestroy } from '@angular/core';
import * as $ from 'jquery';
import gsap from 'gsap';
import { BadgeService, BadgeEvent } from '../../../services/view/badge.service';
import { Show } from 'src/app/model/show';
import { ScreenService } from 'src/app/services/view/screen.service';
import { AppConfigService } from 'src/app/services/config/app-config.service';
import { PinsService, PinsEvent } from '../../../services/view/pins.service';
import {
  CursorService,
  CursorEvent,
} from 'src/app/services/view/cursor.service';
import { UtilsService } from 'src/app/services/utilities/utils.service';
import { SoundService } from 'src/app/services/sound/sound.service';
import { ComboService } from 'src/app/services/game/combo.service';
import { JarvisService } from 'src/app/services/view/jarvis.service';
import { Subscription } from 'rxjs';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
})
export class BadgeComponent implements OnInit, OnDestroy {
  constructor(
    private badgeService: BadgeService,
    private screenService: ScreenService,
    private appConfig: AppConfigService,
    private cursorService: CursorService,
    private pinsService: PinsService,
    private soundService: SoundService,
    private comboService: ComboService,
    private ngZone: NgZone,
    // private jarvisService: JarvisService,

    private utils: UtilsService
  ) {}
  showHelper = false;

  speedFactor = 0;
  animSpeed = 1;
  assetsUrl = this.appConfig.assetsUrl;
  mobileMode = false;

  championImage = this.assetsUrl + '/assets/images/game/none.png';
  championName = '';
  championLevel = 'Facile';
  championBonus = '+10';
  bonus = '+00';
  tmpTxt: string[];
  pctime = false;
  badgeServiceSub: Subscription;
  pinsServiceSub: Subscription;

  ngOnInit(): void {
    this.speedFactor = 0;

    this.badgeServiceSub = this.badgeService.emitter$.subscribe(
      (event: BadgeEvent) => this._eventDispatch(event)
    );
    this.pinsServiceSub = this.pinsService.emitter$.subscribe(
      (event: PinsEvent) => this._eventDispatch(event)
    );
  }

  ngOnDestroy(): void {
    this.badgeServiceSub.unsubscribe();
    this.pinsServiceSub.unsubscribe();
  }

  showBadge(show: Show): any {
    if (this.appConfig.DEBUG) {
      console.log('BadgeComponent:showBadge ' + JSON.stringify(show, null, 4));
    }
    this.pctime = this.comboService.isActive;

    const promise = new Promise((resolve) => {
      this.cursorService.loading();
      this.speedFactor++;
      switch (this.speedFactor) {
        case 1:
          this.animSpeed = 0.9;
          break;
        case 2:
          this.animSpeed = 0.8;
          break;
        case 3:
          this.animSpeed = 0.7;
          break;
        case 4:
          this.animSpeed = 0.6;
          break;
        case 5:
          this.animSpeed = 0.5;
          break;
        default:
          this.animSpeed = 0.4;
          break;
      }
      if (this.appConfig.DEBUG) {
        console.log('BadgeComponent:animSpeed ' + this.animSpeed);
      }
      $('.champion-difficulty').removeClass('easy medium hard');
      this.championName = show.title;
      this.championImage =
        this.assetsUrl + '/assets/images/badges/' + show.badge_id + '.png';

      let img = new Image();
      img.onload = () => {
        this._showBadge(show, resolve);
      };
      img.src =
        this.assetsUrl + '/assets/images/badges/' + show.badge_id + '.png';
    });
    return promise;
  }

  _showBadge(show: Show, resolve): any {
    this.soundService.dimSound();

    switch (show.type) {
      case this.appConfig.EASY_FLAG:
        this.championLevel =
          this.comboService.multiplier > 1
            ? 'Facile x' + this.comboService.multiplier
            : 'Facile';
        this.championBonus =
          '+' + this.appConfig.SCORE_EASY_REFS * this.comboService.multiplier;
        $('.champion-difficulty').addClass('easy');

        break;
      case this.appConfig.MEDIUM_FLAG:
        this.championLevel =
          this.comboService.multiplier > 1
            ? 'Moyen x' + this.comboService.multiplier
            : 'Moyen';
        this.championBonus =
          '+' + this.appConfig.SCORE_MEDIUM_REFS * this.comboService.multiplier;
        $('.champion-difficulty').addClass('medium');

        break;
      case this.appConfig.HARD_FLAG:
        this.championLevel =
          this.comboService.multiplier > 1
            ? 'DIFFICILE x' + this.comboService.multiplier
            : 'DIFFICILE';
        this.championBonus =
          '+ ' + this.appConfig.SCORE_HARD_REFS * this.comboService.multiplier;
        $('.champion-difficulty').addClass('hard');

        break;
      case this.appConfig.CHEATCODE_FLAG:
        this.championLevel = 'Cheatcode';
        this.championBonus = '+' + this.appConfig.SCORE_CHEATCODE;
        $('.champion-difficulty').addClass('easy');

        break;
      default:
        this.championLevel =
          this.comboService.multiplier > 1
            ? 'Facile x' + this.comboService.multiplier
            : 'Facile';
        this.championBonus =
          '+ ' + this.appConfig.SCORE_EASY_REFS * this.comboService.multiplier;
        $('.champion-difficulty').addClass('easy');

        break;
    }
    this.bonus = this.championBonus;

    if (this.comboService.isActive) {
      $('.champion-difficulty').addClass('pctime');
    } else {
      $('.champion-difficulty').removeClass('pctime');
    }
    // if (this.soundService.soundEnabled) {
    //   // let voice = new Audio()
    //   // voice.src = '/assets/sounds/champions/' + show.badge_id + '.mp3'
    //   // voice.load()
    //   // voice.play()
    //   this.soundService.voice(show.badge_id)
    // }

    $('#badgesContainer').css('display', 'flex');
    // $('#badgesContainer').addClass('over')
    // $('.champion-image').css('transform', 'scale(0)');
    gsap.to('.champion-image', {
      duration: 0,
      scale: 0,
      rotate: -90,
    });
    gsap.to('.champion-image-block', {
      duration: 0,
      scale: 1,
      translateY: 90,
    });
    this.screenService.blur();
    // this.soundService.badge();

    // this.jarvisService.hideui();

    gsap.to('#badgesContainer', {
      duration: 0.4,
      opacity: 1,
      ease: this.appConfig.tweenFunc,
      onStart: () => {},
      onComplete: () => {
        gsap.to('.champion-image', {
          duration: 0.4,
          ease: 'back.out',
          scale: 1,
          rotate: 0,
          onStart: () => {
            this.soundService.zap();
            setTimeout(() => {
              this.soundService.bump();
            }, 325);
          },
          onComplete: () => {
            // this.soundService.bump();

            if (show.type === this.appConfig.HARD_FLAG) {
              this.soundService.badge3();
            } else {
              this.soundService.badge();
            }

            gsap.to('.champion-image-block', {
              duration: 0.4,
              ease: 'expo.out',
              delay: 0.2,
              translateY: 0,
              onStart: () => {
                gsap.to('.champion-name', {
                  duration: 0.6,
                  ease: 'expo.out',
                  opacity: 1,
                });
                gsap.to('.champion-difficulty', {
                  delay: 0.2,
                  duration: 0.6,
                  ease: 'expo.out',
                  opacity: 1,
                });
                gsap.to('.champion-bonus', {
                  delay: 0.4,
                  duration: 0.6,
                  ease: 'expo.out',
                  opacity: 1,
                  onStart: () => {
                    this.tmpTxt = this.bonus.split('');
                    this.ngZone.run(() => {
                      for (let i = 0; i < this.tmpTxt.length; i++) {
                        this.letterRandomize(i);
                      }
                    });
                  },
                  onComplete: () => {
                    const duration =
                      this.appConfig.BADGE_DURATION * this.animSpeed;

                    setTimeout(() => {
                      this.hideBadge(resolve);
                    }, duration);
                  },
                });
              },
            });
          },
        });

        // return
      },
    });
  }

  onBadgeChange(): void {
    if (this.appConfig.DEBUG) {
      console.log('BadgeComponent:onBadgeChange');
    }
  }

  letterRandomize(index) {
    const intervals = [];
    const possible = '0123456789*-+=.;"()&%$#@!<>?';
    if (this.appConfig.DEBUG) {
      console.log('BadgeComponent:letterRandomize ' + index);
    }
    this.soundService.coins();

    intervals[index] = setInterval(() => {
      this.tmpTxt[index] = possible.charAt(
        Math.floor(Math.random() * possible.length)
      );
      this.championBonus = this.tmpTxt.join('');
    }, 25 * this.animSpeed);
    setTimeout(() => {
      clearInterval(intervals[index]);
      this.tmpTxt[index] = this.bonus.split('')[index];
      this.championBonus = this.tmpTxt.join('');
    }, 200 * index * this.animSpeed);
  }

  hideBadge(resolve: any): void {
    this.screenService.unblur();
    // this.soundService.zap();

    gsap.to('.champion-image', {
      duration: 0.3,
      ease: 'back.in',
      scale: 0,
      rotate: 90,
      onStart: () => {
        this.soundService.zap2();
      },
    });
    gsap.to('.champion-name', {
      delay: 0.15,
      duration: 0.25 * this.animSpeed,
      ease: 'expo.out',
      opacity: 0,
    });
    gsap.to('.champion-difficulty', {
      delay: 0.15,
      duration: 0.25 * this.animSpeed,
      ease: 'expo.out',
      opacity: 0,
    });
    gsap.to('.champion-bonus', {
      delay: 0.15,
      duration: 0.25 * this.animSpeed,
      ease: 'expo.out',
      opacity: 0,
      onComplete: () => {
        // this.jarvisService.showui();

        gsap.to('#badgesContainer', {
          duration: 0.6 * this.animSpeed,
          opacity: 0.7,
          ease: this.appConfig.tweenFunc,
          onStart: () => {},
          onComplete: () => {
            this.screenService.pause();
            this.screenService.disable();

            resolve('badge hidden');
            // $('#badgesContainer').removeClass('over')

            this.resetBadge();
            // this.pinsService.showFocus(show);
          },
        });
      },
    });
  }

  resetBadge(): void {
    // reset elements
    // $('.champion-image').css('transform', 'unset')
    $('.champion-image-block').css('transform', 'unset');

    this.cursorService.normal();
    this.soundService.undimSound();
  }

  onResize(): void {}

  _eventDispatch(event: BadgeEvent): void {
    switch (event.name) {
      case this.badgeService.SHOWBADGE:
        setTimeout(() => {
          this.showBadge(event.show).then((res) => {
            if (this.appConfig.DEBUG) {
              console.log(res);
            }
            this.badgeService.emitEvent(
              new BadgeEvent(this.badgeService.SHOWBADGESUCESS, event.show)
            );
          });
        }, this.appConfig.BADGE_DELAY);
        break;
      case this.pinsService.SHOWFOCUSSUCESS:
        this.screenService.resume();
        gsap.to('#badgesContainer', {
          duration: 0.3,
          opacity: 0,
          ease: this.appConfig.tweenFunc,
          onComplete: () => {
            $('#badgesContainer').css('display', 'none');
          },
        });
        break;

      default:
        break;
    }
  }
}
