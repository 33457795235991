import { EventEmitter, Injectable } from '@angular/core';
import { AppConfigService } from '../config/app-config.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { ServerService } from '../http/server.service';
import { Track } from 'src/app/model/track';
import { ToastrService } from 'ngx-toastr';
import { Howl, Howler } from 'howler';

export class SoundEvent {
  constructor(public name: string, public track?: Track) {}
}
@Injectable({
  providedIn: 'root',
})
export class SoundService {
  public emitter$: EventEmitter<SoundEvent>;

  SHOW = 'SoundService:SHOW';
  HIDE = 'SoundService:HIDE';
  PLAY = 'SoundService:PLAY';
  PAUSE = 'SoundService:PAUSE';

  soundId = {
    // INTRO
    INTRO: 'intro',
    LIGHTSPEED: 'lightspeed-stop',
    WHOOSH: 'whoosh',

    START: 'start',
    MIX: 'playhard_mix',
    TRACK1: 'track1',
    TRACK2: 'track2',
    TRACK3: 'track3',
    TRACK4: 'track4',
    HEARTBEAT: 'heartbeat2',
    NEGATIVE: 'negative',
    OOOH: 'oooh',
    CRICKETS: 'crickets',
    WIN: 'win',
    LOST: 'lost',
    TICK: 'tick',
    /// UI
    DASH_O: 'dash-o',
    DASH_C: 'dash-c',
    ARC: 'arc',
    BADGE: 'ouh',
    BADGE3: 'ouh-3',
    GOLD: 'gold',
    BONUS: 'bonus',
    BUMP: 'bump',
    WOBBLE: 'wobble',
    RUMBLE: 'rumble',
    APPLYERROR: 'error2',
    OPEN: 'open',
    ZAP: 'zap',
    ZAP2: 'zap2',
    FOCUS: 'focus',
    COUNTER: 'counter',
    DEFEAT: 'defeat',
    VICTORY: 'victory',
    X2: 'x2',
    X3: 'x3',
    X4: 'x4',
    X5: 'x5',
    X6: 'x6',
    BLINK: 'blink',
    BLINK2: 'blink2',
    BLINK3: 'blink3',
    COINS: 'coin',
    TUTO: 'tuto',
    ENDCHRONO: 'chrono_end',
    ULTRACOMBO: 'ultracombo',
    GO: 'go',
  };

  ambianceVolume = 0.3;
  trackVolume = this.appConfig.TRACKVOLUME;
  uiVolume = this.appConfig.LOWVOLUME;
  sfxVolume = this.appConfig.FXVOLUME;
  muteVolume = this.appConfig.LOWVOLUME;
  soundEnabled = true;

  mainSoundInstance: any;
  errorSoundInstance: any;
  ambSoundInstance: any;

  introTrack = null;
  currentTrack = null;
  comboTrack = null;
  duration = 0;
  currentVolume: number;
  previousVolume: number;

  playList: Array<Track>;
  indexTrack = 0;
  jukebox = [];
  lowPassFilter;
  isMuted = false;
  isDimmed = false;
  mobileMode = false;
  introID;
  trackID;
  comboID;
  assetsUrl = this.appConfig.assetsUrl;

  constructor(
    private appConfig: AppConfigService,
    private serverService: ServerService,
    private toastr: ToastrService,
    private $gaService: GoogleAnalyticsService
  ) {
    this.emitter$ = new EventEmitter();
  }

  emitEvent(event: SoundEvent): void {
    if (this.appConfig.DEBUG) {
      console.log('SoundService emitEvent %s', event.name);
    }
    this.emitter$.emit(event);
  }

  init(): void {
    if (this.appConfig.DEBUG) {
      console.log('soundService.init ');
    }
    this.currentVolume = this.appConfig.TRACKVOLUME;
    this.mobileMode = this.appConfig.isMobile;

    // Change global volume.
    Howler.volume(0.3);

    this.serverService.getTracks().subscribe(
      (data: Array<Track>) => {
        this.playList = data;
        for (let index = 0; index < this.playList.length; index++) {
          this.playList[index].loaded = false;
        }
        // if (this.appConfig.DEBUG) {
        //   console.log(
        //     'SoundService:getTracks ' + JSON.stringify(this.playList, null, 4)
        //   )
        // }
      },
      (err) => {
        if (this.appConfig.DEBUG) {
          console.log('SoundService:getTracks 404 !' + err);
        }
        if (this.appConfig.GA_STATS) {
          this.$gaService.event(
            'Fatal Error',
            'Events',
            'Tracklist not loaded'
          );
        }
        this.toastr
          .error(
            'Error [66] ' +
              err.status +
              ' (' +
              err.statusText +
              '). Please restart the game!',
            'Erreur Fatale',
            {
              progressBar: true,
            }
          )
          .onHidden.subscribe(() => {
            // location.reload(true);
          });
        this.playList = [];
      }
    );
  }

  dimSound(): void {
    if (this.appConfig.DEBUG) {
      console.log('soundService.dimSound from volume ' + this.currentVolume);
    }
    this.previousVolume = this.currentVolume;
    if (this.currentTrack) {
      this.currentTrack.fade(this.currentVolume, 0.1, 300);
    }
    if (this.comboTrack) {
      this.comboTrack.fade(this.currentVolume, 0.1, 300);
    }
    this.currentVolume = 0.1;
    this.isDimmed = true;
  }

  undimSound(): void {
    if (this.appConfig.DEBUG) {
      console.log('soundService.undimSound ');
    }
    if (this.isDimmed) {
      if (this.comboTrack) {
        this.comboTrack.fade(
          this.currentVolume,
          this.appConfig.TRACKVOLUME,
          1400
        );
      } else if (this.currentTrack) {
        this.currentTrack.fade(
          this.currentVolume,
          this.appConfig.TRACKVOLUME,
          1400
        );
      }

      this.isDimmed = false;
      this.isMuted = false;
      this.currentVolume = this.appConfig.TRACKVOLUME;
    }
  }

  toggleSound(): boolean {
    if (this.appConfig.DEBUG) {
      console.log('soundService.toggleSound ');
    }
    if (this.soundEnabled) {
      this.soundEnabled = false;

      Howler.volume(0);
    } else {
      this.soundEnabled = true;

      Howler.volume(0.3);
    }

    return this.soundEnabled;
  }

  muteSound(): any {
    if (this.appConfig.DEBUG) {
      console.log('soundService.muteSound ');
    }
    // this.$gaService.event('Mute Sound', 'Interactions', 'mute')

    const promise = new Promise((resolve) => {
      this.currentTrack.pause();
      this.isMuted = true;
      resolve(true);
    });

    return promise;
  }

  unMuteSound(): any {
    if (this.appConfig.DEBUG) {
      console.log(
        'soundService.unMuteSound target volume -> ' + this.previousVolume
      );
    }
    // this.$gaService.event('Unmute Sound', 'Interactions', 'Unmute')

    const promise = new Promise((resolve) => {
      if (!this.currentTrack.playing()) {
        this.currentTrack.play();
        this.currentTrack.volume(0.1);
      }
      // this.currentVolume = this.trackVolume
      this.isMuted = false;
      setTimeout(() => {
        resolve(true);
      }, 100);
    });

    return promise;
  }

  intro(): void {
    if (this.appConfig.DEBUG) {
      console.log('soundService.intro ');
    }
    this.introTrack = new Howl({
      src: [this.assetsUrl + '/assets/sounds/' + this.soundId.INTRO + '.mp3'],
      volume: this.appConfig.INTROVOLUME,
      autoplay: true,
      loop: true,
      html5: true,
      onfade: () => {
        if (this.appConfig.DEBUG) {
          console.log(
            'soundService:intro onfade ' + this.introTrack.volume(this.introID)
          );
        }
        if (this.introTrack.volume(this.introID) === 0) {
          this.introTrack.stop();
          this.introTrack = undefined;
        }
      },
    });
    this.introID = this.introTrack.play();
  }

  stopIntro(): void {
    if (this.appConfig.DEBUG) {
      console.log('soundService.stopIntro');
    }
    this.introTrack.fade(this.appConfig.TRACKVOLUME, 0, 1000, this.introID);
  }

  ultraCombo(): void {
    if (this.appConfig.DEBUG) {
      console.log('soundService.ultraCombo');
    }
    this.stopTracks();
    this.comboTrack = new Howl({
      src: [
        this.assetsUrl + '/assets/sounds/' + this.soundId.ULTRACOMBO + '.mp3',
      ],
      volume: 0.42,
      autoplay: true,
      loop: true,
      html5: true,
      onfade: () => {
        if (this.comboTrack.volume(this.comboID) === 0) {
          this.comboTrack.stop();
          this.comboTrack = undefined;
          this.playTracks(this.appConfig.TRACKVOLUME, true);
        }
      },
    });
    this.comboID = this.comboTrack.play();
  }

  powerup(): void {
    if (this.appConfig.DEBUG) {
      console.log('soundService.powerup');
    }
    this.comboTrack.fade(0.42, this.appConfig.TRACKVOLUME, 1500, this.comboID);
    this.currentVolume = this.appConfig.TRACKVOLUME;
  }

  stopUltraCombo(): void {
    if (this.appConfig.DEBUG) {
      console.log('soundService.stopUltraCombo');
    }
    this.comboTrack.fade(this.currentVolume, 0, 1200, this.comboID);
  }

  error(nberror = 3): void {
    if (this.appConfig.DEBUG) {
      console.log('soundService.error : ' + nberror);
    }
  }

  end(): void {
    if (this.appConfig.DEBUG) {
      console.log('soundService.end');
    }
    this.intro();
  }

  playTracks(volume = this.currentVolume, fadein = false): void {
    this.currentVolume = volume;
    if (volume > 0) {
      this.$gaService.event(
        'Play Track',
        'Events',
        this.playList[this.indexTrack].title
      );
    }
    this.currentTrack = new Howl({
      src: [
        this.assetsUrl +
          '/assets/sounds/playlist/' +
          this.playList[this.indexTrack].trackid +
          '.mp3',
      ],
      html5: true,
      autoplay: false,
      loop: false,
      volume: volume,
      onplay: () => {
        if (this.appConfig.DEBUG) {
          console.log(
            'SoundService:playTracks ' + this.playList[this.indexTrack].title
          );
        }
        this.emitEvent(
          new SoundEvent(this.PLAY, this.playList[this.indexTrack])
        );
      },
      onend: () => {
        this.playNextTrack();
      },
      onfade: () => {
        if (this.currentTrack.volume(this.trackID) === 0) {
          this.currentTrack.stop();
        }
      },
    });

    this.trackID = this.currentTrack.play();
    if (fadein) {
      this.currentTrack.fade(0, this.currentVolume, 5000, this.trackID);
    }
  }

  playNextTrack(): void {
    if (this.appConfig.DEBUG) {
      console.log('SoundService:playNextTrack');
    }
    this.indexTrack++;
    if (this.indexTrack > this.playList.length - 1) {
      this.indexTrack = 0;
    }

    this.playTracks();
  }

  stopTracks(): void {
    if (this.appConfig.DEBUG) {
      console.log('SoundService:stopTracks');
    }
    if (this.currentTrack)
      this.currentTrack.fade(this.currentVolume, 0, 1000, this.trackID);
  }

  playSound(ID: string, volume = this.appConfig.FXVOLUME): void {
    if (this.appConfig.DEBUG) {
      console.log('SoundService:playSound ' + ID);
    }
    new Howl({
      src: [this.assetsUrl + '/assets/sounds/' + ID + '.mp3'],
      volume: volume,
      autoplay: true,
      html5: false,
    });
  }
  // playBlindtestSound(ID: string, volume = this.appConfig.BTVOLUME): Howl {
  //   if (this.appConfig.DEBUG) {
  //     console.log('SoundService:playBlindtestSound ' + ID)
  //   }
  //   const sound: Howl = new Howl({
  //     src: ['/assets/sounds/blindtests/' + ID + '.mp3'],
  //     volume: volume,
  //     autoplay: true,
  //     loop: true,
  //     html5: false
  //   })

  //   return sound
  // }

  voice(ID: string, volume = this.appConfig.VOICEVOLUME): void {
    if (this.appConfig.DEBUG) {
      console.log('SoundService:voice ' + ID);
    }
    const sound = new Howl({
      src: [this.assetsUrl + '/assets/sounds/champions/' + ID + '.mp3'],
      volume: volume,
      autoplay: true,
      html5: false,
    });
    // sound.play()
  }

  openDashB(): void {
    // this.muteSound(1.2)
    this.dimSound();
    this.playSound(this.soundId.DASH_O, this.appConfig.FXVOLUME);
  }

  closeDashB(): void {
    // this.playSound(this.soundId.DASH_C, this.appConfig.FXVOLUME);
    // this.unMuteSound(1.2)
    this.undimSound();
  }

  win(): void {
    this.playSound(this.soundId.WIN, this.appConfig.FXVOLUME);
  }
  lost(): void {
    this.playSound(this.soundId.LOST, this.appConfig.FXVOLUME);
  }
  arc(): void {
    this.playSound(this.soundId.ARC, this.appConfig.FXVOLUME);
  }
  whoosh(): void {
    this.playSound(this.soundId.WHOOSH, this.appConfig.FXVOLUME / 2);
  }
  badge(): void {
    setTimeout(() => {
      this.playSound(this.soundId.BADGE, this.appConfig.FXVOLUME);
    }, 300);
  }
  badge3(): void {
    setTimeout(() => {
      this.playSound(this.soundId.BADGE3, this.appConfig.FXVOLUME);
    }, 300);
  }

  bump(): void {
    this.playSound(this.soundId.BUMP, this.appConfig.TRACKVOLUME);
  }
  wobble(): void {
    this.playSound(this.soundId.WOBBLE, this.appConfig.TRACKVOLUME);
  }
  bonus(): void {
    this.playSound(this.soundId.BONUS, this.appConfig.FXVOLUME);
  }
  open(): void {
    this.playSound(this.soundId.OPEN, this.appConfig.TRACKVOLUME);
  }
  zap(): void {
    this.playSound(this.soundId.ZAP, 0.3);
  }
  zap2(): void {
    this.playSound(this.soundId.ZAP2, 0.3);
  }
  focus(): void {
    this.playSound(this.soundId.FOCUS, this.appConfig.FXVOLUME);
  }
  negative(): void {
    this.playSound(this.soundId.NEGATIVE, 0.3);
  }

  oooh() {
    this.playSound(this.soundId.OOOH, 0.6);
    // setTimeout(() => {
    //   this.playSound(this.soundId.ZAP2, 0.3);
    // }, 500);
  }
  go(): void {
    this.playSound(this.soundId.GO, this.appConfig.FXVOLUME);
  }
  applyError(): void {
    this.playSound(this.soundId.APPLYERROR, this.appConfig.FXVOLUME);
  }

  blink(): void {
    const rand = Math.random();
    if (rand > 0.5) {
      this.playSound(this.soundId.BLINK2, this.appConfig.FXVOLUME);
    } else {
      this.playSound(this.soundId.BLINK3, this.appConfig.FXVOLUME);
    }
  }

  coins(): void {
    this.playSound(this.soundId.COINS, 0.3);
  }
  tuto(): void {
    this.playSound(this.soundId.TUTO, this.appConfig.FXVOLUME);
  }

  count(): void {
    this.playSound(this.soundId.COUNTER, this.appConfig.LOWVOLUME);
  }
  tick(): void {
    this.playSound(this.soundId.TICK, 0.7);
  }
  defeat(): void {
    this.playSound(this.soundId.DEFEAT, this.appConfig.FXVOLUME);
  }
  start(): void {
    this.playSound(this.soundId.START, 1);
  }
  victory(): void {
    this.playSound(this.soundId.VICTORY, this.appConfig.FXVOLUME);
  }
  endChrono(): void {
    setTimeout(() => {
      this.playSound(this.soundId.ENDCHRONO, 1);
    }, 500);
  }
}
