import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, Injector } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { IntroRoutingModule } from './modules/intro/intro-routing.module';
import { IntroModule } from './modules/intro/intro.module';
import { GameModule } from './modules/game/game.module';
import { GameRoutingModule } from './modules/game/game-routing.module';

import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
// import { ToastrModule } from 'ngx-toastr';
import {
  ToastrModule,
  ToastNoAnimation,
  ToastNoAnimationModule,
} from 'ngx-toastr';
import { JwtModule } from '@auth0/angular-jwt';

import { AppConfigService } from './services/config/app-config.service';
import { FooterComponent } from './sections/footer/footer.component';
import { EndRoutingModule } from './modules/end/end-routing.module';
import { EndModule } from './modules/end/end.module';
import { createCustomElement } from '@angular/elements';
import { APP_BASE_HREF } from '@angular/common';
import { AuthModule } from './modules/auth/auth.module';
import { CreditsComponent } from './sections/credits/credits.component';
import { SharedModule } from './modules/shared/shared.module';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { RotateDeviceComponent } from './components/screen/rotate-device/rotate-device.component';
import { MaintenanceComponent } from './sections/maintenance/maintenance.component';
// import { TestComponent } from './components/test/test/test.component';
import { VideoComponent } from './components/video/video.component';
import { CursorComponent } from './components/cursor/cursor.component';

export function tokenGetter(): any {
  return localStorage.getItem('token');
}

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    CreditsComponent,
    RotateDeviceComponent,
    MaintenanceComponent,
    VideoComponent,
    CursorComponent,
    // TestComponent,
  ],
  imports: [
    BrowserModule,
    // NoopAnimationsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    IntroRoutingModule,
    GameRoutingModule,
    EndRoutingModule,
    IntroModule,
    GameModule,
    EndModule,
    AuthModule,
    SharedModule,

    ToastNoAnimationModule.forRoot(),
    NgxGoogleAnalyticsModule.forRoot('G-F7CSBZ909V'),
    JwtModule.forRoot({
      config: {
        tokenGetter,
        headerName: 'Authorization',
        throwNoTokenError: false,
        skipWhenExpired: true,
        whitelistedDomains: [
          // '192.168.0.12:8000',
          'api-popstar90s.popcorn-esd.com',
        ],
        blacklistedRoutes: [],
      },
    }),
    // ServiceWorkerModule.register('./ngsw-worker.js', {
    //   enabled: environment.production,
    // }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [AppConfigService],
      useFactory: (appConfigService: AppConfigService) => {
        return () => {
          // Make sure to return a promise!
          return appConfigService.loadAppConfig();
        };
      },
    },
    { provide: APP_BASE_HREF, useValue: '/' },
  ],
  bootstrap: [AppComponent],
  // entryComponents: [AppComponent], // custom scripts
})
export class AppModule {
  // custom scripts
  // constructor(private injector: Injector) {
  //   const myElement = createCustomElement(AppComponent, { injector });
  //   customElements.define('app-element', myElement);
  // }
  // ngDoBootstrap(): void {}
}
