<div id="click-to-resume" class="justify-content-center align-items-center">
  <div class="d-flex flex-column justify-content-center align-items-center">
    <div class="pause">
      PAUSE
    </div>
    <div>
      Scroll pour voir la partie Légale.<br>
      Clique pour reprendre la partie.
    </div>
  </div>
</div>
