<div id="dashboardContainer" class="flex-column justify-content-center align-items-center">
  <div class="dashboardContent">
    <div *ngIf="mobileMode == true" class="d-flex flex-row justify-content-center align-items-center block-score">
      <div class="recap d-flex flex-column justify-content-center align-items-center">
        <div class="d-flex flex-row justify-content-center align-items-center">
          {{playerNbRefs}}/{{maxRefs}}
        </div>
        <div class="d-flex flex-row justify-content-around align-items-center w-100 mb-2">
          <img src="{{ assetsUrl }}/assets/images/lux/oscar-on.svg" class="mic skull3 life-ok">
          <img src="{{ assetsUrl }}/assets/images/lux/oscar-on.svg" class="mic skull2 life-ok">
          <img src="{{ assetsUrl }}/assets/images/lux/oscar-on.svg" class="mic skull1 life-ok">
        </div>

      </div>
      <div class="ml-3 mr-3 separator">
        <ul>
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="1px"
              height="75px">
              <path fill-rule="evenodd" opacity="0.4" fill="rgb(255, 255, 255)"
                d="M-0.000,0.000 L1.000,0.000 L1.000,75.000 L-0.000,75.000 L-0.000,0.000 Z" />
            </svg>
          </li>
        </ul>
      </div>
      <div class="recap text-left">
        <ul>
          <li class="hello-score-label">TON SCORE</li>
          <li class="hello-score-value">
            {{ playerScore }}
            <div class="icon baseline">
              <img src="{{ assetsUrl }}/assets/images/lux/symbol-pop-corn.svg" class="Symbol_PopCorn_small">
            </div>

          </li>
        </ul>

      </div>
    </div>
    <div class="d-flex flex-row justify-content-center align-items-center">

      <div class="dashboard-title">Bonus QUIZ</div>

    </div>
    <div *ngIf="mobileMode == true" class="d-flex flex-row justify-content-center align-items-center">
      <div class="text-center dashboard-legend">
        Sers-toi des Quiz pour booster tes&nbsp;points<br>PopCorn
        et&nbsp;claquer ton&nbsp;meilleur&nbsp;score !
      </div>
    </div>
    <div *ngIf="mobileMode == false" class="d-flex flex-row justify-content-center align-items-center">
      <div class="text-center dashboard-legend">
        Sers-toi des Quiz pour booster tes&nbsp;points&nbsp;PopCorn<br>
        et&nbsp;claquer ton&nbsp;meilleur&nbsp;score !
      </div>
    </div>
    <div class="d-flex flex-row justify-content-center align-content-around flex-wrap mr-3 ml-3 cells">
      <div class="bonus-cell">
        <bonus [id]="bonus1" [index]="1" [mode]="dashClass"></bonus>
      </div>
      <div class="bonus-cell">
        <bonus [id]="bonus2" [index]="2" [mode]="dashClass"></bonus>
      </div>
      <div class="bonus-cell">
        <bonus [id]="bonus3" [index]="3" [mode]="dashClass"></bonus>
      </div>
      <div class="bonus-cell">
        <bonus [id]="bonus4" [index]="4" [mode]="dashClass"></bonus>
      </div>
      <div class="bonus-cell">
        <bonus [id]="bonus5" [index]="5" [mode]="dashClass"></bonus>
      </div>
      <div class="bonus-cell">
        <bonus [id]="bonus6" [index]="6" [mode]="dashClass"></bonus>
      </div>
    </div>
  </div>

  <div id="close" class="close-cross dashboardContent">
    <img src="{{ assetsUrl }}/assets/images/x.png" srcset="{{ assetsUrl }}/assets/images/x@2x.png 2x,
                     {{ assetsUrl }}/assets/images/x@3x.png 3x" class="img-fluid">
  </div>


  <div id="bg">
    <overlay [keyboard]="showHelper"></overlay>
  </div>
</div>
