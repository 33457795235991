import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import { ConnectComponent } from 'src/app/components/connect/connect.component';
import { RegisterComponent } from 'src/app/components/connect/register/register.component';
import { LoginComponent } from 'src/app/components/connect/login/login.component';
import { AlertComponent } from 'src/app/components/connect/alert/alert.component';
import { SharedModule } from '../shared/shared.module';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [
    ConnectComponent,
    RegisterComponent,
    LoginComponent,
    AlertComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatTabsModule,
    SharedModule,
    // BrowserAnimationsModule,
  ],
  exports: [
    ConnectComponent,
    RegisterComponent,
    LoginComponent,
    AlertComponent,
    CommonModule,
  ],
})
export class AuthModule {}
