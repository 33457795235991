import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UtilsService } from './services/utilities/utils.service';
import { AppConfigService } from 'src/app/services/config/app-config.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import * as $ from 'jquery';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    private utils: UtilsService,
    private appConfig: AppConfigService,
    private $gaService: GoogleAnalyticsService
  ) {}
  imgs = new Array();
  isMobile = false;
  // tslint:disable-next-line: typedef
  ngOnInit() {
    const OS = this.utils.parser.getOS().name;
    const browser = this.utils.parser.getBrowser().name;
    if (this.utils.md().mobile() !== null) {
      this.isMobile = true;
      this.appConfig.isMobile = true;
      // $('.wrapper').css('height', '100vh');
      $('.wrapper').css('height', this.utils.getViewportSize().height + 'px');
    }
    let platform = this.utils.md().mobile();
    if (platform === null) {
      platform = 'Desktop';
      this.appConfig.isMobile = false;
      // $('.wrapper').css('height', '100%');
    }
    console.log(
      '%c PopCorn ESD version ' + this.appConfig.APP_VERSION,
      'color:white; background-color:#fd0165; font-size:14px; padding:50px;padding-top: 10px;padding-bottom:10px;'
    );
    console.log(
      '%c Created by PopCorn 66  \nhttps://www.popcorn66.fr',
      'color:white; background-color:black; font-size:14px; padding:50px;padding-top: 10px;padding-bottom:10px;'
    );
    console.log(
      '%c[' + platform + ' / ' + OS + ' / ' + browser + ']',
      'color:black; background-color:#75effa; font-size:12px; padding:50px;padding-top: 10px;padding-bottom:10px;'
    );

    this.$gaService.event(
      'App Version',
      'Events',
      'version ' + this.appConfig.APP_VERSION
    );
    // this.ngZone.run(() =>
    this.router.navigate(['/prehome'], {
      skipLocationChange: true,
    });

    localStorage.removeItem('currentUser');
    localStorage.removeItem('token');
    $(window).on('resize', () => {
      this.onResize();
    });
    // / );
  }

  onResize(): void {
    $('.wrapper').css('height', this.utils.getViewportSize().height + 'px');
  }
}
