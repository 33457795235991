import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
  AfterViewInit,
} from '@angular/core';
import {
  CloseModalService,
  CloseEvent,
} from 'src/app/services/view/close-modal.service';
import gsap from 'gsap';
import * as $ from 'jquery';
import { AppConfigService } from 'src/app/services/config/app-config.service';
import { UtilsService } from 'src/app/services/utilities/utils.service';
import {
  CursorEvent,
  CursorService,
} from 'src/app/services/view/cursor.service';
import { Subscription } from 'rxjs';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'close',
  templateUrl: './close.component.html',
  styleUrls: ['./close.component.scss'],
})
export class CloseComponent implements OnInit, OnDestroy, AfterViewInit {
  constructor(
    private closeModalService: CloseModalService,
    private appConfig: AppConfigService,
    private cursorService: CursorService,
    private utils: UtilsService
  ) {}

  @ViewChild('close') closeDiv;

  onEscBinding;
  KEYCODE_ESC = 27;
  mobileMode = false;
  rollOverBound;
  rollOutBound;
  closeSub: Subscription;
  assetsUrl = this.appConfig.assetsUrl;

  ngOnInit(): void {
    if (this.appConfig.DEBUG) {
      console.log('CloseComponent:ngOnInit');
    }
    if (this.utils.md().mobile() !== null) {
      this.mobileMode = true;
    }
    this.closeSub = this.closeModalService.emitter$.subscribe(
      (event: CloseEvent) => this._eventDispatch(event)
    );
    this.rollOverBound = this.onRollOver.bind(this);
    this.rollOutBound = this.onRollOut.bind(this);
    this.onEscBinding = this.onEsc.bind(this);
  }
  ngAfterViewInit(): void {
    $(this.closeDiv.nativeElement).on('mouseover', this.rollOverBound);
    $(this.closeDiv.nativeElement).on('mouseout', this.rollOutBound);
  }

  ngOnDestroy(): void {
    this.closeSub.unsubscribe();
    $(this.closeDiv.nativeElement).off('mouseover', this.rollOverBound);
    $(this.closeDiv.nativeElement).off('mouseout', this.rollOutBound);
  }

  _eventDispatch(event: CloseEvent): void {
    if (this.appConfig.DEBUG) {
      console.log('CloseComponent:_eventDispatch: ' + event.name);
    }

    switch (event.name) {
      case this.closeModalService.SHOW:
        $(this.closeDiv.nativeElement).css('display', 'block');
        gsap.to(this.closeDiv.nativeElement, {
          duration: this.appConfig.tweenSpeed,
          autoAlpha: 1,
          ease: this.appConfig.tweenFunc,
          onComplete: () => {
            this.enableESC();
          },
        });
        break;
      case this.closeModalService.HIDE:
        gsap.to(this.closeDiv.nativeElement, {
          duration: this.appConfig.tweenSpeed,
          autoAlpha: 0,
          ease: this.appConfig.tweenFunc,
          onComplete: () => {
            $(this.closeDiv.nativeElement).css('display', 'none');
          },
        });
        break;

      default:
        break;
    }
  }

  closeModal(): void {
    if (this.appConfig.DEBUG) {
      console.log('CloseComponent:closeModal');
    }

    this.closeModalService.close();
    this.disableESC();
  }

  enableESC(): void {
    if (this.appConfig.DEBUG) {
      console.log('CloseComponent:enableESC');
    }

    // $(document).on('keyup', this.onEscBinding)
  }

  disableESC(): void {
    if (this.appConfig.DEBUG) {
      console.log('CloseComponent:disableESC');
    }

    // $(document).off('keyup', this.onEscBinding)
  }

  onEsc(event: KeyboardEvent): void {
    event.preventDefault();
    event.stopPropagation();
    if (this.appConfig.DEBUG) {
      // tslint:disable-next-line: deprecation
      console.log('CloseComponent:onEsc (%s)', event.keyCode);
    }

    // tslint:disable-next-line: deprecation
    if (event.keyCode === this.KEYCODE_ESC) {
      this.closeModal();
    }
  }

  onRollOver(): void {
    this.cursorService.emitEvent(new CursorEvent(this.cursorService.ROLLOVER));
  }

  onRollOut(): void {
    this.cursorService.emitEvent(new CursorEvent(this.cursorService.ROLLOUT));
  }
}
