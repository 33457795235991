import { Injectable } from '@angular/core';
import { AppConfigService } from 'src/app/services/config/app-config.service';
import { Progress } from 'src/app/model/progress';
import { Answer, AnswersType } from 'src/app/model/answers-type';

@Injectable({
  providedIn: 'root',
})
export class ProgressService {
  MAXREFS = 66;
  MAXBONUS = 6;
  MAXQUIZ = 6;

  REFSSCALE = 20;
  BONUSSCALE = 5;
  QUIZSCALE = 10;

  REFS = 'ProgressService:REFS';
  EASY = 'ProgressService:EASY';
  MEDIUM = 'ProgressService:MEDIUM';
  HARD = 'ProgressService:HARD';
  BONUS = 'ProgressService:BONUS';
  QUIZ = 'ProgressService:QUIZ';
  ERROR = 'ProgressService:ERROR';
  MOVIE = 'ProgressService:MOVIE';
  SERIE = 'ProgressService:SERIE';
  GOLD = 'ProgressService:GOLD';
  DURATION = 'ProgressService:DURATION';

  progress: Progress;

  progressValue = 0;
  godModeActivated = false;
  godModeUsed = false;
  cheatCodeUsed = false;
  answerType = new AnswersType();

  constructor(private appConfig: AppConfigService) {}

  init(): void {
    this.progress = new Progress();
    this.progress.nbRefs = 0;
    this.progress.easyRefs = 0;
    this.progress.mediumRefs = 0;
    this.progress.hardRefs = 0;
    this.progress.nbBonus = 0;
    this.progress.nbQuiz = 0;
    this.progress.nbError = 0;
    this.progress.score = 0;
    this.progress.progressValue = 0;
    this.progress.duration = 0;
    this.progress.answers = [];
    this.progress.errors = [];
  }

  reset(): void {
    this.progress = new Progress();
    this.progress.nbRefs = 0;
    this.progress.easyRefs = 0;
    this.progress.mediumRefs = 0;
    this.progress.hardRefs = 0;
    this.progress.nbBonus = 0;
    this.progress.nbQuiz = 0;
    this.progress.nbError = 0;
    this.progress.score = 0;
    this.progress.progressValue = 0;
    this.progress.answers = [];
    this.progress.errors = [];
  }

  loadProgress(loadedGame: Progress): void {
    this.reset();
    this.progress = loadedGame;
    this.progress.errors = [];

    this.applyErrors();

    // this.cleanLoadedAnswers();
    this.computeScore();
    if (this.appConfig.DEBUG) {
      console.log(
        'ProgressService:loadProgress: ' +
          JSON.stringify(this.progress, null, 4)
      );
    }
  }

  cleanLoadedAnswers(): void {
    // on remove toutes infos inutile pour l'historique des réponses

    const loadedAnswers = this.progress.answers;
    const cleanAnsers = [];

    // tslint:disable-next-line: prefer-for-of
    for (let index = 0; index < loadedAnswers.length; index++) {
      const answer: Answer = loadedAnswers[index];
      delete answer.id;
      delete answer.title;
      delete answer.badge_id;
      delete answer.x;
      delete answer.y;
      delete answer.flag;
      delete answer.position;
      delete answer.comboType;
      delete answer.comboName;
      delete answer.created_at;
      delete answer.updated_at;

      if (answer.type === this.answerType.ARTIST) {
        // stockage externes de loaded errors de la chaine de reponses
        // pour ne pas les renvoyer
        // au serveur lors du prochain save
        if (answer.show_type === 0) {
          this.progress.errors.push(answer);
        } else {
          cleanAnsers.push(answer);
        }
      } else {
        cleanAnsers.push(answer);
      }
    }

    this.progress.answers = cleanAnsers;
  }

  saveAnswer(
    type,
    hash: string = '',
    // tslint:disable-next-line: variable-name
    is_found = 0,
    comboName = '',
    position = 0,
    multiplier = 1,
    // tslint:disable-next-line: variable-name
    show_type = 0
  ): void {
    this.progress.answers.push({
      hash,
      type,
      time: Date.now(),
      is_found,
      comboName,
      position,
      multiplier,
      show_type,
    });
    if (this.appConfig.DEBUG) {
      console.log(
        'ProgressService:saveAnswer data: ' +
          JSON.stringify(this.progress.answers, null, 4)
      );
    }
  }

  computeScore(): void {
    this.progress.nbRefs = 0;
    this.progress.easyRefs = 0;
    this.progress.mediumRefs = 0;
    this.progress.hardRefs = 0;
    this.progress.nbBonus = 0;
    this.progress.nbQuiz = 0;
    this.progress.nbError = 0;
    this.progress.score = 0;

    // tslint:disable-next-line: prefer-for-of
    for (let index = 0; index < this.progress.answers.length; index++) {
      const answer: Answer = this.progress.answers[index];

      switch (answer.type) {
        case this.answerType.ARTIST:
          if (answer.show_type > 0) {
            if (answer.hash !== '') {
              this.progress.nbRefs += 1;

              if (answer.show_type === 1) {
                this.progress.easyRefs++;
                this.progress.score +=
                  this.appConfig.SCORE_EASY_REFS * answer.multiplier;
              }
              if (answer.show_type === 2) {
                this.progress.mediumRefs++;
                this.progress.score +=
                  this.appConfig.SCORE_MEDIUM_REFS * answer.multiplier;
              }
              if (answer.show_type === 3) {
                this.progress.hardRefs++;
                this.progress.score +=
                  this.appConfig.SCORE_HARD_REFS * answer.multiplier;
              }
              if (answer.show_type === 4) {
                this.progress.score += this.appConfig.SCORE_CHEATCODE;
              }
            }
          } else {
            // score += this.appConfig.SCORE_ERROR;
            this.progress.nbError += 1;
          }
          break;
        case this.answerType.QUIZZ:
          this.progress.score += this.appConfig.SCORE_QUIZ;
          break;
        case this.answerType.COMBO:
          if (answer.show_type === 2) {
            this.progress.score +=
              this.appConfig.SCORE_COMBO * answer.multiplier;
            this.progress.nbBonus += 1;
          }
          if (answer.show_type === 66) {
            this.godModeUsed = true;
          }
          if (answer.show_type === 42) {
            this.progress.score +=
              this.appConfig.SCORE_CHEATCODE * answer.multiplier;
            this.cheatCodeUsed = true;
          }
          break;
        default:
          break;
      }
    }

    // ajout des loaded errors
    // tslint:disable-next-line: prefer-for-of
    for (let idx = 0; idx < this.progress.errors.length; idx++) {
      this.progress.nbError += 1;
    }

    this.applyErrors();

    if (this.appConfig.DEBUG) {
      console.log(
        'ProgressService:computeScore: ' +
          JSON.stringify(this.progress, null, 4)
      );
    }
    // this.computeProgressValue();
  }

  applyErrors(): void {
    if (this.appConfig.DEBUG) {
      console.log(
        'ProgressService:applyErrors (before): ' + this.progress.score
      );
    }
    if (this.progress.score >= 50) {
      this.progress.score += this.progress.nbError * this.appConfig.SCORE_ERROR;
      if (this.progress.score < 0) {
        this.progress.score = 0;
      }
    }

    if (this.appConfig.DEBUG) {
      console.log(
        'ProgressService:applyErrors (after): ' + this.progress.score
      );
    }
  }

  updateProgress(id: string, multiplier = 1): void {
    if (this.appConfig.DEBUG) {
      console.log('ProgressService:updateProgress: ' + id);
    }

    switch (id) {
      case this.REFS:
        this.progress.nbRefs++;
        break;
      case this.EASY:
        this.progress.easyRefs += multiplier;
        break;
      case this.MEDIUM:
        this.progress.mediumRefs += multiplier;
        break;
      case this.HARD:
        this.progress.hardRefs += multiplier;
        break;
      case this.BONUS:
        this.progress.nbBonus++;
        break;
      case this.QUIZ:
        this.progress.nbQuiz++;
        break;
      case this.ERROR:
        this.progress.nbError++;
        break;
      case this.GOLD:
        this.progress.nbGold++;
        break;

      default:
        break;
    }
    // this.computeProgressValue();
    this.computeScore();
  }
  getProgress(id: string): number {
    if (this.appConfig.DEBUG) {
      console.log('ProgressService:getProgress: ' + id);
    }
    let val: number;
    switch (id) {
      case this.REFS:
        val = this.progress.nbRefs;
        break;
      case this.EASY:
        val = this.progress.easyRefs;
        break;
      case this.MEDIUM:
        val = this.progress.mediumRefs;
        break;
      case this.HARD:
        val = this.progress.hardRefs;
        break;
      case this.BONUS:
        val = this.progress.nbBonus;
        break;
      case this.QUIZ:
        val = this.progress.nbQuiz;
        break;
      case this.ERROR:
        val = this.progress.nbError;
        break;
      case this.DURATION:
        val = this.progress.duration;
        break;

      default:
        val = -1;
        break;
    }
    return val;
  }

  getProgressValue(): number {
    // this.computeProgressValue();
    return this.progress.progressValue;
  }

  fixScore(score: number) {
    if (score !== this.progress.score && this.progress.score > 0) {
      // this.$gaService.event(
      //   'Score Fix',
      //   'Alert',
      //   this.progress.score + ' => ' + score
      // )
      if (this.appConfig.DEBUG) {
        console.warn(
          'ProgressService:fixScore: ' + this.progress.score + ' => ' + score
        );
      }
      this.progress.score = score;
    }
  }

  get score(): Array<number> {
    return [this.progress.score, this.progress.nbRefs];
  }

  set score(value: Array<number>) {
    this.progress.score = value[0];
    this.progress.nbRefs = value[1];
  }

  get errorCount(): number {
    return this.progress.nbError;
  }

  set errorCount(value: number) {
    this.progress.nbError = value;
  }

  set duration(value: number) {
    this.progress.duration = value;
  }
  get duration(): number {
    return this.progress.duration;
  }

  set movieCount(value: number) {
    this.progress.nbMovies = value;
  }
  get movieCount(): number {
    return this.progress.nbMovies;
  }

  set goldCount(value: number) {
    this.progress.nbGold = value;
  }
  get goldCount(): number {
    return this.progress.nbGold;
  }

  set serieCount(value: number) {
    this.progress.nbSeries = value;
  }
  get serieCount(): number {
    return this.progress.nbSeries;
  }

  get bonusCount(): number {
    return this.progress.nbBonus;
  }

  get quizCount(): number {
    return this.progress.nbQuiz;
  }

  get answers(): Array<any> {
    return this.progress.answers;
  }

  set answers(value: Array<any>) {
    this.progress.answers = value;
  }

  resetAnswers(): void {
    this.progress.answers = [];
  }

  public get onGodMode(): boolean {
    return this.godModeActivated;
  }
  public set onGodMode(val: boolean) {
    this.godModeActivated = val;
  }
  public set isGodModeUsed(val: boolean) {
    this.godModeUsed = val;
  }

  public get isGodModeUsed(): boolean {
    return this.godModeUsed;
  }

  // get negativeCount(): number {
  //   return this.progress.negative;
  // }
}
