import { Component, OnInit, Input, OnDestroy } from '@angular/core'
import { AppConfigService } from 'src/app/services/config/app-config.service'
import { UtilsService } from 'src/app/services/utilities/utils.service'

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss']
})
export class OverlayComponent implements OnInit, OnDestroy {
  @Input() keyboard: boolean
  @Input() darker: boolean
  @Input() transparent: boolean
  @Input() error: boolean
  @Input() save: boolean

  constructor(
    private appConfig: AppConfigService,
    private utils: UtilsService
  ) {}
  assetsUrl = this.appConfig.assetsUrl

  ngOnInit(): void {
    if (this.appConfig.DEBUG) {
      console.log('OverlayComponent:init ' + this.keyboard)
    }
    if (this.utils.md().mobile() !== null) {
      setTimeout(() => {
        this.darker = true
      }, 1)
    }
  }

  ngOnDestroy(): void {
    if (this.appConfig.DEBUG) {
      console.log('OverlayComponent:ngOnDestroy')
    }
  }
}
