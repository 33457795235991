import { Component, NgZone, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppConfigService } from 'src/app/services/config/app-config.service';
import { LoadService } from 'src/app/services/game/load.service';
import { ProgressService } from 'src/app/services/game/progress.service';
import {
  AuthenticationService,
  AuthEvent,
} from 'src/app/services/http/authentication.service';
import { PlayerService } from 'src/app/services/user/player.service';
import { UtilsService } from 'src/app/services/utilities/utils.service';
import {
  CursorEvent,
  CursorService,
} from 'src/app/services/view/cursor.service';
import gsap from 'gsap';
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin';
import { RoughEase } from 'gsap/EasePack';
import * as $ from 'jquery';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { RedbullTrackingService } from 'src/app/services/utilities/redbull-tracking.service';
// import { routeAnimations } from 'src/app/animations/route.animations';
import { RotateDeviceService } from 'src/app/services/view/rotate-device.service';

@Component({
  selector: 'home-login',
  templateUrl: './home-login.component.html',
  styleUrls: ['./home-login.component.scss'],
  // animations: [routeAnimations],
})
export class HomeLoginComponent implements OnInit, OnDestroy {
  constructor(
    private appConfig: AppConfigService,
    private cursorService: CursorService,
    private authService: AuthenticationService,
    private loadService: LoadService,
    private progressService: ProgressService,
    private playerService: PlayerService,
    private $gaService: GoogleAnalyticsService,
    private utils: UtilsService,
    private rotateDeviceService: RotateDeviceService,

    private ngZone: NgZone,
    private router: Router
  ) {}

  authSubs: Subscription;
  private onResizeBound: () => {};
  onCloseBound;
  rollOverBound;
  rollOutBound;
  assetsUrl = this.appConfig.assetsUrl;

  ngOnInit(): void {
    gsap.registerPlugin(DrawSVGPlugin, RoughEase);

    if (this.appConfig.DEBUG) {
      console.log('HomeLoginComponent:ngOnInit ');
    }
    this.authSubs = this.authService.emitter$.subscribe((event) =>
      this._eventDispatch(event)
    );

    // this.onResizeBound = this._onCanvasResize.bind(this);
    this.onCloseBound = this.back.bind(this);
    this.rollOverBound = this.onRollOver.bind(this);
    this.rollOutBound = this.onRollOut.bind(this);
    $('#home-login .close-cross').on('mouseover', this.rollOverBound);
    $('#home-login .close-cross').on('mouseout', this.rollOutBound);

    // $(window).on('resize', this.onResizeBound);
    $('#home-login').on('click', this.onCloseBound);
    // $('#home-login').css('cursor', 'pointer')

    // this._onCanvasResize();
    this.show();
    if (this.appConfig.GA_STATS) {
      this.$gaService.pageView('/home-login', 'Login to connect');
    }
  }

  ngOnDestroy() {
    if (this.appConfig.DEBUG) {
      console.log('HomeLoginComponent:ngOnDestroy');
    }
    this.authSubs.unsubscribe();
    // $(window).off('resize', this.onResizeBound);
    $('#home-login .close-cross').off('mouseover', this.rollOverBound);
    $('#home-login .close-cross').off('mouseout', this.rollOutBound);
  }

  show(): void {
    this.cursorService.cross();
    gsap.to('#home-login', {
      // delay: 0.5,
      duration: 0.6,
      autoAlpha: 1,
      ease: this.appConfig.tweenFunc,
      onComplete: () => {
        // gsap.to('#closeLogin', {
        //   duration: 0.6,
        //   autoAlpha: 1,
        //   ease: this.appConfig.tweenFunc,
        //   onComplete: () => {
        //     // $('#closeLogin').css('pointer-events', 'all')
        //   }
        // })
      },
    });
  }

  _onAuthSuccess(event: AuthEvent): void {
    this.playerService.isConnected = true;
    this.playerService.name = event.user.name;
    this.playerService.gameid = event.user.game;
    if (this.appConfig.GA_STATS) {
      this.$gaService.event('Auth Success', 'Events', 'Login Success');
    }

    this._goto('/hello');
    // this.sounsService.stopIntro();
  }
  _onAuthFail(): void {
    if (this.appConfig.GA_STATS) {
      this.$gaService.event('Auth Failed', 'Events', 'Login failed');
    }

    this.playerService.isConnected = false;
  }

  back(): void {
    this._goto('/home');
  }

  _goto(page: string): void {
    this.authSubs.unsubscribe();

    this.ngZone.run(() =>
      this.router.navigate([page], {
        skipLocationChange: true,
      })
    );
  }

  onRollOver(): void {
    this.cursorService.emitEvent(new CursorEvent(this.cursorService.ROLLOVER));
  }

  onRollOut(): void {
    this.cursorService.emitEvent(new CursorEvent(this.cursorService.ROLLOUT));
  }

  /**
   * Commutateur event -> fonction
   * @param event CursorEvent
   */
  private _eventDispatch(event: any): void {
    switch (event.name) {
      case this.authService.AUTHSUCCESS:
        this._onAuthSuccess(event);
        break;
      case this.authService.AUTHFAILED:
        this._onAuthFail();
        break;
      default:
        break;
    }
  }
  _onCanvasResize() {
    //   if (this.appConfig.DEBUG) {
    //     console.log(
    //       'HomeLoginComponent:_onCanvasResize scale : ' + this.utils.getUIScale()
    //     );
    //   }
    //   if (this.utils.md().mobile() !== null) {
    //     if (this.rotateDeviceService.getOrientation() === 'landscape') {
    //       this.rotateDeviceService.enable();
    //     } else {
    //       this.rotateDeviceService.disable();
    //     }
    //   }
    //   if (this.utils.getUIScale() > 1) {
    //     $('#home-login').css(
    //       'transform',
    //       'scale(' + this.utils.getUIScale() + ')'
    //     );
    //   } else {
    //     $('#home-login').css('transform', 'unset');
    //   }
  }
}
