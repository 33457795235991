import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ElementRef,
  ViewChild,
  AfterViewInit,
} from '@angular/core';

import * as $ from 'jquery';
import gsap from 'gsap';
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin';
import { RoughEase } from 'gsap/EasePack';
import { ComboService } from 'src/app/services/game/combo.service';
import { Subscription } from 'rxjs';
import { SVG, Element, Svg } from '@svgdotjs/svg.js';
import { QuizEvent, QuizService } from 'src/app/services/game/quiz.service';
import { BonusEvent, BonusService } from 'src/app/services/view/bonus.service';
import { UtilsService } from 'src/app/services/utilities/utils.service';
import { AppConfigService } from 'src/app/services/config/app-config.service';
import { CursorService } from 'src/app/services/view/cursor.service';
import { GameService } from 'src/app/services/game/game.service';

export class LockedBonus {
  root: Svg;
  frame1: Element;
  frame2: Element;
  lock: Element;

  constructor() {}
}

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'bonus',
  templateUrl: './bonus.component.html',
  styleUrls: ['./bonus.component.scss'],
})
export class BonusComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(
    private bonusService: BonusService,
    private quizService: QuizService,
    private appConfig: AppConfigService,
    private cursorService: CursorService,
    private gameService: GameService,

    private utils: UtilsService
  ) {}

  status = 0;
  idSVG = '';
  currentScore = 0;
  scale = 0.55;
  bonusSubs: Subscription;
  quizSubs: Subscription;
  assetsUrl = this.appConfig.assetsUrl;
  svg = this.appConfig.assetsUrl + '/assets/images/dashboard/bonus/lock.svg';

  lockedBonus: LockedBonus;
  @Input() id: string;
  @Input() index: number;
  @Input() mode: string;

  @ViewChild('frame1') frame1: ElementRef;
  @ViewChild('frame2') frame2: ElementRef;
  @ViewChild('lock') lock: ElementRef;
  @ViewChild('score') score: ElementRef;

  ngOnInit(): void {
    if (this.appConfig.DEBUG) {
      console.log('BonusComponent:ngOnInit');
    }

    gsap.registerPlugin(DrawSVGPlugin, RoughEase);
  }
  ngAfterViewInit(): void {
    if (this.appConfig.DEBUG) {
      console.log('BonusComponent:ngAfterViewInit');
    }

    this.initLockedBonus();

    this.bonusSubs = this.bonusService.emitter$.subscribe((event: BonusEvent) =>
      this._eventDispatch(event)
    );
    this.quizSubs = this.quizService.emitter$.subscribe((event: QuizEvent) =>
      this._eventDispatch(event)
    );
  }

  ngOnDestroy(): void {
    if (this.appConfig.DEBUG) {
      console.log('BonusComponent:ngOnDestroy');
    }

    this.bonusSubs.unsubscribe();
    this.quizSubs.unsubscribe();
  }

  initLockedBonus(): void {
    if (this.appConfig.DEBUG) {
      console.log(
        'BonusComponent:initLockedBonus >' + this.frame1.nativeElement.id
      );
    }
    // const svg = document.getElementById(this.id + 'Root');
    // if (this.mode === 'dash') {
    //   // $(this.id + "Root").attr("transform", "scale(0.55)");
    //   svg.setAttribute('transform', 'scale(0.55)');
    // } else {
    //   svg.setAttribute('transform', 'scale(1.0)');
    // }

    this.lockedBonus = new LockedBonus();
    this.lockedBonus.frame1 = SVG(this.frame1.nativeElement) as Element;
    this.lockedBonus.frame1.attr({ opacity: 0 });

    this.lockedBonus.frame2 = SVG(this.frame2.nativeElement) as Element;
    this.lockedBonus.frame2.attr({ opacity: 0 });

    this.lockedBonus.lock = SVG(this.lock.nativeElement) as Element;
    this.lockedBonus.lock.attr({ opacity: 0 });
  }

  showLockedBonus(): void {
    if (this.appConfig.DEBUG) {
      console.log('BonusComponent:showLockedBonus ');
    }
    this.lockedBonus.frame1.attr({ opacity: 1 });
    this.lockedBonus.frame2.attr({ opacity: 1 });

    this.utils.blink(this.lockedBonus.lock);
  }

  hideLockedBonus(): void {
    if (this.appConfig.DEBUG) {
      console.log('BonusComponent:hideLockedBonus ');
    }

    this.lockedBonus.frame1.attr({ opacity: 0 });
    this.lockedBonus.frame2.attr({ opacity: 0 });
    this.lockedBonus.lock.attr({ opacity: 0 });
  }

  onRollOverButton(): any {
    // this.cursorService.rollOver();
    this.onRollOver();
    if (this.status === 1) {
      gsap.to('#' + this.id + '_badge', {
        duration: 0.2,
        opacity: 1,
        scale: 1.1,
        ease: 'back.out',
      });
    }
  }
  onRollOutButton(): any {
    // this.cursorService.rollOut();
    this.onRollOut();
    if (this.status === 1) {
      gsap.to('#' + this.id + '_badge', {
        duration: 0.2,
        opacity: 1,
        scale: 1,
        ease: 'back.out',
      });
    }
  }

  onImgChange(): void {
    if (this.appConfig.DEBUG) {
      console.log(
        'BonusComponent:onImgChange ' + this.idSVG + ' / ' + this.mode
      );
    }
    if (this.status === 1) {
      if (
        this.svg !==
        this.assetsUrl + '/assets/images/dashboard/bonus/lock.svg'
      ) {
        gsap.to('#' + this.id + '_badge', {
          duration: 0.4,
          opacity: 1,
          scale: 1,
          ease: 'back.out',
        });
      }
    }
  }

  openBonus(event): void {
    event.stopPropagation();
    event.preventDefault();
    if (this.appConfig.DEBUG) {
      console.log('BonusComponent:openBonus ' + this.idSVG);
    }
    this.quizService.show(this.idSVG, this.index);
  }

  unlockBonus(id: string): void {
    if (this.appConfig.DEBUG) {
      console.log('BonusComponent:unlock(%s)', id);
      console.log('BonusComponent:unlock index:' + this.index);
    }
    this.status = 1;
    this.idSVG = id;

    setTimeout(() => {
      const svg = document.getElementsByClassName('_lock');
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < svg.length; i++) {
        const element = svg[i];
        (element as HTMLElement).style.opacity = '0';
      }
    }, 100);
  }

  showBonus(): void {
    if (this.appConfig.DEBUG) {
      console.log('BonusComponent:showBonus ' + this.idSVG);
    }
    this.svg =
      this.assetsUrl + '/assets/images/dashboard/bonus/' + this.idSVG + '.png';
  }

  hideBonus(): void {
    if (this.appConfig.DEBUG) {
      console.log('BonusComponent:hideBonus ' + this.idSVG);
    }

    gsap.to('#' + this.id + '_badge', {
      duration: 0.6,
      opacity: 0,
      scale: 0.01,
      ease: 'back.in',
      onComplete: () => {
        this.svg = this.assetsUrl + '/assets/images/dashboard/bonus/lock.svg';
      },
    });
  }
  showDoneBonus(): void {
    gsap.to('#' + this.id + '_badge_done', {
      duration: 0.6,
      opacity: 1,
      scale: 1,
      ease: 'back.out',
      onComplete: () => {
        gsap.to(this.score.nativeElement, {
          duration: 0.6,
          autoAlpha: 1,
          scale: 1,
          ease: 'power3.out',
        });
      },
    });
  }

  hideDoneBonus(): void {
    gsap.to(this.score.nativeElement, {
      duration: 0.4,
      autoAlpha: 0,
      ease: 'power3.out',
    });
    gsap.to('#' + this.id + '_badge_done', {
      duration: 0.6,
      opacity: 0,
      scale: 0.01,
      ease: 'back.in',
      onComplete: () => {},
    });
  }

  bonusDone(id: string, score: number): void {
    if (this.appConfig.DEBUG) {
      console.log('BonusComponent:bonusDone for %s', id);
    }
    this.gameService.total -= 1;
    this.status = 2;
    this.svg =
      this.assetsUrl +
      '/assets/images/dashboard/bonus/' +
      this.idSVG +
      '-done.png';
    this.currentScore = score;
  }

  updateResults(): void {
    if (this.appConfig.DEBUG) {
      console.log('BonusComponent:updateResults ' + this.idSVG);
    }
    gsap.to('#' + this.id + '_badge_done', {
      duration: 0.4,
      opacity: 1,
      ease: 'back.out',
    });
    // if (this.mode === 'end') {
    //   const svg = document.getElementById('_done_' + this.idSVG);
    //   svg.setAttribute('transform', 'scale(1.3)');
    //   $(svg).css('margin-top', '3px');
    // }

    // if (this.currentScore === 6) {
    //   const svg = document.getElementById(this.idSVG + '_results');
    //   if (svg !== null) {
    //     svg.style.opacity = '0';
    //   }
    // } else {
    //   let svg = document.getElementById(this.idSVG + '_perfect');
    //   if (svg !== null) {
    //     svg.style.opacity = '0';
    //   }
    //   for (let index = 1; index < 7; index++) {
    //     svg = document.getElementById(this.idSVG + '_s' + index);
    //     console.log('BonusComponent:bonusDone svg: ' + svg);

    //     if (svg !== null) {
    //       if (index <= this.currentScore) {
    //         svg.style.opacity = '1';
    //       } else {
    //         svg.style.opacity = '0';
    //       }
    //     }
    //   }
    // }
  }

  _eventDispatch(event: any): void {
    switch (event.name) {
      case this.bonusService.SHOW:
        if ((event as BonusEvent).index === this.index) {
          if (this.appConfig.DEBUG) {
            console.log(
              'BonusComponent:_eventDispatch SHOW status: ' + this.status
            );
          }

          if (this.status === 2) {
            this.showDoneBonus();
          } else if (this.status === 1) {
            this.showBonus();
          } else {
            this.showLockedBonus();
          }
        }
        break;
      case this.bonusService.HIDE:
        if ((event as BonusEvent).index === this.index) {
          if (this.appConfig.DEBUG) {
            console.log(
              'BonusComponent:_eventDispatch HIDE status: ' + this.status
            );
          }

          if (this.status === 2) {
            this.hideDoneBonus();
          } else if (this.status === 1) {
            this.hideBonus();
          } else {
            this.hideLockedBonus();
          }
        }
        break;
      case this.bonusService.UNLOCK:
        if ((event as BonusEvent).index === this.index) {
          if (this.appConfig.DEBUG) {
            console.log(
              'BonusComponent:_eventDispatch UNLOCK status: ' + this.status
            );
          }

          if (this.status === 0) {
            this.unlockBonus((event as BonusEvent).comboName);
          }
        }
        break;
      case this.quizService.DONE:
        if (this.appConfig.DEBUG) {
          console.log(
            'BonusComponent:_eventDispatch DONE index: ' +
              (event as QuizEvent).index
          );
        }
        if ((event as QuizEvent).index === this.index) {
          if (this.appConfig.DEBUG) {
            console.log(
              'BonusComponent:_eventDispatch DONE status: ' + this.status
            );
          }

          if (this.status === 1) {
            this.bonusDone(event.quizID, event.score);
          }
        }
        break;
      default:
        break;
    }
  }

  onRollOver(): void {
    this.cursorService.rollOver();
  }

  onRollOut(): void {
    this.cursorService.rollOut();
  }
}
