import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Show } from '../../model/show';
import { Observable } from 'rxjs';
import { AppConfigService } from '../config/app-config.service';
import { QuizData } from '../game/quiz.service';
import { retry } from 'rxjs/operators';
import { Answer } from 'src/app/model/answers-type';
import { HighScore, Ranking } from 'src/app/model/ranking';
import { Combo } from 'src/app/model/combo';
import { Track } from 'src/app/model/track';

export class Games {
  game: string;
  is_finished: number;
  score: number;
  position: number;
  answers: Array<Answer>;
}

@Injectable({
  providedIn: 'root',
})
export class ServerService {
  constructor(private http: HttpClient, private appConfig: AppConfigService) {}

  lookupShow(titre: string): Observable<Show> {
    const url =
      this.appConfig.apiUrl +
      '/artists/' +
      this.appConfig.lang +
      '/' +
      titre +
      '/0';
    return this.http.get<Show>(url);
  }

  getCombos(): Observable<Array<Combo>> {
    const url = this.appConfig.apiUrl + '/combos/' + this.appConfig.lang;
    return this.http.get<Array<Combo>>(url).pipe(retry(1));
  }
  getRankings(): Observable<Array<Ranking>> {
    const url = this.appConfig.apiUrl + '/profile/' + this.appConfig.lang;
    return this.http.get<Array<Ranking>>(url).pipe(retry(1));
  }

  getTracks(): Observable<Array<Track>> {
    const url = this.appConfig.apiUrl + '/tracks/' + this.appConfig.lang;
    return this.http.get<Array<Track>>(url).pipe(retry(1));
  }

  getQuiz(badgeid: string): Observable<Array<QuizData>> {
    const url =
      this.appConfig.apiUrl + '/quiz/' + this.appConfig.lang + '/' + badgeid;
    return this.http.get<Array<QuizData>>(url).pipe(retry(1));
  }

  getTop66(): Observable<Array<HighScore>> {
    const url = this.appConfig.apiUrl + '/game/' + this.appConfig.lang;
    return this.http.get<Array<HighScore>>(url).pipe(retry(1));
  }

  // tslint:disable-next-line: variable-name
  postGame(gameid: string, answers: Array<any>, is_finished = 0): any {
    const data = JSON.stringify({
      game: gameid,
      answers,
      is_finished,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    };
    return this.http
      .post<Games>(
        this.appConfig.apiUrl + '/game/' + this.appConfig.lang,
        data,
        config
      )
      .pipe(retry(1));
  }

  loadGame(gameid: string, token: string): any {
    // const config = {
    //   headers: {
    //     // 'Content-Type': 'application/json; charset=utf-8',
    //     Authorization: 'Bearer ' + token
    //   }
    // };

    return this.http.get<Games>(
      this.appConfig.apiUrl + '/game/' + this.appConfig.lang + '/' + gameid
    );
  }
}
