import { Injectable, EventEmitter } from '@angular/core';
import { Show } from '../../model/show';
import { Svg, Container, Rect, Text, Circle } from '@svgdotjs/svg.js';
import { AppConfigService } from 'src/app/services/config/app-config.service';
import { ScreenService } from './screen.service';

export class PinObj {
  idx: number;
  svg: Svg;
  core: Circle;
  frame: Circle;
  roll: Circle;
  title: Text;
  group: Container;
  active: boolean;
  x: number;
  y: number;
}

export class PinsEvent {
  constructor(
    public name: string,
    public show?: Show,
    public pinsPool?: Array<PinObj>
  ) {}
}
@Injectable({
  providedIn: 'root',
})
export class PinsService {
  public emitter$: EventEmitter<PinsEvent>;

  public SHOWPINS = 'PinsService:SHOWPINS';
  public CREATEPINS = 'PinsService:CREATEPINS';
  public HIDEPINS = 'PinsService:HIDEPINS';
  public SHOWFOCUS = 'PinsService:SHOWFOCUS';
  public SHOWPINSSUCESS = 'BadgeService:SHOWPINSSUCESS';
  public HIDEPINSSUCESS = 'BadgeService:HIDEPINSSUCESS';
  public SHOWFOCUSSUCESS = 'BadgeService:SHOWFOCUSSUCESS';

  pinsPool = [];
  timer = null;

  constructor(
    private screenService: ScreenService,
    private appConfig: AppConfigService
  ) {
    this.emitter$ = new EventEmitter();
  }
  public emitEvent(event: PinsEvent): void {
    if (this.appConfig.DEBUG) {
      console.log('PinsService emitEvent %s', event.name);
    }
    this.emitter$.emit(event);
  }

  getPinsPool(): Array<PinObj> {
    return this.pinsPool;
  }

  showFocus(show: Show): void {
    if (this.appConfig.DEBUG) {
      console.log('PinsService:show ' + show.badge_id);
    }
    this.emitEvent(new PinsEvent(this.SHOWFOCUS, show));
  }

  showPins(): void {
    if (this.pinsPool.length > 0) {
      if (this.appConfig.DEBUG) {
        console.log('PinsService::showPins');
      }
      setTimeout(() => {
        this.screenService.pause();
      }, 200);
      this.emitEvent(new PinsEvent(this.SHOWPINS, undefined, this.pinsPool));
      this.timer = setTimeout(() => {
        this.hidePins();
      }, this.appConfig.TIMER_CLOSE_PINS * 1000);
    }
  }

  createPin(show: Show): void {
    this.emitEvent(new PinsEvent(this.CREATEPINS, show));
  }

  hidePins(): void {
    clearTimeout(this.timer);
    if (this.appConfig.DEBUG) {
      console.log('PinsService::hidePins');
    }
    this.screenService.resume();
    this.emitEvent(new PinsEvent(this.HIDEPINS, undefined, this.pinsPool));
  }

  pinsHidden(): void {
    this.emitEvent(
      new PinsEvent(this.HIDEPINSSUCESS, undefined, this.pinsPool)
    );
  }

  storePin(pin: PinObj): void {
    if (this.appConfig.DEBUG) {
      console.log('PinsService:storePin ' + pin.title.text());
    }
    this.pinsPool.push(pin);
  }
}
