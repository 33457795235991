import { Injectable, EventEmitter } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { AppConfigService } from 'src/app/services/config/app-config.service';

export class QuizEvent {
  constructor(
    public name: string,
    public quizID?: string,
    public index?: number,
    public score?: number
  ) {}
}

export class QuizResponse {
  text: string;
  x: boolean;
  v: boolean;
}
export class Quiz {
  hash: string;
  idx: number;
  results: Array<number>;
  blink: boolean;
  blinkTimer: any;
  chrono: number;
  chronoStr: string;
  chronoNbr: Array<string>;
  key: number;
  question: string;
  response1: QuizResponse;
  response2: QuizResponse;
  response3: QuizResponse;
  response4: QuizResponse;
}

export class QuizData {
  question: string;
  response1: string;
  response2: string;
  response3: string;
  response4: string;
  lang: string;
  // tslint:disable-next-line: variable-name
  badge_id: string;
  flag: number;
  hash: string;
}
@Injectable({
  providedIn: 'root',
})
export class QuizService {
  public emitter$: EventEmitter<QuizEvent>;

  public SHOW = 'QuizService:SHOW';
  public HIDE = 'QuizService:HIDE';
  public HIDDEN = 'QuizService:HIDDEN';
  public DONE = 'QuizService:DONE';

  constructor(private appConfig: AppConfigService) {
    this.emitter$ = new EventEmitter();
  }

  public emitEvent(event: QuizEvent): void {
    if (this.appConfig.DEBUG) {
      console.log(
        'QuizService emitEvent %s / %s / %s / %s',
        event.name,
        event.quizID,
        event.index,
        event.score
      );
    }

    this.emitter$.emit(event);
  }

  show(id: string, idx: number): void {
    if (this.appConfig.DEBUG) {
      console.log('QuizService:show %s', id);
    }

    this.emitEvent(new QuizEvent(this.SHOW, id, idx));
  }
  hide(): void {
    if (this.appConfig.DEBUG) {
      console.log('QuizService:hide');
    }

    this.emitEvent(new QuizEvent(this.HIDE));
  }

  done(id: string, index: number, score: number): void {
    this.emitEvent(new QuizEvent(this.DONE, id, index, score));
  }
}
