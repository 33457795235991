import { Injectable, EventEmitter } from '@angular/core';
import { AuthenticationService } from '../http/authentication.service';
import { PlayerService } from '../user/player.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ToastrService } from 'ngx-toastr';
import { ServerService, Games } from '../http/server.service';
import { UtilsService } from '../utilities/utils.service';
// import { CursorService } from '../view/cursor.service';
import { AnswersType, Answer } from 'src/app/model/answers-type';
import { AppConfigService } from 'src/app/services/config/app-config.service';
import { Progress } from 'src/app/model/progress';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

export class LoadEvent {
  constructor(public name: string) {}
}

@Injectable({
  providedIn: 'root',
})
export class LoadService {
  public emitter$: EventEmitter<LoadEvent>;

  private loadedGame: Games;
  loadedGameParsed: Progress;
  private loadedCombos = {};

  constructor(
    private appConfig: AppConfigService,
    private authService: AuthenticationService,
    private playerService: PlayerService,
    private jwtHelper: JwtHelperService,
    private toastr: ToastrService,
    private api: ServerService,
    private $gaService: GoogleAnalyticsService,
    private utils: UtilsService // private cursorService: CursorService
  ) {
    this.emitter$ = new EventEmitter();
  }

  public emitEvent(event: LoadEvent): void {
    if (this.appConfig.DEBUG) {
      console.log('LoadService emitEvent %s', event.name);
    }
    this.emitter$.emit(event);
  }

  loadGame(): any {
    if (this.appConfig.DEBUG) {
      console.log('LoadService:loadGame');
    }
    this.$gaService.event('Load DB Game', 'Events', 'Load Game');

    const promise = new Promise((resolve, reject) => {
      this.api
        .loadGame(this.playerService.gameid, this.playerService.token)
        .subscribe(
          (game) => {
            this.loadedGame = game;
            this.playerService.position = game.position;
            if (this.appConfig.DEBUG) {
              console.log(
                'LoadService:loadGame OK ' +
                  JSON.stringify(this.loadedGame, null, 4)
              );
            }
            this.$gaService.event(
              'Load DB Game Success',
              'Events',
              'Load Game'
            );
            if (this.loadedGame.is_finished === 1) {
              if (this.appConfig.DEBUG) {
                console.log('LoadService:loadGame Game finished -> RESET !! ');
              }
              this.reset();
              this.playerService.gameid = ''; // reset gameid
              resolve(this.loadedGameParsed);
            } else {
              this.parseGame().then((loadedGameParsed) => {
                resolve(loadedGameParsed);
              });
            }
          },
          (err) => {
            if (this.appConfig.DEBUG) {
              console.log('LoadService:loadGame 400 !' + JSON.stringify(err));
            }
            this.$gaService.event(
              'Load DB Game Failed',
              'Events',
              'Load Game Error'
            );

            reject(err);
          }
        );
    });

    return promise;
  }

  reset(): void {
    this.loadedCombos = {};
    this.loadedGameParsed = new Progress();
    this.loadedGameParsed.nbRefs = 0;
    this.loadedGameParsed.nbBonus = 0;
    this.loadedGameParsed.nbQuiz = 0;
    this.loadedGameParsed.nbGold = 0;
    this.loadedGameParsed.nbSeries = 0;
    this.loadedGameParsed.nbMovies = 0;
    this.loadedGameParsed.nbError = 0;
    this.loadedGameParsed.score = 0;
    this.loadedGameParsed.progressValue = 0;
    this.loadedGameParsed.answers = [];

    if (this.loadedGame) {
      this.loadedGame.answers = [];
      this.loadedGame.game = '';
      this.loadedGame.score = 0;
      this.loadedGame.position = 0;
    }
    this.playerService.gameid = ''; // reset gameid
  }

  getLoadedGame(): Games {
    if (this.appConfig.DEBUG) {
      console.log('LoadService:getLoadedGame');
    }
    return this.loadedGame;
  }

  getLoadedCombos(): object {
    return this.loadedCombos;
  }

  parseGame(): any {
    if (this.appConfig.DEBUG) {
      console.log('LoadService:parseGame');
    }
    const promise = new Promise((resolve, reject) => {
      const answerType = new AnswersType();

      this.loadedGameParsed = new Progress();
      this.loadedGameParsed.nbRefs = 0;
      this.loadedGameParsed.nbBonus = 0;
      this.loadedGameParsed.nbQuiz = 0;
      this.loadedGameParsed.easyRefs = 0;
      this.loadedGameParsed.mediumRefs = 0;
      this.loadedGameParsed.hardRefs = 0;
      this.loadedGameParsed.nbError = 0;
      this.loadedGameParsed.score = 0;
      this.loadedGameParsed.progressValue = 0;
      this.loadedGameParsed.answers = this.loadedGame.answers;

      // tslint:disable-next-line: prefer-for-of
      for (let index = 0; index < this.loadedGame.answers.length; index++) {
        const answer: Answer = this.loadedGame.answers[index];

        switch (answer.type) {
          case answerType.ARTIST:
            // if ('hash' in answer) {
            if (answer.show_type > 0) {
              if (answer.hash !== '') {
                this.loadedGameParsed.nbRefs += 1;

                if (answer.show_type === 1) {
                  this.loadedGameParsed.easyRefs++;
                  this.loadedGameParsed.score +=
                    this.appConfig.SCORE_EASY_REFS * answer.multiplier;
                }
                if (answer.show_type === 2) {
                  this.loadedGameParsed.mediumRefs++;
                  this.loadedGameParsed.score +=
                    this.appConfig.SCORE_MEDIUM_REFS * answer.multiplier;
                }
                if (answer.show_type === 3) {
                  this.loadedGameParsed.hardRefs++;
                  this.loadedGameParsed.score +=
                    this.appConfig.SCORE_HARD_REFS * answer.multiplier;
                }
              }
            } else {
              // score += this.appConfig.SCORE_ERROR;
              this.loadedGameParsed.nbError += 1;
            }
            break;
          case answerType.QUIZZ:
            this.loadedGameParsed.score += this.appConfig.SCORE_QUIZ;
            break;
          case answerType.COMBO:
            answer.show_type = answer.comboType;
            if (answer.show_type === 2) {
              this.loadedGameParsed.score += this.appConfig.SCORE_COMBO;
              this.loadedGameParsed.nbBonus += 1;
              this.loadedCombos[answer.comboName] = true;
            }
            break;
          default:
            break;
        }
      }
      if (this.loadedGameParsed.score < 0) {
        this.loadedGameParsed.score = 0;
      }

      if (this.appConfig.DEBUG) {
        console.log(
          'LoadService:parseGame : ' +
            JSON.stringify(this.loadedGameParsed, null, 4)
        );
      }
      resolve(this.loadedGameParsed);
    });

    return promise;
  }
}
