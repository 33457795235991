<form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
  <div class="form-group">
    <input type="text" placeholder="{{placeHolderEmail}}" formControlName="email" class="form-control form-email"
      [ngClass]="{ 'is-invalid': submitted && f.email.errors }" (mouseover)="onRollOverButton()"
      (mouseout)="onRollOutButton()" />
    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
      <div *ngIf="f.email.errors.required">{{placeHolderEmail}} is required</div>
      <div *ngIf="f.email.errors.email">{{placeHolderEmail}} must be a valid {{placeHolderEmail}} address</div>
    </div>
  </div>
  <div class="form-group">
    <input type="password" placeholder="{{placeHolderPassowrd}}" formControlName="password"
      class="form-control form-password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
      (mouseover)="onRollOverButton()" (mouseout)="onRollOutButton()" autocomplete="on" />
    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
      <div *ngIf="f.password.errors.required">{{placeHolderPassowrd}} is required</div>
      <div *ngIf="f.password.errors.minlength">{{placeHolderPassowrd}} must be at least 6 characters</div>
    </div>
  </div>
  <div class="form-group">
    <button [disabled]="loading" class="submit-button  border-gradient border-gradient-gold mt-3"
      (mouseover)="onRollOverButton()" (mouseout)="onRollOutButton()" (click)="onInputClick($event)">
      <!-- <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span> -->
      <span>{{ buttonTextOver }}</span>
      <span><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
          style="margin: auto; background: none; display: block; shape-rendering: auto;" width="51px" height="51px"
          viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" class="loading">
          <circle cx="50" cy="50" r="32" stroke-width="8" stroke="#c6a871"
            stroke-dasharray="50.26548245743669 50.26548245743669" fill="none" stroke-linecap="square"
            transform="rotate(58.5425 50 50)">
            <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" keyTimes="0;1"
              values="0 50 50;360 50 50"></animateTransform>
          </circle>
        </svg>{{ buttonText }}</span>
    </button>
  </div>
  <div class="form-group  text-center pt-4">
    <!-- <label class="form-check-label">
      <a class="lost" href="#" target="_new" (mouseover)="onRollOverButton()" (mouseout)="onRollOutButton()"
        (click)="onInputClick($event)">Mot de passe oublié ?</a>
    </label> -->
  </div>
</form>