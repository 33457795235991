import { Injectable } from '@angular/core';
import { ServerService } from '../http/server.service';
import { ToastrService } from 'ngx-toastr';
import { Ranking } from 'src/app/model/ranking';
import { AppConfigService } from '../config/app-config.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Injectable({
  providedIn: 'root',
})
export class RankingService {
  LEVEL0 = 'mixtape';
  // LEVEL1 = "First Class";
  // LEVEL2 = "Sergeant";
  // LEVEL3 = "Lieutenant";
  // LEVEL4 = "Capitain";
  // LEVEL5 = "Colonel";
  // LEVEL6 = "General";

  currentRanking = '';
  currentBadge = 'level0';
  currentText = '';
  currentWidth = 250;

  rankingData: Array<Ranking>;

  constructor(
    private serverService: ServerService,
    private appConfig: AppConfigService,
    private toastr: ToastrService,
    private $gaService: GoogleAnalyticsService
  ) {}

  init(): any {
    const promise = new Promise((resolve) => {
      this.serverService.getRankings().subscribe(
        (data: Array<Ranking>) => {
          if (this.appConfig.DEBUG) {
            console.log('RankingService:init ' + JSON.stringify(data, null, 4));
          }
          this.rankingData = data;
          resolve(true);
        },
        (err) => {
          if (this.appConfig.DEBUG) {
            console.log('RankingService:init 404 !' + err);
          }
          this.$gaService.event(
            'Fatal Error',
            'Events',
            'Ranking infos not loaded'
          );
          this.toastr
            .error(
              '[73] Error code: ' +
                err.status +
                ' (' +
                err.statusText +
                '). Relance le jeu stp !',
              'Fatal Error',
              {
                progressBar: true,
              }
            )
            .onHidden.subscribe(() => {
              // location.reload(true);
            });
          this.rankingData = [];
          resolve(false);
        }
      );
    });

    return promise;
  }

  getRanking(score): string {
    if (this.rankingData.length === 0) {
      this.currentRanking = this.LEVEL0;
      this.currentBadge = 'noob';
    } else {
      if (score <= 50) {
        this.currentRanking = this.rankingData[4].grade;
        this.currentText = this.rankingData[4].desc;
      }
      if (score <= 44) {
        this.currentRanking = this.rankingData[3].grade;
        this.currentText = this.rankingData[3].desc;
      }
      if (score <= 33) {
        this.currentRanking = this.rankingData[2].grade;
        this.currentText = this.rankingData[2].desc;
      }
      if (score <= 22) {
        this.currentRanking = this.rankingData[1].grade;
        this.currentText = this.rankingData[1].desc;
      }
      if (score <= 11) {
        this.currentRanking = this.rankingData[0].grade;
        this.currentText = this.rankingData[0].desc;
      }
    }

    if (this.appConfig.DEBUG) {
      console.log(
        'RankingService:getRanking %s -> %s',
        score,
        this.currentRanking
      );
    }

    return this.currentRanking;
  }

  // getBadges(score): string {
  //   this.getRanking(score);
  //   return this.currentBadge;
  // }

  // getText(score): string {
  //   this.getRanking(score);
  //   return this.currentText;
  // }

  getDesc(): string {
    return this.currentText;
  }
  getWidth(): number {
    return this.currentWidth;
  }

  getGrade(score): string {
    this.getRanking(score);
    return this.currentRanking;
  }
}
