import { Component, OnInit } from '@angular/core';
import {
  RotateDeviceEvent,
  RotateDeviceService,
} from 'src/app/services/view/rotate-device.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'rotate-device',
  templateUrl: './rotate-device.component.html',
  styleUrls: ['./rotate-device.component.scss'],
})
export class RotateDeviceComponent implements OnInit {
  constructor(private rotateDeviceService: RotateDeviceService) {}

  action = 'pour jouer.';

  ngOnInit(): void {
    this.rotateDeviceService.emitter$.subscribe((event: RotateDeviceEvent) => {
      if (event.name === this.rotateDeviceService.SETACTION) {
        this.action = event.action;
      }
    });
  }
}
