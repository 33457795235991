export class Combo {
  id: number;
  comboName: string;
  comboID: string;
  comboType: number;
  total: number;
  type: number;
  flag: number;
  consecutive: number;
  lang: string;
  hash: string;
  active: number;
  position: number;
  duration: number;
  multiplier: number;
  description: string;
}
export class ComboBuffer {
  comboID: string;
  bonusID: string;
  type: number;
  flag: number;
  multiplier: number;
}
