import { EventEmitter, Injectable } from '@angular/core';
import { AppConfigService } from '../config/app-config.service';
import { MobileOrientation } from 'mobile-orientation';
import * as $ from 'jquery';
import gsap from 'gsap';
// import DeviceRotation from "device-rotation";
import { UtilsService } from '../utilities/utils.service';

export class RotateDeviceEvent {
  constructor(public name: string, public action?: string) {}
}

@Injectable({
  providedIn: 'root',
})
export class RotateDeviceService {
  public emitter$: EventEmitter<RotateDeviceEvent>;

  GOHORIZONTAL = 'RotateDeviceService:GOHORIZONTAL';
  GOVERTICAL = 'RotateDeviceService:GOVERTICAL';
  SETACTION = 'RotateDeviceService:SETACTION';

  changeBound;
  orientation;
  currentResolve;
  destination = '';
  iosOrientation = '';
  iOS = false;
  deviceOrientation = 'portrait';

  constructor(
    private utils: UtilsService,
    private appConfig: AppConfigService
  ) {
    this.emitter$ = new EventEmitter();
  }

  init(destination = 'portrait'): void {
    if (this.utils.md().os() === 'iOS' || this.utils.md().os() === 'iPadOS') {
      this.iOS = true;
    }
    // this.destination = destination

    if (this.iOS) {
      // tslint:disable-next-line: deprecation
      window.onorientationchange = this.readDeviceOrientation.bind(this);
      this.readDeviceOrientation();
    } else {
      this.orientation = new MobileOrientation({ withTouch: true });
      if (this.appConfig.DEBUG) {
        console.log(
          'RotateDeviceService init ' +
            JSON.stringify(this.orientation, null, 4)
        );
      }
      // if (this.orientation.isPortrait) {
      //   this.onOrientationChange('portrait')
      //   this.deviceOrientation = 'portrait'
      //   // this.emitEvent(new RotateDeviceEvent(this.GOVERTICAL, ''))
      // } else {
      //   this.onOrientationChange('landscape')
      //   this.deviceOrientation = 'landscape'
      //   // this.emitEvent(new RotateDeviceEvent(this.GOHORIZONTAL, ''))
      // }
      // this.orientation.on('resize', (state) => this.onOrientationChange(state)); // portrait or landscape
      this.orientation.on('portrait', (state) => {
        this.onOrientationChange(state);
        this.deviceOrientation = 'portrait';
        this.emitEvent(new RotateDeviceEvent(this.GOVERTICAL, ''));
      });
      this.orientation.on('landscape', (state) => {
        this.onOrientationChange(state);
        this.deviceOrientation = 'landscape';
        this.emitEvent(new RotateDeviceEvent(this.GOHORIZONTAL, ''));
      });
    }
    this.disable();
  }

  emitEvent(event: RotateDeviceEvent): void {
    if (this.appConfig.DEBUG) {
      console.log('RotateDeviceService emitEvent %s', event.name);
    }
    this.emitter$.emit(event);
  }

  enable(): void {
    $('.rotate-device').css('display', 'flex').css('opacity', 1);
  }

  disable(): void {
    $('.rotate-device').css('display', 'none').css('opacity', 0);
  }

  getOrientation(): string {
    return this.deviceOrientation;
  }

  onOrientationChange2(position): void {}

  readDeviceOrientation(): void {
    // tslint:disable-next-line: deprecation
    if (window.orientation === 90 || window.orientation === -90) {
      // Landscape
      this.iosOrientation = 'landscape';
      this.deviceOrientation = 'landscape';

      this.emitEvent(new RotateDeviceEvent(this.GOHORIZONTAL, ''));
    } else {
      // Portrait
      this.iosOrientation = 'portrait';
      this.deviceOrientation = 'portrait';
      this.emitEvent(new RotateDeviceEvent(this.GOVERTICAL, ''));
    }
    if (this.destination === this.iosOrientation) {
      if (this.currentResolve) this.currentResolve(true);
      gsap.to('.rotate-device', {
        duration: 0,
        opacity: 0,
        onComplete: () => {
          $('.rotate-device').css('display', 'none');
        },
      });

      this.destination = '';
    }
    if (this.appConfig.DEBUG) {
      console.log(
        'RotateDeviceService:readDeviceOrientation %s',
        this.iosOrientation
      );
    }
  }

  onOrientationChange(state): void {
    if (this.appConfig.DEBUG) {
      console.log('RotateDeviceService:onOrientationChange %s', state);
    }
    if (this.destination === state) {
      if (this.currentResolve) this.currentResolve(true);
      gsap.to('.rotate-device', {
        duration: 0,
        opacity: 0,
        onComplete: () => {
          $('.rotate-device').css('display', 'none');
        },
      });

      this.destination = '';
    }
  }

  switchToHorizontal(action = ''): any {
    this.destination = 'landscape';
    this.emitEvent(new RotateDeviceEvent(this.SETACTION, action));
    const promise = new Promise((resolve) => {
      let orientation = '';
      if (this.iOS) {
        orientation = this.iosOrientation;
      } else {
        if (this.orientation) {
          orientation = this.orientation.state;
        }
      }
      if (orientation === this.destination) {
        $('.rotate-device').css('display', 'none');
        resolve(true);
      } else {
        $('.rotate-device').css('display', 'flex');
        this.currentResolve = resolve;
        gsap.to('.rotate-device', {
          duration: 0.6,
          opacity: 1,
          ease: this.appConfig.tweenFunc,
        });
        $('.rotate-device').animate({ scrollTop: 0 }, '300');
      }
    });

    return promise;
  }

  switchToVertical(action = ''): any {
    this.destination = 'portrait';
    this.emitEvent(new RotateDeviceEvent(this.SETACTION, action));

    const promise = new Promise((resolve) => {
      if (this.deviceOrientation === 'portrait') {
        resolve(true);
      } else {
        let orientation = '';
        if (this.iOS) {
          orientation = this.iosOrientation;
        } else {
          if (this.orientation) {
            orientation = this.orientation.state;
          }
        }
        if (orientation === this.destination) {
          $('.rotate-device').css('display', 'none');
          resolve(true);
        } else {
          $('.rotate-device').css('display', 'flex');
          this.currentResolve = resolve;
          gsap.to('.rotate-device', {
            duration: 0.6,
            opacity: 1,
            ease: this.appConfig.tweenFunc,
          });
          $('.rotate-device').animate({ scrollTop: 0 }, '300');
        }
      }
    });

    return promise;
  }
}
