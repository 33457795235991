import {
  Directive,
  ElementRef,
  EventEmitter,
  OnDestroy,
  Output,
} from '@angular/core';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[domChange]',
})
export class DomChangeDirective implements OnDestroy {
  private changes: MutationObserver;

  @Output()
  public domChange = new EventEmitter();

  constructor(private elementRef: ElementRef) {
    const element = this.elementRef.nativeElement;

    this.changes = new MutationObserver((mutations: MutationRecord[]) => {
      mutations.forEach((mutation: MutationRecord) => {
        // console.log('MutationRecord: ' + mutation.type);
        // console.log(
        //   'MutationRecord: ' + JSON.parse(JSON.stringify(mutation.target))
        // );
        // console.log('MutationRecord: ' + mutation.addedNodes);
        this.domChange.emit(mutation);
      });
    });

    this.changes.observe(element, {
      attributes: false,
      childList: true,
      characterData: false,
    });
  }

  ngOnDestroy(): void {
    this.changes.disconnect();
  }
}
