import { Component, OnInit, OnDestroy } from '@angular/core';
import gsap from 'gsap';
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin';
import { RoughEase } from 'gsap/EasePack';
import * as $ from 'jquery';
import { AnswersType, Answer } from 'src/app/model/answers-type';
import { Combo } from 'src/app/model/combo';

import { AppConfigService } from 'src/app/services/config/app-config.service';
import { LoadService } from 'src/app/services/game/load.service';
import { QuizService } from 'src/app/services/game/quiz.service';
import { SoundService } from 'src/app/services/sound/sound.service';
import { BonusService } from 'src/app/services/view/bonus.service';
import { DashboardService } from 'src/app/services/view/dashboard.service';
import { ProgressService } from 'src/app/services/game/progress.service';
import { UtilsService } from 'src/app/services/utilities/utils.service';
import { ComboService } from 'src/app/services/game/combo.service';
import {
  CursorEvent,
  CursorService,
} from 'src/app/services/view/cursor.service';
import { ButtonService } from 'src/app/services/view/button.service';
// import { GameBonus } from 'src/app/model/game-bonus'
import { GameService } from 'src/app/services/game/game.service';
import { JarvisService } from 'src/app/services/view/jarvis.service';
// import { BlindtestService } from '../../../services/game/blindtest.service'

export class BonusModule {
  bonusModuleSeparator1: Element;
  bonusModuleSeparator2: Element;
}

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  constructor(
    private appConfig: AppConfigService,
    private soundService: SoundService,
    private quizService: QuizService,
    // private blindtestService: BlindtestService,
    private dashboardService: DashboardService,
    private gameService: GameService,
    private progressService: ProgressService,
    private comboService: ComboService,
    private loadService: LoadService,
    private jarvisService: JarvisService,
    private utils: UtilsService,
    private bonusService: BonusService,
    private cursorService: CursorService,
    private buttonService: ButtonService
  ) {}
  showHelper = false;
  bonus1 = 'bonus1';
  bonus2 = 'bonus2';
  bonus3 = 'bonus3';
  bonus4 = 'bonus4';
  bonus5 = 'bonus5';
  bonus6 = 'bonus6';
  dashClass = 'dash';
  factor = 0.6;
  playerScore = '0000';
  playerNbRefs = '00';
  playerNbErrors = 0;
  maxRefs = this.appConfig.MAX_REFS;
  bonusModule: BonusModule;
  mobileMode = false;
  onCloseBound;
  rollOverBound;
  rollOutBound;
  enabled = false;
  assetsUrl = this.appConfig.assetsUrl;

  ngOnInit(): void {
    gsap.registerPlugin(DrawSVGPlugin, RoughEase);

    this.dashboardService.emitter$.subscribe((event) =>
      this._eventDispatch(event)
    );
    if (this.utils.md().mobile() !== null) {
      this.mobileMode = true;
    }

    this.onCloseBound = this.back.bind(this);
    this.rollOverBound = this.onRollOver.bind(this);
    this.rollOutBound = this.onRollOut.bind(this);

    setTimeout(() => {
      this._initModules();
    }, 100);
  }

  ngOnDestroy(): void {
    $('#close').off('click', this.onCloseBound);
    $('#dashboardContainer .close-cross').off('mouseover', this.rollOverBound);
    $('#dashboardContainer .close-cross').off('mouseout', this.rollOutBound);
    $('#dashboardContainer').off('click', this.onCloseBound);
  }

  _initModules(): void {
    this.initBonus();
    this._initButtonsEvent();
    this.hideDashboard();
  }

  _initButtonsEvent(): void {
    $('#close').on('click', this.onCloseBound);
    $('#dashboardContainer .close-cross').on('mouseover', this.rollOverBound);
    $('#dashboardContainer .close-cross').on('mouseout', this.rollOutBound);
    $('#dashboardContainer').on('click', this.onCloseBound);
  }

  private initBonus(): void {
    const loadedGame = this.loadService.getLoadedGame();
    if (loadedGame !== undefined) {
      const answerType = new AnswersType();
      let answer: Answer;
      const quiz = {};
      const bonusPosition = {};
      this.dashboardService.isReady = false;

      if (this.appConfig.DEBUG) {
        console.log('DashboardComponent:initBonus');
      }
      for (let i = 0; i < loadedGame.answers.length; i++) {
        answer = loadedGame.answers[i];

        if (
          answer.type === answerType.COMBO &&
          answer.is_found === 1 &&
          answer.show_type === 2
        ) {
          const combo = new Combo();
          combo.comboID = answer.comboName;
          combo.position = answer.position;
          bonusPosition[combo.comboID] = combo.position;
          this.bonusService.unlock(combo);
          this.gameService.unlockedBonus++;
          this.comboService.total += 1;
          this.jarvisService.updateNotif();
        }

        if (answer.type === answerType.QUIZZ && answer.is_found === 1) {
          if (answer.badge_id in quiz) {
            quiz[answer.badge_id] += 1;
          } else {
            quiz[answer.badge_id] = 1;
            // this.jarvisService.updateNotif()
          }
          this.comboService.total -= 1;
          if (this.comboService.total < 0) {
            this.comboService.total = 0;
          }
        }
      }
      for (const key in quiz) {
        if (quiz.hasOwnProperty(key)) {
          const score = quiz[key];
          this.quizService.done(key, bonusPosition[key], score);
          this.jarvisService.updateNotif();
        }
      }

      setTimeout(() => {
        for (let index = 1; index <= this.appConfig.MAX_BONUS; index++) {
          this.bonusService.hide(index);
        }
        this.dashboardService.isReady = true;
      }, 3000);
    } else {
      this.dashboardService.isReady = true;
    }
  }
  showDashboard(): void {
    const score = this.progressService.score;
    this.playerNbRefs = this.utils.pad(score[1], 2);
    this.playerScore = this.utils.pad(score[0], 4);
    this.playerNbErrors = this.progressService.getProgress(
      this.progressService.ERROR
    );
    switch (this.playerNbErrors) {
      case 1:
        $('#dashboardContainer .skull1')
          .addClass('life-lost')
          .removeClass('life-ok');
        break;
      case 2:
        $('#dashboardContainer .skull1')
          .addClass('life-lost')
          .removeClass('life-ok');
        $('#dashboardContainer .skull2')
          .addClass('life-lost')
          .removeClass('life-ok');
        break;
      case 3:
        $('#dashboardContainer .skull3')
          .addClass('life-lost')
          .removeClass('life-ok');
        $('#dashboardContainer .skull2')
          .addClass('life-lost')
          .removeClass('life-ok');
        $('#dashboardContainer .skull1')
          .addClass('life-lost')
          .removeClass('life-ok');
        break;
      default:
        break;
    }
    this.cursorService.cross();
    $('#dashboardContainer').css('display', 'flex');
    gsap.to('#dashboardContainer', {
      duration: this.appConfig.tweenSpeed,
      autoAlpha: 1,
      ease: this.appConfig.tweenFunc,
      onStart: () => {
        this.soundService.openDashB();
        gsap.to('.dashboard-title', {
          delay: 0.2,
          duration: this.appConfig.tweenSpeed,
          autoAlpha: 1,
          ease: this.appConfig.tweenFunc,
          onStart: () => {
            // this.animateLines();
          },
        });
        gsap.to('.dashboard-legend', {
          delay: 0.4,
          duration: this.appConfig.tweenSpeed,
          autoAlpha: 1,
          ease: this.appConfig.tweenFunc,
          onStart: () => {
            this._showBonusModule();
          },
        });
      },
      onComplete: () => {},
    });
  }

  hideDashboard(): void {
    gsap.to('#dashboardContainer', {
      duration: 0.3,
      autoAlpha: 0,
      ease: 'power3.out',
      onStart: () => {
        this.soundService.closeDashB();
        // this.hideLines()
      },
      onComplete: () => {
        $('#dashboardContainer').css('display', 'none');
        $('.dashboard-title').css('opacity', 0);
        $('.dashboard-legend').css('opacity', 0);
        this.cursorService.normal();

        for (let index = 1; index < 7; index++) {
          this.bonusService.hide(index);
        }
      },
    });
  }

  _showBonusModule(): void {
    // BONUS MODULE
    for (let index = 1; index < 7; index++) {
      setTimeout(() => {
        this.bonusService.show(index);
      }, 50 * index);
    }

    gsap.to('#close', {
      duration: this.appConfig.tweenSpeed,
      autoAlpha: 1,
      ease:
        // tslint:disable-next-line: quotemark
        "rough({ template: none.out, strength: 2, points: 15, taper: 'none', randomize: true, clamp: true})",
      onComplete: () => {
        $('#close').css('pointer-events', 'all');
      },
    });
  }

  mask(): void {
    gsap.to('.dashboardContent', {
      duration: this.appConfig.tweenSpeed / 2,
      autoAlpha: 0,
      ease: this.appConfig.tweenFunc,
      onComplete: () => {
        $('.dashboardContent').css('display', 'none');
      },
    });
  }
  unmask(): void {
    gsap.to('.dashboardContent', {
      duration: this.appConfig.tweenSpeed / 2,
      autoAlpha: 1,
      ease: this.appConfig.tweenFunc,
      onStart: () => {
        $('.dashboardContent').css('display', 'block');
      },
    });
  }

  private _eventDispatch(event: any): void {
    switch (event.name) {
      case this.dashboardService.SHOW:
        this.showDashboard();
        break;
      case this.dashboardService.HIDE:
        this.hideDashboard();
        break;
      case this.dashboardService.MASK:
        this.mask();
        break;
      case this.dashboardService.UNMASK:
        this.unmask();
        break;
      default:
        break;
    }
  }

  onRollOver(): void {
    this.cursorService.emitEvent(new CursorEvent(this.cursorService.ROLLOVER));
  }

  onRollOut(): void {
    this.cursorService.emitEvent(new CursorEvent(this.cursorService.ROLLOUT));
  }

  back(): void {
    this.dashboardService.hide();
  }
}
