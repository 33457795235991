import { Injectable, EventEmitter } from '@angular/core'

export class CursorEvent {
  constructor(public name: string) {}
}

@Injectable({
  providedIn: 'root'
})
export class CursorService {
  public emitter$: EventEmitter<CursorEvent>

  public NORMAL = 'cursorService:normalMode'
  public SHOW = 'cursorService:show'
  public HIDE = 'cursorService:hide'
  public ROLLOVER = 'cursorService:onRollOver'
  public ROLLOUT = 'cursorService:onRollOut'
  public WAIT = 'cursorService:waitForClickMode'
  public LOADING = 'cursorService:loading'
  public CLOSE = 'cursorService:closemode'

  constructor() {
    this.emitter$ = new EventEmitter()
  }

  public emitEvent(event: CursorEvent): void {
    // console.log('CursorService emitEvent %s', event.name)

    this.emitter$.emit(event)
  }

  normal() {
    this.emitEvent(new CursorEvent(this.NORMAL))
  }
  rollOver() {
    this.emitEvent(new CursorEvent(this.ROLLOVER))
  }
  rollOut() {
    this.emitEvent(new CursorEvent(this.ROLLOUT))
  }
  loading() {
    this.emitEvent(new CursorEvent(this.LOADING))
  }

  hide() {
    this.emitEvent(new CursorEvent(this.ROLLOVER))
  }
  cross() {
    this.emitEvent(new CursorEvent(this.CLOSE))
  }
}
