<div id="pinsContainer">
  <svg id="focus" expanded="true" height="150px" width="150px">
    <circle class="circ" cx="50%" cy="50%" r="5px"></circle>
    <circle class="circ pulse" cx="50%" cy="50%" r="10px"></circle>
  </svg>
  <div id="pins"></div>
  <div id="bg">
    <overlay [keyboard]="showHelper"></overlay>
  </div>
</div>
