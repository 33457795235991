import { Injectable, EventEmitter } from '@angular/core'
import * as THREE from 'three'
import { AppConfigService } from '../config/app-config.service'
import * as $ from 'jquery'
import gsap from 'gsap'

import { UtilsService } from '../utilities/utils.service'
import { Show } from '../../model/show'
import { Focus } from '../../model/focus'
import { Bound } from '../../model/bound'
import { GoogleAnalyticsService } from 'ngx-google-analytics'

export class MouseControlsEvent {
  constructor(
    public name: string,
    public zoom?: number,
    public bound?: Bound
  ) {}
}

@Injectable({
  providedIn: 'root'
})
export class MouseControlsService {
  public emitter$: EventEmitter<MouseControlsEvent>
  CLICK = 'MouseControlsService:click'
  MOUSEUP = 'MouseControlsService:mouseup'
  CHANGE = 'MouseControlsService:change'
  ENABLE = 'MouseControlsService:enable'
  DISABLE = 'MouseControlsService:disable'
  ZOOM = 'MouseControlsService:zoom'
  VIEWBOUNDARIES = 'MouseControlsService:viewboundaries'

  constructor(
    private appConfig: AppConfigService,
    private $gaService: GoogleAnalyticsService,

    private utils: UtilsService
  ) {
    this.emitter$ = new EventEmitter()
  }

  camera: THREE.PerspectiveCamera
  plane = null
  renderer = null
  domElement: Document
  zoomSpeed = 1.2
  panSpeed = 0.6
  screen = { left: 0, top: 0, width: 0, height: 0 }
  eye = new THREE.Vector3()
  target = new THREE.Vector3()
  lastPosition = new THREE.Vector3()
  target0 = this.target.clone()
  zoomTimer = null
  wheelTimer = null
  echoTimer = null
  minDistance = 500
  maxDistance = Infinity
  currentZoom = 0 // percent
  mousePosX = 0
  mousePosY = 0
  tvWidth = 0
  tvHeight = 0
  keys = [65 /*A*/, 83 /*S*/, 68 /*D*/]
  STATE = {
    NONE: -1,
    PAN: 0,
    ZOOM_PAN: 1,
    ROTATE: 2,
    TOUCH_ROTATE: 3,
    TOUCH_ZOOM_PAN: 4
  }
  _enabled = true
  _enabledClick = true
  _enabledZoom = true
  _state = this.STATE.NONE
  _prevState = this.STATE.NONE
  _panMode = false
  _zoomDone = true
  _autoZoom = false
  _mouseDown = false
  _prevPosZ = 0
  _prevZoom = 100
  _EPS = 0.000001
  _simpleClickDetected = false
  mouseZoomDelta = 0
  myMouse = new THREE.Vector2()
  _dollyDelta = 0
  _pedestalDelta = 0
  _mouse = { x: 0, y: 0 }
  _mouseOnDown = { x: 0, y: 0 }
  _autoFocusZoomX = 0
  _autoFocusZoomY = 0
  _pinchDir = 1
  _bound = new Bound()
  bufferZoom = {}
  _autoZoomTimer = null

  eventBounds = {
    contextMenuBound: () => {},
    mouseMoveBound: () => {},
    mouseWheelBound: () => {},
    mouseDownBound: () => {},
    clickBound: () => {},
    mouseUpBound: () => {},
    mouseDoubleClickBound: () => {},
    touchDownBound: () => {},
    pinchBound: () => {},
    pinchEndBound: () => {},
    panBound: () => {},
    panStartBound: () => {},
    panStopBound: () => {}
  }

  init(camera: THREE.PerspectiveCamera, object, renderer): void {
    if (this.appConfig.DEBUG) {
      console.log('MouseControlsService init')
    }
    this.camera = camera
    this.plane = object
    this.renderer = renderer
    this.domElement = document
    this.minDistance = 642
    // this.minDistance = 842
    this.maxDistance = Infinity
    this.currentZoom = 0 // percent
    this.mousePosX = 0
    this.mousePosY = 0
    this._enabled = true
    this._state = this.STATE.NONE
    this._prevState = this.STATE.NONE
    this._panMode = false
    this._zoomDone = true
    this._autoZoom = false
    // _autoFocusZoom = false,
    // _autoFocusPan = false,
    this._autoFocusZoomX = 0
    this._autoFocusZoomY = 0
    this._prevPosZ = 0
    this._prevZoom = 100

    this.tvWidth = this.appConfig.viewWidth
    this.tvHeight = this.appConfig.viewHeight

    this.handleResize()

    this.update()
  }

  stop(): void {
    this.domElement.removeEventListener(
      'contextmenu',
      this.eventBounds.contextMenuBound
    )
    this.domElement.removeEventListener(
      'mousemove',
      this.eventBounds.mouseMoveBound
    )
    this.domElement.removeEventListener(
      'mousewheel',
      this.eventBounds.mouseWheelBound
    )
    this.domElement.removeEventListener(
      'DOMMouseScroll',
      this.eventBounds.mouseWheelBound
    ) // firefox

    this.domElement.removeEventListener(
      'dblclick',
      this.eventBounds.mouseDoubleClickBound
    )
    this.domElement.removeEventListener(
      'mousedown',
      this.eventBounds.mouseDownBound
    )
    this.domElement.removeEventListener(
      'mouseup',
      this.eventBounds.mouseUpBound
    )
    this.domElement.removeEventListener('click', this.eventBounds.clickBound)
  }
  emitEvent(event: MouseControlsEvent): void {
    // console.log('MouseControlsService emitEvent %s', event.name);
    this.emitter$.emit(event)
  }

  enable(): void {
    this._enabled = true
  }
  disable(): void {
    this._enabled = false
  }
  enableClick(): void {
    this._enabledClick = true
  }
  disableClick(): void {
    this._enabledClick = false
  }
  enableZoom(): void {
    this._enabledZoom = true
  }
  disableZoom(): void {
    this._enabledZoom = false
  }

  setMaxDistance(max): void {
    this.maxDistance = max
  }

  getCurrentZoom(): number {
    return this.currentZoom
  }

  deZoom(): void {
    this._state = this.STATE.NONE
    this._panMode = false
    this.target.copy(this.target0)
  }

  isPanMode(): boolean {
    return this._panMode
  }
  handleResize(): void {
    // console.log("handleREsize !!');
    this._state = this.STATE.NONE
    this._panMode = false
    this.target.copy(this.target0)

    // if (this.domElement === document) {
    this.screen.left = 0
    this.screen.top = 0
    this.screen.width = window.innerWidth
    this.screen.height = window.innerHeight
    // } else {
    //   const box = this.domElement.getBoundingClientRect()
    //   // adjustments come from similar code in the jquery offset() function
    //   const d = this.domElement.ownerDocument.documentElement
    //   this.screen.left = box.left + window.pageXOffset - d.clientLeft
    //   this.screen.top = box.top + window.pageYOffset - d.clientTop
    //   this.screen.width = box.width
    //   this.screen.height = box.height
    // }
  }

  update(): void {
    if (this.appConfig.DEBUG) {
      console.log('MouseControlsService:update')
    }

    this._autoPanCamera()

    this.eye.subVectors(this.camera.position, this.target)

    this._zoomCamera()
    // _pinchCamera();

    if (this._panMode && this._zoomDone) {
      this._panCamera()
    }

    // camera.position.addVectors( target, eye );

    // if(_panMode) {
    this._checkDistances()
    this._checkFrustum()
    // }

    this._getObjDimension()

    if (this.lastPosition.distanceToSquared(this.camera.position) > this._EPS) {
      this.lastPosition.copy(this.camera.position)
    } else {
      // $rootScope.$emit('mouseControlsService:endEvent');
    }
  }

  getFocusPosition(show: Show): any {
    const promise = new Promise((resolve, reject) => {
      const pos = this._toScreenPosition(this.plane, this.camera)

      const aspect = this.screen.width / this.screen.height
      const vFOV = (this.camera.fov * Math.PI) / 180
      const height = 2 * Math.tan(vFOV / 2) * this.camera.position.z
      const width = height * aspect

      const fractionH = this.tvHeight / height
      const fractionW = this.tvWidth / width

      const objHeight = this.screen.height * fractionH
      const objWidth = this.screen.width * fractionW

      const top = (objHeight - height) / 2 - (pos.y - height / 2)
      const left = (objWidth - width) / 2 - (pos.x - width / 2)

      const realy =
        (((Number(show.y) * 2) / 3) * objHeight) / this.tvHeight - top
      const realx =
        (((Number(show.x) * 2) / 3) * objWidth) / this.tvWidth - left

      const x = Math.round(realx)
      const y = Math.round(realy)

      const focus = new Focus()
      focus.xpos = x
      focus.ypos = y

      resolve(focus)
    })
    return promise
  }

  _checkFrustum(): void {
    const friction = 2
    const pos = this._toScreenPosition(this.plane, this.camera)
    const aspect = this.screen.width / this.screen.height

    const vFOV = (this.camera.fov * Math.PI) / 180 // convert vertical fov to radians
    const height = 2 * Math.tan(vFOV / 2) * this.camera.position.z // visible height of the object
    const width = height * aspect

    const fractionH = this.tvHeight / height
    const fractionW = this.tvWidth / width

    const objHeight = this.screen.height * fractionH // object real height on screen
    const objWidth = this.screen.width * fractionW

    const top = (objHeight - height) / 2 - (pos.y - height / 2)
    const bottom = pos.y + objHeight / 2 - this.screen.height

    const left = (objWidth - width) / 2 - (pos.x - width / 2)
    const right = pos.x + objWidth / 2 - this.screen.width

    let target = 0

    if (top < 0) {
      target = (this.tvHeight - height) / 2
      this.camera.position.y += (target - this.camera.position.y) / friction
      this.target.y = this.camera.position.y
      if (Math.abs(target - this.camera.position.y) > 10) {
        // $rootScope.$emit('mouseControlsService:outLimit');
        if (this.appConfig.DEBUG) {
          console.log('_checkFrustum : outOfLimit top')
        }
      }
    }

    if (bottom < 0) {
      target = (-1 * (this.tvHeight - height)) / 2
      this.camera.position.y += (target - this.camera.position.y) / friction
      this.target.y = this.camera.position.y
      if (Math.abs(target - this.camera.position.y) > 10) {
        // $rootScope.$emit('mouseControlsService:outLimit');
        if (this.appConfig.DEBUG) {
          console.log('_checkFrustum : outOfLimit bottom')
        }
      }
    }

    if (right < 0) {
      target = (this.tvWidth - width) / 2
      this.camera.position.x += (target - this.camera.position.x) / friction
      this.target.x = this.camera.position.x
      if (Math.abs(target - this.camera.position.x) > 10) {
        // $rootScope.$emit('mouseControlsService:outLimit');
        if (this.appConfig.DEBUG) {
          console.log('_checkFrustum : outOfLimit right')
        }
      }
    }

    if (left < 0) {
      target = (-1 * (this.tvWidth - width)) / 2
      this.camera.position.x += (target - this.camera.position.x) / friction
      this.target.x = this.camera.position.x
      if (Math.abs(target - this.camera.position.x) > 10) {
        // $rootScope.$emit('mouseControlsService:outLimit');
        if (this.appConfig.DEBUG) {
          console.log('_checkFrustum : outOfLimit left')
        }
      }
    }
  }

  _toScreenPosition(obj, camera): any {
    const vector = new THREE.Vector3()
    const widthHalf = 0.5 * this.screen.width
    const heightHalf = 0.5 * this.screen.height

    obj.updateMatrixWorld()
    vector.setFromMatrixPosition(obj.matrixWorld)
    vector.project(camera)

    vector.x = vector.x * widthHalf + widthHalf
    vector.y = -(vector.y * heightHalf) + heightHalf

    return {
      x: vector.x,
      y: vector.y
    }
  }

  _getObjDimension(): void {
    const pos = this._toScreenPosition(this.plane, this.camera)
    const aspect = this.screen.width / this.screen.height
    const vFOV = (this.camera.fov * Math.PI) / 180 // convert vertical fov to radians
    const height = 2 * Math.tan(vFOV / 2) * this.camera.position.z // visible height of the object
    const width = height * aspect
    const fractionH = this.tvHeight / height
    const fractionW = this.tvWidth / width
    const objHeight = this.screen.height * fractionH // object real height on screen
    const objWidth = this.screen.width * fractionW

    const top = (objHeight - height) / 2 - (pos.y - height / 2)
    const bottom = pos.y + objHeight / 2 - this.screen.height

    const left = (objWidth - width) / 2 - (pos.x - width / 2)
    const right = pos.x + objWidth / 2 - this.screen.width

    this._bound.top = -1 * top
    this._bound.bottom = bottom
    this._bound.left = -1 * left
    this._bound.right = right
    this._bound.height = objHeight
    this._bound.width = objWidth
    this._bound.fractionH = fractionH
    this._bound.fractionW = fractionW

    this.emitEvent(new MouseControlsEvent(this.VIEWBOUNDARIES, 0, this._bound))
  }
  _panCamera(): void {
    if (this._dollyDelta) {
      const thisSideWays = this._cameraSideDir(this.camera)
      this.camera.position.addVectors(
        this.camera.position,
        thisSideWays.multiplyScalar(this._dollyDelta)
      )
      this._dollyDelta =
        Math.abs(this._dollyDelta * 0.7) > 0.6 ? this._dollyDelta * 0.7 : 0
    }
    if (this._pedestalDelta) {
      const thisUpWays = this._cameraUpDir(this.camera)
      this.camera.position.addVectors(
        this.camera.position,
        thisUpWays.multiplyScalar(this._pedestalDelta)
      )
      this._pedestalDelta =
        Math.abs(this._pedestalDelta * 0.7) > 0.6
          ? this._pedestalDelta * 0.7
          : 0
    }
  }

  _cameraSideDir(camera): THREE.Vector3 {
    const vector = new THREE.Vector3(-1, 0, 0)
    vector.applyEuler(
      new THREE.Euler(
        camera.rotation.x,
        camera.rotation.y,
        camera.rotation.z,
        camera.rotation.order
      )
    )
    return vector
  }

  _cameraUpDir(camera): THREE.Vector3 {
    const vector = new THREE.Vector3(0, 1, 0)
    vector.applyEuler(
      new THREE.Euler(
        camera.rotation.x,
        camera.rotation.y,
        camera.rotation.z,
        camera.rotation.order
      )
    )
    return vector
  }

  _autoPanCamera(): void {
    if (this._panMode === false) {
      // console.log('_autoPanCamera');
      const aspect = this.screen.width / this.screen.height
      const ratio = this.tvWidth / this.tvHeight
      const fov = this.camera.fov * (Math.PI / 180)
      let friction = 1 // easing friction
      let dist = 0

      // $('html,body,canvas').css('cursor','none');
      // $('#webgl > .overlay').css('opacity', 0);

      if (aspect >= ratio) {
        dist =
          this.tvWidth /
          aspect /
          (2 * Math.tan((this.camera.fov * Math.PI) / 360))
      } else {
        dist = this.tvHeight / (2 * Math.tan((this.camera.fov * Math.PI) / 360))
      }
      this.camera.position.z += (dist - this.camera.position.z) / friction

      const visibleHeight = 2 * Math.tan(fov / 2) * this.camera.position.z
      const visibleWidth = visibleHeight * aspect
      let target = 0

      friction = 8

      if (visibleHeight < this.tvHeight) {
        dist = (this.tvHeight - visibleHeight) / 2
        target = -(dist * this.mousePosY)
        this.camera.position.y += (target - this.camera.position.y) / friction
        this.camera.position.x += (0 - this.camera.position.x) / friction
      }

      if (visibleWidth < this.tvWidth) {
        dist = (this.tvWidth - visibleWidth) / 2
        target = dist * this.mousePosX
        this.camera.position.x += (target - this.camera.position.x) / friction
        this.camera.position.y += (0 - this.camera.position.y) / friction
      }

      this.target.x = this.camera.position.x
      this.target.y = this.camera.position.y

      this.eye.subVectors(this.camera.position, this.target)
      // maxDistance = Math.sqrt(eye.lengthSq());
      this.maxDistance = this.camera.position.z
    }
  }

  _checkDistances(): void {
    if (this.maxDistance === 0) {
      if (this.appConfig.DEBUG) {
        console.log('_checkDistances : maxDistance = 0!')
      }

      return
    }

    if (this.camera.position.z > this.maxDistance) {
      // console.log('_checkDistances : maxDistance');
      this.camera.position.z = this.maxDistance
      this.eye.subVectors(this.camera.position, this.target)
      this._state = this.STATE.NONE
      this._panMode = false
      this._autoZoom = false
      this.mouseZoomDelta = 0
      // $('html,body').css('cursor','default');
      // $rootScope.$emit('cursorService:normalMode');
    }

    if (this.camera.position.z < this.minDistance) {
      // if(appConst.DEBUG_SERVICE) { c
      // console.log('_checkDistances : minDistance');v
      this.camera.position.z = this.minDistance
      this.eye.subVectors(this.camera.position, this.target)
      this.mouseZoomDelta = 0
      this._autoZoom = false
    }
  }

  _zoomCamera(): void {
    const friction = 0.8
    if (this.mouseZoomDelta !== 0) {
      // console.log('MouseControlsService:_zoomCamera ' + this.mouseZoomDelta);

      // calcul du vectur de direction en fonction de la position de la souris
      const vector = new THREE.Vector3(this.myMouse.x, this.myMouse.y, 0.1)
      vector.unproject(this.camera)
      vector.sub(this.camera.position)

      // application du zoom
      this.camera.position.addVectors(
        this.camera.position,
        vector.setLength(this.mouseZoomDelta)
      )

      // deceleration
      this.mouseZoomDelta =
        Math.abs(this.mouseZoomDelta * friction) > 0.1
          ? this.mouseZoomDelta * friction
          : 0
      this._zoomDone = false
    } else {
      this._zoomDone = true
    }

    this._setCurrentZoom()
  }

  _setCurrentZoom(): void {
    // Update zoom widget value
    const val = this.camera.position.z - this.minDistance
    this.currentZoom = Math.round(
      100 - (val * 100) / (this.maxDistance - this.minDistance)
    )
    if (this.currentZoom < 0) {
      this.currentZoom = 0
    }
    if (this.currentZoom !== this._prevZoom) {
      // $rootScope.$emit('mouseControlsService:onZoomUpdate', this.currentZoom);
      // console.log('MouseControlsService:currentZoom: ', this.currentZoom);
      this.emitEvent(new MouseControlsEvent(this.ZOOM, this.currentZoom))

      this._prevZoom = this.currentZoom
    }
  }

  setZoom(direction: string): void {
    if (direction === '-') {
      this._pinchDir = -1
    } else {
      this._pinchDir = 1
    }

    if (this._autoZoomTimer) clearTimeout(this._autoZoomTimer)

    this._autoZoomTimer = setTimeout(() => {
      this.myMouse.x = (window.innerWidth / 2 / window.innerWidth) * 2 - 1
      this.myMouse.y = -(window.innerHeight / 2 / window.innerHeight) * 2 + 1

      this.mouseZoomDelta =
        (this._pinchDir *
          this.appConfig.AUTOZOOM_SPEED *
          this.camera.position.length()) /
        100

      this._simpleClickDetected = false
      if (!this._panMode) {
        // $rootScope.$emit('mouseControlsService:_onMouseWheel');
      }
      this._panMode = true
      this._mouseWheelEcho()

      // $rootScope.$emit('cursorService:panMode');
      $('#webgl > .overlay').css('opacity', 0)
    }, 100)
  }

  initEvents(): void {
    this.eventBounds.contextMenuBound = this._contextMenu.bind(this)
    this.eventBounds.mouseMoveBound = ((event) => {
      this._onMouseMove(event)
    }).bind(this)
    this.eventBounds.mouseWheelBound = ((event) => {
      this._onMouseWheel(event)
    }).bind(this)
    this.eventBounds.clickBound = ((event) => {
      this._onClick(event)
    }).bind(this)
    this.eventBounds.mouseDownBound = ((event) => {
      this._onMouseDown(event)
    }).bind(this)
    this.eventBounds.mouseUpBound = ((event) => {
      this._onMouseUp(event)
    }).bind(this)
    this.eventBounds.mouseDoubleClickBound = ((event) => {
      this._onDoubleClick(event)
    }).bind(this)
    this.eventBounds.pinchBound = ((event) => {
      this._onPinch(event)
    }).bind(this)
    this.eventBounds.pinchEndBound = ((event) => {
      this._onPinchEnd(event)
    }).bind(this)
    this.eventBounds.panBound = ((event) => {
      this._onPan(event)
    }).bind(this)
    this.eventBounds.panStartBound = ((event) => {
      this._onPanStart(event)
    }).bind(this)
    this.eventBounds.panStopBound = ((event) => {
      this._onPanStop(event)
    }).bind(this)
    this.eventBounds.touchDownBound = ((event) => {
      this._onTouchDown(event)
    }).bind(this)

    this.domElement.addEventListener(
      'contextmenu',
      this.eventBounds.contextMenuBound,
      false
    )
    this.domElement.addEventListener(
      'mousemove',
      this.eventBounds.mouseMoveBound,
      false
    )
    this.domElement.addEventListener(
      'mousewheel',
      this.eventBounds.mouseWheelBound,
      false
    )
    this.domElement.addEventListener(
      'DOMMouseScroll',
      this.eventBounds.mouseWheelBound,
      false
    ) // firefox

    this.domElement.addEventListener(
      'dblclick',
      this.eventBounds.mouseDoubleClickBound,
      false
    )
    this.domElement.addEventListener(
      'mousedown',
      this.eventBounds.mouseDownBound,
      false
    )
    this.domElement.addEventListener(
      'mouseup',
      this.eventBounds.mouseUpBound,
      false
    )
    this.domElement.addEventListener(
      'click',
      this.eventBounds.clickBound,
      false
    )
  }

  resetEvents(): void {
    this.domElement.removeEventListener(
      'contextmenu',
      this.eventBounds.contextMenuBound
    )
    this.domElement.removeEventListener(
      'mousemove',
      this.eventBounds.mouseMoveBound
    )
    this.domElement.removeEventListener(
      'mousewheel',
      this.eventBounds.mouseWheelBound
    )
    this.domElement.removeEventListener(
      'DOMMouseScroll',
      this.eventBounds.mouseWheelBound
    ) // firefox

    this.domElement.removeEventListener(
      'dblclick',
      this.eventBounds.mouseDoubleClickBound
    )
    this.domElement.removeEventListener(
      'mousedown',
      this.eventBounds.mouseDownBound
    )
    this.domElement.removeEventListener(
      'mouseup',
      this.eventBounds.mouseUpBound
    )
    this.domElement.removeEventListener('click', this.eventBounds.clickBound)
  }
  _contextMenu(event): void {
    event.preventDefault()
  }

  _onMouseMove(event): void {
    if (!this._enabled) {
      return
    }

    this._mouse.x = -event.pageX
    this._mouse.y = event.pageY
    this.emitEvent(new MouseControlsEvent(this.CHANGE))

    // console.log(
    //   '>>>> DELTA x(%s) y(%s)',
    //   Math.abs(this._mouseOnDown.x - this._mouse.x),
    //   Math.abs(this._mouseOnDown.y - this._mouse.y)
    // );

    if (Math.abs(this._mouseOnDown.x - this._mouse.x) > 5) {
      this._simpleClickDetected = false
    }
    if (Math.abs(this._mouseOnDown.y - this._mouse.y) > 5) {
      this._simpleClickDetected = false
    }

    this.mousePosY = event.pageY
    this.mousePosY -= this.screen.height / 2
    this.mousePosY = this.mousePosY / (this.screen.height / 2)

    this.mousePosX = event.pageX
    this.mousePosX -= this.screen.width / 2
    this.mousePosX = this.mousePosX / (this.screen.width / 2)

    this.myMouse.x = (event.clientX / window.innerWidth) * 2 - 1
    this.myMouse.y = -(event.clientY / window.innerHeight) * 2 + 1

    if (this._state === this.STATE.ZOOM_PAN) {
      this._dollyDelta +=
        ((this._mouseOnDown.x - this._mouse.x) * this.camera.position.z) / 4000
      this._pedestalDelta +=
        ((this._mouse.y - this._mouseOnDown.y) * this.camera.position.z) / 4000
      this._mouseOnDown.x = this._mouse.x // dampen movement
      this._mouseOnDown.y = this._mouse.y
    }
  }

  _onError(duration): void {
    if (this.appConfig.DEBUG) {
      console.log('mouseControlsService:_onError ' + this.currentZoom)
    }
    this._panMode = true
    const delta1 = 300
    const delta2 = -200
    const delay = 200

    // if (this.currentZoom < 30) {
    //   delta1 = 300;
    //   delta2 = -150;
    //   delay = 200;
    // }

    // calcul du vectur de direction en fonction de la position de la souris
    const vector = new THREE.Vector3(0.5, 0.5, -10)
    vector.unproject(this.camera)
    vector.sub(this.camera.position)

    // application du zoom
    this.camera.position.addVectors(
      this.camera.position,
      vector.setLength(delta1)
    )
    setTimeout(() => {
      this.mouseZoomDelta = delta2
    }, delay)
  }

  _onMouseWheel(event): void {
    clearTimeout(this.wheelTimer)

    if (!this._enabled) {
      return
    }
    if (!this._enabledZoom) {
      return
    }

    if (this._mouseDown) {
      return
    }
    // console.log('MouseControlsService:_onMouseWheel !' + this._panMode);

    let delta = 0
    const direction = 1

    if (event.ctrlKey) {
      // Your zoom/scale factor
      event.preventDefault()

      delta = -event.deltaY * 3
    } else {
      // Your trackpad X and Y positions
      if (event.wheelDelta) {
        // WebKit / Opera / Explorer 9
        delta = event.wheelDelta / 8
      } else if (event.detail) {
        // Firefox
        delta = -event.detail * 3
      }
    }

    // if (this.appConfig.DEBUG) {
    //   console.log('mouseControlsService:_onMouseWheel delta ' + delta)
    // }

    this.mouseZoomDelta +=
      (direction * delta * this.camera.position.length()) / 6800
    // this.mouseZoomDelta +=
    //   (direction * delta * this.camera.position.length()) / 4800

    this._simpleClickDetected = false
    if (!this._panMode) {
      // $rootScope.$emit('mouseControlsService:_onMouseWheel');
    }
    this._panMode = true

    // event.preventDefault();
    event.stopPropagation()
    this.emitEvent(new MouseControlsEvent(this.CHANGE))

    // $('html,body,canvas').css('cursor','-webkit-grab');
    // $rootScope.$emit('cursorService:panMode');
    // $('#webgl > .overlay').css('opacity', 0);

    this.wheelTimer = setTimeout(() => {
      this._mouseWheelEcho()
    }, 5)
    clearTimeout(this.zoomTimer)
    this.zoomTimer = setTimeout(() => {
      if (this.appConfig.GA_STATS) {
        this.$gaService.event('Mouse Zoom', 'Events', 'Wheel Zoom ')
      }
      // console.log('stop wheeling!');
      this.zoomTimer = undefined
      this._state = this.STATE.NONE
    }, 250)
  }

  _mouseWheelEcho() {
    // if (this.appConfig.DEBUG) {
    //   console.log('mouseControlsService:_mouseWheelEcho')
    // }
    let repeated = 0
    clearInterval(this.echoTimer)
    this.echoTimer = setInterval(() => {
      this.emitEvent(new MouseControlsEvent(this.CHANGE))
      repeated++
      // if (this.appConfig.DEBUG) {
      //   console.log('mouseControlsService:_mouseWheelEcho repeated:' + repeated)
      // }
      if (repeated > 500) {
        clearInterval(this.echoTimer)
      }
    }, 1)
  }
  // _onClick(event): void {
  //   console.log(
  //     'mouseControlsService::_onClick ' +
  //       event.target.id +
  //       ' // ' +
  //       event.button
  //   );
  // }

  _onMouseDown(event): void {
    if (this.appConfig.DEBUG) {
      console.log(
        'mouseControlsService::_onMouseDown ' +
          event.target.id +
          ' // ' +
          event.button
      )
    }

    if (!this._enabled) {
      return
    }
    if (event.button !== 0) {
      return
    } // bouton gauche souris
    // if (
    //   event.target.id !== this.appConfigService.canvasID &&
    //   event.target.id !== 'modalCanvas'
    // ) {
    //   return;
    // }

    if (
      event.target.id !== '' &&
      event.target.id !== 'modalCanvas' &&
      event.target.id !== 'save' &&
      event.target.id !== 'radar' &&
      event.target.id !== 'zoomMinus' &&
      event.target.id !== 'zoomPlus' &&
      // event.target.id !== 'luxSaveBtn' &&
      // event.target.id !== 'luxRadarBtn' &&
      event.target.id !== 'legalsBtn' &&
      event.target.id !== 'soundBtn' &&
      event.target.id !== 'creditsBtn' &&
      event.target.id !== 'fullscreenBtn' &&
      event.target.id !== 'profilPic'
    ) {
      this._simpleClickDetected = true

      if (this.appConfig.DEBUG) {
        console.log(
          'mouseControlsService::_onMouseDown  _simpleClickDetected: ' +
            this._simpleClickDetected
        )
      }
    }
    // event.preventDefault();
    // event.stopPropagation();
    this.emitEvent(new MouseControlsEvent(this.CHANGE))

    this._prevState = this._state
    this._prevPosZ = this.camera.position.z

    if (event.target.id !== 'zoomMinus' && event.target.id !== 'zoomPlus') {
      if (this._panMode) {
        this._state = this.STATE.ZOOM_PAN
        const backZ = this._prevPosZ + 25
        gsap.to(this.camera.position, {
          duration: this.appConfig.tweenSpeed / 4,
          // duration: 0,
          z: backZ,
          ease: this.appConfig.tweenFunc,
          onUpdate: () => {
            this.emitEvent(new MouseControlsEvent(this.CHANGE))
          },
          onComplete: () => {
            this.emitEvent(new MouseControlsEvent(this.CHANGE))
          }
        })
      } else {
        this._state = this.STATE.NONE
      }
    }

    // if(_state === STATE.ZOOM_PAN) {
    this._mouseOnDown.x = -event.pageX
    this._mouseOnDown.y = event.pageY
    // }

    this._mouseDown = true

    // $rootScope.$emit('mouseControlsService:onMouseDown');
  }

  _onMouseUp(event): void {
    if (!this._enabled) {
      return
    }
    if (event.button !== 0) {
      return
    } // bouton gauche souris
    if (this.appConfig.DEBUG) {
      console.log(
        'mouseControlsService::_onMouseUp ' + this._simpleClickDetected
      )
    }

    this._state = this._prevState
    this._mouseDown = false

    gsap.to(this.camera.position, {
      duration: this.appConfig.tweenSpeed / 4,
      // duration: 0,
      z: this._prevPosZ,
      ease: this.appConfig.tweenFunc,
      onUpdate: () => {
        this.emitEvent(new MouseControlsEvent(this.CHANGE))
      },
      onComplete: () => {
        this.emitEvent(new MouseControlsEvent(this.CHANGE))
        this.emitEvent(new MouseControlsEvent(this.MOUSEUP))
      }
    })
  }

  _onClick(event): void {
    if (this.appConfig.DEBUG) {
      console.log('mouseControlsService:_onClick')
    }
    if (this._autoZoom) {
      this._autoZoom = false
    } else {
      if (this._enabledClick && this._simpleClickDetected) {
        if (this.appConfig.DEBUG) {
          console.log('_onClick : mouseControlsService::simpleClick')
        }
        this.emitEvent(new MouseControlsEvent(this.CLICK))
        this.emitEvent(new MouseControlsEvent(this.CHANGE))
      }
    }
  }

  _onDoubleClick(event): void {
    if (!this._enabled) {
      return
    }

    event.preventDefault()
    event.stopPropagation()

    // _panMode = true;
    this._autoZoom = true
    this.mouseZoomDelta = -10
    // $rootScope.$emit('mouseControlsService:outLimit');
  }

  _onTouchDown(event): void {
    if (!this._enabled) {
      return
    }
    // if (
    //   event.target.id !== this.appConfigService.canvasID &&
    //   event.target.id !== 'modalCanvas'
    // ) {
    //   return;
    // }
    if (this.appConfig.DEBUG) {
      console.log('mouseControlsService::_onTouchDown ' + event.target.id)
    }
    this._simpleClickDetected = true
    // event.preventDefault();
    // event.stopPropagation();

    if (this.appConfig.DEBUG) {
      console.log('_onTouchDown : mouseControlsService::simpleClick')
    }

    // $rootScope.$emit('mouseControlsService:simpleClick');

    this._prevState = this._state
    this._prevPosZ = this.camera.position.z

    if (this._panMode) {
      this._state = this.STATE.ZOOM_PAN
      const backZ = this._prevPosZ + 25
      gsap.to(this.camera.position, {
        duration: this.appConfig.tweenSpeed,
        z: backZ,
        ease: this.appConfig.tweenFunc
      })
    } else {
      this._state = this.STATE.NONE
    }

    // if(_state === STATE.ZOOM_PAN) {
    this._mouseOnDown.x = -event.pageX
    this._mouseOnDown.y = event.pageY
    // }

    // $rootScope.$emit('mouseControlsService:onMouseDown');
  }

  _onPinch(event): void {
    if (!this._enabled) {
      return
    }
    if (this.appConfig.DEBUG) {
      console.log(
        'mouseControlsService::_onPinch ' + event.type + ' / ' + event.scale
      )
    }

    if (event.type === 'pinchin') {
      this._pinchDir = -1
    } else {
      this._pinchDir = 1
    }

    this.myMouse.x = (event.center.x / window.innerWidth) * 2 - 1
    this.myMouse.y = -(event.center.y / window.innerHeight) * 2 + 1

    if (this._pinchDir > 0) {
      this.mouseZoomDelta =
        (this._pinchDir * event.scale * this.camera.position.length()) / 100
    } else {
      this.mouseZoomDelta =
        ((this._pinchDir / event.scale) * this.camera.position.length()) / 100
    }

    this._simpleClickDetected = false
    if (!this._panMode) {
      // $rootScope.$emit('mouseControlsService:_onMouseWheel');
    }

    this._panMode = true

    $('#webgl > .overlay').css('opacity', 0)
  }

  _onPinchEnd(event): void {
    if (this.appConfig.DEBUG) {
      console.log('Gesture ' + event.type + ' / ' + event.scale)
    }
    this.mouseZoomDelta = 0
  }

  _onPanStart(event): void {
    if (!this._enabled) {
      return
    }
    event.preventDefault()
    // event.stopPropagation();

    if (this.appConfig.DEBUG) {
      console.log('Gesture ' + event.type + ' / ' + event.scale)
    }

    this._prevState = this._state
    this._prevPosZ = this.camera.position.z

    if (this._panMode) {
      this._state = this.STATE.ZOOM_PAN
    } else {
      this._state = this.STATE.NONE
    }
    this._dollyDelta = 0
    this._pedestalDelta = 0

    if (this._state === this.STATE.ZOOM_PAN) {
      this._mouseOnDown.x = -event.center.x
      this._mouseOnDown.y = event.center.y
    }
  }

  _onPan(event): void {
    if (!this._enabled) {
      return
    }
    // if(appConst.DEBUG_SERVICE) { console.log("mouseControlsService::_onPan: center(%s, %s)",event.center.x,event.center.y);}
    // if(appConst.DEBUG_SERVICE) { console.log("mouseControlsService::_onPan: delta(%s, %s)",event.deltaX,event.deltaY);}

    this._mouse.x = -event.center.x
    this._mouse.y = event.center.y

    if (this._state === this.STATE.ZOOM_PAN) {
      this._dollyDelta =
        ((this._mouseOnDown.x - this._mouse.x) * this.camera.position.z) / 1000
      this._pedestalDelta =
        ((this._mouse.y - this._mouseOnDown.y) * this.camera.position.z) / 1000
      this._mouseOnDown.x = this._mouse.x // dampen movement
      this._mouseOnDown.y = this._mouse.y
    }
  }
  _onPanStop(e): void {
    if (!this._enabled) {
      return
    }

    this._state = this._prevState

    // $rootScope.$emit('cursorService:panMode');

    // $rootScope.$emit('mouseControlsService:onMouseUp');
  }
}
