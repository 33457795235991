import { EventEmitter, Injectable } from '@angular/core';
import { ProgressService } from './progress.service';
import { AppConfigService } from 'src/app/services/config/app-config.service';

export class TutosEvent {
  constructor(public name: string, public id?: number, public resolve?: any) {}
}

@Injectable({
  providedIn: 'root',
})
export class TutosService {
  public emitter$: EventEmitter<TutosEvent>;

  public SHOW = 'TutosService:SHOW';
  public HIDE = 'TutosService:HIDE';

  public TUTO_POINTS = 1;
  public TUTO_ERROR = 2;
  public TUTO_RADAR = 3;
  public TUTO_SAVE = 4;
  public TUTO_QUIZ = 5;
  public TUTO_ZOOM = 6;

  public NBTUTO = 6;

  tutoShown = [];

  constructor(
    private progressService: ProgressService,
    private appConfig: AppConfigService
  ) {
    this.emitter$ = new EventEmitter();
  }

  public emitEvent(event: TutosEvent): void {
    if (this.appConfig.DEBUG) {
      console.log('TutosService emitEvent %s', event.name);
    }
    this.emitter$.emit(event);
  }

  show(id: number, resolve?: any): void {
    this.emitEvent(new TutosEvent(this.SHOW, id, resolve));
  }
  hide(): void {
    this.emitEvent(new TutosEvent(this.HIDE));
  }

  showTuto(id): any {
    const promise = new Promise((resolve, reject) => {
      this.show(id, resolve);
    });

    return promise;
  }

  checkTuto(): any {
    const promise = new Promise((resolve, reject) => {
      const nbRef = this.progressService.getProgress(this.progressService.REFS);
      const nbError = this.progressService.getProgress(
        this.progressService.ERROR
      );
      if (nbError === 1) {
        this.show(2, resolve);
      } else if (nbRef === 1) {
        this.show(1, resolve);
      } else {
        resolve(true);
      }
    });

    return promise;
  }

  addTuto(id: number): void {
    if (id !== this.TUTO_RADAR && id !== this.TUTO_SAVE) {
      this.tutoShown.push(id);
    }
  }

  shown(id: number): boolean {
    if (this.tutoShown.indexOf(id) === -1) {
      return false;
    } else {
      return true;
    }
  }

  setShown(id: number): void {
    this.tutoShown.push(id);
  }
}
