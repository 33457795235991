export class Bound {
  top: number;
  bottom: number;
  left: number;
  right: number;
  height: number;
  width: number;
  fractionH: number;
  fractionW: number;
}
