import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ShareService {
  constructor() {}

  giphyURLs: Array<string> = [
    'fx3ikUbHF6lP1JpHwJ',
    'ClKYdg6ZpwtwO77LPg',
    'lycwg3yTDiwQXZqvP1',
    '8knMh1ajimuLwl4NuG',
    'WI2j61yFNUqzc1cnIu',
    'j9SAc24T3MOCuV5TyM',
    'F8f0CNJQLEnImGXze9',
    's0z8NG7HBePN2sderO',
    'HTupY2GaOwgqrJoe63',
    'YQRh9cqCpC4USmz4i8',
    '0nazBrGQLCl8ZyqJEv',
    'oKrTTVRhE8xEA9WpOa',
    'qd32QI47nMufKpRpyo',
    'ITMWoBi5QVzHp7ASkx',
    'kRpmTov1qwH4Q1PNkk',
    'r1CgamQw1p7TM73odR',
    'PUzoHMC5Dfs6yooAnc',
    'PYbXiCkBvByS0r1Srh',
    'kXTunL8Ioq1XQPhgZY',
    'qbwUpapyqwetymf219',
    'uVPStfnBwratmxzyoD',
    'QhaoNYxeQ4QjSsOIC9',
    '4KhQIbhSUwQJpFgtui',
    'rnelaDZP6EO2XPkYQL',
    'yaQ1mjXwMmhCYQkJfl',
    'kfJOuvdAH80zmda8Re',
    'EPSEiFKNNPdcx23AqV',
    'sJEIqK9IAyOOYA8q4v',
    'Iu3RAKEmg0nXiBwWJj',
    'DjvoGojKASXo0ie7J2',
    'UhNh7qFSfHnPTcrfeD',
    'MSCNQCKbgemqBh7VgQ',
    'n3cpanK6OL6Eqv9dc9',
    'oYuwUkq7h5ThYgfUkd',
    'CuNrD7RqRzHDfkIt96',
    '7ikuox090E976NWb8I',
    'xToRToLjEB6zJhuTUE',
    'TC1GxJp3CnmUSRdIvR',
    'TjGxZF8akfgoFzSBlQ',
    'mbulH3LnCP4PuiaENS',
    'aTHhi8NT5RDelMah2G',
    'PzgkVJCDiAEQl7PKMM',
    'LtbnMRocSKaISq2dcF',
    'KUh5wZKjwVqRUkDKJH',
    'hTY8ioFLuFdGxsbOKM',
    'NAeA7UqhaXWuSo8Myj',
    'IzR9hXl55uDbcdNwQt',
    'hoZV8NmxeGIa8Gh1jN',
    'ZpPph3JF8Kwb6hUBtF',
    'zsgzrSSP7BrZpeBmJZ',
    'Ddw8jObHAA2SERLDSs',
    'WSQWmzkeKQxuA44PcN',
    'RsONfrnVzo3WF4mN4k',
    'aBBMFyZz5Ufk1GHsi7',
    'ea4Q66Gd74wJAyEkA9',
    'YiCyaoqfcfYMGbzwIE',
    'kXLSmzy5jnDO1dUGUL',
    'X2NwNjxmvTrEN6HmSr',
    'XH0OAcLaICyZ8IgV2A',
    'iUNCFEli9N9Tq62tSD',
    'sZRHMMzsVlrUQNdEjQ',
    'l71ikBrkDvbqQDDPjS',
    'xu22bxTLktNOQ067HC',
    'Ud2vw2habisDcuRNQu',
    'hrU49EYnSwtUKx9vYe',
    'D8cmuNktzmlyItMUE1',
    'CmWfolb9nSNwzDPN10',
  ];
  getGifURL(score): string {
    // return 'https://gph.is/g/aNV91WG';
    // score = 0;
    return (
      'https://giphy.com/gifs/redbullplayhard-popcorn66-' +
      this.giphyURLs[score]
    );
  }
}
