export class AnswersType {
  ARTIST = 'artists';
  QUIZZ = 'quiz';
  BLINDTEST = 'blindtest';
  COMBO = 'combo';
}

export class Answer {
  id: number;
  hash: string;
  title: string;
  comboName: string;
  comboType: number;
  position: number;
  type: string;
  // tslint:disable-next-line: variable-name
  show_type: number;
  x: string;
  y: string;
  level: string;
  flag: number;
  multiplier: number;
  // tslint:disable-next-line: variable-name
  badge_id: string;
  time: string;
  // tslint:disable-next-line: variable-name
  is_found: number;
  // tslint:disable-next-line: variable-name
  created_at: string;
  // tslint:disable-next-line: variable-name
  updated_at: string;
}
