import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import * as $ from 'jquery';
import gsap from 'gsap';
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin';
import { SVG, Element, Text, Svg, Dom } from '@svgdotjs/svg.js';
import { AppConfigService } from 'src/app/services/config/app-config.service';
import { ComboEvent, ComboService } from 'src/app/services/game/combo.service';
import { GameService } from 'src/app/services/game/game.service';
import { ProgressService } from 'src/app/services/game/progress.service';
import { SaveService } from 'src/app/services/game/save.service';
import { TutosEvent, TutosService } from 'src/app/services/game/tutos.service';
import {
  AuthenticationService,
  AuthEvent,
} from 'src/app/services/http/authentication.service';
import { SoundService } from 'src/app/services/sound/sound.service';
import {
  TimerEvent,
  TimerService,
} from 'src/app/services/utilities/timer.service';
import { UtilsService } from 'src/app/services/utilities/utils.service';
import {
  ButtonEvent,
  ButtonService,
} from 'src/app/services/view/button.service';
import {
  JarvisEvent,
  JarvisService,
} from 'src/app/services/view/jarvis.service';
import {
  MouseControlsEvent,
  MouseControlsService,
} from 'src/app/services/view/mouse-controls.service';
import { PinsService } from 'src/app/services/view/pins.service';
import { Combo } from 'src/app/model/combo';
import { Subscription } from 'rxjs';
import {
  CursorEvent,
  CursorService,
} from 'src/app/services/view/cursor.service';
import { DashboardService } from 'src/app/services/view/dashboard.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
@Component({
  selector: 'lux',
  templateUrl: './lux.component.html',
  styleUrls: ['./lux.component.scss'],
})
export class LuxComponent implements OnInit, OnDestroy, AfterViewInit {
  constructor(
    private jarvisService: JarvisService,
    private utils: UtilsService,
    private buttonService: ButtonService,
    private mouseControlsService: MouseControlsService,
    private cursorService: CursorService,
    private dashboardService: DashboardService,
    private soundService: SoundService,
    private pinsService: PinsService,
    private timerService: TimerService,
    private authService: AuthenticationService,
    private saveService: SaveService,
    private gameService: GameService,
    private comboService: ComboService,
    private progressService: ProgressService,
    private tutosService: TutosService,
    private $gaService: GoogleAnalyticsService,

    private appConfig: AppConfigService
  ) {}

  innerWidth: number;
  innerHeight: number;
  centerX: number;
  centerY: number;
  saveBtn;
  radarBtn;
  profileBtn;
  nbError = 0;
  showConnect = false;
  pulseTween;
  enableMode = false;
  quizCount = 0;
  scaler = 1;
  uiReady = false;
  mobileMode = false;
  nbrefs = '00';
  score = '0000';
  delta = { x: 200, y: 120 };
  chrono = '00';
  bonusType = '';
  assetsUrl = this.appConfig.assetsUrl;

  jarvisSub: Subscription;
  authSub: Subscription;
  comboSub: Subscription;
  timerSub: Subscription;
  tutoSub: Subscription;

  ngOnInit(): void {
    // this.innerWidth = this.utils.getViewportSize().width;
    // this.innerHeight = this.utils.getViewportSize().height;
    // this.centerX = this.innerWidth / 2;
    // this.centerY = this.innerHeight / 2;
    if (this.utils.md().mobile() !== null) {
      this.mobileMode = true;
      // $('#lux').css('height', this.innerHeight + 'px');
    }

    this.jarvisSub = this.jarvisService.emitter$.subscribe(
      (event: JarvisEvent) => this._eventDispatch(event)
    );
    this.authSub = this.authService.emitter$.subscribe((event: AuthEvent) =>
      this._eventDispatch(event)
    );

    this.comboSub = this.comboService.emitter$.subscribe((event: ComboEvent) =>
      this._eventDispatch(event)
    );
    this.timerSub = this.timerService.emitter$.subscribe((event: TimerEvent) =>
      this._eventDispatch(event)
    );
    this.tutoSub = this.tutosService.emitter$.subscribe((event: TutosEvent) =>
      this._eventDispatch(event)
    );

    $(() => {
      this._onResize();

      $(window).on('resize', () => {
        this._onResize();
      });
    });
    this.fillUiWithGameData();

    this.timerService.init();
  }

  ngAfterViewInit(): void {
    this.saveBtn = SVG(document.getElementById('save'));
    this.saveBtn.mouseover(() => {
      this.onRollOverButton();
    });
    this.saveBtn.mouseout(() => {
      this.onRollOutButton();
    });
    this.saveBtn.click((event) => {
      event.preventDefault();
      // event.stopPropagation()

      if (!this.comboService.isActive)
        if (this.enableMode && this.uiReady) {
          this.saveService.saveGame();
        }
    });

    this.radarBtn = SVG(document.getElementById('radar'));

    this.radarBtn.on('mouseover', () => {
      this.onRollOverButton();
    });
    this.radarBtn.on('mouseout', () => {
      this.onRollOutButton();
    });
    this.radarBtn.click((event) => {
      event.preventDefault();
      // event.stopPropagation()

      if (!this.comboService.isActive)
        if (this.enableMode && this.uiReady) {
          this.pinsService.showPins();
        }
    });

    // this.profileBtn = SVG(document.getElementById('profilPic'));
    // this.profileBtn.on('mouseover', () => {
    //   this.onRollOverButton();
    // });
    // this.profileBtn.on('mouseout', () => {
    //   this.onRollOutButton();
    // });

    // this.profileBtn.click((event) => {
    //   event.preventDefault();
    //   // event.stopPropagation()
    //   if (this.appConfig.DEBUG) {
    //     console.log('LuxComponent.profileBtn click');
    //   }
    //   if (!this.comboService.isActive)
    //     if (this.enableMode && this.uiReady) {
    //       if (this.dashboardService.isReady) this.dashboardService.show();
    //     }
    // });

    $('.zoom-button').on('mouseover', () => {
      this.onRollOverButton();
    });
    $('.zoom-button').on('mouseout', () => {
      this.onRollOutButton();
    });
  }

  ngOnDestroy(): void {
    this.jarvisSub.unsubscribe();
    this.authSub.unsubscribe();
    this.comboSub.unsubscribe();
    this.timerSub.unsubscribe();
    this.tutoSub.unsubscribe();
  }

  zoom(event, direction) {
    event.preventDefault();
    event.stopPropagation();
    if (this.appConfig.DEBUG) {
      console.log('LuxComponent zoom click');
    }
    if (this.appConfig.GA_STATS) {
      this.$gaService.event('Widget Zoom', 'Events', 'Zoom ' + direction);
    }
    this.mouseControlsService.setZoom(direction);
  }

  _eventDispatch(event: any): any {
    if (this.appConfig.DEBUG) {
      console.log('LuxComponent._eventDispatch : ' + event.name);
    }
    switch (event.name) {
      case this.jarvisService.BOOT:
        setTimeout(() => {
          this.boot().then((res) => {
            if (this.appConfig.DEBUG) {
              console.log(res);
            }
            this.jarvisService.emitEvent(
              new JarvisEvent(this.jarvisService.BOOTSUCCESS)
            );
            setTimeout(() => {
              this.animateUI().then((res) => {
                if (this.appConfig.DEBUG) {
                  console.log(res);
                }
                this.jarvisService.emitEvent(
                  new JarvisEvent(this.jarvisService.UIREADY)
                );
                this.uiReady = true;
              });
            }, 500);
          });
        }, 500);

        break;
      case this.jarvisService.SHUTDOWN:
        this.shutdown(event.missionStatus).then((res) => {
          if (this.appConfig.DEBUG) {
            console.log(res);
          }
          this.jarvisService.emitEvent(
            new JarvisEvent(this.jarvisService.SHUTDOWNSUCESS)
          );
        });
        break;
      case this.jarvisService.BUILDUI:
        // this.animateUI().then((res) => {
        //   if (this.appConfig.DEBUG) {
        //     console.log(res)
        //   }
        //   this.jarvisService.emitEvent(
        //     new JarvisEvent(this.jarvisService.UIREADY)
        //   )
        //   this.uiReady = true
        // })

        break;
      case this.jarvisService.UPDATESCORE:
        this.updateScorePanel(event.score).then((res) => {
          if (this.appConfig.DEBUG) {
            console.log(res);
          }
        });
        break;
      case this.jarvisService.UPDATEERROR:
        this.updateErrorPanel().then((res) => {
          if (this.appConfig.DEBUG) {
            console.log(res);
          }
        });
        break;
      case this.jarvisService.CONNECTED:
        // this.pulseConnected();
        break;
      case this.jarvisService.SHOWCHRONO:
        this.showChrono();
        break;
      case this.jarvisService.UPDATECHRONO:
        // this.updateChrono();
        break;
      case this.jarvisService.HIDECHRONO:
        this.hideChrono();
        break;
      case this.timerService.CHRONOUPDATE:
        this.updateChrono((event as TimerEvent).value);
        break;
      case this.jarvisService.SHOWUI:
        this.showUI();
        break;
      case this.jarvisService.HIDEUI:
        this.hideUI().then(() => {
          this.jarvisService.emitEvent(
            new JarvisEvent(this.jarvisService.UIHIDDEN)
          );
        });
        break;
      case this.comboService.STARTPOPCORNTIME:
        const bonus = (event as ComboEvent).comboList[0];
        this.enableMode = false;
        this.bonusModeOn(bonus);
        break;
      case this.comboService.ENDPOPCORNTIME:
        this.bonusModeOff();
        this.enableMode = true;
        break;
      case this.jarvisService.ENABLEBUTTONS:
        this.enableMode = true;
        break;
      case this.jarvisService.DISABLEBUTTONS:
        this.enableMode = false;
        break;
      case this.jarvisService.UPDATENOTIF:
        this.updateNotif();
        break;
      case this.jarvisService.ERROR:
        this.showError();
        break;
      case this.jarvisService.BLUR:
        $('#lux').addClass('blur');
        break;
      case this.jarvisService.UNBLUR:
        $('#lux').removeClass('blur');
        break;
      default:
        break;
    }
  }

  _onResize(now = false): void {}

  boot(): any {
    if (this.appConfig.DEBUG) {
      console.log('LuxComponent.boot');
    }

    const promise = new Promise((resolve) => {
      // this.timerService.start();
      this._onResize();

      resolve('LUX just booted');
    });
    return promise;
  }

  shutdown(missionStatus: string): any {
    const promise = new Promise((resolve) => {
      resolve('LUX just shutdown');
    });
    return promise;
  }

  animateUI(): any {
    // tslint:disable-next-line: no-shadowed-variable
    const promise = new Promise((resolve) => {
      this._onResize();

      if (this.progressService.errorCount > 0) {
        for (let i = 0; i < this.progressService.errorCount; i++) {
          this.updateErrorPanel();
        }
      }
      gsap.to('.lux-bloc-score', {
        duration: 0,
        y: this.delta.y,
        x: this.delta.x,
        ease: 'expo.out',
      });
      gsap.to('.lux-bloc-score', {
        duration: 0.8,
        opacity: 1,
        y: 0,
        x: 0,
        ease: 'expo.out',
        onComplete: () => {
          resolve('JARVIS UI ready');
        },
      });
      gsap.to('#lux .logo_rap_jeu', {
        duration: 0,
        y: this.delta.y,
        x: -this.delta.x,
        ease: 'expo.out',
      });
      gsap.to('#lux .logo_rap_jeu', {
        duration: 0.8,
        opacity: 1,
        y: 0,
        x: 0,
        ease: 'expo.out',
      });
      gsap.to('#lux #saveBtn', {
        duration: 0,
        y: -this.delta.y,
        x: -this.delta.x,
        ease: 'expo.out',
      });
      gsap.to('#lux #saveBtn', {
        duration: 0.8,
        opacity: 1,
        y: 0,
        x: 0,
        ease: 'expo.out',
      });
      gsap.to('#lux #radarBtn', {
        duration: 0,
        y: -this.delta.y,
        x: this.delta.x,
        ease: 'expo.out',
      });
      gsap.to('#lux #radarBtn', {
        duration: 0.8,
        opacity: 1,
        y: 0,
        x: 0,
        ease: 'expo.out',
      });
      if (!this.mobileMode) {
        gsap.to('#zoomWidget', {
          duration: 0,
          opacity: 0,
          y: -this.delta.y,
          ease: 'expo.out',
          onStart: () => {
            $('#zoomWidget').css('display', 'flex');
          },
        });
        gsap.to('#zoomWidget', {
          duration: 0.8,
          opacity: 1,
          y: 0,
          x: 0,
          ease: 'expo.out',
        });
      }
      // CREATE BADGE
      // const show = new Show();
      // show.badge_id = 'starwars';
      // show.title = 'Star Wars';
      // show.flag = 1;
      // show.type = 0;
      // show.lang = 'fr';
      // if (this.autoMode === true) {
      //   $('#profilPic').on('click', () => {
      //     // this.showFocus(show);
      //     this.badgeService.showBadge(show);
      //   });
      // }
    });

    return promise;
  }

  _buildInfoPanel(): any {
    // tslint:disable-next-line: no-shadowed-variable
    const promise = new Promise((resolve) => {
      resolve(true);
    });

    return promise;
  }

  updateScorePanel(score: Array<number>): any {
    if (this.appConfig.DEBUG) {
      console.log('LuxComponent:updateScorePanel');
    }

    const promise = new Promise((resolve) => {
      setTimeout(() => {
        this.nbrefs =
          this.utils.pad(score[1], 2) + '/' + this.appConfig.MAX_REFS;
        this.score = this.utils.pad(score[0], 4);
        resolve('LuxComponent score updated');
      }, 1);
    });

    return promise;
  }

  updateErrorPanel(): any {
    if (this.appConfig.DEBUG) {
      console.log('LuxComponent:updateErrorPanel');
    }
    const promise = new Promise((resolve) => {
      this.nbError = this.progressService.getProgress(
        this.progressService.ERROR
      );

      switch (this.nbError) {
        case 1:
          $('#lux .skull1').addClass('life-lost').removeClass('life-ok');
          break;
        case 2:
          $('#lux .skull1').addClass('life-lost').removeClass('life-ok');
          $('#lux .skull2').addClass('life-lost').removeClass('life-ok');
          break;
        case 3:
          $('#lux .skull3').addClass('life-lost').removeClass('life-ok');
          $('#lux .skull2').addClass('life-lost').removeClass('life-ok');
          $('#lux .skull1').addClass('life-lost').removeClass('life-ok');
          break;
        default:
          break;
      }

      resolve('LuxComponent error updated');
    });

    return promise;
  }

  showError(): void {
    if (this.appConfig.DEBUG) {
      console.log('LuxComponent:showError');
    }
  }

  showUI(): void {
    gsap.to('#lux', {
      duration: 0.6,
      ease: 'power3.out',
      autoAlpha: 1,
      // onStart: () => {
      //   $('#lux').css('display', 'flex');
      // },
    });
    if (!this.mobileMode) {
      gsap.to('#zoomWidget', {
        duration: 0.6,
        ease: 'power3.out',
        autoAlpha: 1,
        onStart: () => {
          $('#zoomWidget').css('display', 'flex');
        },
      });
    }

    // if (this.comboService.isActive) {
    //   gsap.to('.bonus-frame', {
    //     duration: 0.6,
    //     ease: 'power3.out',
    //     opacity: 1
    //   })
    // }
  }

  hideUI(): any {
    // tslint:disable-next-line: no-shadowed-variable
    const promise = new Promise((resolve) => {
      gsap.to('#lux', {
        duration: 0.6,
        ease: 'power3.out',
        autoAlpha: 0,
        onComplete: () => {
          // $('#lux').css('display', 'none');
          resolve(true);
        },
      });
      if (!this.mobileMode) {
        gsap.to('#zoomWidget', {
          duration: 0.6,
          ease: 'power3.out',
          autoAlpha: 0,
          onComplete: () => {
            $('#zoomWidget').css('display', 'none');
          },
        });
      }
      // if (this.comboService.isActive) {
      // gsap.to('.bonus-frame', {
      //   duration: 0.6,
      //   ease: 'power3.out',
      //   opacity: 0
      // })
      // }
    });

    return promise;
  }

  bonusModeOn(bonus: Combo): void {
    if (this.appConfig.DEBUG) {
      console.log('LuxComponent.bonusModeOn : ' + bonus.comboName);
    }

    this.chrono = this.utils.pad(bonus.duration, 2);
    $('.bonus-frame').addClass('x' + bonus.multiplier);
    if (bonus.multiplier > 1) {
      this.bonusType = 'POPCORN x' + bonus.multiplier;
    } else {
      this.bonusType = 'GOD MODE';
    }

    // switch (bonus.multiplier) {
    //   case 1:
    //     this.bonusFrame.fill('#fd0165')
    //     this.bonusMode.fill('#fff')
    //     break
    //   case 2:
    //     this.bonusFrame.fill('#75effa')
    //     this.bonusMode.fill('#000')
    //     break
    //   case 3:
    //     this.bonusFrame.fill('#ffff00')
    //     this.bonusMode.fill('#000')
    //     break
    //   case 4:
    //     this.bonusFrame.fill('#fd0165')
    //     this.bonusMode.fill('#fff')
    //     break

    //   default:
    //     break
    // }
    // this.bonusFrame.width(this.bonusMode.bbox().width + 40)
    this._onResize(true);
    this.showChrono();
  }

  bonusModeOff(): void {
    if (this.appConfig.DEBUG) {
      console.log('LuxComponent.bonusModeOff');
    }
    this.hideChrono();
    this.soundService.stopUltraCombo();
    $('.bonus-frame').removeClass('x1');
    $('.bonus-frame').removeClass('x2');
    $('.bonus-frame').removeClass('x3');
    $('.bonus-frame').removeClass('x4');
  }

  showChrono(): void {
    // if (!this.mobileMode) {
    //   this.bonusFrame.animate(600).attr('opacity', '1')
    //   this.bonusMode.animate(600).attr('opacity', '1')
    // }
    // this.chrono.animate(600).attr('opacity', '1')
  }
  updateChrono(seconds: number): void {
    // console.log('LuxComponent.updateChrono ' + seconds);
    this.chrono = this.utils.pad(seconds, 2);
  }
  hideChrono(): void {
    // this.bonusFrame.animate(600).attr('opacity', '0')
    // this.bonusMode.animate(600).attr('opacity', '0')
    // this.chrono.animate(600).attr('opacity', '0')
  }

  fillUiWithGameData(): void {
    this.updateScorePanel(this.progressService.score);
  }

  updateNotif(): void {
    this.quizCount = this.gameService.total;
    if (this.appConfig.DEBUG) {
      console.log('LuxComponent.updateNotif quizCount: %s', this.quizCount);
    }
    if (this.quizCount > 0) {
      gsap.to('#notifBubble', {
        duration: 0.6,
        autoAlpha: 1,
        overwrite: true,
        ease: 'quad.out',
      });
      gsap.to('#quizTotal', {
        duration: 0.6,
        autoAlpha: 1,
        overwrite: true,

        ease: 'quad.out',
      });
    } else {
      gsap.to('#notifBubble', {
        duration: 0.3,
        autoAlpha: 0,
        overwrite: true,

        ease: 'quad.out',
      });
      gsap.to('#quizTotal', {
        duration: 0.3,
        autoAlpha: 0,
        overwrite: true,

        ease: 'quad.out',
      });
    }
  }

  onRollOverButton(): any {
    this.cursorService.emitEvent(new CursorEvent(this.cursorService.ROLLOVER));
  }
  onRollOutButton(): any {
    this.cursorService.emitEvent(new CursorEvent(this.cursorService.ROLLOUT));
  }

  none(): void {}
}
