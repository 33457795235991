import { Component, OnInit, OnDestroy } from '@angular/core';
import * as $ from 'jquery';
import gsap from 'gsap';
import { RoughEase } from 'gsap/EasePack';
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin';
import {
  QuizService,
  QuizEvent,
  QuizData,
  Quiz,
} from 'src/app/services/game/quiz.service';
import { ToastrService } from 'ngx-toastr';
import { AppConfigService } from 'src/app/services/config/app-config.service';
import { ProgressService } from 'src/app/services/game/progress.service';
import { ServerService } from 'src/app/services/http/server.service';
import { SoundService } from 'src/app/services/sound/sound.service';
import { PlayerService } from 'src/app/services/user/player.service';
import { UtilsService } from 'src/app/services/utilities/utils.service';
import { CloseModalService } from 'src/app/services/view/close-modal.service';
import {
  CursorEvent,
  CursorService,
} from 'src/app/services/view/cursor.service';
import { DashboardService } from 'src/app/services/view/dashboard.service';
import { JarvisService } from 'src/app/services/view/jarvis.service';
import { Subscription } from 'rxjs';
import { AnswersType } from 'src/app/model/answers-type';
import { ComboService } from 'src/app/services/game/combo.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { RedbullTrackingService } from 'src/app/services/utilities/redbull-tracking.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss'],
})
export class QuizComponent implements OnInit, OnDestroy {
  quiz: Quiz;
  currentQuizId = 'ships';
  showHelper = false;
  btnTitle1 = 'Jouer';
  btnTitle2 = 'NOW';
  comboName = 'gotaga';
  chrono: number;
  chronoStr: string;
  DISABLEBTN = false;
  quizBatch: Array<QuizData>;
  previousPos = 0;
  timer;
  timerAnim;
  toOpen: boolean;
  score = '0';
  bonus = '00';
  points = '00';
  tmpTxt = [' ', ' '];
  subscription: Subscription;
  currentIndex = 0;
  running = false;
  started = false;
  answersType = new AnswersType();
  description = '';
  assetsUrl = this.appConfig.assetsUrl;
  isMobile = false;
  hidebuttons = false;
  svg = '/assets/images/quiz/none.svg';

  public QUIZ_ID = ['BT1', 'BT2', 'BT3', 'BT4', 'BT5', 'BT6'];
  public STEP_QUIZ = 6;
  public CHRONO_QUIZ = 66;
  quizSub: Subscription;
  clickBound;

  constructor(
    private serverService: ServerService,
    private cursorService: CursorService,
    private soundService: SoundService,
    private closeModalService: CloseModalService,
    private appConfig: AppConfigService,
    private jarvisService: JarvisService,
    private dashboardService: DashboardService,
    private progressService: ProgressService,
    private comboService: ComboService,
    private utils: UtilsService,
    private quizService: QuizService,
    private $gaService: GoogleAnalyticsService,
    // private rbTracking: RedbullTrackingService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    if (this.appConfig.DEBUG) {
      console.log('QuizComponent:ngOnInit');
    }
    if (this.utils.md().mobile() !== null) {
      this.isMobile = true;
    }
    gsap.registerPlugin(DrawSVGPlugin, RoughEase);
    this.toOpen = false;
    this.quiz = new Quiz();
    this.quiz.hash = '';
    this.quiz.question = '';
    this.quiz.idx = 0;
    this.quiz.results = [0];
    this.quiz.blink = false;
    this.quiz.blinkTimer = null;
    this.quiz.chronoStr = '' + this.CHRONO_QUIZ;
    this.quiz.chrono = this.CHRONO_QUIZ;
    this.quiz.response1 = {
      text: 'Réponse 1',
      x: false,
      v: false,
    };
    this.quiz.response2 = {
      text: 'Réponse 2',
      x: false,
      v: false,
    };
    this.quiz.response3 = {
      text: 'Réponse 3',
      x: false,
      v: false,
    };
    this.quiz.response4 = {
      text: 'Réponse 4',
      x: false,
      v: false,
    };
    this.quizSub = this.quizService.emitter$.subscribe((event) =>
      this._eventDispatch(event)
    );
    this.clickBound = this.closeQuiz.bind(this);
  }

  ngOnDestroy(): void {
    this.quizSub.unsubscribe();
  }
  _eventDispatch(event: any): void {
    switch (event.name) {
      case this.quizService.SHOW:
        this.currentIndex = (event as QuizEvent).index;
        this.openQuiz((event as QuizEvent).quizID);
        break;
      case this.closeModalService.CLOSEMODAL:
        this.closeQuiz();
        break;

      default:
        break;
    }
  }

  openQuiz(id: string): void {
    if (this.appConfig.DEBUG) {
      console.log('QuizComponent:openQuiz ' + id);
    }

    if (this.appConfig.GA_STATS) {
      this.$gaService.event('Show Quiz', 'Events', id);
    }
    // this.rbTracking.track(
    //   'gaEvent',
    //   'Events',
    //   'Show Quiz',
    //   id,
    //   'Red Bull Play Hard'
    // );

    this.cursorService.cross();
    $('.quiz-intro').on('click', this.clickBound);

    this.currentQuizId = id;
    this.description = this.comboService.getDescription(id);
    this.toOpen = true;
    this.started = false;

    this.svg = this.assetsUrl + '/assets/images/quiz/bonus/' + id + '.png';
    $('#quizContainer').css('display', 'flex');
    $('.quiz-badge').css('opacity', 0);
  }

  onSVGChange(): void {
    if (this.appConfig.DEBUG) {
      console.log('QuizComponent:onSVGChange isOpen: ' + this.toOpen);
    }

    if (!this.toOpen) {
      return;
    }
    this.closeModalService.hide();

    gsap.to('#quizContainer', {
      duration: 0.4,
      ease: this.appConfig.tweenFunc,
      opacity: 1,
      onComplete: () => {
        gsap.to('.quiz-badge', {
          duration: 0.6,
          opacity: 1,
          ease: this.appConfig.tweenFunc,
        });
        this.soundService.bonus();
        // gsap.to('.title', {
        //   duration: 0.3,
        //   opacity: 1,
        //   ease: this.appConfig.tweenFunc,
        //   onComplete: () => {
        gsap.to('.msg', {
          duration: 0.3,
          delay: 0.1,
          opacity: 1,
          ease: this.appConfig.tweenFunc,
          onComplete: () => {},
        });
        gsap.to('.startquiz-button', {
          duration: 0.3,
          delay: 0.3,
          opacity: 1,
          ease: this.appConfig.tweenFunc,
          onComplete: () => {
            $('.startquiz-button').css('pointer-events', 'all');

            this.subscription = this.closeModalService.emitter$.subscribe(
              (event) => this._eventDispatch(event)
            );
            this.closeModalService.show();
          },
        });
        //   },
        // });
      },
    });
  }

  startQuiz(event): void {
    event.stopPropagation();
    event.preventDefault();
    if (this.started) {
      return;
    }
    this.started = true;
    this.$gaService.event('Start Quiz', 'Interactions', this.currentQuizId);
    // this.rbTracking.track(
    //   'gaEvent',
    //   'Interactions',
    //   'Start Quiz',
    //   this.currentQuizId,
    //   'Red Bull Play Hard'
    // );
    this.cursorService.loading();
    this.soundService.go();

    this.serverService.getQuiz(this.currentQuizId).subscribe(
      (data: Array<QuizData>) => {
        this.quizBatch = data;
        if (this.appConfig.DEBUG) {
          console.log(JSON.stringify(this.quizBatch, null, 4));
        }
        this.initComponent();
      },
      (err) => {
        this.cursorService.normal();
        this.quizBatch = [];
        this.toastr.error(
          '[43] Error code: ' + err.status + ' (' + err.statusText + ')'
        );
      }
    );
  }

  initComponent(): void {
    if (this.appConfig.DEBUG) {
      console.log('QuizComponent:initComponent');
    }
    let max = 4;
    this.hidebuttons = false;
    this.quiz.idx = 1;
    this.quiz.results = [0];
    this.quiz.blink = false;
    this.quiz.blinkTimer = null;
    let pos = Math.floor(Math.random() * 3 + 1);
    if (this.quizBatch[this.quiz.idx - 1].response3 === '-') {
      pos = Math.floor(Math.random() + 1);
      this.hidebuttons = true;
      max = 2;
    }
    $('#progress-timer-handler').css('opacity', 0);
    this.fillButtons(pos, max).then(() => {
      this.start();
    });
  }

  fillButtons(pos: number, max = 4) {
    const promise = new Promise((resolve) => {
      setTimeout(() => {
        this.quiz.question = this.quizBatch[this.quiz.idx - 1].question;
        this.quiz['response' + pos] = {
          text: this.quizBatch[this.quiz.idx - 1].response1,
          x: false,
          v: false,
        };
        let j = 2;
        for (let i = 1; i <= max; i++) {
          if (i !== pos) {
            this.quiz['response' + i] = {
              text: this.quizBatch[this.quiz.idx - 1]['response' + j],
              x: false,
              v: false,
            };
            j++;
          }
        }
        this.quiz.hash = this.quizBatch[this.quiz.idx - 1].hash;
        this.quiz.key = pos;

        resolve(true);
      }, 10);
    });

    return promise;
  }

  start(): void {
    // this.soundService.muteSound();
    this.closeModalService.hide();
    this.running = true;
    gsap.to('.quiz-intro', {
      duration: 0.6,
      opacity: 0,
      ease: this.appConfig.tweenFunc,
      onComplete: () => {
        $('.quiz-intro').css('display', 'none');
        $('.quiz-game').css('display', 'block');

        gsap.to('.quiz-game', {
          duration: 0.6,
          opacity: 1,
          ease: this.appConfig.tweenFunc,
          onStart: () => {
            this.quiz.chronoStr = '' + this.CHRONO_QUIZ;
            this.quiz.chronoNbr = this.quiz.chronoStr.split('');
            this.quiz.chrono = this.CHRONO_QUIZ;
          },
          onComplete: () => {
            this.cursorService.normal();
            this.DISABLEBTN = false;

            this._startTimer();
          },
        });
      },
    });
  }

  checkQuizAnswer(idx: number): void {
    if (this.appConfig.DEBUG) {
      console.log('QuizComponent:checkQuizAnswer popcorn playhard  ' + idx);
    }
    this.DISABLEBTN = true;
    if (this.quiz.key === idx) {
      // good answer
      this.quiz.results.push(1);
      this.answersType = new AnswersType();
      this.progressService.saveAnswer(
        this.answersType.QUIZZ,
        this.quiz.hash,
        1,
        this.currentQuizId
      );
      // this.progressService.updateProgress(this.progressService.QUIZ);
      this.progressService.computeScore();

      switch (idx) {
        case 1:
          this.quiz.response1.text = '';
          this.quiz.response1.v = true;
          break;
        case 2:
          this.quiz.response2.text = '';
          this.quiz.response2.v = true;
          break;
        case 3:
          this.quiz.response3.text = '';
          this.quiz.response3.v = true;
          break;
        case 4:
          this.quiz.response4.text = '';
          this.quiz.response4.v = true;
          break;
      }
      this._playWinSoundFX();
    } else {
      // bad answer
      this.quiz.results.push(0);
      switch (idx) {
        case 1:
          this.quiz.response1.text = '';
          this.quiz.response1.x = true;
          break;
        case 2:
          this.quiz.response2.text = '';
          this.quiz.response2.x = true;
          break;
        case 3:
          this.quiz.response3.text = '';
          this.quiz.response3.x = true;
          break;
        case 4:
          this.quiz.response4.text = '';
          this.quiz.response4.x = true;
          break;
      }
      this._playLostSoundFX();
    }
    this._revealGoodAnswer(this.quiz.key).then(() => {
      if (this.running) {
        this._fecthNextQuizData();
      }
    });
  }

  _revealGoodAnswer(idx): Promise<boolean> {
    const promise = new Promise((resolve) => {
      $('.btn-reponse-quiz-' + idx).addClass('btn-reponse-quiz-focus');
      gsap.to('.btn-reponse-quiz-' + idx, {
        duration: 0.1,
        opacity: 0.3,
        repeat: 9,
        yoyo: true,
        repeatDelay: 0,
        onComplete: () => {
          $('.btn-reponse-quiz-' + idx).removeClass('btn-reponse-quiz-focus');
          $('.button-selector').trigger('blur');
          resolve(true);
        },
      });
    });

    return promise as Promise<boolean>;
  }

  _playWinSoundFX(): void {
    if (this.appConfig.DEBUG) {
      console.log('GOOD!');
    }
    this.soundService.win();
  }

  _playLostSoundFX(): void {
    if (this.appConfig.DEBUG) {
      console.log('WRONG');
    }
    this.soundService.lost();
  }

  _fecthNextQuizData(): void {
    if (this.appConfig.DEBUG) {
      console.log('QuizComponent:_fecthNextQuizData ');
    }

    this.quiz.idx += 1;
    let i = 0;

    if (this.quiz.idx > this.STEP_QUIZ) {
      // END BLIND TEST
      for (i = 1; i < this.quiz.idx; i++) {
        $('.bt-' + i).removeClass('bt-pin-' + i);
        if (this.quiz.results[i] === 1) {
          if (this.appConfig.DEBUG) {
            console.log('end bt-win: ' + i);
          }
          $('.bt-' + i).addClass('bt-win');
        } else {
          if (this.appConfig.DEBUG) {
            console.log('end bt-lost: ' + i);
          }
          $('.bt-' + i).addClass('bt-lost');
        }
      }
      this._stopTimer();
      this._onTimerOut();

      return;
    }

    let pos;
    let max = 4;
    if (this.quizBatch[this.quiz.idx - 1].response3 === '-') {
      max = 2;
      this.hidebuttons = true;
      pos = Math.floor(Math.random() + 1);
      console.log('POS: ' + pos);
    } else {
      do {
        this.hidebuttons = false;
        pos = Math.floor(Math.random() * 3 + 1);
      } while (pos === this.previousPos);
    }

    this.previousPos = pos;

    this.fillButtons(pos, max);
    for (i = 1; i < this.quiz.idx; i++) {
      $('.bt-' + i).removeClass('bt-pin-' + i);
      if (this.quiz.results[i] === 1) {
        if (this.appConfig.DEBUG) {
          console.log('bt-win: ' + i);
        }
        $('.bt-' + i).addClass('bt-win');
      } else {
        if (this.appConfig.DEBUG) {
          console.log('bt-lost: ' + i);
        }
        $('.bt-' + i).addClass('bt-lost');
      }
    }

    $('.bt-' + i).addClass('bt-pin-' + this.quiz.idx);
    this.DISABLEBTN = false;
  }

  _startTimer(): void {
    this.timerAnim = gsap.fromTo(
      '#progress-timer-handler',
      { drawSVG: '0% 0%' },
      {
        duration: this.CHRONO_QUIZ,
        ease: 'none',
        drawSVG: '100% 0%',
        onStart: () => {
          $('#progress-timer-handler').css('opacity', 1);
        },
      }
    );
    this.timer = setInterval(
      () => {
        this.quiz.chrono -= 1;
        if (this.quiz.chrono <= 10) {
          this.soundService.tick();
        }
        if (this.appConfig.DEBUG) {
          console.log('QuizComponent:chrono: %s', this.quiz.chrono);
        }

        if (this.quiz.chrono < 0) {
          this.quiz.chrono = 0;
        }
        if (this.quiz.chrono === 10) {
          this.quiz.blinkTimer = setInterval(() => {
            this.quiz.blink = !this.quiz.blink;
          }, 250);
        }
        if (this.quiz.chrono === 0) {
          this._stopTimer();
          this._onTimerOut();
        }
        this.quiz.chronoStr = this.utils.pad(this.quiz.chrono, 2);
        this.quiz.chronoNbr = this.quiz.chronoStr.split('');
      },
      1000,
      this.CHRONO_QUIZ
    );
  }
  _stopTimer(): void {
    if (this.appConfig.DEBUG) {
      console.log('QuizComponent:_stopTimer');
    }

    this.running = false;
    gsap.killTweensOf('#progress-timer-handler');
    clearInterval(this.timer);
    clearInterval(this.quiz.blinkTimer);
    this.quiz.blink = false;
    this.timer = null;
    this.quiz.blinkTimer = null;
  }

  _onTimerOut(): void {
    console.log('QuizComponent:_onTimerOut');
    for (let i = 1; i <= this.STEP_QUIZ; i++) {
      $('.bt-' + i).removeClass('bt-pin-' + i);
      if (i < this.quiz.results.length) {
        if (this.quiz.results[i] === 1) {
          if (this.appConfig.DEBUG) {
            console.log('timeout bt-win: ' + i);
          }
          $('.bt-' + i).addClass('bt-win');
        } else {
          if (this.appConfig.DEBUG) {
            console.log('timeout bt-lost: ' + i);
          }
          $('.bt-' + i).addClass('bt-lost');
        }
      } else {
        if (this.appConfig.DEBUG) {
          console.log('timeout bt-win: ' + i);
        }
        $('.bt-' + i).addClass('bt-lost');
      }
    }
    setTimeout(() => {
      this._showEnd();
    }, 500);
  }

  _showEnd(): void {
    if (this.appConfig.DEBUG) {
      console.log('QuizComponent:_showEnd');
    }

    this.score = '' + this._getBonus();
    this.$gaService.event(
      'End Quiz',
      'Events',
      this.currentQuizId + '(' + this.score + ')'
    );
    // this.rbTracking.track(
    //   'gaEvent',
    //   'Events',
    //   'End Quiz',
    //   this.currentQuizId + '(' + this.score + ')',
    //   'Red Bull Play Hard'
    // );
    this.bonus = this.utils.pad(
      Number(this.score) * this.appConfig.SCORE_QUIZ,
      2
    );
    this.jarvisService.updateScore(this.progressService.score);
    this.dashboardService.updateScore(this.progressService.score);
    this.dashboardService.updateProgress();
    this.quizService.done(
      this.currentQuizId,
      this.currentIndex,
      this._getBonus()
    );
    // this.comboService.total -= 1;
    this.jarvisService.updateNotif();

    gsap.to('.quiz-game', {
      duration: 0.6,
      opacity: 0,
      ease: this.appConfig.tweenFunc,
      onStart: () => {
        this.soundService.badge();
      },
      onComplete: () => {
        $('.quiz-game').css('display', 'none');
        $('.quiz-end').css('display', 'block');
        $('.points').css('opacity', 0);

        gsap.to('.quiz-end', {
          duration: 0.6,
          opacity: 1,
          ease: this.appConfig.tweenFunc,
          onComplete: () => {
            setTimeout(() => {
              this.points = '00';
              $('.points').css('opacity', 1);
              for (let i = 0; i < this.tmpTxt.length; i++) {
                this.letterRandomize(i);
              }
              this.cursorService.normal();
              setTimeout(() => {
                this.closeQuiz();
              }, 2500);
              // $('.quiz-intro').css('display', 'none');
              // $('.quiz-game').css('display', 'block');
            }, 300);
          },
        });
      },
    });
  }

  _getBonus(): number {
    let bonus = 0;
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.quiz.results.length; i++) {
      bonus += this.quiz.results[i];
    }
    if (this.appConfig.DEBUG) {
      console.log('QuizComponent.getBlindTestBonus +' + bonus);
    }

    return bonus;
  }

  closeQuiz(): void {
    // this.soundService.unMuteSound();

    //   .animate({ opacity: 1 }, 400);
    gsap.to('#quizContainer', {
      duration: 0.4,
      ease: this.appConfig.tweenFunc,
      opacity: 0,
      onComplete: () => {
        // reset
        $('#quizContainer').css('display', 'none');
        $('.quiz-end').css('display', 'none');
        $('.quiz-end').css('opacity', 0);

        $('.quiz-intro').css('display', 'flex');
        $('.quiz-intro').css('visibility', 'visible');
        $('.quiz-intro').css('opacity', 1);
        $('.msg').css('opacity', 0);
        $('.title').css('opacity', 0);
        $('.quiz-badge').css('opacity', 0);
        $('.startquiz-button').css('opacity', 0);
        $('.startquiz-button').css('pointer-events', 'none');
        $('.quiz-intro').off('click', this.clickBound);

        for (let i = 1; i < 7; i++) {
          $('.bt-' + i).removeClass('bt-pin-' + i);
          $('.bt-' + i).removeClass('bt-win');
          $('.bt-' + i).removeClass('bt-lost');
        }
        $('.bt-1').addClass('bt-pin-1');
        this.toOpen = false;
        this.svg = this.assetsUrl + '/assets/images/quiz/none.svg';
        this.subscription.unsubscribe();
      },
    });
  }

  letterRandomize(index): void {
    const intervals = [];
    const possible = '0123456789*-+=.;"()&%$#@!<>?';
    this.soundService.coins();

    intervals[index] = setInterval(() => {
      this.tmpTxt[index] = possible.charAt(
        Math.floor(Math.random() * possible.length)
      );
      this.points = this.tmpTxt.join('');
    }, 25);
    setTimeout(() => {
      clearInterval(intervals[index]);
      this.tmpTxt[index] = this.bonus.split('')[index];
      this.points = this.tmpTxt.join('');
    }, 600 * index);
  }

  onRollOver(): void {
    this.cursorService.emitEvent(new CursorEvent(this.cursorService.ROLLOVER));
  }

  onRollOut(): void {
    this.cursorService.emitEvent(new CursorEvent(this.cursorService.ROLLOUT));
  }
}
