import { Injectable, EventEmitter } from '@angular/core';
import { PlayerService } from '../user/player.service';
import {
  AuthenticationService,
  AuthEvent,
} from 'src/app/services/http/authentication.service';
import { ServerService } from '../http/server.service';
import { JwtHelperService } from '@auth0/angular-jwt';

import gsap from 'gsap';
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin';
import { RoughEase } from 'gsap/EasePack';

import { SVG, Element, Text, Svg } from '@svgdotjs/svg.js';
import { UtilsService } from 'src/app/services/utilities/utils.service';
// import {
//   CursorService,
//   CursorEvent,
// } from 'src/app/services/view/cursor.service';
import * as $ from 'jquery';
import { ToastrService } from 'ngx-toastr';
import { User } from 'src/app/model/user';
import { AppConfigService } from '../config/app-config.service';
import { ProgressService } from './progress.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

export class SaveEvent {
  constructor(public name: string, public playerName?: string) {}
}

@Injectable({
  providedIn: 'root',
})
export class SaveService {
  public emitter$: EventEmitter<SaveEvent>;

  public SHOW = 'SaveService:SHOW';
  public HIDE = 'SaveService:HIDE';
  public CLICK = 'SaveService:CLICK';
  public HIDDEN = 'SaveService:HIDDEN';
  public SAVEREQUEST = 'SaveService:SAVEREQUEST';
  public SAVESUCESS = 'SaveService:SAVESUCESS';
  public SAVEFAILED = 'SaveService:SAVEFAILED';
  public UPDATENAME = 'SaveService:UPDATENAME';

  gameSaved = false;
  changeGameid = false;
  timerFirstSaved;
  closeGame = false;

  feedbackFrame1: Element;
  feedbackCheck1: Element;
  feedbackFrame2: Element;
  feedbackCheck2: Element;
  overwrite = false;
  clickBound;

  constructor(
    private authService: AuthenticationService,
    private playerService: PlayerService,
    private progressService: ProgressService,
    private jwtHelper: JwtHelperService,
    private toastr: ToastrService,
    private api: ServerService,
    private utils: UtilsService,
    private $gaService: GoogleAnalyticsService,
    private appConfig: AppConfigService
  ) {
    this.emitter$ = new EventEmitter();
  }

  public emitEvent(event: SaveEvent): void {
    if (this.appConfig.DEBUG) {
      console.log('SaveService emitEvent %s', event.name);
    }
    this.emitter$.emit(event);
  }

  init(): void {
    gsap.registerPlugin(DrawSVGPlugin, RoughEase);

    if (this.appConfig.DEBUG) {
      console.log('SaveService:init');
      console.log(
        'SaveService isTokenExpired: ' +
          this.jwtHelper.isTokenExpired(localStorage.getItem('token'))
      ); // true or false
      console.log(
        'SaveService getTokenExpirationDate: ' +
          this.jwtHelper.getTokenExpirationDate(localStorage.getItem('token'))
      ); // date
    }
    this.authService.emitter$.subscribe((event) => this._eventDispatch(event));

    this.feedbackFrame1 = SVG(
      document.getElementById('firstSaveFrame')
    ) as Element;
    this.feedbackFrame1.attr({ opacity: 0 });
    this.feedbackCheck1 = SVG(
      document.getElementById('firstSaveCheck')
    ) as Element;
    this.feedbackCheck1.transform({ tx: 30, ty: 32 }).attr({ opacity: 0 });

    this.feedbackFrame2 = SVG(document.getElementById('saveFrame')) as Element;
    this.feedbackFrame2.attr({ opacity: 0 });
    this.feedbackCheck2 = SVG(document.getElementById('saveCheck')) as Element;
    this.feedbackCheck2.transform({ tx: 30, ty: 32 }).attr({ opacity: 0 });
    this.clickBound = this.clickHandler.bind(this);
  }

  reset(): void {
    this.gameSaved = false;
    this.changeGameid = true;
    this.playerService.gameid = '';
    this.overwrite = true;
    // localStorage.removeItem('currentUser');
    // localStorage.removeItem('token');
  }

  _eventDispatch(event: AuthEvent): void {
    switch (event.name) {
      case this.authService.AUTHSUCCESS:
        this.playerService.isConnected = true;
        this.playerService.name = event.user.name;
        if (!this.overwrite) {
          this.playerService.gameid = event.user.game; //// LA !!!
        }
        this.emitEvent(new SaveEvent(this.UPDATENAME, this.playerService.name));
        this.saveGameAuthentified();
        break;
      case this.authService.AUTHFAILED:
        this.playerService.isConnected = false;
        break;
      default:
        break;
    }
  }

  clickHandler(): void {
    if (this.appConfig.DEBUG) {
      console.log('ConnectComponent:clickHandler');
    }
    this.emitEvent(new SaveEvent(this.CLICK));
  }

  saveGame(): void {
    if (this.progressService.answers.length > 0) {
      this.emitEvent(new SaveEvent(this.SHOW));

      if (this.appConfig.DEBUG) {
        console.log('SaveService:saveGame: ' + this.playerService.isConnected);
      }
      if (this.playerService.isConnected) {
        this.showSaved();
      } else {
        this.showConnect();
      }
    }
  }

  showConnect(): void {
    if (this.appConfig.DEBUG) {
      console.log('SaveService:showConnect');
    }

    $('#connectContainer').css('display', 'flex');

    // bug angular material fix
    const tab = document.getElementsByClassName('mat-tab-label-content');
    $(tab[1]).trigger('click');

    gsap.to('#connectContainer', {
      duration: 0.6,
      autoAlpha: 1,
      ease: this.appConfig.tweenFunc,
      onStart: () => {
        $(tab[0]).trigger('click');
      },
      onComplete: () => {
        if (this.utils.md().mobile() === null) {
          $('.first-input').trigger('focus');
        }
      },
    });
    gsap.to('#closeConnect', {
      duration: 0.6,
      autoAlpha: 1,
      ease: this.appConfig.tweenFunc,

      onComplete: () => {
        $('#closeConnect').css('pointer-events', 'all');
      },
    });
  }

  showFirstSave(): void {
    if (this.appConfig.DEBUG) {
      console.log('SaveService:showFirstSave');
    }

    $('#firstSaveContainer').css('display', 'block');

    gsap.to('#firstSaveContainer', {
      duration: 0.6,
      autoAlpha: 1,
      ease: this.appConfig.tweenFunc,
      onStart: () => {},
      onComplete: () => {
        this.feedbackFrame1.attr({ opacity: 1 });
        gsap.fromTo(
          '#firstSaveFrame',
          { drawSVG: '0% 0%' },
          {
            duration: 0.6,
            ease: 'expo.inOut',
            drawSVG: '100% 0%',
            onComplete: () => {
              this.utils.blink(this.feedbackCheck1);
            },
          }
        );
        gsap.to('span.feedback', {
          duration: 0.6,
          autoAlpha: 1,
          ease: this.appConfig.tweenFunc,

          onComplete: () => {
            // this.cursorService.normal()
            this.timerFirstSaved = setTimeout(() => {
              this.emitEvent(new SaveEvent(this.HIDE));
              this.hideFirstSave();
              this.emitEvent(new SaveEvent(this.HIDDEN));
            }, this.appConfig.TIMER_FIRST_SAVE);
          },
        });
        // gsap.to('span.hello', {
        //   duration: 0.6,
        //   autoAlpha: 1,
        //   delay: 0.5,
        //   ease: this.appConfig.tweenFunc,

        //   onComplete: () => {},
        // });
      },
    });

    gsap.to('#closeFirstSave', {
      duration: 0.6,
      autoAlpha: 1,
      ease: this.appConfig.tweenFunc,

      onComplete: () => {
        $('#closeFirstSave').css('pointer-events', 'all');
      },
    });
  }
  showSaved(): void {
    if (this.appConfig.DEBUG) {
      console.log('SaveService:showSaved');
    }

    $('#savedContainer').css('display', 'block');

    gsap.to('#savedContainer', {
      duration: 0.6,
      autoAlpha: 1,
      ease: this.appConfig.tweenFunc,
      onStart: () => {},
      onComplete: () => {
        this.feedbackFrame2.attr({ opacity: 1 });
        gsap.fromTo(
          '#saveFrame',
          { drawSVG: '0% 0%' },
          {
            duration: 0.4,
            ease: 'expo.inOut',
            drawSVG: '100% 0%',
            onComplete: () => {
              this.utils.blink(this.feedbackCheck2);
              this.gameSaved = true;
              if (this.playerService.gameid === '') {
                this.changeGameid = true;
              }
              this.saveGameAuthentified();
            },
          }
        );
      },
    });
  }

  saveGameAuthentified(silent = false, closeGame = 0): void {
    if (this.appConfig.DEBUG) {
      console.log(
        'SaveService:saveGameAuthentified gameid: ' + this.playerService.gameid
      );
    }
    if (this.appConfig.GA_STATS) {
      this.$gaService.event('Save DB Game', 'Events', 'Save Game');
    }

    if (this.closeGame) {
      closeGame = 1;
    }
    this.api
      .postGame(
        this.playerService.gameid,
        this.progressService.answers,
        closeGame
      )
      .subscribe(
        (game) => {
          if (this.appConfig.DEBUG) {
            console.log(
              'SaveService:saveGameAuthentified OK ' +
                JSON.stringify(game, null, 4)
            );
          }
          if (!silent) {
            this.emitEvent(new SaveEvent(this.SAVESUCESS));
          }

          if (this.changeGameid) {
            this.changeGameid = false;
            const currentUser: User = this.authService.currentUserValue;
            currentUser.game = game.game; // on met à jour le gameid
            this.playerService.setPlayer(currentUser);
            localStorage.setItem('currentUser', JSON.stringify(currentUser));
          }

          if (!this.gameSaved) {
            const currentUser: User = this.authService.currentUserValue;
            currentUser.game = game.game;
            this.playerService.gameid = game.game;
            this.playerService.setPlayer(currentUser);
            localStorage.setItem('currentUser', JSON.stringify(currentUser));
            this.gameSaved = true;
            this.hideConnect().then(() => {
              if (!silent) {
                this.showFirstSave();
              }
            });
          } else {
            if (!silent) {
              this.hideSaved(true).then(() => {
                this.emitEvent(new SaveEvent(this.HIDDEN));
              });
            }
          }
          this.progressService.fixScore(game.score);
          this.progressService.cleanLoadedAnswers();
          if (this.appConfig.GA_STATS) {
            this.$gaService.event(
              'Save DB Game Success',
              'Events',
              'Save Game'
            );
          }

          if (closeGame) {
            localStorage.removeItem('currentUser');
            localStorage.removeItem('token');
          }
        },
        (err) => {
          if (this.appConfig.DEBUG) {
            console.log(
              'SaveService:saveGameAuthentified 400 !' + JSON.stringify(err)
            );
          }
          if (this.appConfig.GA_STATS) {
            this.$gaService.event(
              'Save DB Game Failed',
              'Events',
              'Save Game Error'
            );
          }

          this.toastr.error(
            '[24] Un probleme est survenu, merci de recommencer. Error code: ' +
              err.status +
              ' (' +
              err.statusText +
              ').'
          );
          if (!silent) {
            this.emitEvent(new SaveEvent(this.SAVEFAILED));
          }

          if (!this.gameSaved) {
            this.hideConnect().then(() => {
              if (!silent) {
                this.emitEvent(new SaveEvent(this.HIDDEN));
              }
            });
          } else {
            this.hideSaved().then(() => {
              if (!silent) {
                this.emitEvent(new SaveEvent(this.HIDDEN));
              }
            });
          }
        }
      );
  }

  hideConnect(): any {
    if (this.appConfig.DEBUG) {
      console.log('SaveService:hideConnect');
    }

    const promise = new Promise((resolve) => {
      gsap.to('#closeConnect', {
        duration: 0,
        autoAlpha: 0,
        onComplete: () => {
          $('#closeConnect').css('pointer-events', 'none');
        },
      });
      gsap.to('#connectContainer', {
        duration: 0.3,
        autoAlpha: 0,
        ease: this.appConfig.tweenFunc,
        onStart: () => {
          resolve(true);
        },
        onComplete: () => {
          $('#connectContainer').css('display', 'none');
          // resolve(true);
        },
      });
    });

    return promise;
  }
  hideFirstSave(): any {
    if (this.appConfig.DEBUG) {
      console.log('SaveService:hideFirstSave');
    }
    clearInterval(this.timerFirstSaved);

    const promise = new Promise((resolve) => {
      gsap.to('#closeFirstSave', {
        duration: 0,
        autoAlpha: 0,
        onComplete: () => {
          $('#closeFirstSave').css('pointer-events', 'none');
        },
      });
      gsap.to('#firstSaveContainer', {
        duration: 0.6,
        autoAlpha: 0,
        ease: this.appConfig.tweenFunc,
        onComplete: () => {
          $('#firstSaveContainer').css('display', 'none');
          resolve(true);
        },
      });
    });

    return promise;
  }

  hideSaved(hide = false): any {
    if (this.appConfig.DEBUG) {
      console.log('SaveService:hideSaved');
    }
    const promise = new Promise((resolve) => {
      gsap.to('#savedContainer', {
        delay: 2,
        duration: 0.6,
        autoAlpha: 0,
        ease: this.appConfig.tweenFunc,
        onStart: () => {
          if (hide) {
            this.emitEvent(new SaveEvent(this.HIDE));
          }
        },
        onComplete: () => {
          $('#savedContainer').css('display', 'none');
          this.feedbackFrame2.attr({ opacity: 0 });
          this.feedbackCheck2.attr({ opacity: 0 });
          resolve(true);
        },
      });
    });

    return promise;
  }
}
