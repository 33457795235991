<div id="connectContainer" class="flex-column justify-content-center align-items-center">
  <div class="d-flex flex-row justify-content-center align-items-center">
    <alert></alert>
  </div>
  <div class="d-flex flex-row justify-content-center align-items-center">
    <mat-tab-group mat-align-tabs="center" animationDuration="300ms" dynamicHeight disableRipple>
      <mat-tab label="Créer un compte">
        <register></register>
      </mat-tab>
      <mat-tab label=" Déjà un compte">
        <login></login>
      </mat-tab>
    </mat-tab-group>
  </div>

  <div class="bg-connect">
    <overlay [keyboard]="showHelper"></overlay>
  </div>
</div>

<div id="firstSaveContainer">
  <div class="container h100">
    <div class="row h-100 justify-content-center align-items-center">
      <div class="col-6 text-center">
        <div class="icon">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="87px"
            height="100px">
            <path id="firstSaveFrame" fill-rule="evenodd" stroke-width="2px" stroke="#fff" fill-opacity="0"
              fill="rgb(102, 220, 224)"
              d="M43.500,3.900 C65.370,3.900 83.100,21.630 83.100,43.500 C83.100,65.370 65.370,83.100 43.500,83.100 C21.630,83.100 3.900,65.370 3.900,43.500 C3.900,21.630 21.630,3.900 43.500,3.900 Z" />
            <path id="firstSaveCheck" fill-rule="evenodd" fill="#ffbe00"
              d="M9.917,22.525 L0.398,13.128 C-0.133,12.609 -0.133,11.769 0.398,11.248 L2.321,9.368 C2.852,8.852 3.713,8.852 4.244,9.368 L10.878,15.948 L25.671,1.303 C26.202,0.785 27.063,0.785 27.594,1.303 L29.517,3.183 C30.048,3.702 30.048,4.545 29.517,5.060 L11.840,22.525 C11.309,23.043 10.448,23.043 9.917,22.525 Z" />
          </svg>
        </div>
        <div>
          <!-- <span class="hello">Hello {{currentPlayerName}} !</span><br> -->
          <span class="feedback">partie <span class="gold">sauvegardée</span></span>
        </div>

      </div>
    </div>
  </div>

  <div class="bg-connect">
    <overlay [keyboard]="showHelper"></overlay>
  </div>
</div>

<div id="savedContainer">
  <div class="container h100">
    <div class="row h-100 justify-content-center align-items-center">
      <div class="col-6 text-center">
        <div class="icon">

          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="87px"
            height="100px">
            <path id="saveFrame" fill-rule="evenodd" stroke-width="2px" stroke="#fff" fill-opacity="0"
              fill="rgb(102, 220, 224)"
              d="M43.500,3.900 C65.370,3.900 83.100,21.630 83.100,43.500 C83.100,65.370 65.370,83.100 43.500,83.100 C21.630,83.100 3.900,65.370 3.900,43.500 C3.900,21.630 21.630,3.900 43.500,3.900 Z" />
            <path id="saveCheck" fill-rule="evenodd" fill="#ffbe00"
              d="M9.917,22.525 L0.398,13.128 C-0.133,12.609 -0.133,11.769 0.398,11.248 L2.321,9.368 C2.852,8.852 3.713,8.852 4.244,9.368 L10.878,15.948 L25.671,1.303 C26.202,0.785 27.063,0.785 27.594,1.303 L29.517,3.183 C30.048,3.702 30.048,4.545 29.517,5.060 L11.840,22.525 C11.309,23.043 10.448,23.043 9.917,22.525 Z" />
          </svg>
        </div>

      </div>
    </div>
  </div>
  <div class="bg-connect">
    <overlay [keyboard]="showHelper"></overlay>
  </div>
</div>

<div id="closeFirstSave" class="close-first-save">
  <div class="close-cross">
    <img src="{{ assetsUrl }}/assets/images/x.png" srcset="{{ assetsUrl }}/assets/images/x@2x.png 2x,
                     {{ assetsUrl }}/assets/images/x@3x.png 3x" class="img-fluid">
  </div>
</div>
<div id="closeConnect" class="close-connect">
  <div class="close-cross">
    <img src="{{ assetsUrl }}/assets/images/x.png" srcset="{{ assetsUrl }}/assets/images/x@2x.png 2x,
                     {{ assetsUrl }}/assets/images/x@3x.png 3x" class="img-fluid">
  </div>
</div>
