<!-- <div id="dots_layer" class="dots"></div> -->
<div id="overlay_layer" class="overlay"
  [ngClass]="{ 'darker': darker,'transparent': transparent, 'error': error, 'save': save  }">
  <div *ngIf="keyboard">
    <div class="btn-enter text-center">
      <img src="{{assetsUrl}}/assets/images/icon-clavier.svg">

      <br />
      <span class="type-here">Tape le nom d’un personage</span>
      <span class="smallinfo"></span>
    </div>
  </div>
</div>