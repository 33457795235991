import { Injectable, EventEmitter } from '@angular/core';
import { AppConfigService } from 'src/app/services/config/app-config.service';

export class ScreenEvent {
  constructor(public name: string, public errors?: number) {}
}

@Injectable({
  providedIn: 'root',
})
export class ScreenService {
  public emitter$: EventEmitter<ScreenEvent>;

  public START = 'screenService:start';
  public TEXTUREREADY = 'screenService:textureready';
  public PLANETREADY = 'screenService:PLANETREADY';
  public GAMEVIEWLOADED = 'screenService:gameloaded';
  public GAMEVIEWREADY = 'screenService:gameready';
  public GAMEVIEWUNLOADED = 'screenService:gameover';
  public ZOOMIN = 'screenService:zoomin';
  public ZOOMINDONE = 'screenService:zoomindone';
  public ENABLE = 'screenService:enable';
  public DISABLE = 'screenService:disable';
  public GAMEERROR = 'screenService:gameerror';
  public BLUR = 'screenService:blur';
  public UNBLUR = 'screenService:unblur';
  public PAUSE = 'screenService:pause';
  public RESUME = 'screenService:resume';
  public STOP = 'screenService:stop';

  constructor(private appConfig: AppConfigService) {
    this.emitter$ = new EventEmitter();
  }
  emitEvent(event: ScreenEvent): void {
    if (this.appConfig.DEBUG) {
      console.log('ScreenService emitEvent %s', event.name);
    }
    this.emitter$.emit(event);
  }

  enable(): void {
    this.emitEvent(new ScreenEvent(this.ENABLE));
  }
  disable(): void {
    this.emitEvent(new ScreenEvent(this.DISABLE));
  }

  error(errors: number): void {
    this.emitEvent(new ScreenEvent(this.GAMEERROR, errors));
  }

  pause(): void {
    this.emitEvent(new ScreenEvent(this.PAUSE));
  }
  stop(): void {
    this.emitEvent(new ScreenEvent(this.STOP));
  }

  resume(): void {
    this.emitEvent(new ScreenEvent(this.RESUME));
  }

  blur(): void {
    this.emitEvent(new ScreenEvent(this.BLUR));
  }
  unblur(): void {
    this.emitEvent(new ScreenEvent(this.UNBLUR));
  }
}
