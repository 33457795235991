import { EventEmitter, Injectable } from '@angular/core';
// import * as screenfull from 'screenfull';
import * as screenfull from 'screenfull';
import { Screenfull } from 'screenfull';
import { Bound } from '../../model/bound';
import { AppConfigService } from '../config/app-config.service';
import { UtilsService } from '../utilities/utils.service';
import * as OpenSeadragon from 'openseadragon';
import { ScreenEvent, ScreenService } from './screen.service';
import { Show } from 'src/app/model/show';
import { Focus } from 'src/app/model/focus';
import { MouseControlsEvent } from './mouse-controls.service';
import gsap from 'gsap';
import * as $ from 'jquery';
import { SoundService } from 'src/app/services/sound/sound.service';
import {
  RotateDeviceEvent,
  RotateDeviceService,
} from './rotate-device.service';

export class TouchControlsEvent {
  constructor(
    public name: string,
    public zoom?: number,
    public bound?: Bound
  ) {}
}

export class FocusData {
  x: number;
  y: number;
  titre: string;
  badgeid: string;
  show: Show;
}

@Injectable({
  providedIn: 'root',
})
export class TouchControlsService {
  public emitter$: EventEmitter<TouchControlsEvent>;
  TOUCH = 'TouchControlsService:touch';
  TOUCHUP = 'TouchControlsService:touchup';
  // CHANGE = 'MouseControlsService:change';
  // ENABLE = 'MouseControlsService:enable';
  // DISABLE = 'MouseControlsService:disable';
  // ZOOM = 'MouseControlsService:zoom';
  VIEWBOUNDARIES = 'TouchControlsService:viewboundaries';

  constructor(
    private appConfig: AppConfigService,
    private screenService: ScreenService,
    private soundService: SoundService,
    private rotateDeviceService: RotateDeviceService,
    private utils: UtilsService
  ) {
    this.emitter$ = new EventEmitter();
  }

  maxZoomLevel = 6;
  minZoomLevel = 1;
  imgViewer: OpenSeadragon.Viewer;
  loadBound;
  onClickCanvasBound;
  onPressCanvasBound;
  onReleaseCanvasBound;
  onDragCanvasBound;
  onPinchCanvasBound;

  center = {
    x: 0,
    y: 0,
  };

  showFocusMode = false;
  focusResolve;
  dragMode = false;
  animated = false;
  errorTween;
  loadingTween;
  orientation: string;

  focusData: FocusData;

  STATE = {
    NONE: -1,
    PAN: 0,
    ZOOM_PAN: 1,
    ROTATE: 2,
    TOUCH_ROTATE: 3,
    TOUCH_ZOOM_PAN: 4,
  };

  assetsUrl = this.appConfig.assetsUrl;

  init(): void {
    if (this.appConfig.DEBUG) {
      console.log('TouchControlsService init');
    }

    this.loadBound = (() => {
      this._onLoaded();
    }).bind(this);
    this.onClickCanvasBound = ((event) => {
      this._onClickCanvas(event);
    }).bind(this);
    this.onPressCanvasBound = (() => {
      this._onPressCanvas();
    }).bind(this);
    this.onReleaseCanvasBound = (() => {
      this._onReleaseCanvas();
    }).bind(this);
    this.onDragCanvasBound = (() => {
      this._onDragCanvas();
    }).bind(this);
    this.onPinchCanvasBound = (() => {
      this._onPinchCanvas();
    }).bind(this);

    // this.minZoomLevel = this._computeMinZoomLevel();
    this.computeMinZoomLevel().then((minZoomLevel) => {
      this.minZoomLevel = minZoomLevel;
      this.maxZoomLevel = this.minZoomLevel + 5;

      const imgUrl = this.assetsUrl + '/assets/images/game/PopStar90s.jpg';

      const options = {
        id: 'imgViewer',
        defaultZoomLevel: this.maxZoomLevel,
        // defaultZoomLevel: 0,
        // minZoomLevel: this.minZoomLevel,
        minZoomLevel: 1,
        maxZoomLevel: 10,
        visibilityRatio: 1,
        minZoomImageRatio: 1,
        constrainDuringPan: true,
        autoResize: true,
        preserveImageSizeOnResize: false,
        minPixelRatio: 0,
        animationTime: 0.8,

        showZoomControl: false,
        showHomeControl: false,
        showFullPageControl: false,
        showSequenceControl: false,
        gestureSettingsUnknown: {
          clickToZoom: false,
          dblClickToZoom: false,
        },
        gestureSettingsPen: {
          clickToZoom: false,
          dblClickToZoom: false,
        },
        gestureSettingsMouse: {
          clickToZoom: false,
          dblClickToZoom: false,
        },
        gestureSettingsTouch: {
          dblClickToZoom: false,
        },
        tileSources: {
          type: 'image',
          url: imgUrl,
          buildPyramid: true,
        },
      };
      this.imgViewer = OpenSeadragon(options);
      this.imgViewer.addHandler('tile-loaded', this.loadBound);
      $('#loading').css('display', 'flex');
      this.loadingTween = gsap.to('#loading', {
        duration: 0.6,
        opacity: 1,
        ease: this.appConfig.tweenFunc,
      });
    });
  }

  emitEvent(event: TouchControlsEvent): void {
    if (this.appConfig.DEBUG) {
      console.log('TouchControlsService emitEvent %s', event.name);
    }
    this.emitter$.emit(event);
  }

  showTheRoom(): void {
    if (this.appConfig.DEBUG) {
      console.log('TouchControlsService::showTheRoom');
    }
    gsap.to('#imgViewer', {
      duration: 0.6,
      opacity: 1,
      ease: this.appConfig.tweenFunc,
      onStart: () => {
        this.imgViewer.viewport.zoomTo(this.minZoomLevel);
        // this.imgViewer.viewport.zoomTo(0);
        // this.imgViewer.viewport.applyConstraints();
      },
    });
    this.imgViewer.removeHandler('tile-loaded', this.loadBound);

    this.imgViewer.addHandler('canvas-click', this.onClickCanvasBound);
    this.imgViewer.addHandler('animation', this._onAnimate.bind(this));
    this.imgViewer.addHandler('animation-start', this.onPressCanvasBound);
    this.imgViewer.addHandler('animation-finish', this.onReleaseCanvasBound);
    this.imgViewer.addHandler('canvas-pinch', this.onPinchCanvasBound);
    this.imgViewer.addHandler('canvas-drag', this.onDragCanvasBound);
    this.imgViewer.addHandler(
      'canvas-drag-end',
      this._onDragEndCanvas.bind(this)
    );

    this.center.x = this.imgViewer.viewport.getCenter(false).x;
    this.center.y = this.imgViewer.viewport.getCenter(false).y;
    this.screenService.emitEvent(
      new ScreenEvent(this.screenService.GAMEVIEWREADY)
    );

    this.rotateDeviceService.emitter$.subscribe((event: RotateDeviceEvent) =>
      this.onRotateDevice(event)
    );
  }

  onRotateDevice(event: RotateDeviceEvent): void {
    if (this.appConfig.DEBUG) {
      console.log('TouchControlsService::onRotateDevice ' + event.name);
    }

    const bound = new OpenSeadragon.Rect();

    this.computeMinZoomLevel().then((minZoomLevel) => {
      this.minZoomLevel = minZoomLevel;
      this.maxZoomLevel = this.minZoomLevel + 5;
      this.imgViewer.viewport.zoomTo(this.minZoomLevel);
      this.imgViewer.viewport.applyConstraints();
    });

    // if (event.name === this.rotateDeviceService.GOHORIZONTAL) {
    //   this.minZoomLevel = 1;
    //   this.imgViewer.viewport.fitHorizontally(true);
    // } else {
    //   this.imgViewer.viewport.fitVertically(true);
    // }
    // if (this.utils.md().mobile()) {
    //   this.maxZoomLevel = this.minZoomLevel + 4;
    // }
    // this.imgViewer.viewport.zoomTo(this.minZoomLevel);
    // this.imgViewer.viewport.applyConstraints();
  }

  hideTheRoom(): void {
    const zoomLevel =
      this.maxZoomLevel - Math.abs(this.maxZoomLevel - this.minZoomLevel) / 2;
    if (this.appConfig.DEBUG) {
      console.log('TouchControlsService::hideTheRoom');
    }
    gsap.to('#imgViewer', {
      duration: 0.6,
      opacity: 0,
      ease: this.appConfig.tweenFunc,
      onStart: () => {
        this.imgViewer.viewport.zoomTo(zoomLevel);
        this.imgViewer.viewport.applyConstraints();
      },
      onComplete: () => {
        this.screenService.emitEvent(
          new ScreenEvent(this.screenService.GAMEVIEWUNLOADED)
        );
      },
    });
  }

  getFocusPosition(show: Show): any {
    if (this.appConfig.DEBUG) {
      'TouchControlsService::getFocusPosition ' + show.badge_id;
    }

    const promise = new Promise((resolve, reject) => {
      const ratio =
        this.appConfig.RESOLUTION.height / this.appConfig.RESOLUTION.width;
      if (this.appConfig.DEBUG) {
        console.log(
          'TouchControlsService::getFocusPosition show (%s, %s)',
          show.x,
          show.y
        );
      }

      this.focusData = new FocusData();

      this.focusData.x = parseInt(show.x, 10);
      this.focusData.y = parseInt(show.y, 10);
      this.focusData.titre = show.title;
      this.focusData.badgeid = show.badge_id;
      this.focusData.show = show;

      const pan = this.imgViewer.viewport.imageToViewportCoordinates(
        new OpenSeadragon.Point(parseInt(show.x, 10), parseInt(show.y, 10))
      );

      if (this.appConfig.DEBUG) {
        console.log(
          'TouchControlsService::getFocusPosition pan (%s, %s)',
          pan.x,
          pan.y
        );
      }
      this.showFocusMode = true;
      this.focusResolve = resolve;
      this.imgViewer.viewport.panTo(pan).ensureVisible();

      setTimeout(() => {
        if (!this.animated) {
          this.onReleaseCanvasBound();
        }
      }, 100);
    });
    return promise;
  }

  _onReleaseCanvas(): void {
    if (this.appConfig.DEBUG) {
      console.log(
        'TouchControlsService::_onReleaseCanvas currewnt zoom: ' +
          this.imgViewer.viewport.getZoom()
      );
    }

    if (this.imgViewer.viewport.getZoom() < this.minZoomLevel) {
      this.imgViewer.viewport.zoomTo(this.minZoomLevel);
      this.imgViewer.viewport.applyConstraints();
    } else {
      this.center.x = this.imgViewer.viewport.getCenter(false).x;
      this.center.y = this.imgViewer.viewport.getCenter(false).y;
      this.dragMode = false;
      this.animated = false;
      if (this.appConfig.DEBUG) {
        console.log(
          'TouchControlsService::_onReleaseCanvas center (%s, %s)',
          this.center.x,
          this.center.y
        );
      }
      this.emitEvent(new TouchControlsEvent(this.VIEWBOUNDARIES));

      if (this.showFocusMode) {
        this.showFocusMode = false;

        const point = this.imgViewer.viewport.imageToWindowCoordinates(
          new OpenSeadragon.Point(this.focusData.x, this.focusData.y)
        );

        if (this.appConfig.DEBUG) {
          console.log(
            'TouchControlsService::_onReleaseCanvas / getFocusPosition point: ' +
              JSON.stringify(point, null, 4)
          );
        }

        const focus = new Focus();
        focus.xpos = point.x;
        focus.ypos = point.y;

        this.focusResolve(focus);
      }
    }
  }

  error(nbErrors): void {
    if (this.appConfig.DEBUG) {
      console.log('TouchControlsService:error ' + nbErrors);
    }
    if (nbErrors > this.appConfig.MAX_ERRORS - 1) {
      this.soundService.negative();
    }
    gsap.killTweensOf('#error');
    if (this.errorTween) {
      gsap.killTweensOf('.error-warning');
      $('.error-warning').css('display', 'none').css('opacity', 0);
    }
    $('#error').css('display', 'block').css('opacity', 1);
    gsap.to('#error', {
      duration: 1.2,
      opacity: 0,
      delay: 0.5,
      ease: 'power3.out',
      onComplete: () => {
        $('#error').css('display', 'none');

        if (nbErrors === this.appConfig.MAX_ERRORS - 1) {
          $('.error-warning').css('display', 'block');
          gsap.to('.error-warning', {
            duration: 0.6,
            opacity: 1,
            ease: 'power3.out',
            onComplete: () => {
              gsap.to('.error-warning', {
                duration: 0.6,
                opacity: 0,
                delay: 0.5,
                ease: 'power3.out',
                onComplete: () => {
                  this.errorTween = gsap.to('.error-warning', {
                    duration: 1.6,
                    opacity: 0.7,
                    ease: 'power3.out',
                    repeat: -1,
                    yoyo: true,
                  });
                },
              });
            },
          });
        } else {
          gsap.to('#error', {
            duration: 0.6,
            opacity: 0,
            delay: 0.5,
            ease: 'power3.out',
            onComplete: () => {
              $('#error').css('display', 'none');
            },
          });
        }
      },
    });
  }
  enable(): void {}
  disable(): void {}
  _pause(): void {}
  _resume(): void {}
  _blur(): void {}
  _unblur(): void {}

  _onLoaded(): void {
    if (this.appConfig.DEBUG) {
      console.log('TouchControlsService::_onLoaded');
    }
    this.imgViewer.removeHandler('tile-loaded', this.loadBound);
    gsap.killTweensOf('#loading');
    gsap.to('#loading', {
      duration: 0.6,
      opacity: 0,
      ease: this.appConfig.tweenFunc,
      onComplete: () => {
        $('#loading').css('display', 'none');

        this.screenService.emitEvent(
          new ScreenEvent(this.screenService.GAMEVIEWLOADED)
        );
      },
    });
  }

  _computeMinZoomLevel(): number {
    const innerWidth = window.innerWidth;
    const innerHeight = window.innerHeight;
    // let innerWidth = window.screen.width;
    // let innerHeight = window.screen.height;
    // if (this.utils.md().os() === 'iOS' || this.utils.md().os() === 'iPadOS') {
    //   innerWidth = window.innerWidth;
    //   innerHeight = window.innerHeight;
    // } else {
    //   innerWidth = window.screen.width;
    //   innerHeight = window.screen.height;
    // }
    // if (this.utils.md().mobile() !== null) {
    //   const OS = this.utils.parser.getOS().name;

    //   if (OS === 'iOS') {
    //     if (this.appConfig.DEBUG) {
    //       console.log('TouchControlsService:_computeMinZoomLevel 1 Safari !!');
    //     }

    //     return 1;
    //   }
    // }

    const ratio =
      this.appConfig.RESOLUTION.width / this.appConfig.RESOLUTION.height;
    let minZoom = 1;

    if (this.appConfig.DEBUG) {
      console.log(
        'TouchControlsService:_computeMinZoomLevel innerWidth:' + innerWidth
      );
      console.log(
        'TouchControlsService:_computeMinZoomLevel innerHeight:' + innerHeight
      );
    }
    const currentHeight = innerWidth / ratio;
    if (currentHeight < innerHeight) {
      const targetWidth = innerHeight * ratio;
      minZoom = targetWidth / innerWidth;
    }
    // minZoom = 0;
    if (this.appConfig.DEBUG) {
      console.log('TouchControlsService:_computeMinZoomLevel ' + minZoom);
    }
    return minZoom;
  }
  computeMinZoomLevel(): any {
    const promise = new Promise((resolve) => {
      setTimeout(() => {
        const innerWidth = window.innerWidth;
        const innerHeight = window.innerHeight;

        const ratio =
          this.appConfig.RESOLUTION.width / this.appConfig.RESOLUTION.height;
        let minZoom = 1;

        if (this.appConfig.DEBUG) {
          console.log(
            'TouchControlsService:_computeMinZoomLevel innerWidth:' + innerWidth
          );
          console.log(
            'TouchControlsService:_computeMinZoomLevel innerHeight:' +
              innerHeight
          );
        }
        const currentHeight = innerWidth / ratio;
        if (currentHeight < innerHeight) {
          const targetWidth = innerHeight * ratio;
          minZoom = targetWidth / innerWidth;
        }
        // minZoom = 0;
        if (this.appConfig.DEBUG) {
          console.log('TouchControlsService:_computeMinZoomLevel ' + minZoom);
        }
        resolve(minZoom);
      }, 100);
    });

    return promise;
  }

  _onClickCanvas(event): void {
    if (this.appConfig.DEBUG) {
      console.log(
        'TouchControlsService::_onClickCanvas  ' +
          JSON.stringify(event.quick, null, 4)
      );
    }
    if (this.utils.md().os() !== 'iOS' && this.utils.md().os() !== 'iPadOS') {
      if (!(screenfull as Screenfull).isFullscreen) {
        (screenfull as Screenfull).toggle();
      }
    }

    if (event.quick === true) {
      this.emitEvent(new TouchControlsEvent(this.TOUCH));
    } else {
      // this.emitEvent(new TouchControlsEvent(this.TOUCHUP));
    }
    this.emitEvent(new TouchControlsEvent(this.VIEWBOUNDARIES));
  }

  _onAnimate(): void {
    if (this.appConfig.DEBUG) {
      console.log('TouchControlsService::_onAnimate');
    }
    if (this.imgViewer.viewport.getZoom() < this.minZoomLevel) {
      this.imgViewer.viewport.zoomTo(this.minZoomLevel, null, true);
      this.imgViewer.viewport.applyConstraints();
    }
    if (this.imgViewer.viewport.getZoom() > this.maxZoomLevel) {
      this.imgViewer.viewport.zoomTo(this.maxZoomLevel, null, true);
      this.imgViewer.viewport.applyConstraints();
    }
    // this.emitEvent(new TouchControlsEvent(this.VIEWBOUNDARIES));

    // if (this.imgViewer) {
    //   console.log(
    //     'TouchControlsService getContainerSize: (%s, %s)',
    //     this.imgViewer.viewport.getContainerSize().x,
    //     this.imgViewer.viewport.getContainerSize().y
    //   );
    //   console.log(
    //     'TouchControlsService getBounds: (%s, %s) [%s / %s]',
    //     this.imgViewer.viewport.getBounds(false).x,
    //     this.imgViewer.viewport.getBounds(false).y,
    //     this.imgViewer.viewport.getBounds(false).width,
    //     this.imgViewer.viewport.getBounds(false).height
    //   );
    // }
  }
  _onPressCanvas(): void {
    if (this.appConfig.DEBUG) {
      console.log('TouchControlsService::_onPressCanvas');
    }
    this.animated = true;
    this.emitEvent(new TouchControlsEvent(this.VIEWBOUNDARIES));
  }

  _onPinchCanvas(): void {
    // console.log('TouchControlsService::_onPinchCanvas');
    this.dragMode = true;
    // this.emitEvent(new TouchControlsEvent(this.VIEWBOUNDARIES));
  }
  _onDragCanvas(): void {
    // console.log('TouchControlsService::_onDragCanvas');
    this.dragMode = true;
    // this.emitEvent(new TouchControlsEvent(this.VIEWBOUNDARIES));
  }
  _onDragEndCanvas(): void {
    // console.log('TouchControlsService::_onDragEndCanvas');
    // this.emitEvent(new TouchControlsEvent(this.VIEWBOUNDARIES));
  }

  public get viewport(): OpenSeadragon.Viewport {
    return this.imgViewer.viewport;
  }
}
