export class Players {
  id = 0;
  token = '';
  jwt = '';
  username = '';
  password = '';
  firstName = '';
  lastName = '';
  ageRange = '';
  location = '';
  picture = 'assets/images/anonymousProfile.png';
  email = '';
  gender = '';
  provider = 'anonymous';
  lang = 'fr';
  lastLogin = 0;
  lastSaved = 0;
  startTime = 0;
  endTime = 0;
  connected = false;
  score = 0;
  score66 = 0;
  baseScore = 50;
  nbError = 0;
  nbCombo = 0;
  nbGold = 0;
  nbSeries = 0;
  nbMovies = 0;
  position = 0;
  badges = [];
  quizz = [];
  blindtest = [];
  answers = [];
  gameid = '';
}

export class Player {
  id: number;
  token: string;
  jwt: string;
  gameid: string;
  username: string;
  password: string;
  name: string;
  ageRange: string;
  location: string;
  picture: string;
  email: string;
  gender: string;
  provider: string;
  lang: string;
  lastLogin: number;
  lastSaved: number;
  startTime: number;
  endTime: number;
  connected: boolean;
  score: number;
  score66: number;
  baseScore: number;
  nbError: number;
  nbCombo: number;
  nbGold: number;
  nbSeries: number;
  nbMovies: number;
  position: number;
  ranking: string;
  badges: Array<any>;
  quizz: Array<any>;
  blindtest: Array<any>;
  answers: Array<any>;
}
