import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Power3 } from 'gsap';
// import data from '../../../assets/conf/app-config.json';
import * as $ from 'jquery';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  private appConfig: any;
  webgl = false;
  realViewWidth = 4961;
  realViewHeight = 3508;
  viewWidth = Math.floor((this.realViewWidth * 2) / 3);
  viewHeight = Math.floor((this.realViewHeight * 2) / 3);
  RESOLUTION = { width: this.realViewWidth, height: this.realViewHeight };
  ZOOM_SPEED = 4;
  canvasID = 'gameview';
  AUTOZOOM_SPEED = 6;

  BADGE_DELAY = 50;
  BADGE_DURATION = 1000; // 1500
  BADGE_SCALE_FACTOR = 0.95;
  BADGE_SCALE_UP_FACTOR = 1;
  BADGE_SCALE_DOWN_FACTOR = 0.00001;
  BADGE_SCALE_GOLD_FACTOR = 0.1;

  SCORE_REFS = 10;
  SCORE_EASY_REFS = 10;
  SCORE_MEDIUM_REFS = 20;
  SCORE_HARD_REFS = 30;
  SCORE_CHEATCODE = 100;
  SCORE_ERROR = -50;
  SCORE_QUIZ = 10;
  SCORE_GOLD = 10;
  SCORE_COMBO = 30;
  MAX_ERRORS = 3;
  MAX_REFS = 40;

  EASY_FLAG = 1;
  MEDIUM_FLAG = 2;
  HARD_FLAG = 3;
  CHEATCODE_FLAG = 4;

  BONUS_STEP = 6;
  SAVE_STEP = 15;
  TUTO_BONUS_STEP = 6;
  TUTO_RADAR_STEP = 10;
  TUTO_ZOOM_STEP = 7;

  EASY_COLOR = '#234595';
  MEDIUM_COLOR = '#ffbe00';
  HARD_COLOR = '#d7003c';
  CANVAS_COLOR = 0xaed9d6;
  // ZOOMIN_EASE = "power3.easeInOut";
  ZOOMIN_EASE = 'power3.inOut';
  ZOOMIN_DURATION = 3;
  TIMER_CLOSE_PINS = 42;
  ENDSCREEN_DURATION = 2; // sec
  TIMER_FIRST_SAVE = 1500;

  INTROVOLUME = 0.8;
  TRACKVOLUME = 1;
  BTVOLUME = 0.8;
  VOICEVOLUME = 0.8;
  FXVOLUME = 0.4;
  LOWVOLUME = 0.4;

  COMBO_MAX_HISTORY = 84;
  MAX_BONUS = 6;
  TOP_LIMIT = 10;

  // tslint:disable-next-line: quotemark
  INPUT_MASK = "^[a-zA-Z0-9àéèç\\-',&:!?.\\s]+$";
  INPUT_MAX_FONTSIZE = 90;
  INPUT_MIN_FONTSIZE = 10;
  INPUT_PADDING = 100;

  ERROR_DURATION = 5;
  LUCID_ID = 'L456-2';
  // RB_ALIAS = 'rb_play_hard_2020_staging';
  RB_ALIAS = 'rb_play_hard_2020';
  RB_SOURCE = 'RB_PLAYHARD_';
  LANG = 'fr';
  API_URL = 'http://192.168.0.31:8000';
  ASSETS_URL = '';
  DEBUG = true;
  GA_STATS = true;

  APP_VERSION = '1.0';
  IS_MOBILE = false;
  TWEEN = 0.4;

  PRODUCTION_MODE = false;

  constructor(private http: HttpClient) {}

  /**
   * Load the main app config file app-config.json
   */
  loadAppConfig(): any {
    if (this.PRODUCTION_MODE) {
      this.ASSETS_URL = '';
      this.API_URL = 'https://api-popstar90s.popcorn-esd.com';
      this.DEBUG = false;
    } else {
      this.ASSETS_URL = '';
      this.API_URL = 'https://api-popstar90s.popcorn-esd.com';
    }
    return this.http
      .get(this.ASSETS_URL + '/assets/conf/app-config.json')
      .toPromise()
      .then((data) => {
        this.appConfig = data;
      });
  }

  // getters

  get lang(): string {
    // if (!this.appConfig) {
    // throw Error('Config file not loaded!');
    return this.LANG;
    // }
    // return this.appConfig.LANG;
  }

  get isWebGLSupported(): boolean {
    return this.webgl;
  }

  set isWebGLSupported(value: boolean) {
    this.webgl = value;
  }

  get apiUrl(): string {
    // if (!this.appConfig) {
    // throw Error('Config file not loaded!');
    return this.API_URL;
    // }
    // return this.appConfig.API_URL;
  }

  get assetsUrl(): string {
    // if (!this.appConfig) {
    // throw Error('Config file not loaded!');
    return this.ASSETS_URL;
    // }
    // return this.appConfig.ASSETS_URL;
  }

  get tweenSpeed(): any {
    return this.TWEEN;
  }

  get tweenFunc(): any {
    return 'power3.out';
  }

  get isMobile(): boolean {
    return this.IS_MOBILE;
  }

  set isMobile(val: boolean) {
    this.IS_MOBILE = val;
  }
}
