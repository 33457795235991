import { Component, OnInit, OnDestroy } from '@angular/core';
import * as $ from 'jquery';
import { SVG, Svg } from '@svgdotjs/svg.js';
import {
  PinsService,
  PinsEvent,
  PinObj,
} from '../../../services/view/pins.service';
import {
  MouseControlsService,
  MouseControlsEvent,
} from 'src/app/services/view/mouse-controls.service';
import { Show } from 'src/app/model/show';
import { Focus } from 'src/app/model/focus';
import { Bound } from 'src/app/model/bound';
import { AppConfigService } from 'src/app/services/config/app-config.service';
import { UtilsService } from 'src/app/services/utilities/utils.service';
import {
  CursorService,
  CursorEvent,
} from 'src/app/services/view/cursor.service';
import { SoundService } from 'src/app/services/sound/sound.service';
import gsap from 'gsap';
import {
  TouchControlsService,
  TouchControlsEvent,
} from '../../../services/view/touch-controls.service';
import * as OpenSeadragon from 'openseadragon';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'pins',
  templateUrl: './pins.component.html',
  styleUrls: ['./pins.component.scss'],
})
export class PinsComponent implements OnInit, OnDestroy {
  innerWidth: number;
  innerHeight: number;
  centerX: number;
  centerY: number;
  mainStage: Svg;
  factor = 1;
  viewBound: Bound;
  showHelper = false;
  clickBound;
  escBound;
  rollOverPinTimeout;
  runnerPool = [];
  onEscBinding;
  KEYCODE_ESC = 27;

  constructor(
    private pinsService: PinsService,
    private mouseControlsService: MouseControlsService,
    private touchContolsService: TouchControlsService,
    private cursorService: CursorService,
    private appConfig: AppConfigService,
    private soundService: SoundService,
    private utils: UtilsService
  ) {}

  ngOnInit(): void {
    this.mainStage = SVG()
      .addTo(document.getElementById('pins'))
      .size('100%', '100%');

    this.pinsService.emitter$.subscribe((event: PinsEvent) =>
      this._eventDispatch(event)
    );
    this.mouseControlsService.emitter$.subscribe(
      (event: MouseControlsEvent) => {
        if (event.name === this.mouseControlsService.VIEWBOUNDARIES) {
          this.updatePinsContainer(event.bound);
        }
      }
    );
    if (this.utils.md().mobile() !== null) {
      this.touchContolsService.emitter$.subscribe(
        (event: TouchControlsEvent) => {
          if (event.name === this.touchContolsService.VIEWBOUNDARIES) {
            this.updatePinsContainerMobile();
          }
        }
      );
    }

    this.clickBound = (() => {
      this.onClickContainer();
    }).bind(this);
    // this.escBound = ((event) => {
    //   this.onEsc(event);
    // }).bind(this);
  }

  ngOnDestroy(): void {
    if (this.appConfig.DEBUG) {
      console.log('PinsComponent:ngOnDestroy');
    }
  }

  _eventDispatch(event: PinsEvent): void {
    switch (event.name) {
      case this.pinsService.SHOWFOCUS:
        this.showFocus(event.show).then((res) => {
          if (this.appConfig.DEBUG) {
            console.log(res);
          }
          this.pinsService.emitEvent(
            new PinsEvent(this.pinsService.SHOWFOCUSSUCESS, event.show)
          );
        });
        break;
      case this.pinsService.CREATEPINS:
        this.createPin(event.show);
        this.touchContolsService.emitEvent(
          new TouchControlsEvent(this.touchContolsService.VIEWBOUNDARIES)
        );
        break;
      case this.pinsService.SHOWPINS:
        this.cursorService.loading();
        this.showPins(event.pinsPool).then((res) => {
          // if (this.appConfig.DEBUG) {
          //   console.log(res);
          // }
          this.cursorService.cross();
          this.pinsService.emitEvent(
            new PinsEvent(this.pinsService.SHOWPINSSUCESS, event.show)
          );
        });
        break;
      case this.pinsService.HIDEPINS:
        this.cursorService.normal();
        this.hidePins(event.pinsPool).then((res) => {
          if (this.appConfig.DEBUG) {
            console.log(res);
          }
          this.pinsService.pinsHidden();
        });
        break;
      default:
        break;
    }
  }

  updatePinsContainer(bound: Bound): void {
    // console.log('PinsComponent:updatePinsContainer top: ' + bound.top);

    const aspect = $(window).innerWidth() / $(window).innerHeight();
    const ratio = this.appConfig.realViewWidth / this.appConfig.realViewHeight;
    this.viewBound = bound;
    if (aspect >= ratio) {
      this.factor = this.appConfig.realViewWidth / $(window).innerWidth();
    } else {
      this.factor = this.appConfig.realViewHeight / $(window).innerHeight();
    }

    if (this.pinsService.getPinsPool().length > 0) {
      this.pinsService.getPinsPool().forEach((pin) => {
        const x = pin.x / this.factor + bound.left;
        const y = pin.y / this.factor + bound.top;

        pin.core.transform({ px: x, py: y });
        pin.frame.transform({ rotate: 45, px: x, py: y });
        pin.roll.transform({ rotate: 45, px: x, py: y });
        if (pin.active) {
          pin.title.center(x, y - 30);
          if (x < 100) {
            pin.title.move(x + 30, y - 6);
          }
        } else {
          pin.title.center(x, y - 30);
          if (x < 100) {
            pin.title.move(x + 30, y - 6);
          }
        }
      });
    }
  }

  updatePinsContainerMobile(): void {
    if (this.appConfig.DEBUG) {
      console.log('PinsComponent:updatePinsContainerMobile');
    }

    if (this.pinsService.getPinsPool().length > 0) {
      this.pinsService.getPinsPool().forEach((pin) => {
        const point =
          this.touchContolsService.viewport.imageToWindowCoordinates(
            new OpenSeadragon.Point(pin.x, pin.y)
          );

        pin.core.transform({ px: point.x, py: point.y, ox: 2, oy: 2 });
        pin.frame.transform({ px: point.x, py: point.y, ox: 7, oy: 7 });
        pin.roll.transform({ px: point.x, py: point.y });
        if (pin.active) {
          pin.title.center(point.x, point.y - 30);
          if (point.x < 100) {
            pin.title.move(point.x + 30, point.y - 6);
          }
        } else {
          pin.title.center(point.x, point.y - 30);
          if (point.x < 100) {
            pin.title.move(point.x + 30, point.y - 6);
          }
        }
      });
    }
  }

  createPin(show: Show): void {
    if (this.appConfig.DEBUG) {
      console.log('PinComponent:createPin ' + show.badge_id);
    }

    // this.mainStage = SVG()
    //   .addTo(document.getElementById('pin'))
    //   .size('100%', '100%');
    const group = this.mainStage.nested();

    const core = group.circle(4);
    core.attr({ fill: '#fff' }).addClass('disabled');
    // .transform({ px: Number(show.x), py: Number(show.y) });
    let frame;
    if (this.utils.md().mobile() !== null) {
      frame = group.circle(14);
      frame
        .attr({
          stroke: '#fff',
          'stroke-width': 1,
          fill: '#fff',
          'fill-opacity': 0.01,
        })
        .addClass('disabled');
    } else {
      frame = group.circle(18);
      frame
        .attr({
          stroke: '#fff',
          'stroke-width': 2,
          fill: '#fff',
          'fill-opacity': 0.01,
        })
        .addClass('disabled');
    }
    frame
      .attr({
        stroke: '#fff',
        'stroke-width': 2,
        fill: '#fff',
        'fill-opacity': 0.01,
      })
      .addClass('disabled');
    const roll = group.circle(30);
    roll.attr({
      fill: '#fff',
      'fill-opacity': 0.01,
    });

    show.title = show.title.replace(/<br>/gi, ' ');
    // show.title = show.title.replace('/', '');
    const title = group.text(show.title.toUpperCase());
    title
      .font({
        family: 'Futura',
        size: 14,
        leading: '1em',
        anchor: 'middle',
        weight: '500',
      })
      .attr({
        opacity: 0,
        fill: '#fff',
        'letter-spacing': 1.5,
      })
      .addClass('disabled');
    // .center(Number(show.x), position.ypos - 10);

    const obj = {
      idx: 0,
      svg: this.mainStage,
      core,
      frame,
      title,
      group,
      roll,
      active: false,
      x: Number(show.x),
      y: Number(show.y),
    };
    // ROLLOVER

    roll.on('mouseover', () => {
      this.onMouseOverPin(obj);
    });
    roll.on('mouseout', () => {
      this.onMouseOutPin(obj);
    });

    group.attr({ opacity: 0 });

    this.pinsService.storePin(obj);

    setTimeout(() => {
      if (this.utils.md().mobile() !== null) {
        this.updatePinsContainerMobile();
      }
    }, 100);
  }

  onMouseOverPin(obj: PinObj): void {
    if (this.appConfig.DEBUG) {
      console.log('onMouseOverPin: ' + obj.title);
    }
    this.rollOverPinTimeout = setTimeout(() => {
      this.cursorService.emitEvent(
        new CursorEvent(this.cursorService.ROLLOVER)
      );
      obj.active = true;
      obj.frame
        .animate({ duration: 300, ease: 'quartOut' })
        .attr({ 'fill-opacity': 1 });
      // obj.title
      //   .animate({ duration: 300, delay: 150 })
      //   .attr({ opacity: 1 })
      this.utils.blink(obj.title);
      // .center(x, y - 30);
    }, 250);
  }
  onMouseOutPin(obj: PinObj): void {
    if (this.appConfig.DEBUG) {
      console.log('onMouseOutPin: ' + obj.title);
    }
    if (this.rollOverPinTimeout) {
      clearTimeout(this.rollOverPinTimeout);
    }
    this.cursorService.emitEvent(new CursorEvent(this.cursorService.ROLLOUT));
    //   this.runnerTitle.finish();
    //   this.runnerFrame.finish();
    obj.frame
      .animate({ duration: 200, ease: 'quartOut' })
      .attr({ 'fill-opacity': 0.01 });
    obj.title
      .animate({ duration: 100, ease: 'sineIn' })
      .attr({ opacity: 0 })
      // .center(x, y - 30)
      .after(() => {
        obj.active = false;
      });
  }

  showPins(pinsPool: Array<PinObj>): any {
    const promise = new Promise((resolve, reject) => {
      if (pinsPool.length > 0) {
        this.soundService.open();

        $('#pinsContainer')
          .css('display', 'block')
          .animate({ opacity: 1 }, 400);
        $('#pinsContainer > #bg')
          .css('display', 'block')
          .animate({ opacity: 0.42 }, 400, () => {
            // tslint:disable-next-line: prefer-for-of
            for (let i = 0; i < pinsPool.length; i++) {
              const pin = pinsPool[i];

              pin.title.attr({ opacity: 0 });
              this.utils.blink(pin.group, i * 25).then((runner) => {
                this.runnerPool[i] = runner;
              });
            }
            $('#pinsContainer').on('click', this.clickBound);
            // $(document).on(
            //   'keyup',
            //   null,
            //   { callbackFunc: this.clickBound },
            //   this.escBound
            // );
          });
        resolve('pins ok');
      } else {
        reject('no pins');
      }
    });
    return promise;
  }

  onClickContainer(): void {
    $('#pinsContainer').off('click', this.clickBound);
    this.pinsService.hidePins();
  }

  onEsc(event): void {
    event.preventDefault();
    event.stopPropagation();
    if (this.appConfig.DEBUG) {
      console.log('PinComponent:onEsc');
    }

    $(document).off('keyup', this.escBound);
    if (event.keyCode === 27) {
      event.data.callbackFunc();
    } else {
      $(document).on(
        'keyup',
        null,
        { callbackFunc: this.clickBound },
        this.escBound
      );
    }
  }

  hidePins(pinsPool: Array<PinObj>): any {
    const promise = new Promise((resolve) => {
      if (pinsPool.length > 0) {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < pinsPool.length; i++) {
          const pin = pinsPool[i];
          gsap.killTweensOf(this.runnerPool[i]);
          pin.group
            .animate({ duration: 100, ease: 'quadOut' })
            .attr({ opacity: 0 });
        }
      }
      $('#pinsContainer').animate({ opacity: 0 }, 200, () => {
        $('#pinsContainer').css('display', 'none');
      });
      $('#pinsContainer > #bg').animate({ opacity: 0 }, 200, () => {
        $('#pinsContainer > #bg').css('display', 'none');
        resolve('pins hidden');
      });
    });

    return promise;
  }

  showFocus(show: Show): any {
    if (this.appConfig.DEBUG) {
      console.log('PinsComponent:showFocus ' + show.badge_id);
    }
    const promise = new Promise((resolve) => {
      if (this.utils.md().mobile() !== null) {
        this.touchContolsService
          .getFocusPosition(show)
          .then((focusObj: Focus) => {
            // const focusObj = new Focus();
            // focusObj.xpos = 500;
            // focusObj.ypos = 300;

            this.createPin(show);

            $('#focus').css('top', focusObj.ypos - 75 + 'px');
            $('#focus').css('left', focusObj.xpos - 75 + 'px');
            $('#focus').css('opacity', 1);
            $('#pinsContainer').css('display', 'block').css('opacity', 1);
            // .animate({ opacity: 1 }, 400);
            if (this.appConfig.DEBUG) {
              console.log(
                'PinsComponent:showFocus: focus xpos: ' + focusObj.xpos
              );
              console.log(
                'PinsComponent:showFocus: focus ypos: ' + focusObj.ypos
              );
            }

            gsap.to('#focus', {
              duration: 0.2,
              ease: 'quad.out',
              opacity: 1,
              onComplete: () => {
                gsap.to('#focus', {
                  delay: 1,
                  duration: 0.2,
                  ease: 'quad.out',
                  opacity: 0,
                  onComplete: () => {
                    $('#pinsContainer').animate({ opacity: 0 }, 400, () => {
                      $('#pinsContainer').css('display', 'none');
                    });
                    resolve('focus hidden!');
                  },
                });
              },
            });
          });
      } else {
        this.mouseControlsService
          .getFocusPosition(show)
          .then((focusObj: Focus) => {
            // const focusObj = new Focus();
            // focusObj.xpos = 500;
            // focusObj.ypos = 300;
            this.createPin(show);

            $('#focus').css('top', focusObj.ypos - 75 + 'px');
            $('#focus').css('left', focusObj.xpos - 75 + 'px');
            $('#focus').css('opacity', 1);
            $('#pinsContainer').css('display', 'block').css('opacity', 1);
            // .animate({ opacity: 1 }, 400);
            if (this.appConfig.DEBUG) {
              console.log(
                'PinsComponent:showFocus: focus xpos: ' + focusObj.xpos
              );
              console.log(
                'PinsComponent:showFocus: focus ypos: ' + focusObj.ypos
              );
            }

            gsap.to('#focus', {
              duration: 0.2,
              ease: 'quad.out',
              opacity: 1,
              onComplete: () => {
                gsap.to('#focus', {
                  delay: 1,
                  duration: 0.2,
                  ease: 'quad.out',
                  opacity: 0,
                  onComplete: () => {
                    $('#pinsContainer').animate({ opacity: 0 }, 400, () => {
                      $('#pinsContainer').css('display', 'none');
                    });
                    resolve('focus hidden!');
                  },
                });
              },
            });
          });
      }
    });
    return promise;
  }
}
