import { Injectable, EventEmitter } from '@angular/core';
import * as TimeMe from 'timeme.js';
import { SoundService } from 'src/app/services/sound/sound.service';
import { AppConfigService } from 'src/app/services/config/app-config.service';

export class TimerEvent {
  constructor(public name: string, public value?: number) {}
}

@Injectable({
  providedIn: 'root',
})
export class TimerService {
  public emitter$: EventEmitter<TimerEvent>;
  timer;
  bonusTimer;
  nbSeconds = 0;
  paused = false;

  UPDATE = 'TimerService:UPDATE';
  CHRONOSTART = 'TimerService:CHRONOSTART';
  CHRONOUPDATE = 'TimerService:CHRONOUPDATE';
  CHRONOEND = 'TimerService:CHRONOEND';

  constructor(
    private soundService: SoundService,
    private appConfig: AppConfigService
  ) {
    this.emitter$ = new EventEmitter();
  }

  emitEvent(event: TimerEvent, log: boolean = false): void {
    if (this.appConfig.DEBUG) {
      if (log) {
        console.log('TimerService emitEvent %s', event.value);
      }
    }
    this.emitter$.emit(event);
  }

  init(): void {
    if (this.appConfig.DEBUG) {
      console.log('TimerService:init');
    }

    TimeMe.initialize({
      currentPageName: 'PlayHard', // current page
      idleTimeoutInSeconds: 30, // seconds
    });
    TimeMe.stopTimer();
  }

  start(): void {
    if (this.appConfig.DEBUG) {
      console.log('TimerService:start');
    }
    TimeMe.startTimer();
    this.timer = setInterval(() => {
      // console.log('TimerService: ' + TimeMe.getTimeOnCurrentPageInSeconds());
      this.emitEvent(
        new TimerEvent(this.UPDATE, TimeMe.getTimeOnCurrentPageInSeconds())
      );
    }, 1000);
  }

  stop(): void {
    if (this.appConfig.DEBUG) {
      console.log('TimerService:stop');
    }
    clearInterval(this.timer);
    TimeMe.stopTimer();
  }

  startChrono(nbSeconds: number = 42): void {
    if (this.appConfig.DEBUG) {
      console.log('TimerService:startChrono: ' + nbSeconds);
    }
    clearInterval(this.bonusTimer);
    this.nbSeconds = nbSeconds;
    this.emitEvent(new TimerEvent(this.CHRONOSTART, nbSeconds), true);
    this.paused = false;
    this.bonusTimer = setInterval(() => {
      if (!this.paused) {
        this.emitEvent(new TimerEvent(this.CHRONOUPDATE, this.nbSeconds), true);
        this.nbSeconds--;
        if (this.nbSeconds < 11) {
          this.soundService.dimSound();
          this.soundService.tick();
        }
        if (this.nbSeconds < 0) {
          // this.soundService.undimSound()
          this.soundService.endChrono();
          this.stopChrono();
        }
      }
    }, 1000);
  }

  pauseChrono(): void {
    if (this.appConfig.DEBUG) {
      console.log('TimerService:pauseChrono');
    }

    this.paused = true;
  }

  resumeChrono(): void {
    if (this.appConfig.DEBUG) {
      console.log('TimerService:resumeChrono');
    }

    this.paused = false;
  }

  stopChrono(): void {
    if (this.appConfig.DEBUG) {
      console.log('TimerService:stopChrono');
    }
    clearInterval(this.bonusTimer);
    this.nbSeconds = 66;

    this.emitEvent(new TimerEvent(this.CHRONOEND, 0), true);
  }
}
