import { Injectable, EventEmitter } from '@angular/core';
import { Combo } from 'src/app/model/combo';
import { AppConfigService } from 'src/app/services/config/app-config.service';

export class BonusEvent {
  constructor(
    public name: string,
    public index?: number,
    public comboName?: string,
    public score?: number
  ) {}
}
@Injectable({
  providedIn: 'root',
})
export class BonusService {
  public emitter$: EventEmitter<BonusEvent>;

  public SHOW = 'BonusService:show';
  public HIDE = 'BonusService:hide';
  public UNLOCK = 'BonusService:unlock';
  public DONE = 'BonusService:done';
  public RECAP = 'BonusService:recap';

  constructor(private appConfig: AppConfigService) {
    this.emitter$ = new EventEmitter();
  }

  public emitEvent(event: BonusEvent): void {
    if (this.appConfig.DEBUG) {
      console.log(
        'BonusEvent emitEvent %s (%s) / %s / %s',
        event.name,
        event.comboName,
        event.index,
        event.score
      );
    }
    this.emitter$.emit(event);
  }

  show(idx): void {
    this.emitEvent(new BonusEvent(this.SHOW, idx));
  }

  hide(idx): void {
    this.emitEvent(new BonusEvent(this.HIDE, idx));
  }

  unlock(combo: Combo): void {
    this.emitEvent(new BonusEvent(this.UNLOCK, combo.position, combo.comboID));
  }
  done(idx): void {}

  recap(comboName: string, position: number, score: number): void {
    this.emitEvent(new BonusEvent(this.RECAP, position, comboName, score));
  }
}
