import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from 'src/app/model/user';
import { HttpClient } from '@angular/common/http';
import { map, first } from 'rxjs/operators';
import { AppConfigService } from 'src/app/services/config/app-config.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AlertService } from '../utilities/alert.service';
// import { AuthService } from 'angularx-social-login';
// import { FacebookLoginProvider } from 'angularx-social-login';

export class AuthEvent {
  constructor(public name: string, public user?: User) {}
}

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  public emitter$: EventEmitter<AuthEvent>;

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  public AUTHSUCCESS = 'AuthenticationService:AUTHSUCCESS';
  public AUTHFAILED = 'AuthenticationService:AUTHFAILED';

  constructor(
    private http: HttpClient,
    private appConfig: AppConfigService,
    private alertService: AlertService,
    // private fbAuthService: AuthService,
    private jwtHelper: JwtHelperService
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser'))
    );
    this.currentUser = this.currentUserSubject.asObservable();
    this.emitter$ = new EventEmitter();
  }

  public init(): void {
    if (this.appConfig.DEBUG) {
      console.log('AuthenticationService:init');
      console.log(
        'AuthenticationService:token: ' + localStorage.getItem('token')
      );
    }

    // this.fbAuthService.authState.subscribe((user) => {
    //   this.fbLoginCallback(user);
    // });
  }
  public emitEvent(event: AuthEvent): void {
    if (this.appConfig.DEBUG) {
      console.log('AuthenticationService emitEvent %s', event.name);
    }
    this.emitter$.emit(event);
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  public get token(): string {
    return localStorage.getItem('token');
  }

  register(user: User, providerType: string): any {
    if (this.appConfig.DEBUG) {
      console.log(
        'AuthenticationService:register' + JSON.stringify(user, null, 4)
      );
    }
    localStorage.removeItem('currentUser');
    localStorage.removeItem('token');

    user.provider_type = providerType;
    if (providerType === 'login') {
      user.provider_token = user.password;
      user.provider_id = user.email;
      delete user.password;
    }

    if (this.appConfig.DEBUG) {
      console.log(user);
    }
    return this.http
      .post<User>(this.appConfig.apiUrl + '/user/' + this.appConfig.lang, user)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  login(user: User): any {
    if (this.appConfig.DEBUG) {
      console.log(
        'AuthenticationService:login' + JSON.stringify(user, null, 4)
      );
    }
    localStorage.removeItem('currentUser');
    localStorage.removeItem('token');
    user.provider_type = 'login';
    user.provider_token = user.password;
    user.provider_id = user.email;

    return this.http
      .post<User>(this.appConfig.apiUrl + '/login/' + this.appConfig.lang, user)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  logout(): void {
    console.log('AuthenticationService:logout');

    // remove user from local storage and set current user to null
    localStorage.removeItem('currentUser');
    localStorage.removeItem('token');
    this.currentUserSubject.next(null);
  }

  isAuthenticated(): boolean {
    return this.jwtHelper.isTokenExpired();
  }

  submitRegister(values): void {
    if (this.appConfig.DEBUG) {
      console.log(
        'AuthenticationService:submitRegister' + JSON.stringify(values, null, 4)
      );
    }

    // values.name = values.fname;
    // reset alerts on submit
    this.alertService.clear();
    this.register(values, 'login')
      .pipe(first())
      .subscribe(
        (data) => {
          // data.game = '';
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(data));
          this.currentUserSubject.next(data);
          localStorage.setItem('token', data.jwt);
          if (this.appConfig.DEBUG) {
            console.log('Registration successful ' + JSON.stringify(data));
          }
          this.emitEvent(new AuthEvent(this.AUTHSUCCESS, data));
        },
        (error) => {
          const errorCode = error.error;
          if (this.appConfig.DEBUG) {
            // console.log('Registration failed ' + JSON.stringify(error));
            console.log('Registration failed ' + errorCode[0]);
          }

          if (errorCode[0] === 2) {
            this.alertService.error('User already exists');
          } else {
            this.alertService.error('Registration failed');
          }
          this.emitEvent(new AuthEvent(this.AUTHFAILED));
        }
      );
  }

  submitLogin(values): void {
    if (this.appConfig.DEBUG) {
      console.log(
        'AuthenticationService:submitLogin' + JSON.stringify(values, null, 4)
      );
    }
    // reset alerts on submit
    this.alertService.clear();
    this.login(values)
      .pipe(first())
      .subscribe(
        (data) => {
          // data.game = '';
          if (data.game === null) {
            data.game = '';
          }
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(data));
          this.currentUserSubject.next(data);
          localStorage.setItem('token', data.jwt);
          if (this.appConfig.DEBUG) {
            console.log('Login successful ' + JSON.stringify(data));
          }
          this.emitEvent(new AuthEvent(this.AUTHSUCCESS, data));
        },
        (error) => {
          if (this.appConfig.DEBUG) {
            console.log('Login failed ' + JSON.stringify(error));
          }
          this.alertService.error('Username or password is incorrect');
          this.emitEvent(new AuthEvent(this.AUTHFAILED));
        }
      );
  }

  fbLoginCallback(user): void {
    if (this.appConfig.DEBUG) {
      console.log(
        'AuthenticationService:fbLoginCallback' + JSON.stringify(user, null, 4)
      );
    }
  }
}
