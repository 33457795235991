<div id="home-login" class="d-flex flex-column justify-content-center align-items-center h-100">
  <div class="d-flex flex-row align-items-center title">
    M'IDENTIFIER
  </div>
  <div class="d-flex flex-row align-items-center">
    <img src="{{ assetsUrl }}/assets/images/ranking/liseret.svg" class="liseret">
  </div>
  <div class="d-flex flex-column text-center align-items-center">
    <alert></alert>
    <login></login>
  </div>
  <div class="close-cross">
    <img src="{{ assetsUrl }}/assets/images/x.png" srcset="{{ assetsUrl }}/assets/images/x@2x.png 2x,
                   {{ assetsUrl }}/assets/images/x@3x.png 3x" class="img-fluid">
  </div>
</div>
