import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  ButtonService,
  ButtonEvent,
} from 'src/app/services/view/button.service';
import * as screenfull from 'screenfull';
import { UtilsService } from 'src/app/services/utilities/utils.service';
import { SoundService } from 'src/app/services/sound/sound.service';
import * as $ from 'jquery';
import { CreditsService } from 'src/app/services/view/credits.service';
import { Router } from '@angular/router';
import { GameService } from 'src/app/services/game/game.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { RedbullTrackingService } from 'src/app/services/utilities/redbull-tracking.service';
import {
  CursorEvent,
  CursorService,
} from 'src/app/services/view/cursor.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy {
  constructor(
    private buttonService: ButtonService,
    private soundService: SoundService,
    private creditsService: CreditsService,
    private gameService: GameService,
    private utils: UtilsService,
    private $gaService: GoogleAnalyticsService,
    // private rbTracking: RedbullTrackingService,
    private cursorService: CursorService,

    private router: Router
  ) {}

  isFullscreen = false;
  isMobile = false;

  ngOnInit(): void {
    if (this.utils.md().mobile() !== null) {
      this.isMobile = true;
      $('#footer').css('display', 'flex');
      $('.footer-button').css('display', 'none');
    } else {
      this.isMobile = false;
      $('#footer').css('display', 'flex');
      $('.footer-button').css('display', 'flex');
    }
  }

  ngOnDestroy(): void {
    // console.log('FooterComponent:ngOnDestroy');
  }

  onRollOverButton(): any {
    this.cursorService.emitEvent(new CursorEvent(this.cursorService.ROLLOVER));
  }
  onRollOutButton(): any {
    this.cursorService.emitEvent(new CursorEvent(this.cursorService.ROLLOUT));
  }

  fullscreen(event: MouseEvent): any {
    event.preventDefault();
    event.stopPropagation();
    this.$gaService.event('Fullscreen', 'Interactions', 'Go FullScreen');
    // this.rbTracking.track(
    //   'gaEvent',
    //   'Interactions',
    //   'Fullscreen',
    //   'Go FullScreen',
    //   'Red Bull Play Hard'
    // );
    if (screenfull.isEnabled) {
      screenfull.toggle();
    }
  }

  showCredits(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.$gaService.event('Show Credits', 'Interactions', 'Show credits page');
    // this.rbTracking.track(
    //   'gaEvent',
    //   'Interactions',
    //   'Show Credits',
    //   'Show credits page',
    //   'Red Bull Play Hard'
    // );
    this.creditsService.show();
  }

  toggleRBHeaderFooter(event: MouseEvent): void {
    // console.log('FooterComponent:toggleRBHeaderFooter');
    this.$gaService.event('Show Legals', 'Interactions', 'Show RB Footer');
    // this.rbTracking.track(
    //   'gaEvent',
    //   'Interactions',
    //   'Show Legals',
    //   'Show RB Footer',
    //   'Red Bull Play Hard'
    // );
    event.preventDefault();
    event.stopPropagation();
    if (this.router.url === '/game') {
      this.gameService.pauseGame();
    }
    window.parent.postMessage('show-footer', '*');
    $('.footer').css('display', 'block');

    // window.scrollTo(0, document.body.scrollHeight);
    // this.scrollSmoothToBottom('main-page');
  }

  scrollSmoothToBottom(id): void {
    const div = document.getElementById(id);
    $('#' + id).animate(
      {
        scrollTop: div.scrollHeight - div.clientHeight,
      },
      500
    );
  }

  toggleSound(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    // console.log('FooterComponent:toggleSound');
    const playing = this.soundService.toggleSound();
    if (playing) {
      $('.btn-sound').removeClass('mute');
      this.$gaService.event('Unmute Sound', 'Interactions', 'Sound on');
      // this.rbTracking.track(
      //   'gaEvent',
      //   'Interactions',
      //   'Unmute Sound',
      //   'Sound on',
      //   'Red Bull Play Hard'
      // );
    } else {
      $('.btn-sound').addClass('mute');
      this.$gaService.event('Mute Sound', 'Interactions', 'Souund off');
      // this.rbTracking.track(
      //   'gaEvent',
      //   'Interactions',
      //   'Mute Sounds',
      //   'Souund off',
      //   'Red Bull Play Hard'
      // );
    }
  }
}
