import { Injectable } from '@angular/core';
import { AppConfigService } from 'src/app/services/config/app-config.service';
declare global {
  interface Window {
    dataLayer: any;
  }
}

@Injectable({
  providedIn: 'root',
})
export class RedbullTrackingService {
  constructor(private appConfig: AppConfigService) {
    window.dataLayer = window.dataLayer || [];
  }

  track(event, eventCategory, eventAction, eventLabel, source): void {
    if (this.appConfig.DEBUG) {
      console.log(
        'RedbullTrackingService:track ' +
          event +
          ' / ' +
          eventCategory +
          ' / ' +
          eventAction
      );
    }
    window.dataLayer.push({
      event,
      eventCategory,
      eventAction,
      eventLabel,
      source,
    });
  }
}
