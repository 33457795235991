import { Injectable, EventEmitter } from '@angular/core';

export class ButtonEvent {
  constructor(public name: string) {}
}

@Injectable({
  providedIn: 'root'
})
export class ButtonService {
  public emitter$: EventEmitter<ButtonEvent>;

  public ROLLOVER = 'buttonService:rollover';
  public ROLLOUT = 'buttonService:rollout';
  public CLICK = 'buttonService:click';

  constructor() {
    this.emitter$ = new EventEmitter();
  }

  emitEvent(event: ButtonEvent): void {
    // console.log('ButtonService emitEvent %s', event.name);
    this.emitter$.emit(event);
  }
}
