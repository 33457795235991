import { Injectable, EventEmitter } from '@angular/core';
import { ServerService } from '../http/server.service';
import { Show } from '../../model/show';
import { AppConfigService } from 'src/app/services/config/app-config.service';
import { ToastrService } from 'ngx-toastr';
import { ProgressService } from './progress.service';
import { DashboardService } from 'src/app/services/view/dashboard.service';
import { JarvisService } from 'src/app/services/view/jarvis.service';
import { PlayerService } from '../user/player.service';
import { AnswersType } from 'src/app/model/answers-type';
import { LoadService } from './load.service';
import { Combo, ComboBuffer } from 'src/app/model/combo';
import { Subscription } from 'rxjs';
import { QuizEvent, QuizService } from 'src/app/services/game/quiz.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { RedbullTrackingService } from 'src/app/services/utilities/redbull-tracking.service';

export class ComboEvent {
  constructor(
    public name: string,
    public comboList: Array<Combo>,
    public resolve?: any
  ) {}
}

@Injectable({
  providedIn: 'root',
})
export class ComboService {
  public emitter$: EventEmitter<ComboEvent>;
  combosBuffer: Array<ComboBuffer>;
  popCornTimeBuffer: Array<ComboBuffer>;
  combosData: Array<Combo>;
  comboCount: object;
  popCorntimeCount: object;
  previousComboID: string;
  previousPCTID: string;
  matched: Array<Combo>;
  comboMatched: Array<Combo>;
  answersType = new AnswersType();
  currentMultiplier: number;
  isActive: boolean;
  totalMatched: number;
  quizSubs: Subscription;

  public SHOW = 'ComboService:SHOW';
  public HIDE = 'ComboService:HIDE';
  public HIDDEN = 'ComboService:HIDDEN';
  public STARTPOPCORNTIME = 'ComboService:STARTPOPCORNTIME';
  public ENDPOPCORNTIME = 'ComboService:ENDPOPCORNTIME';
  public KAZUHISA = 'ComboService:KAZUHISA';
  public CHEATCODE = 'ComboService:CHEATCODE';

  constructor(
    private serverService: ServerService,
    private appConfig: AppConfigService,
    private progressService: ProgressService,
    private playerService: PlayerService,
    private jarvisService: JarvisService,
    private dashboardService: DashboardService,
    private loadService: LoadService,
    private quizService: QuizService,
    private $gaService: GoogleAnalyticsService,
    private rbTracking: RedbullTrackingService,
    private toastr: ToastrService
  ) {
    this.emitter$ = new EventEmitter();
  }

  public emitEvent(event: ComboEvent): void {
    if (this.appConfig.DEBUG) {
      console.log('ComboService emitEvent %s', event.name);
    }
    this.emitter$.emit(event);
  }

  init(): void {
    this.combosBuffer = [];
    this.popCornTimeBuffer = [];
    this.comboCount = {};
    this.popCorntimeCount = {};
    this.previousComboID = '';
    this.previousPCTID = '';
    this.matched = [];
    this.comboMatched = [];
    this.currentMultiplier = 1;
    this.isActive = false;
    this.totalMatched = 0;

    this.serverService.getCombos().subscribe(
      (data: Array<Combo>) => {
        if (this.appConfig.DEBUG) {
          console.log('ComboService:init ' + JSON.stringify(data, null, 4));
        }
        this.combosData = data;
        if (this.playerService.hasGame()) {
          const loadedCombos = this.loadService.getLoadedCombos();
          // tslint:disable-next-line: prefer-for-of
          for (let index = 0; index < this.combosData.length; index++) {
            const combo = this.combosData[index];
            if (combo.comboName in loadedCombos) {
              if (this.appConfig.DEBUG) {
                console.log(
                  'ComboService:init loadedCombo -> ' + combo.comboName
                );
              }
              combo.active = 0;
              this.totalMatched++;
            }
          }
        }
      },
      (err) => {
        if (this.appConfig.DEBUG) {
          console.log('ComboService:init 404 !' + err);
        }
        if (this.appConfig.GA_STATS) {
          this.$gaService.event(
            'Fatal Error',
            'Events',
            'Combos infos not loaded'
          );
        }
        this.toastr
          .error(
            'Error [66] ' +
              err.status +
              ' (' +
              err.statusText +
              '). Relance le jeu stp !',
            'Erreur Fatale',
            {
              progressBar: true,
            }
          )
          .onHidden.subscribe(() => {
            // location.reload(true);
          });
        this.combosData = [];
      }
    );
  }

  // ajout du dernier comboID dans l'historique
  addCombo(show: Show): void {
    let combo = new ComboBuffer();
    combo.comboID = show.combo_id;
    combo.bonusID = show.bonus_id;
    combo.flag = show.flag;
    combo.type = show.type;

    this.combosBuffer.push(combo);
    if (this.combosBuffer.length > this.appConfig.COMBO_MAX_HISTORY) {
      this.combosBuffer.shift();
    }

    if (this.appConfig.DEBUG) {
      console.log(
        '%cComboService:addCombo combosBuffer: ' +
          JSON.stringify(this.combosBuffer),
        'color:black; background-color:yellow;padding:10px;padding-top: 10px;padding-bottom:10px;'
      );
    }

    if (this.isActive && combo.bonusID.indexOf('bonus') >= 0) {
      if (this.appConfig.DEBUG) {
        console.log(
          'ComboService:addCombo PopCorn Time IS ACTIVE => ignoring entry'
        );
      }
      return;
    }

    const nbref = this.progressService.score[1];
    if (nbref >= 65 && combo.bonusID.indexOf('bonus') >= 0) {
      if (this.appConfig.DEBUG) {
        console.log(
          'ComboService:addCombo ignoring last entry at ' + nbref + '/66'
        );
      }
      return;
    }

    combo = new ComboBuffer();
    combo.comboID = 'bonus' + show.type;
    combo.flag = show.flag;
    combo.type = show.type;

    this.popCornTimeBuffer.push(combo);
    if (this.popCornTimeBuffer.length > this.appConfig.COMBO_MAX_HISTORY) {
      this.popCornTimeBuffer.shift();
    }

    if (this.appConfig.DEBUG) {
      console.log(
        '%cComboService:addCombo popCornTimeBuffer: ' +
          JSON.stringify(this.popCornTimeBuffer),
        'color:black; background-color:yellow;padding:10px;padding-top: 10px;padding-bottom:10px;'
      );
    }
  }

  checkCombos(): any {
    const promise = new Promise((resolve) => {
      this.comboCount = {};

      // tslint:disable-next-line: prefer-for-of
      for (let index = 0; index < this.combosData.length; index++) {
        const combo = this.combosData[index];
        if (combo.active === 0) {
          continue;
        }
        if (this.appConfig.DEBUG) {
          console.log(
            '%cComboService:checkCombos ' +
              combo.comboName +
              '(' +
              combo.comboID +
              ')',
            'color:black; background-color:aqua;padding:5px;padding-top: 5px;padding-bottom:5px;'
          );
        }

        this.checkNonConsecutiveCombo(combo);
        this.checkConsecutivePCtime(combo);
      }

      if (this.appConfig.DEBUG) {
        console.log(
          '%cComboService:checkCombos popCorntimeCount ' +
            JSON.stringify(this.popCorntimeCount, null, 4),
          'color:black; background-color:aqua;padding:5px;padding-top: 5px;padding-bottom:5px;'
        );
        console.log(
          '%cComboService:checkCombos comboCount ' +
            JSON.stringify(this.comboCount, null, 4),
          'color:black; background-color:aqua;padding:5px;padding-top: 5px;padding-bottom:5px;'
        );
      }

      const combined = [...this.comboMatched, ...this.matched];
      this.matched = combined;
      this.comboMatched = [];
      if (this.matched.length > 0) {
        this.showCombo(resolve);
      } else {
        resolve(true); // retour à la boucle principale du jeu
      }
    });

    return promise;
  }

  checkConsecutivePCtime(combo: Combo): boolean {
    if (this.appConfig.DEBUG) {
      console.log('ComboService:checkConsecutivePCtime');
    }
    this.previousPCTID = '';

    for (let index = 0; index < this.popCornTimeBuffer.length; index++) {
      const buffer = this.popCornTimeBuffer[index];

      // test si le comboId match
      if (buffer.comboID === combo.comboID) {
        if (this.appConfig.DEBUG) {
          console.log(
            'ComboService:checkConsecutivePCtime ID found ! ' + combo.comboID
          );
        }

        // check si les flags matches
        if (this.checkFlag(combo, buffer) && this.checkType(combo, buffer)) {
          if (this.appConfig.DEBUG) {
            console.log('ComboService:checkConsecutivePCtime flags match !');
            console.log(
              'ComboService:checkConsecutivePCtime previousPCTID: ' +
                this.previousPCTID
            );
          }

          // test si on match le precedent
          if (buffer.comboID === this.previousPCTID) {
            // si oui on augmente le compte et on stocke la valuer de l'id
            if (
              (this.isActive && combo.comboType === 2) ||
              this.isActive === false
            ) {
              if (this.popCorntimeCount[combo.comboID] !== undefined) {
                this.popCorntimeCount[combo.comboID] += 1;
              } else {
                this.popCorntimeCount[combo.comboID] = 1;
              }
            }
          } else {
            // this.popCorntimeCount[this.previousPCTID] = 0;
            this.popCorntimeCount[buffer.comboID] = 1;
          }
        }
      }
      this.previousPCTID = buffer.comboID;
    }

    if (this.popCorntimeCount[combo.comboID] === combo.total) {
      // MATCH !
      // console.log(
      //   '!!!! ComboService:checkConsecutivePCtime MATCH ' + combo.comboName
      // );

      this.matched.push(combo);
      // this.totalMatched++;

      if (this.appConfig.DEBUG) {
        console.log(
          '%cComboService:checkConsecutivePCtime MATCH ' + combo.comboName,
          'color:white; background-color:green; font-size:14px; padding:100px;padding-top: 10px;padding-bottom:10px;'
        );
      }

      if (this.appConfig.GA_STATS) {
        this.$gaService.event(
          'PopCornTime Unlocked',
          'Events',
          combo.comboName
        );
      }

      let multiplier = combo.multiplier;
      if (combo.comboType === 2) {
        multiplier = this.currentMultiplier;
      }
      // this.progressService.saveAnswer(
      //   this.answersType.COMBO,
      //   combo.hash,
      //   1,
      //   combo.comboName,
      //   combo.position,
      //   multiplier,
      //   combo.comboType
      // )
      // this.updateScore(combo)

      // if (combo.comboType === 1) {
      //   combo.active = 0
      // } else {
      if (this.appConfig.DEBUG) {
        console.log(
          'ComboService:checkConsecutivePCtime reset count of ' + combo.comboID
        );
      }
      this.popCorntimeCount[combo.comboID] = 0;
      this.filterOut(combo.comboID, 'popcorntime');
      // }

      return true;
    } else {
      return false;
    }
  }

  checkConsecutiveCombo(combo: Combo): boolean {
    if (this.appConfig.DEBUG) {
      console.log('ComboService:checkConsecutiveCombo');
    }
    this.previousComboID = '';

    // tslint:disable-next-line: prefer-for-of
    for (let index = 0; index < this.combosBuffer.length; index++) {
      const buffer = this.combosBuffer[index];

      // test si le comboId match
      if (buffer.comboID === combo.comboID) {
        if (this.appConfig.DEBUG) {
          console.log(
            'ComboService:checkConsecutiveCombo ID found ! ' + combo.comboID
          );
        }

        // check si les flags matches
        if (this.checkFlag(combo, buffer) && this.checkType(combo, buffer)) {
          if (this.appConfig.DEBUG) {
            console.log('ComboService:checkConsecutiveCombo flags match !');
            console.log(
              'ComboService:checkConsecutiveCombo previousComboID: ' +
                this.previousComboID
            );
          }

          // test si on match le precedent
          if (buffer.comboID === this.previousComboID) {
            // si oui on augmente le compte et on stocke la valuer de l'id

            if (
              (this.isActive && combo.comboType === 2) ||
              this.isActive === false
            ) {
              if (this.comboCount[combo.comboID] !== undefined) {
                this.comboCount[combo.comboID] += 1;
              } else {
                this.comboCount[combo.comboID] = 1;
              }
            }
          } else {
            // this.comboCount[this.previousComboID] = 0;
            if (this.previousComboID !== '') {
              this.filterOut(this.combosBuffer[0].comboID);
              this.comboMatched = [];
              this.comboCount = [];
            }

            this.comboCount[buffer.comboID] = 1;
          }
        }
      }
      this.previousComboID = buffer.comboID;
    }
    // if (this.appConfig.DEBUG) {
    //   console.log(
    //     'ComboService:checkConsecutiveCombo count ' +
    //       JSON.stringify(this.comboCount, null, 4)
    //   )
    // }

    if (this.comboCount[combo.comboID] === combo.total) {
      // MATCH !
      // console.log(
      //   '!!!! ComboService:checkConsecutiveCombo MATCH ' + combo.comboName
      // );

      this.comboMatched.push(combo);
      // if (combo.comboType === 2) {
      this.totalMatched++;
      // }
      // if (combo.comboType === 1) {
      //   this.isActive = true
      //   this.currentMultiplier = combo.multiplier
      // }
      if (this.appConfig.DEBUG) {
        console.log(
          '%cComboService:checkConsecutiveCombo MATCH ' + combo.comboName,
          'color:white; background-color:green; font-size:14px; padding:100px;padding-top: 10px;padding-bottom:10px;'
        );
      }

      if (this.appConfig.GA_STATS) {
        this.$gaService.event('Combo Unlocked', 'Events', combo.comboName);
      }

      let multiplier = combo.multiplier;
      if (combo.comboType === 2) {
        multiplier = this.currentMultiplier;
      }
      this.progressService.saveAnswer(
        this.answersType.COMBO,
        combo.hash,
        1,
        combo.comboName,
        combo.position,
        multiplier,
        combo.comboType
      );
      this.updateScore(combo);

      // if (combo.comboType === 1) {
      //   combo.active = 0
      // } else {

      if (combo.total === 6) {
        if (this.appConfig.DEBUG) {
          console.log(
            'ComboService:checkConsecutiveCombo reset count of ' + combo.comboID
          );
        }
        this.comboCount[combo.comboID] = 0;
        this.filterOut(combo.comboID);
      }

      // }

      return true;
    } else {
      return false;
    }
  }

  checkNonConsecutiveCombo(combo: Combo): boolean {
    if (this.appConfig.DEBUG) {
      console.log('ComboService:checkNonConsecutiveCombo');
    }

    // on parcours le buffer à la recherche du comboID
    // tslint:disable-next-line: prefer-for-of
    for (let index = 0; index < this.combosBuffer.length; index++) {
      const buffer = this.combosBuffer[index];
      // test si le comboId match
      if (buffer.comboID === combo.comboID) {
        if (this.appConfig.DEBUG) {
          console.log(
            'ComboService:checkNonConsecutiveCombo ID match ! ' + combo.comboID
          );
        }
        // check si les flags matches
        if (this.checkFlag(combo, buffer) && this.checkType(combo, buffer)) {
          // si oui on augente le compte
          if (this.appConfig.DEBUG) {
            console.log('ComboService:checkNonConsecutiveCombo flags match !');
          }
          if (
            (this.isActive && combo.comboType === 2) ||
            this.isActive === false
          ) {
            if (this.comboCount[combo.comboID] !== undefined) {
              this.comboCount[combo.comboID] += 1;
            } else {
              this.comboCount[combo.comboID] = 1;
            }
          }
        }
      }
    }

    if (this.appConfig.DEBUG) {
      console.log(
        'ComboService:checkNonConsecutiveCombo count ' +
          JSON.stringify(this.comboCount, null, 4)
      );
    }
    // if (this.comboCount[combo.comboName + '_' + combo.type] === combo.total) {
    if (this.comboCount[combo.comboID] === combo.total) {
      // MATCH !
      // console.log(
      //   '!!!! ComboService:checkNonConsecutiveCombo MATCH ' + combo.comboName
      // );
      this.matched.push(combo);
      // if (combo.comboType === 2) {
      this.totalMatched++;
      // }
      if (combo.comboType === 1) {
        this.isActive = true;
        this.currentMultiplier = combo.multiplier;
      }
      if (this.appConfig.DEBUG) {
        console.log(
          '%cComboService:checkNonConsecutiveCombo MATCH ' + combo.comboName,
          'color:white; background-color:green; font-size:14px; padding:100px;padding-top: 10px;padding-bottom:10px;'
        );
      }

      this.$gaService.event(
        'Combo NonConsecutive Unlocked',
        'Events',
        combo.comboName
      );

      this.progressService.saveAnswer(
        this.answersType.COMBO,
        combo.hash,
        1,
        combo.comboName,
        combo.position,
        combo.multiplier,
        combo.comboType
      );
      this.updateScore(combo);

      if (combo.comboType === 2) {
        combo.active = 0;
      } else {
        if (this.appConfig.DEBUG) {
          console.log(
            'ComboService:checkNonConsecutiveCombo reset count of ' +
              combo.comboID
          );
        }
        this.comboCount[combo.comboID] = 0;
        this.filterOut(combo.comboID);
      }

      return true;
    } else {
      return false;
    }
  }

  updateScore(combo: Combo): void {
    this.progressService.computeScore();
    this.jarvisService.updateScore(this.progressService.score);
    this.dashboardService.updateScore(this.progressService.score);
    this.dashboardService.updateProgress();
    this.dashboardService.unlockBonus(combo);
  }

  checkFlag(combo: Combo, buffer: ComboBuffer): boolean {
    if (combo.flag === -1) {
      return true;
    }
    if (combo.flag === buffer.flag) {
      return true;
    }
    return false;
  }

  checkType(combo: Combo, buffer: ComboBuffer): boolean {
    if (combo.type === -1) {
      return true;
    }
    if (combo.type === buffer.type) {
      return true;
    }
    return false;
  }

  showCombo(gameLoopResolve): void {
    // if (this.totalMatched > 6) {
    //   gameResolve(true)
    //   return
    // }
    if (this.appConfig.DEBUG) {
      console.log(
        '%cComboService:showCombo TOTAL MATCHED : ' + this.totalMatched,
        'color:white; background-color:sandybrown; font-size:14px; padding:100px;padding-top: 10px;padding-bottom:10px;'
      );
    }
    this.emitEvent(new ComboEvent(this.SHOW, this.matched, gameLoopResolve));
    this.matched = [];
    if (this.appConfig.DEBUG) {
      console.log(
        'ComboService:showCombo combosBuffer ' +
          JSON.stringify(this.combosBuffer, null, 4)
      );
      console.log(
        'ComboService:showCombo popCornTimeBuffer ' +
          JSON.stringify(this.popCornTimeBuffer, null, 4)
      );
    }
    // this.combosBuffer = [];
  }

  startPopCornTime(bonus: Combo): void {
    this.isActive = true;
    this.currentMultiplier = bonus.multiplier;
    if (this.appConfig.GA_STATS) {
      this.$gaService.event(
        'Start PopCornTime',
        'Events',
        'PopCornTime x' + this.currentMultiplier
      );
    }
    this.emitEvent(new ComboEvent(this.STARTPOPCORNTIME, [bonus]));
  }
  stopPopCornTime(): void {
    if (this.isActive) {
      this.emitEvent(new ComboEvent(this.ENDPOPCORNTIME, null));

      this.progressService.onGodMode = false;
      this.isActive = false;
      if (this.appConfig.GA_STATS) {
        this.$gaService.event(
          'Stop PopCornTime',
          'Events',
          'PopCornTime x' + this.currentMultiplier
        );
      }
    }
    this.currentMultiplier = 1;
  }

  getDescription(comboId: string): any {
    // tslint:disable-next-line: prefer-for-of
    for (let index = 0; index < this.combosData.length; index++) {
      const combo = this.combosData[index];
      if (combo.comboID === comboId) {
        if (this.appConfig.DEBUG) {
          console.log('ComboService:getDescription ' + combo.comboName);
        }
        return combo.description;
      }
    }
  }

  // godMode(): void {
  //   if (this.appConfig.DEBUG) {
  //     console.log('ComboService:godMode')
  //   }

  //   if (this.appConfig.GA_STATS) {
  //     this.$gaService.event('God Mode Unlocked', 'Events', 'Invincible')
  //   }
  //   let godModeCombo = new Combo()
  //   // godModeCombo.comboName = 'God Mode';
  //   // godModeCombo.comboID = 'godmode';
  //   // godModeCombo.comboType = 66;
  //   // godModeCombo.duration = 66;
  //   // godModeCombo.multiplier = 1;
  //   // godModeCombo.hash =
  //   //   '5421add38e69038c4351ca16419b78c805329597b4ab84c914d2d27a5d757dcc';

  //   // tslint:disable-next-line: prefer-for-of
  //   for (let index = 0; index < this.combosData.length; index++) {
  //     const combo = this.combosData[index]
  //     if (combo.comboID === 'godmode') {
  //       godModeCombo = combo
  //     }
  //   }
  //   this.progressService.saveAnswer(
  //     this.answersType.COMBO,
  //     godModeCombo.hash,
  //     1,
  //     godModeCombo.comboName,
  //     0,
  //     1,
  //     godModeCombo.comboType
  //   )
  //   this.progressService.onGodMode = true
  //   this.progressService.isGodModeUsed = true
  //   this.emitEvent(new ComboEvent(this.KAZUHISA, [godModeCombo]))
  // }

  // cheatCode(): void {
  //   if (this.appConfig.GA_STATS) {
  //     this.$gaService.event('Cheat Code Unlocked', 'Events', 'Cheat Code')
  //   }
  //   // let cheatCodeCombo = new Combo();

  //   // tslint:disable-next-line: prefer-for-of
  //   for (let index = 0; index < this.combosData.length; index++) {
  //     const combo = this.combosData[index]
  //     if (combo.comboID === 'cheatcode') {
  //       const cheatCodeCombo = combo
  //       this.progressService.saveAnswer(
  //         this.answersType.COMBO,
  //         cheatCodeCombo.hash,
  //         1,
  //         cheatCodeCombo.comboName,
  //         0,
  //         this.currentMultiplier,
  //         cheatCodeCombo.comboType
  //       )
  //       this.updateScore(cheatCodeCombo)

  //       this.emitEvent(new ComboEvent(this.CHEATCODE, [cheatCodeCombo]))
  //     }
  //   }
  // }

  filterOut(comboId: string, type = 'combos'): void {
    // console.log('ComboService:filterOut comboId ' + comboId)
    // const filtered = this.combosBuffer.filter((value, index, arr) => {
    //   return value.comboID !== comboId;
    // });
    if (type === 'combos') {
      const tmp = [];
      for (const i in this.combosBuffer) {
        if (this.combosBuffer[i].comboID === comboId) {
          this.combosBuffer[i].comboID = 'done';
        } else {
          tmp.push(this.combosBuffer[i]);
        }
      }
      this.combosBuffer = tmp;
      // console.log(
      //   'ComboService:filterOut  combosBuffer ' +
      //     JSON.stringify(this.combosBuffer, null, 4)
      // )
    }
    if (type === 'popcorntime') {
      for (const i in this.popCornTimeBuffer) {
        if (this.popCornTimeBuffer[i].comboID === comboId) {
          this.popCornTimeBuffer[i].comboID = 'done';
        }
      }
      // console.log(
      //   'ComboService:filterOut  popCornTimeBuffer ' +
      //     JSON.stringify(this.popCornTimeBuffer, null, 4)
      // )
    }
  }

  public get multiplier(): number {
    return this.currentMultiplier;
  }

  public set multiplier(v: number) {
    this.currentMultiplier = v;
  }

  public get total(): number {
    return this.totalMatched;
  }
  public set total(num: number) {
    this.totalMatched = num;
  }

  public get active(): boolean {
    return this.isActive;
  }

  public set active(v: boolean) {
    this.isActive = v;
  }
}
