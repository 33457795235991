/*
 * @Author: Priska @ PopCorn 66
 * @Date: 2019-08-17 19:44:50
 * @Last Modified by: Priska
 * @Last Modified time: 2021-11-15 00:42:59
 */
import { Component, OnInit, Inject, AfterViewInit } from '@angular/core'
import { Stage, Container, Shadow, Shape } from 'createjs-module'
import { gsap, Sine, Expo } from 'gsap'
import * as createjs from 'createjs-module'

import * as $ from 'jquery'
import { CursorService } from '../../services/view/cursor.service'
import * as MobileDetect from 'mobile-detect'
import { AppConfigService } from 'src/app/services/config/app-config.service'

@Component({
  selector: 'cursor',
  templateUrl: './cursor.component.html',
  styleUrls: ['./cursor.component.scss']
})
export class CursorComponent implements AfterViewInit {
  private mainStage: Stage
  private domElement: Document
  private container: Container
  private cross: Container
  private cursor: Shape
  private eye: Shape
  private loader: Shape
  private outer: Shape
  private resizeTimer: any
  private mouseX: number
  private mouseY: number
  private onResizeBound: () => {}
  private onMouseBound: () => {}
  private visible = true

  xPos = 0
  yPos = 0
  dX = 0
  dY = 0

  CURSOR_SIZE = 10
  CURSOR_SPEED = 1.8
  CURSOR_STROKE = 2

  private cursorState = {
    NORMAL: 'NORMAL',
    DISABLE: 'DISABLE',
    WAITFORCLICK: 'WAITFORCLICK',
    LOADING: 'LOADING',
    PAN: 'PAN',
    CLOSE: 'CLOSE'
  }

  currentMode = this.cursorState.NORMAL
  modeHistory = []
  tutoPan = 1
  cursorRadius = {
    value: this.CURSOR_SIZE
  }

  constructor(
    private cursorService: CursorService,
    private appConfig: AppConfigService
  ) {
    ;(window as any).createjs = createjs
  }

  ngAfterViewInit() {
    const mobile = new MobileDetect(window.navigator.userAgent)

    if (mobile.mobile() === null && mobile.tablet() === null) {
      if (this.appConfig.DEBUG) {
        console.log('CursorComponent ngAfterViewInit')
      }
      this.mainStage = new Stage('cursorCanvas')
      this.domElement = document

      this.container = new Container()
      this.container.name = 'cursorContainer'
      this.mainStage.addChild(this.container)
      this.container.shadow = new Shadow('rgba(0, 0, 0, 0.35)', 0, 0, 15)

      this.cursor = new Shape()
      this.cursor.graphics
        .beginStroke('#fff')
        .setStrokeStyle(this.CURSOR_STROKE)
        .drawCircle(0, 0, this.CURSOR_SIZE)
      this.container.addChild(this.cursor)

      this.eye = new Shape()
      this.eye.graphics.beginFill('#fff').drawCircle(0, 0, 2)
      // this.container.addChild(this.eye);

      this.loader = new Shape()
      this.loader.graphics
        .beginStroke('#fff')
        .setStrokeStyle(this.CURSOR_STROKE)
        .arc(0, 0, this.CURSOR_SIZE + 4, -Math.PI / 2, 0, false)
      this.container.addChild(this.loader)
      this.loader.alpha = 0

      this.outer = new Shape()
      this.outer.graphics
        .beginStroke('#fff')
        .setStrokeStyle(this.CURSOR_STROKE)
        .drawCircle(0, 0, this.CURSOR_SIZE)
      this.outer.alpha = 0
      this.container.addChild(this.outer)

      this.mouseX = window.innerWidth / 2
      this.mouseY = window.innerHeight / 2

      this.cross = new Container()
      let line1 = new createjs.Shape()
      line1.graphics.beginFill('#fff').drawRect(0, 0, this.CURSOR_SIZE * 2, 2)
      this.cross.addChild(line1)
      line1.regX = this.CURSOR_SIZE
      line1.regY = 1
      line1.rotation = 45
      let line2 = new createjs.Shape()
      line2.graphics.beginFill('#fff').drawRect(0, 0, this.CURSOR_SIZE * 2, 2)
      this.cross.addChild(line2)
      line2.regX = this.CURSOR_SIZE
      line2.regY = 1
      line2.rotation = -45
      this.container.addChild(this.cross)
      this.cross.alpha = 0
      this.cross.scaleX = this.cross.scaleY = 0

      // $(window).on('resize', () => {
      //   clearTimeout(this.resizeTimer)
      //   this.resizeTimer = setTimeout(this._onCanvasResize, 10)
      // })
      window.addEventListener('resize', () => {
        clearTimeout(this.resizeTimer)
        this.resizeTimer = setTimeout(() => {
          this._onCanvasResize()
        }, 10)
      })
      this.container.alpha = 0

      this._onCanvasResize()

      this.container.x = this.xPos = this.mouseX
      this.container.y = this.yPos = this.mouseY

      this.onResizeBound = this.show.bind(this)
      this.domElement.addEventListener('mousemove', this.onResizeBound, false)

      this._animate()
    }
  }

  /**
   * Affiche le curseur au premier mouvement de la souris
   */
  show() {
    this.domElement.removeEventListener('mousemove', this.onResizeBound)
    this.onResizeBound = this._onMouseMove.bind(this)
    this.domElement.addEventListener('mousemove', this.onResizeBound, false)

    this.container.x = this.mouseX
    this.container.y = this.mouseY
    this.xPos = this.mouseX
    this.yPos = this.mouseY

    gsap.to(this.container, {
      duration: 0.3,
      alpha: 1,
      ease: 'sine.out',
      onComplete: () => {
        this._initEventListener()
      }
    })
  }

  /**
   * Resize le cursorCanvas à chaque resize de la fenetre du navigateur
   */
  private _onCanvasResize() {
    if (this.appConfig.DEBUG) {
      console.log('CursorComponent _onCanvasResize')
    }
    const c = $('#cursorCanvas')
    const retina = 1

    c.attr('width', window.innerWidth * retina)
    c.attr('height', window.innerHeight * retina)
    // const canvas = <HTMLCanvasElement>document.getElementById("cursorCanvas");
    // let context = canvas.getContext("2d");
    // context.scale(retina, retina);

    // const canvas = document.getElementById("output");
    // canvas.width = 1000;
    // canvas.height = 750;
    // canvas.style.width = "500px";
    // canvas.style.height = "375px";
  }

  /**
   * Calcul la position du curseur à chaque raffraichissement de la view
   */
  private _handleTick() {
    this.dX = this.mouseX - this.xPos
    this.dY = this.mouseY - this.yPos

    this.xPos += this.dX / this.CURSOR_SPEED
    this.yPos += this.dY / this.CURSOR_SPEED

    this.container.x = this.xPos
    this.container.y = this.yPos

    this.mainStage.update()
  }

  private _animate() {
    requestAnimationFrame(this._animate.bind(this))
    this._handleTick()
  }

  /**
   * Stock les valeurs clientX, clientY => mouseX, mouseY
   * @param event evenement DOM souris
   */
  private _onMouseMove(event) {
    this.mouseX = event.clientX
    this.mouseY = event.clientY
  }

  /**
   * Creation du listener d'event
   */
  private _initEventListener() {
    this.domElement.addEventListener(
      'mousedown',
      this._onMouseDown.bind(this),
      false
    )
    this.cursorService.emitter$.subscribe((event) => this._eventDispatch(event))
  }

  /**
   * Commutateur event -> fonction
   * @param event CursorEvent
   */
  private _eventDispatch(event) {
    // if (this.appConfig.DEBUG) {
    //   console.log('CursorComponent:_eventDispatch: ' + event.name)
    // }
    switch (event.name) {
      case this.cursorService.SHOW:
        this._show()
        break
      case this.cursorService.HIDE:
        this._hide()
        break
      case this.cursorService.ROLLOVER:
        this._hide()
        break
      case this.cursorService.ROLLOUT:
        this._show()
        break
      case this.cursorService.LOADING:
        this._loadingMode(event)
        break
      case this.cursorService.WAIT:
        this._waitForClickMode(event)
        break
      case this.cursorService.NORMAL:
        this._normalMode(event)
        break
      case this.cursorService.CLOSE:
        this._closeMode(event)
        break

      default:
        break
    }
  }
  /**
   * Gestion de l'event mousedown
   *
   * @param event DOM event
   */
  private _onMouseDown(event) {
    if (this.currentMode !== this.cursorState.LOADING) {
      if (this.visible) {
        this._onMouseMove(event)
        if (event.which === 1) {
          gsap.killTweensOf(this.cursorRadius)
          gsap.to(this.cursorRadius, {
            duration: 0.3,
            value: this.CURSOR_SIZE * 2,
            ease: 'expo.out',
            onUpdate: (i, target, targets) => {
              this.cursor.graphics
                .clear()
                .beginStroke('#fff')
                .setStrokeStyle(this.CURSOR_STROKE)
                .drawCircle(0, 0, this.cursorRadius.value)
            }
          })

          // gsap.killTweensOf(this.cursor);
          // gsap.to(this.cursor, 0.3, {alpha:1, scaleX:2, scaleY:2, ease:Expo.easeOut});

          gsap.killTweensOf(this.eye)
          gsap.to(this.eye, {
            duration: 0.3,
            alpha: 0,
            scaleX: 0,
            scaleY: 0,
            ease: 'expo.out'
          })

          if (this.currentMode === this.cursorState.WAITFORCLICK) {
            gsap.killTweensOf(this.outer)
            gsap.to(this.outer, {
              duration: 0.3,
              scaleX: 2,
              scaleY: 2,
              alpha: 0,
              ease: 'sine.out',
              onComplete: () => {
                this.outer.alpha = 0
              }
            })
          }
          // if (this.currentMode === this.cursorState.PAN) {
          //   gsap.killTweensOf(this.arrow1);
          //   gsap.to(this.arrow1, 0.3, {
          //     x: CURSOR_SIZE * 2 + 5,
          //     alpha: 1,
          //     ease: Back.easeOut
          //   });
          //   gsap.killTweensOf(this.arrow2);
          //   gsap.to(this.arrow2, 0.3, {
          //     y: CURSOR_SIZE * 2 + 5,
          //     alpha: 1,
          //     ease: Back.easeOut
          //   });
          //   gsap.killTweensOf(this.arrow3);
          //   gsap.to(this.arrow3, 0.3, {
          //     x: -CURSOR_SIZE * 2 - 5,
          //     alpha: 1,
          //     ease: Back.easeOut
          //   });
          //   gsap.killTweensOf(this.arrow4);
          //   TwegsapenMax.to(this.arrow4, 0.3, {
          //     y: -CURSOR_SIZE * 2 - 5,
          //     alpha: 1,
          //     ease: Back.easeOut
          //   });
          // }

          this.onMouseBound = this._onMouseUp.bind(this)
          this.domElement.addEventListener('mouseup', this.onMouseBound, false)
        }
      }
    }
  }

  /**
   * Gestion de l'event mouseup
   * @param event DOM event
   */
  private _onMouseUp(event) {
    if (this.visible) {
      this._onMouseMove(event)

      this.domElement.removeEventListener('mouseup', this.onMouseBound)

      if (this.currentMode === this.cursorState.CLOSE) {
        gsap.killTweensOf(this.cursor)
        gsap.to(this.cursor, {
          duration: 0.3,
          alpha: 1,
          ease: 'expo.out'
        })

        gsap.killTweensOf(this.cursorRadius)
        gsap.to(this.cursorRadius, {
          duration: 0.3,
          value: this.CURSOR_SIZE * 1.5,
          ease: 'expo.out',
          onUpdate: (i, target, targets) => {
            this.cursor.graphics
              .clear()
              .beginStroke('#fff')
              .setStrokeStyle(this.CURSOR_STROKE)
              .drawCircle(0, 0, this.cursorRadius.value)
          }
        })
        gsap.killTweensOf(this.eye)
        gsap.to(this.eye, {
          duration: 0.3,
          alpha: 0,
          scaleX: 0,
          scaleY: 0,
          ease: 'expo.out'
        })
      } else {
        gsap.killTweensOf(this.cursor)
        gsap.to(this.cursor, {
          duration: 0.3,
          alpha: 1,
          ease: 'expo.out'
        })

        gsap.killTweensOf(this.cursorRadius)
        gsap.to(this.cursorRadius, {
          duration: 0.3,
          value: this.CURSOR_SIZE,
          ease: 'expo.out',
          onUpdate: (i, target, targets) => {
            this.cursor.graphics
              .clear()
              .beginStroke('#fff')
              .setStrokeStyle(this.CURSOR_STROKE)
              .drawCircle(0, 0, this.cursorRadius.value)
          }
        })

        gsap.killTweensOf(this.eye)
        gsap.to(this.eye, {
          duration: 0.3,
          alpha: 1,
          scaleX: 1,
          scaleY: 1,
          ease: 'expo.out'
        })
      }
      if (this.currentMode === this.cursorState.WAITFORCLICK) {
        gsap.killTweensOf(this.outer)
        gsap.to(this.outer, {
          duration: 0.3,
          scaleX: 1,
          scaleY: 1,
          alpha: 0,
          ease: 'sine.out'
        })
      }
      // if (this.currentMode === cursorState.PAN) {
      //   gsap.killTweensOf(this.arrow1);
      //   gsap.to(this.arrow1, 0.3, {
      //     x: CURSOR_SIZE + 5,
      //     alpha: 0.5 * _tutoPan,
      //     ease: Sine.easeOut
      //   });
      //   gsap.killTweensOf(this.arrow2);
      //   gsap.to(this.arrow2, 0.3, {
      //     y: CURSOR_SIZE + 5,
      //     alpha: 0.5 * _tutoPan,
      //     ease: Sine.easeOut
      //   });
      //   gsap.killTweensOf(this.arrow3);
      //   gsap.to(this.arrow3, 0.3, {
      //     x: -CURSOR_SIZE - 5,
      //     alpha: 0.5 * _tutoPan,
      //     ease: Sine.easeOut
      //   });
      //   gsap.killTweensOf(this.arrow4);
      //   gsap.to(this.arrow4, 0.3, {
      //     y: -CURSOR_SIZE - 5,
      //     alpha: 0.5 * _tutoPan,
      //     ease: Sine.easeOut
      //   });
      // }
    }
  }
  private _show() {
    // console.log('CursorComponent::_show');
    this.visible = true
    switch (this.currentMode) {
      case this.cursorState.WAITFORCLICK:
        gsap.killTweensOf(this.cursor)
        gsap.to(this.cursor, {
          duration: 0.3,
          alpha: 1,
          ease: 'expo.out'
        })
        gsap.killTweensOf(this.cursorRadius)
        gsap.to(this.cursorRadius, {
          duration: 0.3,
          value: this.CURSOR_SIZE,
          ease: 'sine.out',
          onUpdate: (i, target, targets) => {
            this.cursor.graphics
              .clear()
              .beginStroke('#fff')
              .setStrokeStyle(this.CURSOR_STROKE)
              .drawCircle(0, 0, this.cursorRadius.value)
          },
          onComplete: () => {
            gsap.killTweensOf(this.outer)
            this.outer.alpha = 1
            this.outer.scaleX = 1
            this.outer.scaleY = 1
            gsap.to(this.outer, {
              duration: 1,
              scaleX: 2,
              scaleY: 2,
              alpha: 0,
              ease: 'sine.out',
              repeat: -1
            })
          }
        })
        gsap.killTweensOf(this.cursor)
        gsap.to(this.cursor, {
          duration: 0.3,
          alpha: 1,
          ease: 'sine.out'
        })
        gsap.killTweensOf(this.eye)
        gsap.to(this.eye, {
          duration: 0.3,
          alpha: 0,
          scaleX: 0,
          scaleY: 0,
          ease: 'expo.out'
        })
        break
      case this.cursorState.CLOSE:
        gsap.killTweensOf(this.cross)
        gsap.to(this.cross, {
          duration: 0.3,
          alpha: 1,
          scaleX: 1,
          scaleY: 1,
          ease: 'expo.out'
        })

        gsap.killTweensOf(this.cursor)
        gsap.to(this.cursor, {
          duration: 0.3,
          alpha: 1,
          ease: 'sine.out'
        })
        gsap.killTweensOf(this.cursorRadius)
        gsap.to(this.cursorRadius, {
          duration: 0.3,
          value: this.CURSOR_SIZE * 1.6,
          ease: 'sine.out',
          onUpdate: (i, target, targets) => {
            this.cursor.graphics
              .clear()
              .beginStroke('#fff')
              .setStrokeStyle(this.CURSOR_STROKE)
              .drawCircle(0, 0, this.cursorRadius.value)
          }
        })
        break

      default:
        gsap.killTweensOf(this.eye)
        gsap.to(this.eye, {
          duration: 0.3,
          alpha: 1,
          scaleX: 1,
          scaleY: 1,
          ease: 'expo.out'
        })
        gsap.killTweensOf(this.cursor)
        gsap.to(this.cursor, { duration: 0.3, alpha: 1, ease: 'expo.out' })
        gsap.killTweensOf(this.cursorRadius)
        gsap.to(this.cursorRadius, {
          duration: 0.3,
          value: this.CURSOR_SIZE,
          ease: 'expo.out',
          onUpdate: (i, target, targets) => {
            this.cursor.graphics
              .clear()
              .beginStroke('#fff')
              .setStrokeStyle(this.CURSOR_STROKE)
              .drawCircle(0, 0, this.cursorRadius.value)
          }
        })
        break
    }
  }
  private _hide() {
    // console.log('CursorComponent::_hide');
    this.visible = false

    if (this.currentMode === this.cursorState.LOADING) {
      return
    }

    // if (this.currentMode === cursorState.CLOSE) {
    //   gsap.killTweensOf(this.cross);
    //   gsap.to(this.cross, 0.3, {
    //     alpha: 0,
    //     scaleX: 0,
    //     scaleY: 0,
    //     ease: Expo.easeOut
    //   });
    // }
    // if (this.currentMode === cursorState.PAN) {
    //   gsap.killTweensOf(this.arrows);
    //   gsap.to(this.arrows, 0.3, { alpha: 0, ease: Expo.easeOut });
    // }
    if (this.currentMode === this.cursorState.WAITFORCLICK) {
      gsap.killTweensOf(this.outer)
      gsap.to(this.outer, { duration: 0.3, alpha: 0, ease: 'expo.out' })
    }

    gsap.killTweensOf(this.cursor)
    gsap.to(this.cursor, { duration: 0.5, alpha: 0, ease: 'expo.out' })

    gsap.killTweensOf(this.cursorRadius)
    gsap.to(this.cursorRadius, {
      duration: 0.5,
      value: this.CURSOR_SIZE * 2.5,
      ease: 'expo.out',
      onUpdate: (i, target, targets) => {
        this.cursor.graphics
          .clear()
          .beginStroke('#fff')
          .setStrokeStyle(this.CURSOR_STROKE)
          .drawCircle(0, 0, this.cursorRadius.value)
      }
    })

    gsap.killTweensOf(this.eye)
    gsap.to(this.eye, {
      duration: 0.3,
      alpha: 0,
      scaleX: 0,
      scaleY: 0,
      ease: 'expo.out'
    })

    gsap.killTweensOf(this.cross)
    gsap.to(this.cross, {
      duration: 0.3,
      alpha: 0,
      scaleX: 0,
      scaleY: 0,
      ease: 'expo.out'
    })
  }

  _normalMode(event, save = true) {
    this._transitionTo(this.cursorState.NORMAL, save)

    if (this.visible) {
      gsap.killTweensOf(this.cursor)
      gsap.to(this.cursor, { duration: 0.3, alpha: 1, ease: 'sine.out' })
      gsap.killTweensOf(this.eye)
      gsap.to(this.eye, {
        duration: 0.3,
        alpha: 1,
        scaleX: 1,
        scaleY: 1,
        ease: 'expo.out'
      })
    }
    gsap.killTweensOf(this.cursorRadius)
    gsap.to(this.cursorRadius, {
      duration: 0.3,
      value: this.CURSOR_SIZE,
      ease: 'sine.out',
      onUpdate: (i, target, targets) => {
        this.cursor.graphics
          .clear()
          .beginStroke('#fff')
          .setStrokeStyle(this.CURSOR_STROKE)
          .drawCircle(0, 0, this.cursorRadius.value)
      }
    })
  }

  _closeMode(event, save = true) {
    this._transitionTo(this.cursorState.CLOSE, true)
    gsap.killTweensOf(this.cross)
    gsap.to(this.cross, {
      duration: 0.3,
      alpha: 1,
      scaleX: 1,
      scaleY: 1,
      ease: 'expo.out'
    })

    gsap.killTweensOf(this.cursor)
    gsap.to(this.cursor, {
      duration: 0.3,
      alpha: 1,
      ease: 'sine.out'
    })
    gsap.killTweensOf(this.cursorRadius)
    gsap.to(this.cursorRadius, {
      duration: 0.3,
      value: this.CURSOR_SIZE * 1.6,
      ease: 'sine.out',
      onUpdate: (i, target, targets) => {
        this.cursor.graphics
          .clear()
          .beginStroke('#fff')
          .setStrokeStyle(this.CURSOR_STROKE)
          .drawCircle(0, 0, this.cursorRadius.value)
      }
    })
  }

  _loadingMode(event, block = false) {
    this._transitionTo(this.cursorState.LOADING, true)

    if (block) {
      $(document).css('pointer-events', 'none')
    }

    gsap.killTweensOf(this.eye)
    gsap.to(this.eye, {
      duration: 0.3,
      alpha: 0,
      scaleX: 0,
      scaleY: 0,
      ease: 'expo.out'
    })

    gsap.killTweensOf(this.loader)
    gsap.to(this.loader, {
      duration: 0.3,
      alpha: 1,
      rotation: 0,
      ease: 'expo.out',
      onComplete: () => {
        gsap.to(this.loader, {
          duration: 1,
          rotation: 360,
          ease: 'sine.out',
          repeat: -1
        })
      }
    })

    gsap.killTweensOf(this.cursor)
    gsap.to(this.cursor, { duration: 0.3, alpha: 1, ease: 'expo.out' })
    gsap.killTweensOf(this.cursorRadius)
    gsap.to(this.cursorRadius, {
      duration: 0.3,
      value: this.CURSOR_SIZE,
      ease: 'sine.out',
      onUpdate: (i, target, targets) => {
        this.cursor.graphics
          .clear()
          .beginStroke('#fff')
          .setStrokeStyle(this.CURSOR_STROKE)
          .drawCircle(0, 0, this.cursorRadius.value)
      }
    })
  }
  _waitForClickMode(event, save = true) {
    this._transitionTo(this.cursorState.WAITFORCLICK, save)

    gsap.killTweensOf(this.eye)
    gsap.to(this.eye, {
      duration: 0.3,
      alpha: 0,
      scaleX: 0,
      scaleY: 0,
      ease: 'expo.out'
    })

    gsap.killTweensOf(this.cursor)
    gsap.to(this.cursor, { duration: 0.3, alpha: 1, ease: 'sine.out' })
    gsap.killTweensOf(this.cursorRadius)
    gsap.to(this.cursorRadius, {
      duration: 0.3,
      value: this.CURSOR_SIZE,
      ease: 'sine.out',
      onUpdate: (i, target, targets) => {
        this.cursor.graphics
          .clear()
          .beginStroke('#fff')
          .setStrokeStyle(this.CURSOR_STROKE)
          .drawCircle(0, 0, this.cursorRadius.value)
      },
      onComplete: () => {
        gsap.killTweensOf(this.outer)
        this.outer.alpha = 1
        this.outer.scaleX = 1
        this.outer.scaleY = 1
        gsap.to(this.outer, {
          duration: 1,
          scaleX: 2,
          scaleY: 2,
          alpha: 0,
          ease: 'sine.out',
          repeat: -1
        })
      }
    })
  }

  _transitionTo(targetMode, save) {
    this.visible = true
    if (save === undefined) {
      save = true
    }
    if (save) {
      if (targetMode === this.cursorState.PAN) {
        // var i = this.modeHistory.length;
        if (this.currentMode !== this.cursorState.PAN) {
          this.modeHistory.push(this.currentMode) // on conserve le mode courant pour l'empilement des modales
        } else {
          return
        }
      } else {
        this.modeHistory.push(this.currentMode) // on conserve le mode courant pour l'empilement des modales
      }
      if (this.modeHistory.length > 5) {
        // max history size
        this.modeHistory.shift()
      }
    }
    this.currentMode = targetMode
    $(document).css('pointer-events', 'auto')

    gsap.killTweensOf(this.cross)
    gsap.to(this.cross, {
      duration: 0.3,
      alpha: 0,
      scaleX: 0,
      scaleY: 0,
      ease: 'expo.out'
    })

    // // loading mode off
    gsap.killTweensOf(this.loader)
    gsap.to(this.loader, {
      duration: 0.3,
      alpha: 0,
      ease: 'expo.out',
      onComplete: () => {
        this.loader.rotation = 0
      }
    })

    gsap.killTweensOf(this.eye)
    gsap.to(this.eye, {
      duration: 0.3,
      alpha: 0,
      scaleX: 0,
      scaleY: 0,
      ease: 'expo.out'
    })

    // disbale mode off
    // gsap.killTweensOf(this.disableSign);
    // gsap.to(this.disableSign, 0.3, {
    //   alpha: 0,
    //   rotation: 0,
    //   ease: Expo.easeOut
    // });
    // WAITFORCLICK mode off
    gsap.killTweensOf(this.outer)
    gsap.to(this.outer, {
      duration: 0.3,
      scaleX: 2,
      scaleY: 2,
      alpha: 0,
      ease: 'sine.out',
      onComplete: () => {
        this.outer.alpha = 0
      }
    })

    // disable PAN mode

    // gsap.killTweensOf(this.arrows);
    // gsap.to(this.arrows, 0.3, { alpha: 0, ease: Sine.easeOut });
    // gsap.killTweensOf(this.arrow1);
    // gsap.to(this.arrow1, 0.3, {
    //   x: this.CURSOR_SIZE + 5,
    //   alpha: 0.5 * _tutoPan,
    //   ease: Sine.easeOut
    // });
    // gsap.killTweensOf(this.arrow2);
    // gsap.to(this.arrow2, 0.3, {
    //   y: this.CURSOR_SIZE + 5,
    //   alpha: 0.5 * _tutoPan,
    //   ease: Sine.easeOut
    // });
    // gsap.killTweensOf(this.arrow3);
    // gsap.to(this.arrow3, 0.3, {
    //   x: -this.CURSOR_SIZE - 5,
    //   alpha: 0.5 * _tutoPan,
    //   ease: Sine.easeOut
    // });
    // gsap.killTweensOf(this.arrow4);
    // gsap.to(this.arrow4, 0.3, {
    //   y: -this.CURSOR_SIZE - 5,
    //   alpha: 0.5 * _tutoPan,
    //   ease: Sine.easeOut
    // });
  }
}
